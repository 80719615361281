import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  Renderer2,
  EventEmitter,
  Output,
} from '@angular/core';
import { UtilsService } from '../../services';

@Component({
  selector: 'card-comp',
  templateUrl: 'card.html',
  styleUrls: ['card.scss'],
})
export class CardComponent implements OnInit, OnChanges {
  @Input() compOptions: any;
  @Output() cardClicked = new EventEmitter<any>();
  @ViewChild('evsCard', { static: true }) evsCard: any;

  iconPath = './uikit-assets/assets/icons/orion-svg-sprite.svg#';

  debug = false;
  canClick = true;

  id: any;
  nested = false;
  locked = false;
  width: any;
  height: any;
  type: any;
  safariFix: any;

  constructor(
    private utils: UtilsService,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.debug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.debug;
    this.id =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.id)
        ? this.compOptions.id
        : this.id;
    this.nested =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.nested)
        ? this.compOptions.nested
        : this.nested;
    this.locked =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.locked)
        ? this.compOptions.locked
        : this.locked;
    this.width =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.width)
        ? this.compOptions.width
        : this.width;
    this.height =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.height)
        ? this.compOptions.height
        : this.height;
    this.type =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.type)
        ? this.compOptions.type
        : this.type;
    this.canClick =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.canClick)
        ? this.compOptions.canClick
        : this.canClick;
    this.safariFix =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.safariFix)
        ? this.compOptions.safariFix
        : this.safariFix;

    this.canClick = !!this.id ? this.canClick : false;

    if (!!this.width) {
      this.renderer.setStyle(
        this.evsCard.nativeElement,
        'width',
        this.width + 'px'
      );
    }
    if (!!this.height) {
      this.renderer.setStyle(
        this.evsCard.nativeElement,
        'height',
        this.height + 'px'
      );
    }
  }

  handleClick() {
    if (this.canClick) {
      this.cardClicked.emit(this.id);
    }
  }
}
