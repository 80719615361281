import {
  OnInit,
  AfterViewInit,
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core';

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { UtilsService } from '@commons/services';

@Component({
  selector: 'autocomplete-local-comp',
  templateUrl: 'autocomplete-local.html',
  styleUrls: ['autocomplete-local.scss'],
})
export class AutocompleteLocalComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() compOptions: any;
  @ViewChild('autoComplete') autoComplete: any;
  @ViewChild('resultOptions') resultOptions: any;
  @ViewChild('searchInput') searchInput: any;
  @Output() inputSelected = new EventEmitter<any>();

  iconPath = './../assets/icons/orion-svg-sprite.svg#';
  isDebug = false;
  type = 'autocomplete'; // autocomplete / select
  id = 'autocomplete_field' + Date.now();
  required = true;
  service = null;
  serviceFields = null;
  fieldName = null;
  fieldOutput = null;
  options = [];
  cleanedChildren = [];
  hasResults = false;
  hasSelected = false;
  hasNotMatch = false;
  forceClose = false;
  clickInside = false;
  keyboardIndex = null;
  componentBlocked = false;
  inputValue = '';
  prefillDone = false;
  prefillCallServerLaunched = false;
  placeholder = '';
  shiftActive = false;
  selectData = [];
  selectedSelect = null;

  acForm: UntypedFormGroup;

  get getACForm() {
    return this.acForm.controls;
  }

  constructor(private utils: UtilsService) {}

  ngOnChanges() {
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  ngAfterViewInit() {
    if (
      this.utils.isDefined(this.autoComplete) &&
      this.utils.isDefined(this.autoComplete.nativeElement)
    ) {
      if (this.utils.isDefined(this.id)) {
        this.autoComplete.nativeElement.id = this.id;
      }
    }
  }

  resetVars() {
    // reset
    this.keyboardIndex = null;
    this.options = [];
    this.forceClose = false;
    this.hasNotMatch = false;
    this.hasResults = this.options.length > 0 ? true : false;
  }

  // re-open results when user keydown on input
  keyDown(event) {
    if (this.hasSelected) {
      event.preventDefault();
      return;
    }
    this.forceClose = false;
    this.hasNotMatch = false;

    // if (this.acForm.controls['search'].value !== null) {
    //   if (this.acForm.controls['search'].value.trim() === '') {
    //     this.inputSelected.emit([]);
    //   }
    // }
  }

  @HostListener('document:keydown', ['$event']) keydown(event) {
    const userInput = this.getInputValue();
    // KEY SHIFT
    if (event.key === 'Shift') {
      this.shiftActive = true;
    }
    if (
      !this.forceClose &&
      ((userInput !== '' && this.type !== 'select') ||
        this.type === 'select') &&
      !this.hasSelected
    ) {
      // KEY ARROW DOWN
      if (event.key === 'ArrowDown' && this.hasResults) {
        event.preventDefault();
        // this.giveBlur();
        this.keyboardIndex =
          this.keyboardIndex === null
            ? 0
            : this.keyboardIndex < this.options.length - 1
              ? this.keyboardIndex + 1
              : 0;
        this.assignKeyboardEvent();
      } else if (event.key === 'ArrowUp' && this.hasResults) {
        event.preventDefault();
        // this.giveBlur();
        this.keyboardIndex =
          this.keyboardIndex === null
            ? 0
            : this.keyboardIndex > 0
              ? this.keyboardIndex - 1
              : this.options.length - 1;
        this.assignKeyboardEvent();
      } else if (event.key === 'Tab') {
        if (this.type === 'select') {
          if (!this.hasSelected && !this.forceClose) {
            this.openSelect();
            // process tab event
            const allInputs = document.querySelectorAll('input');
            const inputsArray = Array.prototype.slice.call(allInputs);
            const ix = inputsArray.findIndex(e => e.id === 'se' + this.id);
            if (document.activeElement.id === 'se' + this.id) {
              const finalIx = this.shiftActive ? ix - 1 : ix + 1;
              allInputs[finalIx].focus();
            }
          }
        }
        event.preventDefault();
      } else if (event.key === 'Enter') {
        if (this.keyboardIndex === null) {
          if (this.options.length === 0) {
            // no result found = reset field
            if (this.type === 'autocomplete') {
              this.hasNotMatch = true;
              this.acForm.controls['search'].patchValue(null);
            }
            // console.log('keydown enter');
            this.inputSelected.emit([]);
          } else {
            if (this.type === 'autocomplete') {
              if (this.options.length === 1) {
                if (this.isDebug) {
                  console.log(this.options);
                }
                this.optionSelected(this.options[0]);
              } else {
                // prevent Enter action if input not in possible results
                event.preventDefault();
              }
            }
            if (this.type === 'search') {
              this.forceClose = true;
              this.exitComponent();
            }
          }
        } else {
          this.optionSelected(this.options[this.keyboardIndex]);
          this.hasNotMatch = false;
          if (this.type === 'select') {
            if (document.activeElement.id === 'se' + this.id) {
              const activeElement: any = document.activeElement;
              activeElement.blur();
            }
          }
        }
      } else {
        if (this.type === 'select') {
          event.preventDefault();
        }
      }
    } else {
      if (this.type === 'select') {
        if (event.key === 'Tab') {
          setTimeout(() => {
            if (document.activeElement.id === 'se' + this.id) {
              const activeElement: any = document.activeElement;
              activeElement.selectionStart = activeElement.selectionEnd;
              if (this.forceClose) {
                this.openSelect();
              }
            } else {
              if (!this.forceClose) {
                this.openSelect();
              }
            }
          }, 20);
        }
      } else {
        if (event.key === 'Tab' && userInput !== '' && this.type !== 'select') {
          const allInputs = document.querySelectorAll('input');
          const inputsArray = Array.prototype.slice.call(allInputs);
          const ix = inputsArray.findIndex(e => e.id === 'ac' + this.id);
          if (document.activeElement.id === 'ac' + this.id) {
            const finalIx = this.shiftActive ? ix - 1 : ix + 1;
            allInputs[finalIx].focus();
          }
        } else if (
          !this.componentBlocked &&
          userInput !== '' &&
          this.hasSelected
        ) {
          // if result ok delete = delete all word
          if (this.clickInside) {
            if (event.key === 'Delete' || event.key === 'Backspace') {
              this.deleteUserInput();
            }
          }
        }
      }
    }
  }

  @HostListener('document:keyup', ['$event']) keyup(event) {
    if (event.key === 'Shift') {
      this.shiftActive = false;
    }
  }

  @HostListener('mousedown') clickin() {
    this.clickInside = true;
  }

  @HostListener('document:mousedown') clickout() {
    if (!this.clickInside) {
      // LE PROB EST QUE CHAQUE CLIC ENGENDRE UN EXIT COMPONENT
      this.exitComponent();
    }
    this.clickInside = false;
  }

  setFocus() {
    this.keyboardIndex = null;
    if (this.forceClose && !this.hasSelected) {
      this.forceClose = false;
    }
  }

  giveFocus() {
    if (
      this.utils.isDefined(this.searchInput) &&
      this.utils.isDefined(this.searchInput.nativeElement)
    ) {
      this.searchInput.nativeElement.focus();
    }
  }

  giveBlur() {
    if (
      this.utils.isDefined(this.searchInput) &&
      this.utils.isDefined(this.searchInput.nativeElement)
    ) {
      this.searchInput.nativeElement.blur();
    }
  }

  sendBlurEvent() {
    if (this.acForm.controls['search'].value !== null) {
      if (this.acForm.controls['search'].value.trim() === '') {
        // console.log('sendBlurEvent');
        this.inputSelected.emit([]);
      }
    }
  }

  getInputValue() {
    let userInput = '';
    if (this.utils.isDefined(this.acForm.controls['search'].value)) {
      userInput = this.acForm.controls['search'].value.trim();
    }
    return userInput;
  }

  exitComponent() {
    const userInput = this.getInputValue();
    this.forceClose = true;
    if (this.isDebug) {
      console.log('exitComponent userInput', userInput);
    }
    if (userInput !== '') {
      if (this.options.length === 0) {
        if (this.type === 'autocomplete') {
          this.hasNotMatch = true;
          this.acForm.controls['search'].patchValue(null);
        }
        // console.log('exitComponent option 0');
        this.inputSelected.emit([]);
      } else {
        if (this.type === 'autocomplete') {
          if (this.options.length === 1 && !this.hasSelected) {
            this.optionSelected(this.options[0]);
          }
          if (this.options.length > 1 && !this.hasSelected) {
            this.hasNotMatch = true;
            this.acForm.controls['search'].patchValue(null);
            // console.log('exitComponent option > 1 !hasSelected');
            this.inputSelected.emit([]);
          }
        }
        if (this.type === 'search') {
          let serviceFieldsValue = this.options;
          if (this.utils.isDefined(this.serviceFields)) {
            serviceFieldsValue = [];
            for (const option of this.options) {
              if (this.serviceFields.length === 1) {
                serviceFieldsValue.push({ id: option[this.serviceFields[0]] });
              } else {
                const result = {};
                for (const field of this.serviceFields) {
                  result[field] = option[field];
                }
                serviceFieldsValue.push(result);
              }
            }
          }
          this.inputSelected.emit(serviceFieldsValue);
        }
      }
    } else {
      if (this.type === 'search') {
        // console.log('exitComponent userinput vide');
        this.inputSelected.emit([]);
      }
    }
  }

  deleteUserInput() {
    this.hasSelected = false;
    this.resetVars();
    this.acForm.controls['search'].patchValue(null);
    // console.log('deleteUserInput');
    this.inputSelected.emit([]);
    this.giveFocus();
  }

  optionSelected(value, noFocus?) {
    this.forceClose = true;
    this.hasSelected = true;
    // apply selected search to input
    let fieldValue = value;
    let serviceFieldValue = value;
    if (this.utils.isDefined(this.serviceFields)) {
      if (!!this.fieldOutput && !!value[this.fieldOutput]) {
        serviceFieldValue = value[this.fieldOutput];
      } else {
        serviceFieldValue = value[this.serviceFields[0]];
      }
      if (!!this.fieldName && !!value[this.fieldName]) {
        fieldValue = value[this.fieldName];
      } else {
        fieldValue = value[this.serviceFields[0]];
      }
    }
    // emit to parent
    if (this.type === 'autocomplete') {
      // console.log('emit emit emit');
      this.acForm.controls['search'].patchValue(fieldValue); // because object - previously simple string
      this.acForm.controls['search'].markAsTouched();
      this.inputSelected.emit(serviceFieldValue);
    }
    if (this.type === 'select') {
      this.acForm.controls['search'].patchValue(fieldValue); // because object - previously simple string
      this.acForm.controls['search'].markAsTouched();
      this.selectedSelect = value[this.fieldName];
      this.inputSelected.emit(serviceFieldValue);
    }
    if (this.type === 'search') {
      if (this.utils.isDefined(this.fieldName)) {
        this.acForm.controls['search'].patchValue(value[this.fieldName]); // because object - previously simple string
        this.acForm.controls['search'].markAsTouched();
        this.processResults(value[this.fieldName]);
      }
      const serviceFieldsValue = [];
      if (this.utils.isDefined(this.serviceFields)) {
        if (this.serviceFields.length === 1) {
          serviceFieldsValue.push({ id: value[this.serviceFields[0]] });
        } else {
          const result = {};
          for (const field of this.serviceFields) {
            result[field] = value[field];
          }
          serviceFieldsValue.push(result);
        }
      }
      this.inputSelected.emit(serviceFieldsValue);
    }
    // give focus
    if (!noFocus) {
      this.giveFocus();
    }
  }

  assignKeyboardEvent() {
    if (
      this.utils.isDefined(this.cleanedChildren) &&
      this.cleanedChildren.length > 0
    ) {
      if (this.keyboardIndex !== null) {
        // scroll to element selected by keyboard
        const resultHeight = this.cleanedChildren[0].offsetHeight;
        const startAt = 3;
        // scroll à partir de startAt(3) elements
        const scrollY =
          this.cleanedChildren[this.keyboardIndex].offsetTop -
            resultHeight * startAt <
          0
            ? 0
            : this.cleanedChildren[this.keyboardIndex].offsetTop -
              resultHeight * startAt;
        this.resultOptions.nativeElement.scrollTo(0, scrollY);
      } else {
        // re-give focus on input field
        if (
          this.searchInput &&
          this.utils.isDefined(this.searchInput) &&
          this.utils.isDefined(this.searchInput.nativeElement)
        ) {
          this.searchInput.nativeElement.focus();
        }
      }
    }
  }

  cleanChildren() {
    if (
      this.utils.isDefined(this.resultOptions) &&
      this.utils.isDefined(this.resultOptions.nativeElement)
    ) {
      const children = this.resultOptions.nativeElement.childNodes;
      // clean
      this.cleanedChildren = [];
      for (const child of children) {
        if (child.nodeName === 'DIV' && child.className === 'evs_option') {
          this.cleanedChildren.push(child);
        }
      }
    }
  }

  sendResult() {
    const userInput = this.getInputValue();
    this.processResults(userInput, true);
  }

  async processResults(value, exit?, noFocus = false) {
    if (this.isDebug) {
      console.log('processResults noFocus', noFocus);
      console.log(value, this.forceClose);
    }
    if (this.utils.isDefined(value) && !this.forceClose) {
      if (this.isDebug) {
        console.log('processResults value', value);
      }
      // value length = 2 caractères minimum
      if (value.length >= 2) {
        if (noFocus) {
          this.prefillCallServerLaunched = true;
        }
        await this.service.get(value).subscribe(
          res => {
            if (this.isDebug) {
              console.log('res from service get', value, res);
            }
            this.keyboardIndex = null;
            this.hasResults = res.length > 0 ? true : false;
            this.forceClose = false;
            this.options = res;
            if (
              this.inputValue !== '' &&
              this.options.length > 0 &&
              !this.prefillDone
            ) {
              this.optionSelected(this.options[0], noFocus);
              this.prefillDone = true;
            }
            setTimeout(() => {
              this.cleanChildren();
              if (exit) {
                this.exitComponent();
              }
            });
          },
          err => {
            throw new Error(
              'Component autocomplete ProcessResult: ' + JSON.stringify(err)
            );
          }
        );
      } else {
        this.resetVars();
      }
    }
  }

  openSelect() {
    this.forceClose = !this.forceClose;
  }

  constructForm() {
    if (this.required) {
      this.acForm = new UntypedFormGroup({
        search: new UntypedFormControl('', Validators.required),
      });
    } else {
      this.acForm = new UntypedFormGroup({
        search: new UntypedFormControl(''),
      });
    }
  }

  async setGlobals() {
    this.isDebug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.isDebug;
    this.type =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.type)
        ? this.compOptions.type
        : this.type; // autocomplete || search engine ||inptu  select
    this.required =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.required)
        ? this.compOptions.required
        : this.required;
    this.service =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.service)
        ? this.compOptions.service
        : null;
    this.serviceFields =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.fields)
        ? this.compOptions.fields
        : null;
    this.fieldName =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.fieldname)
        ? this.compOptions.fieldname
        : null;
    this.fieldOutput =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.output)
        ? this.compOptions.output
        : null;
    this.id =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.id)
        ? this.compOptions.id
        : this.id;
    this.inputValue =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.value)
        ? this.compOptions.value
        : this.inputValue;
    this.placeholder =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.placeholder)
        ? this.compOptions.placeholder
        : this.placeholder;
    this.selectData =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.selectData)
        ? this.compOptions.selectData
        : this.selectData;

    this.constructForm();

    if (this.type !== 'select') {
      if (this.service === null) {
        this.componentBlocked = true;
        return;
      }

      if (this.inputValue !== '' && !this.prefillCallServerLaunched) {
        this.forceClose = false;
        this.prefillDone = false;
        if (this.isDebug) {
          console.log(
            'prepare to fill input value added to form',
            this.acForm,
            this.inputValue
          );
        }

        // on simule la saisie au clavier
        await this.processResults(this.inputValue, false, true);
        // this.inputValue = '';
        if (this.isDebug) {
          console.log('options after processResults', this.options);
        }
      }
      this.acForm.valueChanges.subscribe(value => {
        if (this.utils.isDefined(value) && this.utils.isDefined(value.search)) {
          if (value.search.trim() !== '') {
            if (this.isDebug) {
              console.log('valueChanges', value);
            }
            this.processResults(value.search);
          } else {
            this.resetVars();
          }
        } else {
          this.resetVars();
        }
      });
    } else {
      this.options = this.selectData;
      this.hasResults = true;
      this.forceClose = true;
      if (!!this.inputValue && this.inputValue !== '') {
        const foundValue = this.options.find(e => e.value === this.inputValue);
        if (!!foundValue) {
          this.optionSelected(foundValue);
        }
      }
    }
  }
}
