import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { GiftModel } from '../models/gift';
import { Student } from '../models/student';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { UserErrorHandlerService } from '../../app/services/user-error-handler.service';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init2', initializer: 'onInit' })
@EasyDebugDecorator
export class GiftsService {
  private _gifts: Array<GiftModel> = [];
  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private storageService: StorageService,
    private userErrorHandlerService: UserErrorHandlerService
  ) {}

  async onInit() {
    return 'Gift done';
  }

  fetchGiftsByUserId(accountId: string): Observable<GiftModel[]> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId) {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/gifts`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(this.storageKey(accountId), data);
          this._gifts = this.buildGiftsFromRawData(data);
          return of(this._gifts);
        }),
        catchError(async err => {
          this.userErrorHandlerService.addError({
            criticity: 1,
            service: 'gifts',
            platform: 'both',
            data: err,
            errorCode: 'gsfgbuid',
          });
          return [];
        })
      );
    }
  }

  async loadStudentGifts(student: Student) {
    if (student.isGuest()) {
      this._gifts = [];
    } else {
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadFromStorage(student);
      } else {
        this.fetchGiftsByUserId(student.remoteId).subscribe(res => {
          this._gifts = res;
        });
      }
    }
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get gifts(): Array<GiftModel> {
    return this._gifts;
  }

  private;

  async loadFromStorage(student: Student) {
    this.storageService.get(this.storageKey(student.remoteId)).then(res => {
      this._gifts = !!res ? this.buildGiftsFromRawData(res) : [];
    });
  }

  storageKey(accountId: String): string {
    return `${accountId}-gifts`;
  }

  buildGiftFromRawData(data: any): GiftModel {
    return new GiftModel({
      id: data.id,
      type: data.type,
      credits: data.attributes.credits,
      driving_exam_credits: data.attributes.driving_exam_credits,
      code: data.attributes.code,
      description: data.attributes.description,
      created_at: data.attributes.created_at,
      updated_at: data.attributes.updated_at,
    });
  }

  buildGiftsFromRawData(data: any): Array<GiftModel> {
    const arr: Array<GiftModel> = [];
    if (!!data && !!data.data && data.data.length > 0) {
      for (const gift of data.data) {
        arr.push(this.buildGiftFromRawData(gift));
      }
    }
    return arr;
  }
}
