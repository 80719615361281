import { Subscription } from 'rxjs';
import { EvsRecord } from './evsRecord';

export interface ICancelAsync {
  subscription: Subscription;
  cancel: boolean;
}

export class CancelAsync extends EvsRecord implements ICancelAsync {
  private _subscription: Subscription;
  private _cancel: boolean;

  constructor(obj: ICancelAsync) {
    super(obj);
  }

  public get subscription(): Subscription {
    return this._subscription;
  }
  public set subscription(value: Subscription) {
    this._subscription = value;
  }
  public get cancel(): boolean {
    return this._cancel;
  }
  public set cancel(value: boolean) {
    this._cancel = value;
  }
}
