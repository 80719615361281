<div class="opening-slider animated fadeIn">
  <div class="opening-slider-wrapper">
    <div class="background">
      <img src="{{cardsContent[randomScreen].asset}}" alt="Temoignage eleve" />
    </div>

    <div class="opening-text">
      <h1 class="opening-text-title">
        {{ cardsContent[randomScreen].subTitle }}
      </h1>
      <div class="opening-text-card">
        <p class="student-quote">
          {{ cardsContent[randomScreen].studentQuote }}
        </p>
        <div class="stars-wrapper">
          <img
            class="stars"
            *ngFor="let i of utils.numberToArray(5)"
            src="assets/svg/trustpilot-star.svg"
            alt="Etoile" />
        </div>
        <p class="student">
          <span class="highlight"
            >{{ cardsContent[randomScreen].student }}</span
          >
          - Avis vérifié Trustpilot
        </p>
      </div>
    </div>

    <div class="call-center-container">
      <a
        *ngIf="!isPlatformDesktop"
        class="call-center-link"
        [href]="'tel:' + callCenterPhoneFormatted">
        <div class="call-center-text-wrapper">
          <button class="btn light">Des questions&nbsp;? Appelle-nous</button>
        </div>
      </a>
      <div *ngIf="isPlatformDesktop" class="call-center-text-wrapper">
        <div class="call-center-title">
          Des questions sur le Pack Permis&nbsp;?
        </div>
        <div class="call-center-desc">
          Appelle le <span class="highlight-orange">{{ callCenterPhone }}</span
          ><span class="lowlight"> (appel gratuit)</span>
        </div>
      </div>
    </div>

    <div class="btn-opening">
      <button class="btn outline" (click)="savoirplus()">En savoir plus</button>
    </div>
  </div>
</div>

<span class="ignorer" (click)="closeSlider()">
  <img
    src="assets/2022/icons/close-off.svg"
    alt="EnVoitureSimone"
    height="24" />
</span>
