<!-- calendar -->
<ng-container *ngIf="showBloc == 1">
  <div
    class="evs_calendar"
    [id]="id"
    [ngClass]="{'evs_calendar_loaded': loaded}"
    #evsCalendar>
    <!-- MONTH YEAR TODAY SELECTION -->
    <div *ngIf="showTime" class="timepicker">
      <article class="clock">
        <div class="digits">
          <div
            *ngIf="showHours"
            class="amBtn"
            (click)="setAmPm('am')"
            [ngClass]="{'amBtnLoaded': loaded, 'active': timeIsAm}">
            am
          </div>
          <div
            *ngIf="showHours"
            class="pmBtn"
            (click)="setAmPm('pm')"
            [ngClass]="{'pmBtnLoaded': loaded, 'active': !timeIsAm}">
            pm
          </div>
          <ng-container *ngIf="showHours">
            <div
              class="digit"
              [ngClass]="{'digitLoaded': loaded}"
              [ngStyle]="{'transform': 'rotate(' + (30 * i) + 'deg)'}"
              *ngFor="let digit of hoursData; let i = index">
              <span (mouseenter)="rotateHours(i)" (click)="setHours(digit)"
                >{{digit}}</span
              >
            </div>
          </ng-container>
          <ng-container *ngIf="showMinutes">
            <div
              class="digit small-digit"
              [ngClass]="{'small-digitLoaded': loaded}"
              [ngStyle]="{'transform': 'rotate(' + (30 * i) + 'deg)'}"
              *ngFor="let digit of minutesData; let i = index">
              <span (mouseenter)="rotateMinutes(i)" (click)="setMinutes(digit)"
                >{{digit}}</span
              >
            </div>
          </ng-container>
        </div>
        <div
          class="hours-container"
          [ngClass]="{'disabled': !showHours}"
          #hoursContainer>
          <div class="hours"></div>
        </div>
        <div
          class="minutes-container"
          [ngClass]="{'disabled': !showMinutes}"
          #minutesContainer>
          <div class="minutes"></div>
        </div>
        <!-- <div class="seconds-container">
          <div class="seconds"></div>
        </div> -->
      </article>
    </div>
    <div *ngIf="!showTime" class="flexGrid">
      <div
        class="todayButton"
        [ngStyle]="{
          'font-size': (caseWidth / 3) + 'px',
          'padding': (caseWidth / 5.5) + 'px ' + (caseWidth / 4.5) + 'px',
          'border-radius': (caseWidth / 4) + 'px'
        }"
        (click)="prevNext(0)">
        Aujourd'hui
      </div>
      <div class="dateSelection">
        <ion-icon
          name="chevron-back"
          [ngStyle]="{'font-size': (caseWidth / 2) + 'px'}"
          (click)="prevNext(-1)"></ion-icon>
        <span
          class="currentDate"
          [ngClass]="{'clickable': editable}"
          [ngStyle]="{'font-size': (caseWidth / 2.3) + 'px'}"
          (click)="toggleYear()">
          {{months[month]}} {{year}}
        </span>
        <ion-icon
          name="chevron-forward"
          [ngStyle]="{'font-size': (caseWidth / 2) + 'px'}"
          (click)="prevNext(1)"></ion-icon>
      </div>
    </div>
    <!-- YEARS -->
    <div
      *ngIf="showYear && !showTime"
      class="Rtable Rtable--4cols margin-top-10">
      <div
        class="Rtable-cell"
        *ngFor="let year of calendarYears"
        [ngStyle]="{'height': caseWidth+'px'}">
        <span
          class="selectableYear"
          (click)="changeYear(year.year)"
          [ngStyle]="{'font-size': (caseWidth / 2) + 'px', 'width': (caseWidth * 1.75) + 'px', 'height': caseWidth + 'px', 'line-height': caseWidth + 'px'}">
          {{year.year}}
        </span>
      </div>
    </div>
    <!-- DAYS NAME -->
    <div
      *ngIf="!showYear && !showTime"
      class="Rtable Rtable--7cols margin-top-10">
      <div
        class="Rtable-cell"
        *ngFor="let item of days"
        [ngStyle]="{'height': caseWidth+'px'}">
        <span
          class="dayLetter"
          [appTooltip]="item"
          [ngStyle]="{'font-size': (caseWidth / 2) + 'px'}">
          {{utils.getFirstLetter(item) | uppercase}}
        </span>
      </div>
      <!-- DATES -->
      <div
        class="Rtable-cell"
        *ngFor="let day of calendarData"
        [ngStyle]="{'height': caseWidth+'px'}">
        <span
          (click)="setEditableDate((month + 1) + '-' + day.day + '-' + year)"
          *ngIf="day.day != ''"
          [appTooltip]="day.tooltip"
          [ngStyle]="{'font-size': (caseWidth / 2) + 'px', 'width': caseWidth + 'px', 'height': caseWidth + 'px', 'line-height': caseWidth + 'px'}"
          [ngClass]="day.class">
          {{day.day}}
        </span>
      </div>
    </div>
  </div>
</ng-container>

<!-- skeleton -->
<!-- <ng-container *ngIf="showBloc == 0">
  <div class="skeleton dateSelection margin-top-20" text-center [ngStyle]="{'display': 'block', 'width': '100%'}">
    <div class="margin-left-20 float-left" [ngStyle]="{'width': '25px'}">
      <div class="rectangle-25h rounded-10 self-center"></div>
    </div>
    <div class="margin-right-20 float-right" [ngStyle]="{'width': '25px'}">
      <div class="rectangle-25h rounded-10 self-center"></div>
    </div>
    <div [ngStyle]="{'width': '200px', 'margin': 'auto'}">
      <div class="rectangle-25h rounded-10 self-center"></div>
    </div>
  </div>
  <div class="skeleton Rtable Rtable--7cols">
    <div class="Rtable-cell  margin-bottom-10" *ngFor="let item of days">
      <div [ngStyle]="{'width': '25px', 'margin': '3px auto'}">
        <div class="rectangle-25h rounded-25 self-center"></div>
      </div>
    </div>
    <div class="Rtable-cell" *ngFor="let item of numberToArray(35 + (day_no + 1)); let i = index">
      <ng-container *ngIf="i < day_no">
        <div [ngStyle]="{'width': '20px', 'margin': '5px auto'}">
          <div class="self-center"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="i >= day_no && checkIndex(i)">
        <div [ngStyle]="{'width': '20px', 'margin': '5px auto'}">
          <div class="rectangle-20h rounded-20 self-center"></div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container> -->
