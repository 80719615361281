/* tslint:disable:member-ordering */
import {
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

// SERVICES
import { UtilsService } from '../services';

@Directive({
  selector: '[appSlider]',
  exportAs: 'SliderDirective', // allow template to convert into @ViewChild
})
export class SliderDirective implements OnInit, AfterViewInit, OnChanges {
  @Input('appSlider') sliderConfig: any;
  @Output() canClick = new EventEmitter<boolean>();

  parent: any;
  divSlides: any;
  divBullets: any;
  originalContent = [];
  originalSizes = [];
  isAnimated = false;
  isDraggable = true;
  isClickBullet = false;
  focusSlider = false;
  innerWidth = window.innerWidth;

  // CONFIG
  isDebug = false;
  isBullets = false;
  isArrows = false;
  autoSlide = false;
  autoSliderTimer = 0;
  isInline = false;
  isBouncable = false;
  isDecelerate = false;
  nbLines = 1;
  isVertical = false;
  verticalLimit = 0;
  startAt = 0;
  forceRemoveArrows = false;

  // SLIDER PARAMS
  nbSlides = 0;
  currentSlide = 0;
  containerWidth: any = 0;
  inlineWidthItem = 0;
  inlineHeightItem = 0;
  retry = 0;

  // IMG
  imgToload = [];
  nbTotalImg = 0;
  imgLoaded = 0;
  imgCpt = 0;
  isFullImg = false;

  // SLIDES MOVE
  marginLeft = 0;
  marginLeftDrag = 0;

  posX = 0;
  posY = 0;
  draggingStartTime = 0;
  draggingEndTime = 0;
  draggingStartPosX = 0;
  draggingEndPosX = 0;
  draggingStartPosY = 0;
  draggingEndPosY = 0;
  isDrag = false;
  hasMoved = false;
  oldDiffX = 0;
  lastAcc = 0;
  decelerateAnimInterval = null;

  // autoslide
  timer = 5000; // millisec
  userStopAutoSlider = false;
  timeOut = null;

  // arrows
  btControls: any;
  btLeft: any;
  btRight: any;
  isClickArrow = false;

  firstInterval: any;
  waitInterval: any;
  cptWaintInerval = 0;

  id: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private utils: UtilsService
  ) {
    this.parent = this.el.nativeElement;
    this.renderer.setStyle(this.parent, 'opacity', '0');
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const sliderConfig: SimpleChange = changes.sliderConfig;
    if (this.utils.isDefined(sliderConfig)) {
      this.sliderConfig = sliderConfig.currentValue;
      this.getSliderConfig();
      setTimeout(() => {
        this.resizeInlineSlider();
      });
    }
  }

  ngOnInit() {
    this.getSliderConfig();
  }

  ngAfterViewInit() {
    // get view generated original contents when ready
    this.firstInterval = setInterval(() => {
      if (this.isDebug) {
        console.log('setInterval search for getBoundingClientRect');
      }
      if (this.parent.getBoundingClientRect().width > 0 && this.nbSlides <= 0) {
        this.initSlider();
      }
    }, 100);
  }

  initSlider() {
    console.log('initSlider');
    clearInterval(this.firstInterval);
    const children = this.parent.childNodes;
    this.getAllContents(children);
    // nbslides == nbcontents - 1 | start at 0
    if (this.nbSlides > 0) {
      this.setSlider();
    }
  }

  resizeInlineSlider() {
    if (!!this.divSlides) {
      this.containerWidth = this.parent.getBoundingClientRect();
      const children = this.divSlides.childNodes;
      if (!!children && children.length > 0) {
        this.inlineWidthItem = children[0].offsetWidth;
      }
      let slidesWidth: any;
      if (this.isInline) {
        if (!this.isVertical) {
          slidesWidth = Math.ceil(
            this.originalSizes.reduce((x, y) => x + y.width, 0)
          );
          if (this.nbLines > 1) {
            slidesWidth =
              this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2) <
              this.containerWidth.width
                ? this.containerWidth.width
                : this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2);
          }
          // replace slides
          if (!!this.divSlides) {
            const marginType = !this.isVertical ? 'transform' : 'margin-top';
            this.renderer.setStyle(
              this.divSlides,
              marginType,
              !this.isVertical ? 'none' : 0
            );
          }
          this.checkSlidable();
        }
      }
      if (this.isDebug) {
        console.log('resizeInlineSlider slidesWidth', slidesWidth);
      }
      this.renderer.setStyle(this.divSlides, 'width', slidesWidth + 'px');
    }
  }

  getAllContents(contents) {
    this.nbSlides = 0;
    let contentWidths = 0;
    for (const [index, child] of contents.entries()) {
      console.log('child -> ', child);
      // check si on gère bien tous les types commentaires ou text et autres trucs qu'on ne voudrait pas
      if (child.nodeName !== '#comment' && child.nodeName !== '#text') {
        this.originalContent.push(child);
        if (index > 0) {
          let previousChildWidth = this.originalContent[index - 1].offsetWidth;
          if (
            !Number.isInteger(
              this.originalContent[index - 1].getBoundingClientRect().width
            )
          ) {
            if (
              this.originalContent[index - 1].getBoundingClientRect().width >
              this.originalContent[index - 1].offsetWidth
            ) {
              previousChildWidth =
                this.originalContent[index - 1].getBoundingClientRect().width;
            } else {
              console.log(
                this.originalContent[index - 1].getBoundingClientRect().width,
                this.originalContent[index - 1].offsetWidth
              );
              previousChildWidth =
                this.originalContent[index - 1].offsetWidth + 2;
            }
          }
          contentWidths += previousChildWidth;
        }
        let childWidth = child.offsetWidth;
        console.log('childWidth => ', childWidth);
        if (!Number.isInteger(child.getBoundingClientRect().width)) {
          if (child.getBoundingClientRect().width > child.offsetWidth) {
            childWidth = child.getBoundingClientRect().width;
          } else {
            childWidth = child.offsetWidth + 2;
          }
        }
        const currentStyle =
          child.currentStyle || window.getComputedStyle(child);
        const marginRight = parseInt(
          currentStyle.marginRight.replace('px', '')
        );
        if (!!marginRight && marginRight > 0) {
          childWidth += marginRight;
        }
        this.originalSizes.push({
          width: childWidth,
          offsetLeft: contentWidths,
        });
        this.inlineWidthItem =
          child.offsetWidth > this.inlineWidthItem
            ? child.offsetWidth
            : this.inlineWidthItem;
        this.inlineHeightItem =
          child.offsetHeight > this.inlineHeightItem
            ? child.offsetHeight
            : this.inlineHeightItem;
        if (child.nodeName.toLowerCase() === 'img') {
          this.originalContent[this.originalContent.length - 1].draggable =
            false; // chrome
          this.originalContent[this.originalContent.length - 1].ondragstart =
            () => {
              // firefox
              return false;
            };
          this.nbTotalImg++;
        }
      }
    }
    console.log('originalSizes -> ', this.originalSizes);
    this.nbSlides = this.originalContent.length - 1;
    this.isFullImg = this.nbTotalImg - 1 === this.nbSlides ? true : false;

    if (this.isDebug) {
      console.log('getAllContents', this.nbSlides);
    }

    if (this.nbSlides > 1) {
      if (this.inlineHeightItem === 0 && this.isVertical && this.retry < 3) {
        if (this.isDebug) {
          console.log('getAllContents inlineHeightItem === 0 retry');
        }
        this.retry++;
        setTimeout(() => {
          console.log('retry');
          this.getAllContents(contents);
        }, 0);
      }
    } else {
      if (this.isDebug) {
        console.log('no slider if only 1 element');
      }
      // no slider if only 1 element
      this.renderer.setStyle(this.parent, 'line-height', '0');
      this.renderer.removeStyle(this.parent, 'opacity');
    }
  }

  setSlider() {
    if (this.isDebug) {
      console.log('setSlider styles');
    }
    // Assign parent size
    // this.renderer.setStyle(
    //   this.parent,
    //   'transition',
    //   'opacity .6s ease-out'
    // );
    if (this.isVertical) {
      const slidesHeight =
        this.verticalLimit === 0
          ? this.inlineHeightItem * (this.nbSlides + 1)
          : this.verticalLimit;
      this.renderer.setStyle(this.parent, 'height', slidesHeight + 'px');
    }
    this.renderer.setStyle(this.parent, 'overflow', 'hidden');
    if (!this.isInline) {
      this.renderer.setStyle(this.parent, 'text-align', 'center');
    }
    this.renderer.setStyle(this.parent, 'position', 'relative');
    this.renderer.setStyle(this.parent, 'line-height', '0');

    this.containerWidth = this.parent.getBoundingClientRect();

    // clean parent
    this.parent.innerHTML = '';

    setTimeout(() => {
      // const widthParent = (!this.isVertical)
      //   ? this.containerWidth.width + 'px'
      //   : (!this.isFullImg)
      //     ? this.inlineWidthItem + 'px'
      //     : this.containerWidth.width + 'px';
      const widthParent = !this.isVertical
        ? '100%'
        : !this.isFullImg
          ? this.inlineWidthItem + 'px'
          : '100%';
      if (this.isDebug) {
        console.log('setSlider widthParent', widthParent);
      }
      this.renderer.setStyle(this.parent, 'width', widthParent);
      // remove arrows if container < 250px
      this.isArrows =
        this.containerWidth.width <= 250 && !this.isVertical
          ? false
          : !!this.sliderConfig.arrows
            ? this.sliderConfig.arrows
            : false;
      console.log('4 arrow', this.isArrows);

      if (this.nbTotalImg > 0) {
        this.loadImgs();
      } else {
        this.putContent();
      }
    }, 0);
  }

  getSliderConfig() {
    this.isDebug = !!this.sliderConfig.debug
      ? this.sliderConfig.debug
      : this.isDebug;
    this.id = !!this.sliderConfig.id ? this.sliderConfig.id : this.id;
    this.isBullets = !!this.sliderConfig.bullets
      ? this.sliderConfig.bullets
      : this.isBullets;
    this.isArrows = !!this.sliderConfig.arrows
      ? this.sliderConfig.arrows
      : this.isArrows;
    this.autoSlide = !!this.sliderConfig.autoSlide
      ? this.sliderConfig.autoSlide
      : this.autoSlide;
    this.autoSliderTimer = !!this.sliderConfig.timer
      ? this.sliderConfig.timer * 1000
      : this.timer;
    this.timer = this.autoSliderTimer; // assign

    this.isVertical = !!this.sliderConfig.vertical
      ? this.sliderConfig.vertical
      : this.isVertical;
    this.verticalLimit = !!this.sliderConfig.verticalLimit
      ? this.sliderConfig.verticalLimit
      : this.verticalLimit;

    this.isInline = !!this.sliderConfig.inline
      ? this.sliderConfig.inline
      : this.isInline;
    this.isBouncable = !!this.sliderConfig.bounce
      ? this.sliderConfig.bounce
      : this.isBouncable;
    this.isDecelerate = !!this.sliderConfig.decelerate
      ? this.sliderConfig.decelerate
      : this.isDecelerate;
    this.nbLines = !!this.sliderConfig.nblines
      ? this.sliderConfig.nblines
      : this.nbLines;
    this.startAt = !!this.sliderConfig.startAt
      ? this.sliderConfig.startAt
      : this.startAt;
    // slider inline = no bullets + no autoslide
    this.isBullets = this.isInline ? false : this.isBullets;
    this.autoSlide = this.isInline ? false : this.autoSlide;
    this.forceRemoveArrows = !!this.sliderConfig.forceRemoveArrows
      ? this.sliderConfig.forceRemoveArrows
      : this.forceRemoveArrows;
  }

  loadImgs() {
    this.imgLoaded = 0;
    for (const content of this.originalContent) {
      // Images
      if (content.nodeName.toLowerCase() === 'img') {
        this.imgToload[this.imgCpt] = new Image();
        this.imgToload[this.imgCpt].onload = () => {
          this.imgLoaded++;
          if (this.imgLoaded === this.nbTotalImg) {
            // all img loaded
            this.putContent();
          }
        };
        this.imgToload[this.imgCpt].onerror = () => {
          // img not found - remove from contents
          this.originalContent.splice(this.originalContent.indexOf(content), 1);
          this.nbTotalImg--;
          this.nbSlides = this.originalContent.length - 1;
          this.isFullImg = this.nbTotalImg - 1 === this.nbSlides ? true : false;
          if (this.imgLoaded === this.nbTotalImg) {
            // all img loaded
            this.putContent();
          }
        };
        this.imgToload[this.imgCpt].src = content.src;
      }
    }
  }

  putContent() {
    if (this.isDebug) {
      console.log('********************************');
    }
    if (this.isDebug) {
      console.log('putContent');
    }
    // remove if exist
    if (!!this.divSlides && !!this.parent) {
      if (this.divSlides.parentNode === this.parent) {
        if (this.isDebug) {
          console.log('putContent remove divSlides from parent');
        }
        this.parent.removeChild(this.divSlides);
      }
    }
    if (!!this.divBullets && !!this.parent && this.isBullets) {
      if (this.divBullets.parentNode === this.parent) {
        if (this.isDebug) {
          console.log('putContent remove divBullets from parent');
        }
        this.parent.removeChild(this.divBullets);
      }
    }
    // get containerWidth
    this.containerWidth = this.parent.getBoundingClientRect();
    if (this.isDebug) {
      console.log('putContent containerWidth', this.containerWidth);
    }
    // create divSlides if not exists
    this.divSlides = document.createElement('div');
    this.divSlides.className = 'dirslider_divSlides';
    // push divSlide into parent
    this.parent.appendChild(this.divSlides);

    // assign divSlides width from new containerWidth
    if (this.isDebug) {
      console.log('putContent currentSlide', this.currentSlide);
    }
    const nbSlides =
      this.currentSlide === 0 || this.currentSlide === this.nbSlides
        ? this.nbSlides + 2
        : this.nbSlides + 1;
    if (this.isDebug) {
      console.log('putContent nbSlides', nbSlides);
    }
    let slidesWidth: any;
    if (!this.isInline) {
      slidesWidth = this.containerWidth.width * nbSlides;
    } else {
      if (!this.isVertical) {
        // slidesWidth = this.inlineWidthItem * (this.nbSlides + 1);
        slidesWidth = Math.ceil(
          this.originalSizes.reduce((x, y) => x + y.width, 0)
        );
        console.log(
          this.originalSizes,
          Math.ceil(this.originalSizes.reduce((x, y) => x + y.width, 0))
        );
        if (this.nbLines > 1) {
          slidesWidth =
            this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2) <
            this.containerWidth.width
              ? this.containerWidth.width
              : this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2);
        }
        this.checkSlidable();
      }
    }
    this.renderer.setStyle(this.divSlides, 'width', slidesWidth + 'px');
    if (this.isInline) {
      if (!this.isVertical) {
        // prevent height changes when resize inline sliders
        setTimeout(() => {
          this.renderer.setStyle(
            this.divSlides,
            'height',
            this.divSlides.offsetHeight + 'px'
          );
        });
      }
    }
    // real marginLeft
    const nbDecalScreen =
      this.currentSlide === 0 ? this.currentSlide + 1 : this.currentSlide;
    this.marginLeft = !this.isInline
      ? this.containerWidth.width * nbDecalScreen * -1
      : this.marginLeftDrag;
    // make diff between real MarginLeft and dragged marginLeft - to go back to real if mouseup too soon
    this.marginLeftDrag = this.marginLeft;
    const marginType = !this.isVertical ? 'transform' : 'margin-top';
    this.renderer.setStyle(
      this.divSlides,
      marginType,
      !this.isVertical
        ? 'translateX(' + this.marginLeft + 'px)'
        : this.marginLeft
    );
    // add contents
    let slide: any;

    // add previous slide
    if (this.currentSlide === 0 && !this.isInline) {
      slide = document.createElement('div');
      slide.className = 'dirslider_slide';
      slide.style.width = this.containerWidth.width + 'px';
      // append content into div
      if (this.isDebug) {
        console.log(
          'putContent cloneNode 1',
          this.originalContent[this.originalContent.length - 1]
        );
      }
      if (
        typeof this.originalContent[this.originalContent.length - 1] !==
        'undefined'
      ) {
        const clonedContent =
          this.originalContent[this.originalContent.length - 1].cloneNode(true);
        slide.appendChild(clonedContent);
        // push div into divSlide
        this.divSlides.appendChild(slide);
      }
    }

    // loop all accessible slides
    for (const content of this.originalContent) {
      slide = document.createElement('div');
      slide.className = 'dirslider_slide';
      const itemWidth = !this.isInline
        ? this.containerWidth.width
        : !this.isVertical
          ? 'initial'
          : this.containerWidth.width;
      // remove screen size wwhen inline slider horizontal
      if (itemWidth !== 'initial') {
        slide.style.width = itemWidth + 'px';
      }
      // prevent space between divs
      if (this.isInline && this.isVertical) {
        slide.style.verticalAlign = 'middle';
      }
      // append content into div
      slide.appendChild(content);
      // push div into divSlide
      this.divSlides.appendChild(slide);
    }

    // add next slide -> index 0
    if (this.currentSlide === this.nbSlides && !this.isInline) {
      slide = document.createElement('div');
      slide.className = 'dirslider_slide';
      slide.style.width = this.containerWidth.width + 'px';
      // append content into div
      // if (this.isDebug) { console.log('putContent cloneNode 2', this.originalContent[0]); }
      if (typeof this.originalContent[0] !== 'undefined') {
        const clonedContent = this.originalContent[0].cloneNode(true);
        slide.appendChild(clonedContent);
        // push div into divSlide
        this.divSlides.appendChild(slide);
      }
    }

    if (!this.isInline) {
      // >768 = 12 + 6 // <=768 = 16 + 6
      const bulletSize =
        innerWidth > 768
          ? (this.nbSlides + 1) * 18 - 6
          : (this.nbSlides + 1) * 22 - 6;
      this.isBullets =
        bulletSize > this.containerWidth.width
          ? false
          : !!this.sliderConfig.bullets
            ? this.sliderConfig.bullets
            : false;
    }

    // add bullets
    if (this.isBullets) {
      // create bullets
      this.divBullets = document.createElement('div');
      this.divBullets.className = 'dirslider_divBullets';
      let bulletDiv: any;
      for (let i = 0; i < this.originalContent.length; i++) {
        const classActive =
          i === this.currentSlide
            ? 'dirslider_bullet dirslider_activeBullet'
            : 'dirslider_bullet';
        bulletDiv = document.createElement('div');
        bulletDiv.id = i;
        bulletDiv.className = classActive;
        // push div into divBullets
        this.divBullets.appendChild(bulletDiv);
      }
      // push divSlide into parent
      this.parent.appendChild(this.divBullets);
    }
    // autoslide
    if (this.autoSlide && !this.userStopAutoSlider) {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.processAutoSlide();
      }, this.timer);
    }

    this.addArrows();

    if (this.isInline) {
      if (!!this.divSlides && !!this.containerWidth) {
        // particular case: when margin on mobile bigger than desktop
        this.marginLeftDrag =
          this.divSlides.offsetWidth -
            this.containerWidth.width +
            this.marginLeftDrag <
          0
            ? this.containerWidth.width - this.divSlides.offsetWidth
            : this.marginLeftDrag;
      }
    }

    if (this.isDebug) {
      console.log('removeStyle opacity = show ?');
    }
    this.renderer.removeStyle(this.parent, 'opacity');

    if (this.startAt > 0 && this.startAt <= this.originalSizes.length) {
      if (this.isInline && this.nbLines === 1) {
        const offsetLeftLimit =
          this.divSlides.offsetWidth - this.containerWidth.width;
        this.marginLeft =
          this.originalSizes[this.startAt - 1].offsetLeft > offsetLeftLimit
            ? offsetLeftLimit * -1
            : this.originalSizes[this.startAt - 1].offsetLeft * -1;
        this.renderer.setStyle(
          this.divSlides,
          'transform',
          'translateX(' + this.marginLeft + 'px)'
        );
      }
    }
  }

  addArrows() {
    // add arrows
    console.log('isArrow => ', this.isArrows);
    if (this.isArrows) {
      setTimeout(() => {
        if (typeof this.btLeft === 'undefined') {
          this.btLeft = document.createElement('div');
          this.btLeft.className = 'dirslider_btLeft';
          // this.btLeft.innerHTML = '<i class="icon ion-ios-arrow-back"></i>';
          // this.btLeft.innerHTML = '<img src="assets/svg/arrowLeft.svg">';
          // push div into divSlide
          this.parent.appendChild(this.btLeft);
          const tmpTop = !this.isVertical
            ? this.divSlides.offsetHeight / 2 - this.btLeft.offsetHeight / 2
            : this.containerWidth.height / 2 - this.btLeft.offsetHeight / 2;
          this.renderer.setStyle(this.btLeft, 'top', tmpTop + 'px');
        }
        if (typeof this.btRight === 'undefined') {
          this.btRight = document.createElement('div');
          this.btRight.className = 'dirslider_btRight';
          // this.btRight.innerHTML = '<i class="icon ion-ios-arrow-forward"></i>';
          // this.btRight.innerHTML = '<img src="assets/svg/arrowRight.svg">';
          // push div into divSlide
          this.parent.appendChild(this.btRight);
          const tmpTop = !this.isVertical
            ? this.divSlides.offsetHeight / 2 - this.btRight.offsetHeight / 2
            : this.parent.offsetHeight / 2 - this.btRight.offsetHeight / 2;
          this.renderer.setStyle(this.btRight, 'top', tmpTop + 'px');
        }
        const opacity = this.focusSlider ? 1 : 0;
        this.renderer.setStyle(this.btLeft, 'opacity', opacity);
        this.renderer.setStyle(this.btRight, 'opacity', opacity);
      });
    } else {
      if (!!this.btLeft && !!this.parent) {
        if (this.btLeft.parentNode === this.parent) {
          this.parent.removeChild(this.btLeft);
          this.btLeft = undefined;
        }
      }
      if (!!this.btRight && !!this.parent) {
        if (this.btRight.parentNode === this.parent) {
          this.parent.removeChild(this.btRight);
          this.btRight = undefined;
        }
      }
    }
  }

  checkSlidable() {
    // this.containerWidth = this.parent.getBoundingClientRect();
    if (this.isInline && !this.isVertical) {
      let slidesWidth = Math.ceil(
        this.originalSizes.reduce((x, y) => x + y.width, 0)
      );
      if (this.nbLines > 1) {
        slidesWidth = this.inlineWidthItem * Math.ceil(this.nbSlides / 2);
      }
      if (slidesWidth < this.containerWidth.width) {
        this.marginLeftDrag = 0;
        this.marginLeft = 0;
        this.isDraggable = false;
      } else {
        if (!this.isDraggable) {
          this.marginLeft = 0;
          this.marginLeftDrag = 0;
        }
        this.isDraggable = true;
      }
    }
    // remove arrows if not draggable
    this.isArrows = this.isDraggable && !this.forceRemoveArrows ? true : false;
    console.log('1', this.isArrows);
    this.addArrows();
  }

  waitForBoudingClientRect() {
    clearInterval(this.waitInterval);
    return new Promise(resolve => {
      this.waitInterval = setInterval(() => {
        // console.log('waitForBoudingClientRect');
        this.cptWaintInerval++;
        if (
          this.parent.getBoundingClientRect().width > 0 ||
          this.cptWaintInerval > 300
        ) {
          // console.log('waitForBoudingClientRect found');
          this.cptWaintInerval = 0;
          resolve(this.parent.getBoundingClientRect());
        }
      }, 40);
    });
  }

  async processResizeEvent() {
    clearTimeout(this.timeOut);
    this.containerWidth = await this.waitForBoudingClientRect();
    clearInterval(this.waitInterval);
    this.innerWidth = window.innerWidth;

    if (this.isDebug) {
      console.log(this.containerWidth);
    }

    // const widthParent = (!this.isVertical)
    //   ? this.containerWidth.width + 'px'
    //   : (!this.isFullImg)
    //     ? this.inlineWidthItem + 'px'
    //     : this.containerWidth.width + 'px';
    // this.renderer.setStyle(
    //   this.parent,
    //   'width',
    //   widthParent
    // );

    this.checkSlidable();

    if (this.isInline) {
      if (this.isVertical && !!this.divSlides && !!this.divSlides.children) {
        console.log('resize');
        this.getAllContents(this.divSlides.children);
      }
      // particular case: when margin on mobile bigger than desktop
      if (!!this.divSlides && !!this.containerWidth) {
        this.marginLeftDrag =
          this.divSlides.offsetWidth -
            this.containerWidth.width +
            this.marginLeftDrag <
          0
            ? this.containerWidth.width - this.divSlides.offsetWidth
            : this.marginLeftDrag;
      }
    }
    // remove arrows if container < 250px
    this.isArrows =
      this.containerWidth.width <= 250 && !this.isVertical
        ? false
        : !!this.sliderConfig.arrows
          ? this.sliderConfig.arrows
          : false;

    console.log('2 arrow', this.isArrows);

    // remove arrows if not draggable
    this.isArrows = this.isDraggable ? true : false;
    console.log('3 arrow', this.isArrows);

    this.putContent();

    // re-position arrows
    let tmpTop: any;
    if (!!this.btLeft) {
      tmpTop = !this.isVertical
        ? this.divSlides.offsetHeight / 2 - this.btLeft.offsetHeight / 2 + 2
        : this.containerWidth.height / 2 - this.btLeft.offsetHeight / 2 + 2;
      this.renderer.setStyle(this.btLeft, 'top', tmpTop + 'px');
    }
    if (!!this.btRight) {
      tmpTop = !this.isVertical
        ? this.divSlides.offsetHeight / 2 - this.btRight.offsetHeight / 2 + 2
        : this.parent.offsetHeight / 2 - this.btRight.offsetHeight / 2 + 2;
      this.renderer.setStyle(this.btRight, 'top', tmpTop + 'px');
    }
  }

  // RESIZE EVENT
  @HostListener('window:resize', ['$event']) onResize(event) {
    // console.log('HostListener resize', event, this.waitInterval);
    this.processResizeEvent();
  }

  // KEYBOARD EVENT
  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    if (
      event.key === 'ArrowLeft' &&
      this.focusSlider &&
      !this.isAnimated &&
      this.isDraggable
    ) {
      this.processClickArrow(1);
    }
    if (
      event.key === 'ArrowRight' &&
      this.focusSlider &&
      !this.isAnimated &&
      this.isDraggable
    ) {
      this.processClickArrow(-1);
    }
  }

  // MOUSE EVENTS
  @HostListener('mouseover') onMouseOver() {
    this.focusSlider = true;
    if (this.isArrows && !!this.btLeft && !!this.btRight) {
      this.renderer.setStyle(this.btLeft, 'opacity', '1');
      this.renderer.setStyle(this.btRight, 'opacity', '1');
    }
  }
  @HostListener('mouseout') onMouseOut() {
    this.focusSlider = false;

    if (this.isArrows && !!this.btLeft && !!this.btRight) {
      this.renderer.setStyle(this.btLeft, 'opacity', '0');
      this.renderer.setStyle(this.btRight, 'opacity', '0');
    }
  }
  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    const target: any = event.target;
    this.utils.preventDefault(event);
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      !this.isAnimated
    ) {
      this.isClickBullet = true;
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else {
      if (!this.isAnimated && this.isDraggable) {
        this.processMouseDown(event);
      }
    }
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (!this.isAnimated && this.isDraggable) {
      this.processMouseMove(event);
    }
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    const target: any = event.target;
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      this.isClickBullet &&
      !this.isAnimated
    ) {
      this.processClickBullet(target.id);
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(1);
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(-1);
    } else {
      this.processMouseUp(event);
    }
    this.isClickBullet = false;
    this.isClickArrow = false;
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
    this.processMouseUp(event);
  }

  // TOUCH EVENTS
  @HostListener('touchstart', ['$event']) onTouchStart(event) {
    const target: any = event.target;
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      !this.isAnimated
    ) {
      this.isClickBullet = true;
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else {
      if (!this.isAnimated) {
        this.processMouseDown(event);
      }
    }
  }
  @HostListener('touchmove', ['$event']) onTouchMove(event) {
    if (!this.isAnimated) {
      this.processMouseMove(event);
    }
  }
  @HostListener('touchend', ['$event']) onTouchEnd(event) {
    const target: any = event.target;
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      this.isClickBullet &&
      !this.isAnimated
    ) {
      this.processClickBullet(target.id);
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(1);
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(-1);
    } else {
      this.processMouseUp(event);
    }
    this.isClickBullet = false;
    this.isClickArrow = false;
  }

  getTranslateValues(element) {
    const style = window.getComputedStyle(element);
    const matrix = style['transform'];

    // No transform property. Simply return 0 values.
    if (matrix === 'none' || typeof matrix === 'undefined') {
      return {
        x: 0,
        y: 0,
        z: 0,
      };
    }

    // Can either be 2d or 3d transform
    const matrixType = matrix.includes('3d') ? '3d' : '2d';
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === '2d') {
      return {
        x: parseFloat(matrixValues[4]),
        y: parseFloat(matrixValues[5]),
        z: 0,
      };
    }

    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
      return {
        x: parseFloat(matrixValues[12]),
        y: parseFloat(matrixValues[13]),
        z: parseFloat(matrixValues[14]),
      };
    }
  }

  processMouseDown(event) {
    if (!!this.decelerateAnimInterval) {
      clearInterval(this.decelerateAnimInterval);

      this.marginLeftDrag = this.getTranslateValues(this.divSlides).x;
      this.renderer.setStyle(
        this.divSlides,
        'transform',
        'translateX(' + this.marginLeftDrag + 'px)'
      );
      this.marginLeft = this.marginLeftDrag;
    }
    // remove transition during drag
    if (!!this.divSlides && !!this.parent) {
      this.divSlides.classList.add('dirslider_noTransition');
      this.posX = this.utils.unify(event).clientX - this.parent.offsetLeft;
      this.posY = this.utils.unify(event).clientY;
      this.isDrag = true;
      this.lastAcc = 0;
      this.hasMoved = false;
      this.draggingStartTime = event.timeStamp;
      this.draggingStartPosX = this.utils.unify(event).clientX;
      this.draggingStartPosY = this.utils.unify(event).clientY;
    }
  }

  processMouseMove(event) {
    if (this.isDrag) {
      this.utils.preventDefault(event);
      const diffX =
        this.utils.unify(event).clientX - this.parent.offsetLeft - this.posX;
      const diffY = this.utils.unify(event).clientY - this.posY;
      const tmpDiffX = diffX < 0 ? diffX * -1 : diffX;
      const tmpDiffY = diffY < 0 ? diffY * -1 : diffY;
      // check if scroll or swipe
      if (tmpDiffX > tmpDiffY) {
        this.marginLeftDrag = this.marginLeft + diffX;
        if (this.isInline) {
          if (!this.isBouncable) {
            // can't swipe out of bounds
            this.marginLeftDrag =
              this.marginLeftDrag > 0 ? 0 : this.marginLeftDrag;
            this.marginLeftDrag =
              this.divSlides.offsetWidth -
                this.containerWidth.width +
                this.marginLeftDrag <
              0
                ? this.containerWidth.width - this.divSlides.offsetWidth
                : this.marginLeftDrag;
          }
        }
        // can't swipe more than 1 screen
        if (!this.isInline) {
          if (this.marginLeftDrag < this.marginLeft) {
            if (
              (this.marginLeftDrag + this.marginLeft * -1) * -1 >
              this.containerWidth.width
            ) {
              this.marginLeftDrag = this.marginLeft - this.containerWidth.width;
            }
          } else {
            if (
              (this.marginLeft + this.marginLeftDrag * -1) * -1 >
              this.containerWidth.width
            ) {
              this.marginLeftDrag = this.marginLeft + this.containerWidth.width;
            }
          }
        }
        // move only if necessary
        if (this.marginLeftDrag !== this.marginLeft) {
          this.lastAcc = this.oldDiffX - diffX;
          this.oldDiffX = diffX;
          this.hasMoved = true;
          this.renderer.setStyle(
            this.divSlides,
            'transform',
            'translateX(' + this.marginLeftDrag + 'px)'
          );
        } else {
          this.lastAcc = 0;
          this.hasMoved = false;
          this.canClick.emit(false);
        }
      } else {
        if (this.isInline && this.isVertical) {
          this.marginLeftDrag = this.marginLeft + diffY;
          // can't swipe out of bounds
          this.marginLeftDrag =
            this.marginLeftDrag > 0 ? 0 : this.marginLeftDrag;
          this.marginLeftDrag =
            this.divSlides.offsetHeight -
              this.containerWidth.height +
              this.marginLeftDrag <
            0
              ? this.containerWidth.height - this.divSlides.offsetHeight
              : this.marginLeftDrag;
          // move only if necessary
          if (this.marginLeftDrag !== this.marginLeft) {
            this.hasMoved = true;
            this.renderer.setStyle(
              this.divSlides,
              'margin-top',
              this.marginLeftDrag + 'px'
            );
          } else {
            this.hasMoved = false;
          }
        }
      }
    }
  }

  processMouseUp(event) {
    if (this.isDrag) {
      // add transition when drag finished
      this.divSlides.classList.remove('dirslider_noTransition');
      if (!this.isInline) {
        const diff =
          this.utils.unify(event).clientX - this.parent.offsetLeft - this.posX;
        const tmpDiff = diff < 0 ? diff * -1 : diff;
        if (tmpDiff <= this.containerWidth.width / 3 && !this.isInline) {
          this.isAnimated = true;
          // go back if drag not enough
          this.renderer.setStyle(
            this.divSlides,
            'transform',
            'translateX(' + this.marginLeft + 'px)'
          );
        } else {
          // user move = stop autoSlide
          clearTimeout(this.timeOut);
          this.userStopAutoSlider = true;
          // move prev/next
          if (diff < 0) {
            this.marginLeft = this.marginLeft - this.containerWidth.width;
          } else {
            this.marginLeft = this.marginLeft + this.containerWidth.width;
          }
          if (diff < 0) {
            this.currentSlide =
              this.currentSlide + 1 > this.nbSlides ? 0 : this.currentSlide + 1;
          } else {
            this.currentSlide =
              this.currentSlide - 1 < 0 ? this.nbSlides : this.currentSlide - 1;
          }
          this.renderer.setStyle(
            this.divSlides,
            'transform',
            'translateX(' + this.marginLeft + 'px)'
          );
        }
      } else {
        if (this.isDecelerate) {
          this.draggingEndTime = event.timeStamp;
          this.draggingEndPosX = this.utils.unify(event).clientX;
          this.draggingEndPosY = this.utils.unify(event).clientY;

          const draggingTimeDiff =
            (this.draggingEndTime - this.draggingStartTime) / 1000;
          const draggingDirection = this.lastAcc > 0 ? -1 : 1;
          const draggingPosDistance = Math.sqrt(
            Math.pow(this.draggingEndPosX - this.draggingStartPosX, 2) +
              Math.pow(this.draggingEndPosY - this.draggingStartPosY, 2)
          );
          const velocity = draggingPosDistance / draggingTimeDiff;

          this.marginLeftDrag =
            this.marginLeftDrag + velocity * draggingDirection;
          this.decelerateAnim();
        } else {
          this.marginLeftDrag =
            this.marginLeftDrag > 0 ? 0 : this.marginLeftDrag;
          this.marginLeftDrag =
            this.divSlides.offsetWidth -
              this.containerWidth.width +
              this.marginLeftDrag <
            0
              ? this.containerWidth.width - this.divSlides.offsetWidth
              : this.marginLeftDrag;

          this.renderer.setStyle(
            this.divSlides,
            'transform',
            'translateX(' + this.marginLeftDrag + 'px)'
          );
        }
      }
      if (this.hasMoved === true) {
        if (this.isInline) {
          this.marginLeft = this.marginLeftDrag;
        }
        this.hasMoved = false;
        this.utils.preventDefault(event);
        // send parent user CANNOT handle clicks from element in slides
        this.canClick.emit(false);
      } else {
        this.marginLeftDrag = this.marginLeft;
        // send parent user CAN handle clicks from element in slides
        this.canClick.emit(true);
      }
      this.isDrag = false;
      if (!this.isInline) {
        setTimeout(() => {
          // remove transitions to reconstruct slider
          this.isAnimated = false;
          this.divSlides.classList.add('dirslider_noTransition');
          this.putContent();
        }, 400);
      }
    } else {
      this.canClick.emit(true);
    }
  }

  decelerateAnim() {
    const decelerateReducer =
      window.innerWidth > 768 ? 20 : window.innerWidth > 375 ? 30 : 40;
    const lastAccReducer =
      window.innerWidth > 768 ? 1.5 : window.innerWidth > 375 ? 1.25 : 1;
    // console.log(decelerateReducer, lastAccReducer);
    this.lastAcc = this.lastAcc / lastAccReducer;
    this.decelerateAnimInterval = setInterval(() => {
      // const decelerateValue = (Math.abs(this.lastAcc / decelerateReducer) > 1) ? this.lastAcc / decelerateReducer : (this.lastAcc > 0) ? 1 : -1;
      const decelerateValue = this.lastAcc / decelerateReducer;
      if (this.lastAcc !== 0) {
        this.lastAcc -= decelerateValue;
        this.marginLeftDrag -= this.lastAcc;
        this.lastAcc = Math.abs(this.lastAcc) <= 1 ? 0 : this.lastAcc;
        if (this.marginLeftDrag >= 0) {
          this.marginLeftDrag = 0;
          this.lastAcc = 0;
        }
        if (
          this.divSlides.offsetWidth -
            this.containerWidth.width +
            this.marginLeftDrag <
          0
        ) {
          this.marginLeftDrag =
            this.containerWidth.width - this.divSlides.offsetWidth;
          this.lastAcc = 0;
        }
        this.renderer.setStyle(
          this.divSlides,
          'transform',
          'translateX(' + this.marginLeftDrag + 'px)'
        );
      } else {
        this.marginLeft = this.marginLeftDrag;
        clearInterval(this.decelerateAnimInterval);
      }
    }, 20);
  }

  processClickBullet(index) {
    // user move = stop autoSlide
    clearTimeout(this.timeOut);
    this.userStopAutoSlider = true;
    // move to screen
    this.isAnimated = true;
    const nbDecalScreen =
      this.currentSlide === 0 ? Number(index) + 1 : Number(index);
    this.marginLeft = this.containerWidth.width * nbDecalScreen * -1;
    this.currentSlide = Number(index);
    this.renderer.setStyle(
      this.divSlides,
      'transform',
      'translateX(' + this.marginLeft + 'px)'
    );
    setTimeout(() => {
      // remove transitions to reconstruct slider
      this.isAnimated = false;
      this.divSlides.classList.add('dirslider_noTransition');
      this.putContent();
    }, 400);
  }

  processAutoSlide() {
    if (this.autoSlide && !this.userStopAutoSlider) {
      this.isAnimated = true;
      this.currentSlide =
        this.currentSlide + 1 > this.nbSlides ? 0 : this.currentSlide + 1;

      this.renderer.setStyle(
        this.divSlides,
        'transform',
        'translateX(' + (this.marginLeft - this.containerWidth.width) + 'px)'
      );

      setTimeout(() => {
        // remove transitions to reconstruct slider
        this.isAnimated = false;
        this.divSlides.classList.add('dirslider_noTransition');
        this.putContent();
      }, 400);
    } else {
      clearTimeout(this.timeOut);
    }
  }

  processClickArrow(sens) {
    // user move = stop autoSlide
    clearTimeout(this.timeOut);
    this.userStopAutoSlider = true;
    // process move
    this.isAnimated = true;
    if (sens > 0) {
      this.currentSlide =
        this.currentSlide - 1 >= 0 ? this.currentSlide - 1 : this.nbSlides;
    }
    if (sens < 0) {
      this.currentSlide =
        this.currentSlide + 1 > this.nbSlides ? 0 : this.currentSlide + 1;
    }

    this.containerWidth = this.parent.getBoundingClientRect();

    if (!this.isVertical) {
      this.marginLeftDrag = this.marginLeft + this.containerWidth.width * sens;
    } else {
      const decal =
        this.verticalLimit > 0
          ? this.verticalLimit
          : this.containerWidth.height;
      this.marginLeftDrag = this.marginLeft + decal * sens;
    }

    if (this.isInline) {
      // can't swipe out of bounds
      this.marginLeftDrag = this.marginLeftDrag > 0 ? 0 : this.marginLeftDrag;
      if (!this.isVertical) {
        this.marginLeftDrag =
          this.divSlides.offsetWidth -
            this.containerWidth.width +
            this.marginLeftDrag <
          0
            ? this.containerWidth.width - this.divSlides.offsetWidth
            : this.marginLeftDrag;
      } else {
        this.marginLeftDrag =
          this.divSlides.offsetHeight -
            this.containerWidth.height +
            this.marginLeftDrag <
          0
            ? this.containerWidth.height - this.divSlides.offsetHeight
            : this.marginLeftDrag;
      }
    }

    const marginType = !this.isVertical ? 'transform' : 'margin-top';
    this.renderer.setStyle(
      this.divSlides,
      marginType,
      'translateX(' + this.marginLeftDrag + 'px)'
    );

    if (!this.isInline) {
      setTimeout(() => {
        // remove transitions to reconstruct slider
        this.isAnimated = false;
        this.divSlides.classList.add('dirslider_noTransition');
        this.putContent();
      }, 400);
    } else {
      this.isAnimated = false;
      const nbDecalScreen =
        this.currentSlide === 0 ? this.currentSlide + 1 : this.currentSlide;
      this.marginLeft = !this.isInline
        ? this.containerWidth.width * nbDecalScreen * -1
        : this.marginLeftDrag;
      // make diff between real MarginLeft and dragged marginLeft - to go back to real if mouseup too soon
      this.marginLeftDrag = this.marginLeft;
    }
  }
}
