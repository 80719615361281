import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';

import { BookletModel } from '../models/booklet';
import { Student } from '../models/student';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class BookletService {
  private _booklets: any = [];

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private networkService: NetworkStatusService
  ) {}

  async onInit() {
    // console.log('Booklet done');
    return 'Booklet done';
  }

  fetchBooklet(accountId: string): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId && accountId !== 'GUEST') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/booklet`;

      return this.http.get(url).pipe(
        switchMap(data => {
          // console.log('Booklet data: ', data);
          this.storageService.set(this.storageKey(accountId), data);
          this._booklets = this.buildBookletsFromRawData(data);
          return of(this._booklets);
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Booklet Service failed',
            errorOriginal: err,
          };
        })
      );
    } else {
      return of([]);
    }
  }

  async loadStudentBooklets(student: Student) {
    if (student.isGuest()) {
      this._booklets = [];
    } else {
      // TODO: Handle offline with localstorage
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadFromStorage(student);
      } else {
        this.fetchBooklet(student.remoteId).subscribe(res => {
          this._booklets = res;
        });
      }
    }
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get booklets(): any {
    return this._booklets;
  }

  private;

  async loadFromStorage(student: Student) {
    this.storageService.get(this.storageKey(student.remoteId)).then(res => {
      this._booklets = !!res ? this.buildBookletsFromRawData(res) : [];
    });
  }

  storageKey(accountId: String): string {
    return `${accountId}-booklets`;
  }

  /// Serialize data ///
  buildBookletsFromRawData(data: any): Array<BookletModel> {
    const arr: Array<BookletModel> = [];
    const textData = JSON.stringify(data);
    const parsedData = JSON.parse(textData);

    if (!!parsedData && !!parsedData.data && parsedData.data.length > 0) {
      for (const elt of parsedData.data) {
        const booklet = new BookletModel({
          id: elt.id,
          category: elt.attributes.category,
          statusTitle: elt.attributes.statusTitle,
          evaluation: elt.attributes.evaluation, // A: Acquis | nil: Non évoqué | EVA: En Voie d'Acquisition
          teacher_id: elt.attributes.teacher_id,
          topic: elt.attributes.topic,
        });
        arr.push(booklet);
      }
    }
    return arr;
  }
}
