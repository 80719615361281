import {
  NgZone,
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { PointsDeRendezVousService } from '@app/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'tunnel-no-code-comp',
  templateUrl: 'tunnel-no-code.html',
  styleUrls: ['tunnel-no-code.scss'],
})
export class TunnelNoCodeComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() compDataSent = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  userId = null;
  node: any = null;

  isActive: Boolean = false;

  alreadyCodeForm: FormGroup;
  formCompleted = false;

  compDataToSend = null;
  dataSent = false;

  showBasket = false;
  basketInfos: any = {};
  bulletsList: any = [];
  gearboxType = 'manuelle';

  isDesktop = window.innerWidth > 767 ? true : false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ngZone.run(() => {
      this.isDesktop = window.innerWidth > 767 ? true : false;
    });
  }

  constructor(
    private ngZone: NgZone,
    private pointsDeRendezVousService: PointsDeRendezVousService,
    private formBuilder: FormBuilder
  ) {
    this.constructForms();
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.userId =
      this.compOptions && typeof this.compOptions.userId !== 'undefined'
        ? this.compOptions.userId
        : this.userId;
    this.node =
      this.compOptions && typeof this.compOptions.node !== 'undefined'
        ? this.compOptions.node
        : this.node;
    this.isActive =
      this.compOptions && typeof this.compOptions.isActive !== 'undefined'
        ? this.compOptions.isActive
        : this.isActive;
    this.gearboxType =
      this.compOptions &&
      typeof this.compOptions.gearboxType !== 'undefined' &&
      this.compOptions.gearboxType === 'BVA'
        ? 'automatique'
        : this.gearboxType;
    if (this.isDebug) {
      // console.log('TunnelAlreadyCodeComponent - compOptions: ', this.compOptions);
    }
    if (!!this.node && !!this.node.resume_purchase_info) {
      this.basketInfos = this.node.resume_purchase_info;
    }
    if (!!this.node && !!this.node.data && !!this.node.data.bullet_points) {
      this.bulletsList = this.node.data.bullet_points;
    }

    console.log(this.bulletsList);

    this.dataSent = false; // reset
  }

  constructForms() {
    this.alreadyCodeForm = this.formBuilder.group({
      alreadyCode: ['', Validators.required],
    });
  }

  get getAlreadyCodeForm() {
    return this.alreadyCodeForm.controls;
  }

  setAlreadyCode(value: any) {
    if (!!value && value.toString().trim() !== '') {
      this.alreadyCodeForm.controls['alreadyCode'].patchValue(value);
      this.alreadyCodeForm.controls['alreadyCode'].markAsTouched();
      this.formCompleted = true;
      // this.sendCompData({action: 'navigate', data: {direction: 'next'}}); // auto navigate to next step
    } else {
      this.alreadyCodeForm.controls['alreadyCode'].patchValue('');
      this.alreadyCodeForm.controls['alreadyCode'].markAsTouched();
    }
  }

  toggleBasketDetails() {
    this.showBasket = !this.showBasket;
  }

  setCompDataToSend(data: any) {
    this.compDataToSend = {
      already_code: {
        cdr_obtained: false, // string to bool
      },
    };
    // console.log('compDataToSend: ', this.alreadyCodeForm.value.alreadyCode, this.compDataToSend);
  }

  sendCompData(data: any) {
    if (!this.dataSent) {
      this.dataSent = true;
      // console.log('TunnelLocationComponent - sendCompData: ', data);
      this.setCompDataToSend(null);
      if (!!data) {
        data.compData = this.compDataToSend;
        this.compDataSent.emit(data);
      } else {
        this.compDataSent.emit({
          action: 'error',
          data: 'No data sent from TunnelLocationComponent',
        });
      }
    }
  }
}
