import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { NavigationService } from '@app/services';

import { ModalIframeComponent } from '@app/modals';

import {
  Gesture,
  GestureController,
  IonContent,
  ModalController,
} from '@ionic/angular';

import { EasyDebugDecorator } from '@app/app.decorators';
import * as moment from 'moment-timezone';

@EasyDebugDecorator
@Component({
  selector: 'calendar-code-exam-reservation',
  templateUrl: 'calendar-code-exam-reservation.html',
  styleUrls: ['calendar-code-exam-reservation.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarCodeExamReservationComponent implements OnInit, OnChanges {
  @Input() compOptions: any;
  @Output() changeWeek = new EventEmitter<boolean>();
  @Output() selectSession = new EventEmitter<any>();
  @ViewChild('codeExamReservationCalendar')
  codeExamReservationCalendar: ElementRef;

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  isDesktop = window.innerWidth > 767 ? true : false;

  days = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'];
  fullDays = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ];
  months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  sessions = {};
  firstSession = null;
  firstSessionDay = 0;
  firstDayOfWeek = null;
  currentWeekDays = [];
  currentMonth = '';
  selectedDay = null;
  selectedDaySessions = null;
  gesture: Gesture;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = window.innerWidth > 767 ? true : false;
    this.setGesture();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private modalController: ModalController,
    private gestureController: GestureController
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
      this.setGlobals();
    }
  }

  ngOnInit() {
    this.gesture = this.gestureController.create(
      {
        el: this.codeExamReservationCalendar.nativeElement,
        gestureName: 'week-swipe-gesture',
        threshold: 30,
        onEnd: event => this.weekSwipeHandlerEnd(event),
      },
      true
    );
    this.setGesture();
    this.setGlobals();
    // console.log('sessions', this.sessions);

    this.setWeek(true);
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.sessions =
      this.compOptions && typeof this.compOptions.sessions !== 'undefined'
        ? this.compOptions.sessions
        : this.sessions;

    this.cdr.detectChanges();
  }

  setGesture() {
    if (this.isDesktop) {
      this.gesture.enable(false);
    }
    if (!this.isDesktop) {
      this.gesture.enable(true);
    }
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  setWeek(init = false) {
    if (init) {
      // DATE TO CHANGE
      const tomorrow = this.dateToFormatFr();
      tomorrow.date(tomorrow.date() + 1);
      tomorrow.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

      // DATE TO CHANGE
      this.firstSession =
        Object.keys(this.sessions).length > 0
          ? moment(`${Object.keys(this.sessions)[0]}T00:00:00`)
          : tomorrow;
      this.firstSessionDay =
        this.firstSession.day() === 0 ? 6 : this.firstSession.day() - 1;
      // DATE TO CHANGE
      this.firstDayOfWeek = this.dateToFormatFr(this.firstSession);
      // DATE TO CHANGE
      this.firstDayOfWeek.date(
        this.firstDayOfWeek.date() - this.firstSessionDay
      );
      this.firstDayOfWeek = this.dateToFormatFr(new Date(this.firstDayOfWeek));
    }
    this.currentWeekDays = this.days.map((elt, index) => {
      // DATE TO CHANGE
      let date = this.dateToFormatFr(this.firstDayOfWeek);
      // DATE TO CHANGE
      date.date(date.date() + index);
      date = this.dateToFormatFr(date);
      return {
        dayName: elt,
        fullDate: date,
        dateNumber: date.date(),
        monthNumber: date.month(),
        year: date.year(),
      };
    });
    this.currentMonth = this.currentWeekDays
      .reduce((acc, curr) => {
        if (acc.indexOf(this.months[curr.monthNumber]) === -1) {
          acc.push(this.months[curr.monthNumber]);
        }
        return acc;
      }, [])
      .join(' - ');

    this.selectDay(this.currentWeekDays[this.firstSessionDay]);
    this.cdr.detectChanges();
    // console.log('currentWeekDays', this.currentWeekDays);
  }

  selectDay(day) {
    this.selectedDay = day.fullDate;
    const formattedSlectedDay = `${day.year}-${day.monthNumber + 1 >= 10 ? day.monthNumber + 1 : '0' + (day.monthNumber + 1)}-${day.dateNumber >= 10 ? day.dateNumber : '0' + day.dateNumber}`;
    this.selectedDaySessions = this.sessions[formattedSlectedDay];
    if (!!this.selectedDaySessions) {
      this.selectedDaySessions = this.selectedDaySessions.map(elt => {
        // DATE TO CHANGE
        const startsAt = this.dateToFormatFr(elt.starts_at);
        const formattedHour = `${startsAt.hours() >= 10 ? startsAt.hours() : '0' + startsAt.hours()}:${startsAt.minutes() >= 10 ? startsAt.minutes() : '0' + startsAt.minutes()}`;
        const weekDay = this.fullDays[startsAt.day()];
        const dateNumber =
          startsAt.date() < 10 ? '0' + startsAt.date() : startsAt.date();
        const month = this.months[startsAt.month()];
        const formattedDate = `${weekDay} ${dateNumber} ${month}`;
        return {
          ...elt,
          formattedHour: formattedHour,
          formattedDate: formattedDate,
        };
      });
    }
    // console.log('selectedDaySessions', this.selectedDaySessions);
  }

  changeWeekEvent(direction) {
    if (direction === 'next') {
      // DATE TO CHANGE
      this.firstDayOfWeek.date(this.firstDayOfWeek.date() + 7);
      this.firstDayOfWeek = this.dateToFormatFr(this.firstDayOfWeek);
    }
    if (direction === 'previous') {
      // DATE TO CHANGE
      this.firstDayOfWeek.date(this.firstDayOfWeek.date() - 7);
      this.firstDayOfWeek = this.dateToFormatFr(this.firstDayOfWeek);
    }
    this.firstSessionDay = 0;
    this.setWeek();

    this.changeWeek.emit(direction);
  }

  weekSwipeHandlerEnd(event) {
    if (event?.type === 'pan') {
      const deltaX = event.deltaX;
      const direction = deltaX < 0 ? 'next' : 'previous';
      this.changeWeekEvent(direction);
    }
  }

  selectSessionEvent(session) {
    this.selectSession.emit(session);
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options?) {
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
