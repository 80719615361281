import {
  NgZone,
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { Platform } from '@ionic/angular';

import { PointsDeRendezVousService } from '@app/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'tunnel-location-comp',
  templateUrl: 'tunnel-location.html',
  styleUrls: ['tunnel-location.scss'],
})
export class TunnelLocationComponent implements AfterViewInit, OnChanges {
  @Input() compOptions: any;
  @Output() compDataSent = new EventEmitter<any>();

  @ViewChild('gMap', { static: false }) mapElement: ElementRef;

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  userId = null;
  userIsTyping = false;

  isActive: Boolean = false;

  compDataToSend: any = null;
  dataSent = false;

  isDesktop = window.innerWidth > 767 ? true : false;

  autocomplete: any;
  placeLocationsSent = false;
  locationFound = false;
  geocoder: any;

  userInput = '';
  userAddress = '';
  userPosition: any = null;

  pricingZone = '';
  idfDepartement = ['75', '77', '78', '91', '92', '93', '94', '95'];

  apiLoading = false;
  geolocationLoading = false;
  noGeolocText = '';
  modalNoGeoloc = false;
  skipGeoloc = false;
  geolocFounded = false;
  modalLocationError = false;
  modalSearchError = false;

  modalNoResults = false;
  addressInvalid = false;

  gearboxType = 'BVM';
  dispoResults: any = [];
  fetchByCoordinatesFailed = false;

  userNearestLocation = '';
  fetchNearestLocationFailed = false;
  savedNearestLocations = null;
  cardNearestLocationData: any = null;

  map: any;
  mapLoading = false;
  franceLocation = { lat: 46.927638, lng: 1.713749 };
  bounds = new google.maps.LatLngBounds();
  boundsPoints = [];
  mapLoaded = false;
  markers = [];
  lastActiveMarker: any;
  infoWindow: any;
  infoWindowShown = false;
  lastZoom = null;
  zoomOptionsUpdated = false;

  isCordova = false;
  isIOS = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ngZone.run(() => {
      this.isDesktop = window.innerWidth > 767 ? true : false;
    });
  }

  constructor(
    protected ngZone: NgZone,
    protected renderer: Renderer2,
    public platform: Platform,
    protected pointsDeRendezVousService: PointsDeRendezVousService
  ) {
    this.isCordova = this.platform.is('cordova');
    this.isIOS = this.isCordova && this.platform.is('ios');
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.userId =
      this.compOptions && typeof this.compOptions.userId !== 'undefined'
        ? this.compOptions.userId
        : this.userId;
    this.isActive =
      this.compOptions && typeof this.compOptions.isActive !== 'undefined'
        ? this.compOptions.isActive
        : this.isActive;
    this.gearboxType =
      this.compOptions && typeof this.compOptions.gearboxType !== 'undefined'
        ? this.compOptions.gearboxType
        : this.gearboxType;
    if (this.isDebug) {
      // console.log('TunnelLocationComponent - compOptions: ', this.compOptions);
    }
    this.dataSent = false; // reset
  }

  ngAfterViewInit() {
    this.googleMapsInit();
  }

  googleMapsInit() {
    if (!!this.mapElement) {
      // console.log('googleMapsInit with mapElement');
      const zoomValue = window.innerWidth >= 768 ? 5 : 5;
      const styledMapType = new google.maps.StyledMapType(
        [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
        { name: 'Styled Map' }
      );

      // console.log(this.mapElement);
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        center: this.franceLocation,
        zoom: zoomValue,
        zoomControl: true,
        minZoom: 5,
        maxZoom: 18,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'styled_map'],
        },
      });

      // Associate the styled map with the MapTypeId and set it to display.
      this.map.mapTypes.set('styled_map', styledMapType);
      this.map.setMapTypeId('styled_map');

      const options = {
        componentRestrictions: { country: 'fr' },
      };

      const input: any = document.getElementById('userInput');
      input.placeholder = '';
      this.userAddress = input.value;
      this.autocomplete = new google.maps.places.Autocomplete(input, options);

      google.maps.event.addListener(this.autocomplete, 'place_changed', e => {
        // console.log('place_changed', e);
        this.mapLoading = true;
        input.blur();
        // console.log('user input', input.value);
        this.hideToasterInvalidAddress();
        this.removeUserPositionMarker();
        this.resetMarker();
        this.resetActiveMarker();
        this.geoCoding();
      });

      google.maps.event.addListener(this.map, 'zoom_changed', () => {
        input.blur();
        // console.log('zoom value', this.map.getZoom());
        this.lastZoom = this.map.getZoom();
      });

      window.addEventListener('wheel', (event: any) => {
        input.blur();
        if (!this.zoomOptionsUpdated) {
          // console.log('update setOptions on wheel');
          this.map.setOptions({
            minZoom: 5,
            maxZoom: 18,
          });
          this.zoomOptionsUpdated = true;
        }
      });
      window.addEventListener('touchstart', (event: any) => {
        // input.blur();
        if (!this.zoomOptionsUpdated) {
          // console.log('update setOptions on wheel');
          this.map.setOptions({
            minZoom: 5,
            maxZoom: 18,
          });
          this.zoomOptionsUpdated = true;
        }
      });

      this.geocoder = new google.maps.Geocoder();

      this.infoWindow = new google.maps.InfoWindow({
        content: '',
        disableAutoPan: true,
      });

      this.map.addListener('click', (event: any) => {
        input.blur();
        if (this.infoWindowShown) {
          this.infoWindow.close();
          this.infoWindowShown = false;
        }
        // this.reduceCards();
      });

      this.map.addListener('dragstart', () => {
        input.blur();
        if (this.infoWindowShown) {
          this.infoWindow.close();
          this.infoWindowShown = false;
        }
        // this.reduceCards();
      });

      google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
        // console.log('tilesloaded');
        this.mapLoaded = true;
      });
    } else {
      const input: any = document.getElementById('userInput');
      input.placeholder = '';
      this.userAddress = input.value;
    }
  }

  geoCoding() {
    this.geolocationLoading = false;
    this.placeLocationsSent = true;
    const address: any = document.getElementById('userInput');
    address.placeholder = '';
    this.userAddress = address.value;
    if (!!address && !!address.value && address.value !== '') {
      this.geocoder.geocode(
        { address: address.value },
        (results: any, status: any) => {
          // console.log('geoCoding results', results);
          if (status === 'OK') {
            this.setCompDataToSend(results[0]);
            this.hideToasterInvalidAddress();
            this.addMarker(
              {
                position: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                },
              },
              false,
              true
            );
            this.userPosition = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };
            // console.log('GEOCODING OK', results[0].formatted_address + '\nlat: ' + results[0].geometry.location.lat() + ' long: ' + results[0].geometry.location.lng());
            this.fetchByCoordinates(
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng()
            );
          } else {
            // console.log('GEOCODING KO', status);
            this.showToasterInvalidAddress();
          }
        }
      );
    }
  }

  showCurrentLocation() {
    if (!this.geolocationLoading) {
      const input: any = document.getElementById('userInput');
      input.placeholder = '';
      this.skipGeoloc = false;
      this.geolocFounded = false;
      if (navigator.geolocation && 'geolocation' in navigator) {
        this.geolocationLoading = true;
        setTimeout(() => {
          this.geolocationLoading = false;
          if (!this.skipGeoloc && !this.geolocFounded) {
            this.skipGeoloc = true;
            this.geolocFounded = false;
            this.noGeolocText =
              'Nous ne sommes pas parvenus à récupérer ta position !';
            this.showModalNoGeoloc();
          }
        }, 5000);
        // ya un prob avec ce truc des fois il se lance mais ne fait rien d'autre ni confirmation ni erreur
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
            this.geolocFounded = true;
            this.skipGeoloc = false;
            if (!this.skipGeoloc) {
              this.geolocationLoading = false;
              // console.log(position);
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.geocoder.geocode(
                { location: pos },
                (results: any, status: any) => {
                  // console.log('results', results);
                  // console.log('status', status);
                  if (status === 'OK') {
                    if (results[0]) {
                      this.setCompDataToSend(results[0]);
                      if (!!results[0].formatted_address) {
                        const address: any =
                          document.getElementById('userInput');
                        input.placeholder = '';
                        address.value = results[0].formatted_address;
                        this.placeLocationsSent = true;
                      }
                      this.geolocationLoading = false;
                      this.addMarker(
                        {
                          position: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                          },
                        },
                        false,
                        true
                      );
                      this.userPosition = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                      };
                      this.fetchByCoordinates(pos.lat, pos.lng);
                    } else {
                      this.geolocationLoading = false;
                      this.skipGeoloc = true;
                      this.geolocFounded = false;
                      this.noGeolocText =
                        'Nous ne sommes pas parvenus à récupérer ta position !';
                      this.showModalNoGeoloc();
                    }
                  } else {
                    this.geolocationLoading = false;
                    this.skipGeoloc = true;
                    this.geolocFounded = false;
                    this.noGeolocText =
                      'Nous ne sommes pas parvenus à récupérer ta position !';
                    this.showModalNoGeoloc();
                  }
                }
              );
            }
          },
          () => {
            this.geolocationLoading = false;
            this.geolocFounded = false;
            if (!this.skipGeoloc) {
              this.skipGeoloc = true;
              this.noGeolocText =
                'Nous ne sommes pas parvenus à récupérer ta position !';
              this.showModalNoGeoloc();
            }
          }
        );
      } else {
        this.geolocationLoading = false;
        this.geolocFounded = false;
        if (!this.skipGeoloc) {
          this.noGeolocText =
            'Ton navigateur ne supporte pas la Geolocalisation!';
          this.showModalNoGeoloc();
        }
      }
    }
  }

  fetchByCoordinates(lat: any, lng: any, nonDeserved?: boolean) {
    this.ngZone.run(() => {
      this.apiLoading = true;
      this.userNearestLocation = '';
      this.cardNearestLocationData = null;
    });
    // console.log('fetchByCoordinates', lat, lng, nonDeserved);
    const nonDeservedParam = !!nonDeserved && nonDeserved ? nonDeserved : false;
    this.pointsDeRendezVousService
      .fetchByCoordinates(lat, lng, nonDeservedParam, this.gearboxType)
      .subscribe(
        res => {
          // console.log('fetchByCoordinates res', res);
          if (!!res && !!res.errorCode && res.errorCode === 'E301') {
            this.fetchByCoordinatesFailed = true;
          }
          if (this.fetchByCoordinatesFailed) {
            this.ngZone.run(() => {
              this.locationFound = false;
              this.apiLoading = false;
            });
            this.showModalSearchError();
          } else if (!!res && res.length > 0) {
            this.dispoResults = [];
            const sortedByDistance = res.sort(
              (a: any, b: any) => a.location.distance - b.location.distance
            );
            for (let i = 0, len = sortedByDistance.length; i < len; i++) {
              const horaires = [];
              const teachers = [];
              if (sortedByDistance[i].day_of_week.length > 0) {
                const orderedDays = [
                  '1-Monday',
                  '2-Tuesday',
                  '3-Wednesday',
                  '4-Thursday',
                  '5-Friday',
                  '6-Saturday',
                  '0-Sunday',
                ];
                // const sortedDayOfWeek = res[i].day_of_week.sort((a: any, b: any) => a.key.localeCompare(b.name));
                for (let d = 0, lend = orderedDays.length; d < lend; d++) {
                  let cleanDay = '';
                  const findDayInWeek = sortedByDistance[i].day_of_week.find(
                    elt => elt.key === orderedDays[d]
                  );
                  switch (orderedDays[d]) {
                    case '0-Sunday':
                      cleanDay = 'DIM';
                      break;
                    case '1-Monday':
                      cleanDay = 'LUN';
                      break;
                    case '2-Tuesday':
                      cleanDay = 'MAR';
                      break;
                    case '3-Wednesday':
                      cleanDay = 'MER';
                      break;
                    case '4-Thursday':
                      cleanDay = 'JEU';
                      break;
                    case '5-Friday':
                      cleanDay = 'VEN';
                      break;
                    case '6-Saturday':
                      cleanDay = 'SAM';
                      break;
                  }
                  horaires.push({
                    day: cleanDay,
                    horaire: !!findDayInWeek
                      ? findDayInWeek.min_hour + ' - ' + findDayInWeek.max_hour
                      : '-',
                  });
                }
              }
              if (sortedByDistance[i].teachers.length > 0) {
                for (
                  let t = 0, lent = sortedByDistance[i].teachers.length;
                  t < lent;
                  t++
                ) {
                  // ajouter la rule de si sortedByDistance[i].teachers[t].nb_rating < 50 on affiche nouveau
                  // check avec le produit comment on affiche ça au pire
                  const optimizedImg =
                    !!sortedByDistance[i].teachers[t].picture_url &&
                    sortedByDistance[i].teachers[t].picture_url !==
                      'missing_avatar.png'
                      ? sortedByDistance[i].teachers[t].picture_url.replace(
                          'original',
                          'thumb'
                        )
                      : 'assets/img/default-avatar.png';
                  teachers.push({
                    image: optimizedImg,
                    name: sortedByDistance[i].teachers[t].first_name,
                    carModel: sortedByDistance[i].teachers[t].car_model,
                    note: sortedByDistance[i].teachers[t].rating,
                    notes: sortedByDistance[i].teachers[t].nb_rating,
                  });
                }
              }
              let cleanDistance = 0 + 'm';
              if (sortedByDistance[i].location.distance < 1) {
                cleanDistance =
                  sortedByDistance[i].location.distance.toFixed(3).substr(2) +
                  'm';
                if (cleanDistance.charAt(0) === '0') {
                  // 039m
                  cleanDistance = cleanDistance.substr(1); // 39m
                }
                if (cleanDistance.charAt(0) === '0') {
                  // 009m
                  cleanDistance = cleanDistance.substr(1); // 9m
                }
              } else {
                cleanDistance =
                  sortedByDistance[i].location.distance.toFixed(1) + 'km';
                if (cleanDistance.substr(-3) === '0km') {
                  cleanDistance = cleanDistance.slice(0, -4) + 'km';
                }
              }
              this.dispoResults.push({
                address: sortedByDistance[i].location.name,
                distance: cleanDistance,
                itemActive: 'horaires',
                horaires: horaires,
                teachers: teachers,
                data: sortedByDistance[i],
              });
            }
            this.dispoResults[0].active = true;
            // console.log('fetchByCoordinates dispoResults', this.dispoResults);
            this.ngZone.run(() => {
              this.locationFound = true;
              this.apiLoading = false;
            });
          } else {
            this.ngZone.run(() => {
              this.locationFound = false;
            });
            // console.log('fetchByCoordinates no results');
            this.dispoResults = [];
            // this.showModalNoResults();
            this.fetchNearestLocation(lat, lng);
          }
        },
        error => {
          this.ngZone.run(() => {
            this.locationFound = false;
          });
          console.error(
            'PointsDeRendezVousPage: fetchByLocationId error => ' +
              JSON.stringify(error)
          );
        }
      );
  }

  async fetchNearestLocation(lat: any, lng: any) {
    // console.log('fetchNearestLocation', lat, lng);
    this.savedNearestLocations = null;
    const locationArray =
      await this.pointsDeRendezVousService.fetchNearestLocation(
        lat,
        lng,
        this.gearboxType
      );
    this.ngZone.run(() => {
      this.apiLoading = false;
    });
    // console.log('fetchNearestLocation locationArray', locationArray);
    if (!!locationArray && !!locationArray.errorCode) {
      return;
    }
    const result = locationArray[0].attributes;
    this.savedNearestLocations = locationArray;
    if (!!result && !!result.more_15km_radius) {
      if (!!result.name && !!result.zipcode) {
        // force Angular to update the view with async data
        this.ngZone.run(() => {
          this.userNearestLocation = `${result.city} (${result.zipcode})`;
        });
        // console.log('userNearestLocation => ', this.userNearestLocation);
      }
    }
    let pricingZone: any = null;
    if (
      !!result &&
      !!result.departement &&
      !!result.departement.number &&
      !!result.departement.department &&
      result.departement.pricing_zone_id
    ) {
      if (this.idfDepartement.indexOf(result.departement.number) > -1) {
        pricingZone = `Île-de-France`;
      } else {
        pricingZone = result.departement.department;
      }
    }
    const nearestSpot = this.addMarker(
      {
        position: { lat: result.latitude, lng: result.longitude },
        location: {
          name: result.name,
          zipcode: result.zipcode,
          pricingZone: pricingZone,
        },
      },
      true,
      false
    );
    this.showMapCard(nearestSpot);
    for (let i = 0, len = locationArray.length; i < len; i++) {
      if (
        i > 0 &&
        !!locationArray[i]?.attributes?.name &&
        !!locationArray[i].attributes.zipcode
      ) {
        this.addMarker(
          {
            position: {
              lat: locationArray[i].attributes.latitude,
              lng: locationArray[i].attributes.longitude,
            },
            location: {
              name: locationArray[i].attributes.name,
              zipcode: locationArray[i].attributes.zipcode,
              pricingZone: pricingZone,
            },
          },
          false,
          false,
          true
        );
      }
    }
  }

  addMarker(
    markerProperties: any,
    showInfowWindow = false,
    userPosition = false,
    unmarked = false
  ) {
    const markerSettings: any = {};
    markerSettings.position = markerProperties.position;
    markerSettings.animation = google.maps.Animation.DROP;
    if (userPosition) {
      markerSettings.icon =
        'https://app.envoituresimone.com/assets/png/pin-location.png';
    } else {
      if (!unmarked) {
        if (this.gearboxType.toUpperCase() === 'BVA') {
          markerSettings.icon = 'assets/png/pin-active-auto.png';
        } else {
          markerSettings.icon =
            'https://app.envoituresimone.com/assets/png/pin-active.png';
        }
      } else {
        markerSettings.icon =
          'https://app.envoituresimone.com/assets/png/pin-default.png';
      }
    }
    let markerTitle = '';
    if (userPosition) {
      markerTitle = 'Tu es ici';
    } else {
      markerTitle = markerProperties.location.name;
    }
    // console.log('market title => ', markerTitle);
    markerSettings.title = markerTitle;
    if (userPosition) {
      markerSettings.userPosition = true;
    }
    // console.log(`market settings => `, markerSettings);
    const marker: any = new google.maps.Marker(markerSettings);
    marker.setMap(this.map); // add to map

    if (userPosition) {
      this.showInfoWindow(marker);
    }

    if (!userPosition && showInfowWindow) {
      this.lastActiveMarker = marker;
    }

    // addlisteners
    marker.addListener('mouseover', () => {
      // this.showInfoWindow(marker);
      // if (!marker.userPosition) {
      //   marker.setAnimation(google.maps.Animation.BOUNCE);
      // }
    });
    marker.addListener('mouseout', () => {
      // marker.setAnimation(null);
      // if (this.infoWindowShown) {
      //   this.infoWindow.close();
      //   this.infoWindowShown = false;
      // }
    });
    marker.addListener('click', () => {
      const input: any = document.getElementById('userInput');
      input.placeholder = '';
      input.blur();
      // console.log('this.markers', this.markers);
      // console.log('marker', marker);
      // console.log('this.lastActiveMarker', this.lastActiveMarker);
      const findMarker = this.markers.find(elt => elt === marker);
      if (findMarker !== this.lastActiveMarker) {
        if (!!findMarker && !marker.userPosition) {
          if (!!this.lastActiveMarker) {
            this.lastActiveMarker.setIcon(
              'https://app.envoituresimone.com/assets/png/pin-default.png'
            );
          }
          findMarker.setIcon(
            'https://app.envoituresimone.com/assets/png/pin-active.png'
          );
          this.lastActiveMarker = marker;
        }
        if (marker.userPosition) {
          this.showInfoWindow(marker);
        }
        // this.pricingZone = marker.pricingZone;
        // this.zipCode = marker.zipCode;
      } else {
        this.updateZoom();
        this.centerMap(marker.position.lat(), marker.position.lng());
      }
      if (!!findMarker) {
        this.showMapCard(findMarker);
      }
    });

    if (showInfowWindow) {
      // this.updateZoom();
      this.centerMap(marker.position.lat(), marker.position.lng());
      // this.showInfoWindow(marker);
    }
    this.markers.push(marker);
    return marker;
  }

  showMapCard(marker: any) {
    // missing first result of nearest locations
    // only work with marker click
    // console.log('marker', marker);
    // console.log('this.savedNearestLocations', this.savedNearestLocations);
    if (!!marker) {
      const findLocation = this.savedNearestLocations.find(
        elt => elt.attributes.name === marker.title
      );
      if (!!findLocation) {
        this.ngZone.run(() => {
          this.cardNearestLocationData = {
            name: findLocation.attributes.city || 'Erreur',
            distance: !!findLocation.attributes.distance
              ? findLocation.attributes.distance + 'km'
              : '0km',
          };
        });
      }
    }
  }

  updateZoom() {
    // console.log(this.map.getZoom(), this.lastZoom);
    if (this.map.getZoom() < this.lastZoom) {
      this.map.setZoom(this.lastZoom);
    }
  }

  centerMap(lat: any, lng: any) {
    this.newBounds();
    this.addToBounds(this.userPosition.lat, this.userPosition.lng);
    this.addToBounds(lat, lng);
    this.processBounds();
    this.fitBounds();
  }

  newBounds() {
    this.bounds = new google.maps.LatLngBounds();
    this.boundsPoints = [];
  }

  addToBounds(lat: any, lng: any) {
    this.boundsPoints.push(new google.maps.LatLng(lat, lng));
  }

  processBounds() {
    // console.log('processBounds', this.boundsPoints);
    for (let i = 0, len = this.boundsPoints.length; i < len; i++) {
      this.bounds.extend(this.boundsPoints[i]);
    }
  }

  fitBounds() {
    if (this.boundsPoints.length === 1) {
      this.map.setOptions({
        minZoom: 11,
        maxZoom: this.map.getZoom() >= 11 ? this.map.getZoom() : 16,
      });
      this.zoomOptionsUpdated = false;
      this.lastZoom = this.map.getZoom() !== 14 ? this.map.getZoom() : 14;
    } else {
      // console.log('setOptions 18');
      this.map.setOptions({
        minZoom: 5,
        maxZoom: 18,
      });
      this.zoomOptionsUpdated = true;
    }
    const paddingBounds = 50;
    // const paddingLeftBounds = (this.isDesktop) ? 432 : 50;
    const paddingBottomBounds = 75;
    // const paddingRightBounds = (this.isDesktop) ? 50 : 50;
    // console.log({bottom: paddingBottomBounds, top: paddingTopBounds, left: paddingLeftBounds, right: paddingRightBounds});
    this.map.fitBounds(this.bounds, {
      bottom: paddingBottomBounds,
      top: 75,
      left: paddingBounds,
      right: paddingBounds,
    });

    // if (this.boundsPoints.length === 1) {
    //   const middleMapH = this.mapElement.nativeElement.offsetHeight / 2;
    //   const middleMapW = this.mapElement.nativeElement.offsetWidth / 2;
    //   const cardHeight = (this.showResultsFullCards) ? 307 : 140;
    //   const middleVisibleZone = (this.mapElement.nativeElement.offsetHeight - cardHeight) / 2;
    //   const offsetYMap = middleMapH - middleVisibleZone - 20; // 20 = pin /2
    //   const offsetXMap = middleMapW + 216 - 20; // 20 = pin /2
    //   console.log('middleMap', middleMap);
    //   console.log('middleVisibleZone', middleVisibleZone);
    //   console.log('offsetYMap = middleMap - middleVisibleZone', offsetYMap);
    //   if (!this.isDesktop) {
    //     // set center above cards
    //     this.map.panBy(0, offsetYMap);
    //   } else {
    //     this.map.panBy(offsetXMap, 0);
    //   }
    // }
  }

  resetMarker() {
    for (let i = 0, len = this.markers.length; i < len; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  }

  resetActiveMarker() {
    this.updateMarker(true);
    this.infoWindow.close();
    this.infoWindowShown = false;
  }

  updateMarker(reset?: boolean) {
    // console.log('updateMarker reset', reset);
    // console.log('updateMarker this.lastActiveMarker', this.lastActiveMarker);
    if (reset) {
      for (let i = 0, len = this.markers.length; i < len; i++) {
        this.markers[i].setIcon(
          'https://app.envoituresimone.com/assets/png/pin-default.png'
        );
      }
    }
  }

  showInfoWindow(marker: any) {
    // console.log('marker => ', marker);
    const contentString = marker.title;
    if (this.infoWindowShown) {
      this.infoWindow.close();
      this.infoWindowShown = false;
    }
    this.infoWindow = new google.maps.InfoWindow({
      content: contentString,
      disableAutoPan: true,
    });
    this.infoWindow.open(this.map, marker);
    this.infoWindowShown = true;
  }

  removeUserPositionMarker() {
    const markerUserLocation = this.markers.find(elt => elt.userPosition);
    const markerUserLocationIndex = this.markers.findIndex(
      elt => elt.userPosition
    );
    if (!!markerUserLocation) {
      google.maps.event.clearListeners(
        this.markers[markerUserLocationIndex],
        'click'
      );
      this.markers.splice(markerUserLocationIndex, 1);
      markerUserLocation.setMap(null);
    } else {
      // console.log('no user position marker');
    }
    this.infoWindow.close();
    this.infoWindowShown = false;
  }

  showToasterInvalidAddress() {
    this.addressInvalid = true;
    setTimeout(() => {
      this.hideToasterInvalidAddress();
      this.cleanMap();
    }, 1000);
  }

  hideToasterInvalidAddress() {
    this.addressInvalid = false;
  }

  showModalNoResults() {
    this.modalNoResults = true;
  }

  hideModalNoResults(withNoReset?: boolean) {
    this.modalNoResults = false;
    if (!withNoReset) {
      this.cleanMap();
    }
  }

  showModalNoGeoloc() {
    this.modalNoGeoloc = true;
    this.geolocationLoading = false;
  }

  hideModalNoGeoloc() {
    this.modalNoGeoloc = false;
    this.geolocationLoading = false;
  }

  showModalLocationError() {
    this.modalLocationError = true;
    this.geolocationLoading = false;
  }

  hideModalLocationError() {
    this.modalLocationError = false;
    this.geolocationLoading = false;
  }

  showModalSearchError() {
    this.modalSearchError = true;
    this.geolocationLoading = false;
  }

  hideModalSearchError() {
    this.modalSearchError = false;
    this.geolocationLoading = false;
  }

  cleanMap() {
    this.savedNearestLocations = null;
    this.ngZone.run(() => {
      this.locationFound = false;
      this.cardNearestLocationData = null;
    });
    this.resetInput();
    this.removeUserPositionMarker();
    this.resetMarker();
    this.resetActiveMarker();
    this.centerMap(this.franceLocation.lat, this.franceLocation.lng);
  }

  checkUserInputValue() {
    const input: any = document.getElementById('userInput');
    input.placeholder = '';
    if (!!input) {
      this.userIsTyping = input.value.length > 0 ? true : false;
    }
  }

  resetInput() {
    const input: any = document.getElementById('userInput');
    input.placeholder = '';
    input.value = '';
    if (this.placeLocationsSent) {
      this.dispoResults = [];
    }
    if (this.modalNoResults) {
      this.modalNoResults = false;
    }
    this.hideToasterInvalidAddress();
    this.placeLocationsSent = false;
    this.userNearestLocation = '';
    this.userIsTyping = false;
    input.focus();
    setTimeout(() => {
      this.userAddress = '';
    }, 200);
  }

  setCompDataToSend(data: any) {
    const postalCode = data.address_components.find(
      elt => elt.types[0] === 'postal_code'
    );
    const cityName = data.address_components.find(
      elt => elt.types[0] === 'locality'
    );
    this.compDataToSend = {
      location: {
        zipcode: postalCode?.long_name || null, // string
        city_name: cityName?.long_name || null, // string
        address: data.formatted_address || null, // string
        latitude: data.geometry.location.lat() || null, // float
        longitude: data.geometry.location.lng() || null, // float
      },
    };
    // console.log('compDataToSend: ', this.compDataToSend);
  }

  sendCompData(data: any) {
    if (!this.dataSent) {
      this.dataSent = true;
      // console.log('TunnelLocationComponent - sendCompData: ', data);
      if (!!data) {
        data.compData = this.compDataToSend;
        this.compDataSent.emit(data);
      } else {
        this.compDataSent.emit({
          action: 'error',
          data: 'No data sent from TunnelLocationComponent',
        });
      }
    }
  }
}
