import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppInitService } from '../../app/services/app-init.service';
import { StorageService } from '@app/app.services';

@Injectable({
  providedIn: 'root',
})
export class EvsResponseInterceptor implements HttpInterceptor {
  whitelistUrls = [
    // 'validate_token',
    // 'auth',
    // 'students'
  ];
  blacklistUrls = [
    // 'payments',
    // 'coupons',
    // 'gifts',
    // 'students',
    // 'data_prop',
    // 'cerfa',
    // 'theory_tests',
    // 'badges',
    // 'cdr_lessons',
    // 'series',                  // getSeries
    // 'series/',                 // getSerie
    // 'sessions',                // getSessions
    // 'sessions/',               // getSession
    // 'questions',               // getQuestions
    // 'questions/',              // getQuestion
    // 'questions/5345601267',    // getQuestion/5345601267 for test question 7 serie 4
    // 'themes',
    // 'offers',
    // 'driving_exam',
    // 'lessons',
    // 'locations',
    // 'availability',
    // 'availability_group_by_day',
    // 'booklet',
    // 'account_dashboard',
    // 'official_papers',
  ];
  randomBlacklistUrls = [
    // 'payments',
    // 'coupons',
    // 'gifts',
    // 'students',
    // 'data_prop',
    // 'cerfa',
    // 'theory_tests',
    // 'badges',
    // 'cdr_lessons',
    // 'series',                  // getSeries
    // 'series/',                 // getSerie
    // 'sessions',                // getSessions
    // 'sessions/',               // getSession
    // 'questions',               // getQuestions
    // 'questions/',              // getQuestion
    // 'questions/5345601267',    // getQuestion/5345601267 for test question 7 serie 4
    // 'themes',
    // 'offers',
    // 'driving_exam',
    // 'lessons',
    // 'locations',
    // 'availability',
    // 'availability_group_by_day',
    // 'booklet',
    // 'account_dashboard',
    // 'official_papers',
  ];
  randomInt = this.randomMinMax(0, this.randomBlacklistUrls.length - 1); // fail request randomly
  failRandomReq = false;
  failOneShotOnly = false;

  isCordova = false;

  constructor(
    private appInitService: AppInitService,
    public platform: Platform,
    private storageService: StorageService
  ) {
    this.isCordova = this.platform.is('cordova');
    this.appInitService.onStepChange().subscribe(state => {
      if (state?.initDone) {
        if (this.failRandomReq && !!this.randomBlacklistUrls[this.randomInt]) {
          if (this.isCordova) {
            alert(
              'Parcours dégradés coupure du service => ' +
                this.randomBlacklistUrls[this.randomInt].toUpperCase()
            );
          } else {
            console.log(
              '%cParcours dégradés coupure du service => ' +
                this.randomBlacklistUrls[this.randomInt].toUpperCase(),
              'font-size: 16px; color: #fff; background: #8746ae; padding: 16px; margin: 16px 0; border-radius: 30px; border: 10px solid lightgray'
            );
          }
        }
        if (this.failOneShotOnly) {
          // reset
          this.blacklistUrls = [];
          this.randomBlacklistUrls = [];
          this.failRandomReq = false;
        }
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(async (event: HttpEvent<any>) => {
        // console.log('event => ', event);
        if (event instanceof HttpResponse) {
          // console.log('EvsResponseInterceptor', event.headers, event.url);
          // alert(`EvsResponseInterceptor ${JSON.stringify(event.url)}`);
          if (event.url.includes('/omniauth_callback/google')) {
            console.log('headers => ', event.headers.get('authorization'));
            console.log('headers2 => ', event.headers.get('Authorization'));
          }
          if (
            event.ok &&
            (event.url.includes('/omniauth_callback/google') ||
              event.url.includes('/omniauth_callback/facebook') ||
              event.url.includes('/auth/sign_in') ||
              event.url.endsWith('/auth') ||
              event.url.endsWith('api/v1/session')) &&
            event.headers.has('authorization')
          ) {
            let jwtToken = event.headers.get('authorization');
            jwtToken = await this.storageService.set('jwtToken', jwtToken);
            // console.log('JWT -> ', jwtToken, Date.now());
          }
          event = this.modifyResponse(event);
        }
        return event;
      })
    );
  }

  private modifyResponse(event: any) {
    let changeReq = false;
    // let changeReq = true;
    for (let i = 0, len = this.whitelistUrls.length; i < len; i++) {
      if (event.url.includes(this.whitelistUrls[i])) {
        changeReq = false;
      }
    }
    for (let i = 0, len = this.blacklistUrls.length; i < len; i++) {
      if (event.url.includes(this.blacklistUrls[i])) {
        changeReq = true;
      }
    }
    if (this.randomBlacklistUrls.length > 0 && this.failRandomReq) {
      if (event.url.includes(this.randomBlacklistUrls[this.randomInt])) {
        changeReq = true;
      }
    }
    let newRes = event;
    if (changeReq) {
      newRes = event.clone({ status: 500, ok: false, body: null }); // 500 Unknown Error
      console.log('EvsResponseInterceptor', event.url, event, newRes);
      throw new HttpErrorResponse(newRes);
    }
    return newRes;
  }

  private randomMinMax(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
