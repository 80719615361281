<!-- calendar -->
<ng-container *ngIf="!fetchLessonFailed">
  <div class="calendarContainer" [ngStyle]="{'width': calendarSize + 'px'}">
    <div
      class="calendarScreens"
      [ngStyle]="{'width': (calendarDates.length * calendarSize) + 'px'}"
      #calendarScreens>
      <div
        class="calendarScreen"
        *ngFor="let item of calendarDates; let i = index"
        [ngStyle]="{'width': calendarSize + 'px'}">
        <calendar-dashboard-comp
          [content]="content"
          [compOptions]="{'calledFrom': calledFrom, 'isIpad': isIpad, date: item, lessons: lessons, debug: (i == 1)}"
          (hasClicked)="updateSlider($event)"></calendar-dashboard-comp>
      </div>
    </div>
  </div>
  <button class="btn small center" (click)="openLink('reserver-lecon')">
    Réserver une leçon
  </button>
</ng-container>

<ng-container *ngIf="fetchLessonFailed">
  <bloc-error-comp [compOptions]="{'lightMode': true}"></bloc-error-comp>
</ng-container>

<!-- skeleton -->
<!-- <ng-container *ngIf="showBloc == 0">
  <div class="margin-top-80 content-center">
    <ion-spinner name="crescent"></ion-spinner>
  </div>
</ng-container> -->
