import { EvsRecord } from './evsRecord';
import { Serie } from './serie';
import { Asset } from './asset';
import { Answer } from './answer';

export interface IQuestion {
  id: string;
  assets: Array<Asset>;
  assets_size: number;
  series: Array<Serie>;
  answers: Array<Answer>;
  themes: Array<string>;
  question_text: string;
  text1: string;
  text2: string;
  answer_a: string;
  answer_b: string;
  answer_c: string;
  answer_d: string;
  correctAnswers: Array<string>;
  correction: string[];
  updatedAt: string;
  question_img1: string;
  question_img1_type: string;
  question_img1_geometries: any;
  question_img2: string;
  question_img2_type: string;
  question_img2_geometries: any;
  question_audio: string;
  question_video: string;
  answer_img1: string;
  answer_img1_type: string;
  answer_img1_geometries: any;
  answer_img2: string;
  answer_img2_type: string;
  answer_img2_geometries: any;
  answer_audio: string;
  answer_video: string;
  off_question_img1: string;
  off_question_img2: string;
  off_question_audio: string;
  off_question_video: string;
  off_answer_img1: string;
  off_answer_img2: string;
  off_answer_audio: string;
  downloadState: boolean;
}

export class Question extends EvsRecord implements IQuestion {
  private _id: string;
  private _assets: Array<Asset>;
  private _assets_size: number;
  private _series: Array<Serie>;
  private _answers: Array<Answer>;
  private _themes: Array<string>;
  private _question_text: string;
  private _text1: string;
  private _text2: string;
  private _answer_a: string;
  private _answer_b: string;
  private _answer_c: string;
  private _answer_d: string;
  private _correctAnswers: Array<string>;
  private _correction: string[];
  private _updatedAt: string;
  private _question_img1: string;
  private _question_img1_type: string;
  private _question_img1_geometries: any;
  private _question_img2: string;
  private _question_img2_type: string;
  private _question_img2_geometries: any;
  private _question_audio: string;
  private _question_video: string;
  private _answer_img1: string;
  private _answer_img1_type: string;
  private _answer_img1_geometries: any;
  private _answer_img2: string;
  private _answer_img2_type: string;
  private _answer_img2_geometries: any;
  private _answer_audio: string;
  private _answer_video: string;
  private _off_question_img1: string;
  private _off_question_img2: string;
  private _off_question_audio: string;
  private _off_question_video: string;
  private _off_answer_img1: string;
  private _off_answer_img2: string;
  private _off_answer_audio: string;
  private _downloadState: boolean;

  constructor(obj: IQuestion) {
    super(obj);
  }

  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  public get assets(): Array<Asset> {
    return this._assets;
  }
  public set assets(value: Array<Asset>) {
    this._assets = value;
  }
  public get assets_size(): number {
    return this._assets_size;
  }
  public set assets_size(value: number) {
    this._assets_size = value;
  }
  public get series(): Array<Serie> {
    return this._series;
  }
  public set series(value: Array<Serie>) {
    this._series = value;
  }
  public get answers(): Array<Answer> {
    return this._answers;
  }
  public set answers(value: Array<Answer>) {
    this._answers = value;
  }
  public get themes(): Array<string> {
    return this._themes;
  }
  public set themes(value: Array<string>) {
    this._themes = value;
  }
  public get question_text(): string {
    return this._question_text;
  }
  public set question_text(value: string) {
    this._question_text = value;
  }
  public get text1(): string {
    return this._text1;
  }
  public set text1(value: string) {
    this._text1 = value;
  }
  public get text2(): string {
    return this._text2;
  }
  public set text2(value: string) {
    this._text2 = value;
  }
  public get answer_a(): string {
    return this._answer_a;
  }
  public set answer_a(value: string) {
    this._answer_a = value;
  }
  public get answer_b(): string {
    return this._answer_b;
  }
  public set answer_b(value: string) {
    this._answer_b = value;
  }
  public get answer_c(): string {
    return this._answer_c;
  }
  public set answer_c(value: string) {
    this._answer_c = value;
  }
  public get answer_d(): string {
    return this._answer_d;
  }
  public set answer_d(value: string) {
    this._answer_d = value;
  }
  public get correctAnswers(): Array<string> {
    return this._correctAnswers;
  }
  public set correctAnswers(value: Array<string>) {
    this._correctAnswers = value;
  }
  public get correction(): string[] {
    return this._correction;
  }
  public set correction(value: string[]) {
    this._correction = value;
  }
  public get updatedAt(): string {
    return this._updatedAt;
  }
  public set updatedAt(value: string) {
    this._updatedAt = value;
  }
  public get question_img1(): string {
    return this._question_img1;
  }
  public set question_img1(value: string) {
    this._question_img1 = value;
  }
  public get question_img1_type(): string {
    return this._question_img1_type;
  }
  public set question_img1_type(value: string) {
    this._question_img1_type = value;
  }
  public get question_img1_geometries(): any {
    return this._question_img1_geometries;
  }
  public set question_img1_geometries(value: any) {
    this._question_img1_geometries = value;
  }
  public get question_img2(): string {
    return this._question_img2;
  }
  public set question_img2(value: string) {
    this._question_img2 = value;
  }
  public get question_img2_type(): string {
    return this._question_img2_type;
  }
  public set question_img2_type(value: string) {
    this._question_img2_type = value;
  }
  public get question_img2_geometries(): any {
    return this._question_img2_geometries;
  }
  public set question_img2_geometries(value: any) {
    this._question_img2_geometries = value;
  }
  public get question_audio(): string {
    return this._question_audio;
  }
  public set question_audio(value: string) {
    this._question_audio = value;
  }
  public get question_video(): string {
    return this._question_video;
  }
  public set question_video(value: string) {
    this._question_video = value;
  }
  public get answer_img1(): string {
    return this._answer_img1;
  }
  public set answer_img1(value: string) {
    this._answer_img1 = value;
  }
  public get answer_img1_type(): string {
    return this._answer_img1_type;
  }
  public set answer_img1_type(value: string) {
    this._answer_img1_type = value;
  }
  public get answer_img1_geometries(): any {
    return this._answer_img1_geometries;
  }
  public set answer_img1_geometries(value: any) {
    this._answer_img1_geometries = value;
  }
  public get answer_img2(): string {
    return this._answer_img2;
  }
  public set answer_img2(value: string) {
    this._answer_img2 = value;
  }
  public get answer_img2_type(): string {
    return this._answer_img2_type;
  }
  public set answer_img2_type(value: string) {
    this._answer_img2_type = value;
  }
  public get answer_img2_geometries(): any {
    return this._answer_img2_geometries;
  }
  public set answer_img2_geometries(value: any) {
    this._answer_img2_geometries = value;
  }
  public get answer_audio(): string {
    return this._answer_audio;
  }
  public set answer_audio(value: string) {
    this._answer_audio = value;
  }
  public get answer_video(): string {
    return this._answer_video;
  }
  public set answer_video(value: string) {
    this._answer_video = value;
  }
  public get off_question_img1(): string {
    return this._off_question_img1;
  }
  public set off_question_img1(value: string) {
    this._off_question_img1 = value;
  }
  public get off_question_img2(): string {
    return this._off_question_img2;
  }
  public set off_question_img2(value: string) {
    this._off_question_img2 = value;
  }
  public get off_question_audio(): string {
    return this._off_question_audio;
  }
  public set off_question_audio(value: string) {
    this._off_question_audio = value;
  }
  public get off_question_video(): string {
    return this._off_question_video;
  }
  public set off_question_video(value: string) {
    this._off_question_video = value;
  }
  public get off_answer_img1(): string {
    return this._off_answer_img1;
  }
  public set off_answer_img1(value: string) {
    this._off_answer_img1 = value;
  }
  public get off_answer_img2(): string {
    return this._off_answer_img2;
  }
  public set off_answer_img2(value: string) {
    this._off_answer_img2 = value;
  }
  public get off_answer_audio(): string {
    return this._off_answer_audio;
  }
  public set off_answer_audio(value: string) {
    this._off_answer_audio = value;
  }
  public get downloadState(): boolean {
    return this._downloadState;
  }
  public set downloadState(value: boolean) {
    this._downloadState = value;
  }
}
