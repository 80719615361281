import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'cours-card-comp',
  templateUrl: 'cours-card.html',
  styleUrls: ['cours-card.scss'],
})
export class CoursCardComponent implements OnInit, OnChanges {
  @Input() compOptions: any;

  debug = false;
  title = 'Sans titre !';
  canClick = true;

  constructor(private utils: UtilsService) {}

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    // detect @input changes
    if (this.utils.isDefined(compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.debug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.debug;
    this.title =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.title)
        ? this.compOptions.title
        : this.title;
    this.canClick =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.canClick)
        ? this.compOptions.canClick
        : this.canClick;
  }

  handleClick() {
    if (this.canClick) {
      // console.log('click on ' + this.title);
    }
  }
}
