export interface iAbility {
  context: Object;
  callback: Function;
}

const __abilities: Array<iAbility> = [];
let __currentAccount: any;

export function setAccount(acc) {
  __currentAccount = acc;
}

export function setAbility(key: string, obj: iAbility) {
  __abilities[key] = obj;
}

function buildKey(ctor: Function, ability: any): string {
  const targetName = !!ability.target ? ability.target.name : 'Null';
  return `${ctor.name}Can${ability.action}On${targetName}`;
}

export function AbleTo(
  abilities: Array<{ action: string; target: any; eval: any }>
) {
  return function <T extends { new (...args: any[]): {} }>(ctor: T) {
    for (const ability of abilities) {
      setAbility(buildKey(ctor, ability), {
        context: null,
        callback: ability.eval,
      });
    }
    return class extends ctor {
      can(action: string, instance: Object = null) {
        const key = buildKey(ctor, {
          action: action,
          target: !!instance ? instance.constructor : null,
        });
        if (!!__abilities[key]) {
          return __abilities[key].callback(__currentAccount, instance);
        } else {
          console.error(
            `Ability ${action} on ${instance.constructor.name} does not existe`
          );
        }
      }
    };
  };
}
