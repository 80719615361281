import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';

import { ExamenModel } from '../models/examen';
import { Student } from '../models/student';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class ExamenCodeService {
  private _examens: any = [];

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private networkService: NetworkStatusService
  ) {}

  async onInit() {
    // console.log('ExamCode done');
    return 'ExamCode done';
  }

  fetchExamenCodeByUserId(accountId: string): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId && accountId !== 'GUEST') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/theory_tests`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(this.storageKey(accountId), data);
          this._examens = this.buildExamensFromRawData(data);
          return of(this._examens);
        }),
        catchError(err => {
          return of({
            errorCode: 'E301',
            errorMessage: 'ExamCode Service failed',
            errorOriginal: err,
          });
        })
      );
    } else {
      return of([]);
    }
  }

  async loadStudentExamens(student: Student) {
    if (student.isGuest()) {
      this._examens = [];
    } else {
      // TODO: Handle offline with localstorage
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadFromStorage(student);
      } else {
        this.fetchExamenCodeByUserId(student.remoteId).subscribe(res => {
          this._examens = res;
        });
      }
    }
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get examens(): any {
    return this._examens;
  }

  private;

  async loadFromStorage(student: Student) {
    this.storageService.get(this.storageKey(student.remoteId)).then(res => {
      this._examens = !!res ? this.buildExamensFromRawData(res) : [];
    });
  }

  storageKey(accountId: String): string {
    return `${accountId}-examens`;
  }

  /// Serialize data ///
  buildExamensFromRawData(data: any): Array<ExamenModel> {
    const arr: Array<ExamenModel> = [];
    const textData = JSON.stringify(data);
    const parsedData = JSON.parse(textData);

    for (const elt of parsedData.data) {
      const examens = new ExamenModel({
        id: elt.id,
        type: elt.type,
        date: elt.attributes.date,
        result: elt.attributes.result,
        cancelled_at: elt.attributes.cancelled_at,
      });
      arr.push(examens);
    }
    return arr;
  }

  codeExamReservationBasketStorageKey(accountId: string): string {
    return `${accountId}-CodeExamReservationBasket`;
  }

  async setCodeExamReservationBasketStorage(accountId, data) {
    return await this.storageService.set(
      this.codeExamReservationBasketStorageKey(accountId),
      data
    );
  }

  async getCodeExamReservationBasketStorage(accountId) {
    return await this.storageService.get(
      this.codeExamReservationBasketStorageKey(accountId)
    );
  }

  async deleteCodeExamReservationBasketStorage(accountId) {
    return await this.storageService.remove(
      this.codeExamReservationBasketStorageKey(accountId)
    );
  }

  fetchPrefectures(): Promise<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/prefectures`;

    if (this.networkService?.isOffline()) {
      return of([]).toPromise();
    }

    return this.http
      .get(url)
      .pipe(
        switchMap(async (data: any) => {
          if (!!data) {
            return this.buildPrefecturesFromRawData(data);
          }
          return [];
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Student-documents Service failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  private buildPrefecturesFromRawData(data: any): Array<any> {
    const prefectures: Array<any> = [];

    if (!!data && !!data.data) {
      for (const prefecture of data.data) {
        prefectures.push(this.buildPrefectureFromRawData(prefecture));
      }
    }

    return prefectures;
  }

  private buildPrefectureFromRawData(data: any): any {
    return {
      id: data.id,
      department: data.attributes.department,
      number: data.attributes.number,
    };
  }

  fetchNearbyExamCenters(student, prefectureId): Promise<any> {
    if (!!student && !!student.remoteId && !!prefectureId) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/code_exams/sites`;

      if (this.networkService?.isOffline() || student.isGuest()) {
        return of([]).toPromise();
      }

      const params = {
        prefecture_id: prefectureId,
      };

      return this.http
        .get(url, { params: params })
        .pipe(
          switchMap(async (data: any) => {
            if (!!data && !!data.data) {
              return data.data;
            }
            return [];
          }),
          catchError(async err => {
            return {
              errorCode: 'E301',
              errorMessage: 'Student-documents Service failed',
              errorOriginal: err,
            };
          })
        )
        .toPromise();
    }
    return of([]).toPromise();
  }

  fetchExamCentersSessions(
    student,
    examCenterId,
    dateMin,
    dateMax
  ): Promise<any> {
    if (!!student && !!student.remoteId && !!examCenterId) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/code_exams/sessions`;

      if (this.networkService?.isOffline() || student.isGuest()) {
        return of([]).toPromise();
      }

      const params = {
        id_laposte: examCenterId,
        date_min: dateMin,
        date_max: dateMax,
      };

      return this.http
        .get(url, { params: params })
        .pipe(
          switchMap(async (data: any) => {
            if (!!data && !!data.data) {
              return data.data;
            }
            return [];
          }),
          catchError(async err => {
            return {
              errorCode: 'E301',
              errorMessage: 'Student-documents Service failed',
              errorOriginal: err,
            };
          })
        )
        .toPromise();
    }
    return of([]).toPromise();
  }

  createCodeExamReservation(student, sessionId): Observable<any> {
    if (!!student && !!student.remoteId && !!sessionId) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/code_exams`;

      if (this.networkService?.isOffline()) {
        return of(null);
      }

      let data = {
        session_id: sessionId,
      };

      return this.http.post(url, data).pipe(
        switchMap(async res => {
          return res;
        }),
        catchError(async err => {
          return err;
        })
      );
    }
    return of(null);
  }
}
