import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

import { ServiceLocator } from './serviceLocator.service';

import 'reflect-metadata';
// We will follow the linux concept for init
// by defining steps of initialization
// every one can be subscribe to the init
// we have function allowing us to re-run init at a certain step

interface IInitStep {
  init: number;
  stepDone: boolean;
  initDone: boolean;
}

interface IStepsHandler {
  init0: Array<{
    instance: Object;
    initializer: Function;
    context: any;
    initFuncName: string;
  }>;
  init1: Array<{
    instance: Object;
    initializer: Function;
    context: any;
    initFuncName: string;
  }>;
  init2: Array<{
    instance: Object;
    initializer: Function;
    context: any;
    initFuncName: string;
  }>;
  init3: Array<{
    instance: Object;
    initializer: Function;
    context: any;
    initFuncName: string;
  }>;
  init4: Array<{
    instance: Object;
    initializer: Function;
    context: any;
    initFuncName: string;
  }>;
  init5: Array<{
    instance: Object;
    initializer: Function;
    context: any;
    initFuncName: string;
  }>;
}

const __stepsHandler: IStepsHandler = {
  init0: [],
  init1: [],
  init2: [],
  init3: [],
  init4: [],
  init5: [],
};

export function Initialable(options: any) {
  const step = options.step || 'init0';
  const initializerName = options.initializer || 'onInit';
  const services = options.services || [];
  return function <T extends new (...args: any[]) => {}>(ctor: T) {
    const types = Reflect.getMetadata('design:paramtypes', ctor);
    Reflect.defineMetadata('design:paramtypes', types, ctor);
    return class extends ctor {
      constructor(...args) {
        // Getting the list of services expected by the overloaded constructor
        const servicesInstance = [];
        for (let i = 0; i < types.length; i++) {
          // Getting services instances
          servicesInstance.push(ServiceLocator.injector.get(types[i]));
        }

        // Calling constructor
        super(...servicesInstance);

        // Finally registering instance
        __stepsHandler[step].push({
          instance: this,
          initializer: this[initializerName],
          context: {},
          initFuncName: initializerName,
        });
        return this;
      }
    };
  };
}

@Injectable({
  providedIn: 'root',
})
@EasyDebugDecorator
export class AppInitService {
  private stepObservable: BehaviorSubject<IInitStep>;

  done = false;
  isLightInit = false;

  constructor() {
    this.stepObservable = new BehaviorSubject({
      init: null,
      stepDone: false,
      initDone: false,
    });
  }

  // Just use to launch the init
  async run() {
    return this.init0();
  }

  runLightInit(): Promise<any> {
    this.done = false;
    this.isLightInit = true;
    return this.init2();
  }

  initFrom1() {
    return this.init1();
  }

  initFrom2() {
    return this.init2();
  }

  initFrom3() {
    return this.init3();
  }

  initFrom4() {
    return this.init4();
  }

  initFrom5() {
    return this.init5();
  }

  onStepChange(): Observable<IInitStep> {
    return this.stepObservable;
  }

  init0(): Promise<any> {
    return this.runStep({ init: 0, stepDone: false, initDone: false }).then(
      () => {
        return this.init1();
      },
    );
  }

  init1(): Promise<any> {
    return this.runStep({ init: 1, stepDone: false, initDone: false }).then(
      () => {
        return this.init2();
      },
    );
  }

  init2(): Promise<any> {
    return this.runStep({ init: 2, stepDone: false, initDone: false }).then(
      () => {
        return this.init3();
      },
    );
  }

  init3(): Promise<any> {
    if (this.isLightInit) {
      return new Promise<void>((resolve, reject) => {
        this.runStep({ init: 3, stepDone: false, initDone: true }).then(() => {
          this.isLightInit = false;
          resolve();
        });
      });
    } else {
      return this.runStep({ init: 3, stepDone: false, initDone: false }).then(
        () => {
          return this.init4();
        },
      );
    }
  }

  init4(): Promise<any> {
    return this.runStep({ init: 4, stepDone: false, initDone: false }).then(
      () => {
        return this.init5();
      },
    );
  }

  init5(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.runStep({ init: 5, stepDone: false, initDone: false }).then(() => {
        this.stepObservable.next({ init: 5, stepDone: true, initDone: true });
        this.done = true;
        resolve();
      });
    });
  }

  runStep(state: IInitStep): Promise<any> {
    this.stepObservable.next(state);
    // console.log('Running step:', state.init);
    return new Promise<void>((resolve, reject) => {
      // console.log('runStep', resolve, reject);
      // Run all the server to init
      const observables: Array<Observable<any>> = [];
      for (const elt of __stepsHandler['init' + state.init]) {
        // WARNING: in init function, this is not always properly set...
        observables.push(elt.instance[elt.initFuncName]());
      }
      // console.log('Launching init', observables.length);
      if (observables.length !== 0) {
        forkJoin(observables).subscribe((success) => {
          // console.log('APP INIT success', success);
          resolve();
          state.stepDone = true;
          this.stepObservable.next(state);
        });
      } else {
        resolve();
        state.stepDone = true;
        this.stepObservable.next(state);
      }
    });
  }
}
