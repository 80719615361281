import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { UtilsService } from '@commons/services';

// import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

// @EasyDebugDecorator
class ConfettiParticle {
  canvas: any;
  ctx: CanvasRenderingContext2D = null;

  screenWidth: number = window.innerWidth;
  screenHeight: number = window.innerHeight;
  W: any;
  H: any;
  mp: any;

  x: any;
  y: any;
  r: any;
  d: any;
  color: any;
  tilt: any;
  tiltAngleIncremental: any;
  tiltAngle: any;

  velocity: any;
  opacity = 1;

  isMobile = true;

  constructor(
    canvas: any,
    x: any,
    y: any,
    color: any,
    mp: any,
    velocity: any,
    isMobile: any
  ) {
    if (!!canvas) {
      this.canvas = canvas;
      this.ctx = this.canvas.getContext('2d');
      this.W = this.canvas.width;
      this.H = this.canvas.height;
      // this.x = Math.floor(this.W / 2);
      // this.y = Math.floor(this.H / 2);
      this.x = x;
      this.y = y;
      this.mp = mp;
      this.r = this.randomFromTo(15, 30); // radius
      this.d = Math.random() * this.mp + 10; // density
      this.tilt = Math.floor(Math.random() * 10) - 10;
      this.tiltAngleIncremental = Math.random() * 0.1 + 0.1;
      this.tiltAngle = 0;
      this.velocity = velocity;
      this.isMobile = !isMobile;
    }
    if (!!color) {
      this.color = color;
    }
  }

  randomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }

  draw() {
    const reducer = this.isMobile ? 2 : 1;
    this.ctx.save();
    this.ctx.globalAlpha = this.opacity;
    this.ctx.beginPath();
    this.ctx.lineWidth = this.r / (2 * reducer);
    this.ctx.strokeStyle = this.color;
    this.ctx.moveTo(this.x + this.tilt + this.r / (4 * reducer), this.y);
    this.ctx.lineTo(
      this.x + this.tilt,
      this.y + this.tilt + this.r / (4 * reducer)
    );
    this.ctx.stroke();
    this.ctx.restore();
  }
}

// @EasyDebugDecorator
@Component({
  selector: 'fireworks-comp',
  templateUrl: 'fireworks.html',
  styleUrls: ['fireworks.scss'],
})
export class ParticlesComponent implements OnInit {
  @Input() compOptions: any;
  @Output() confettisFinished = new EventEmitter<any>();
  @ViewChild('htmlCanvas', { static: true }) htmlCanvas: any;

  pageTitle = 'Particles';
  activeLink = 'code';

  screenWidth: number = window.innerWidth;
  isDesktop = window.innerWidth > 545 ? true : false;

  startX = Math.floor(window.innerWidth / 2);
  startY = Math.floor(window.innerHeight / 2);
  addX = 0;
  addY = 0;
  startAt = 0;
  activateOnclick = false;

  canvas: any;
  ctx: any;
  W: any;
  H: any;
  particles = [];
  mp = Math.round(window.innerWidth * 0.6); // max particles
  opacityDecrease = 0.012;
  gravity = 0.3;
  friction = 0.99;
  power = 32;
  radians = (Math.PI * 2) / this.mp;
  angle = 0;
  tiltAngle = 0;
  confettiActive = true;
  deactivationTimerHandler: any;
  reactivationTimerHandler: any;
  animationHandler: any;
  animationFrame: any;

  velocity: any;

  // objects
  particleColors = {
    colorOptions: ['#0075F2', '#85BDCB', '#EA5B0C', '#FFE548'],
    colorIndex: 0,
    colorIncrementer: 0,
    getColor: function () {
      if (this.colorIncrementer >= 10) {
        this.colorIncrementer = 0;
        this.colorIndex++;
        if (this.colorIndex >= this.colorOptions.length) {
          this.colorIndex = 0;
        }
      }
      this.colorIncrementer++;
      return this.colorOptions[this.colorIndex];
    },
  };

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.W = window.innerWidth;
    this.H = window.innerHeight;
    this.canvas.width = this.W;
    this.canvas.height = this.H;
    this.mp = Math.round(window.innerWidth * 0.6); // max particles
    this.radians = (Math.PI * 2) / this.mp;
    this.isDesktop = window.innerWidth > 545 ? true : false;
  }

  @HostListener('mousedown', ['$event']) onClick(event) {
    if (this.activateOnclick) {
      this.initializeConfetti(
        this.utils.unify(event).clientX,
        this.utils.unify(event).clientY
      );
    }
  }
  @HostListener('touchstart', ['$event']) onTouchStart(event) {
    if (this.activateOnclick) {
      this.initializeConfetti(
        this.utils.unify(event).clientX,
        this.utils.unify(event).clientY
      );
    }
  }

  constructor(private utils: UtilsService) {
    window.requestAnimationFrame = window.requestAnimationFrame;
  }

  ngOnInit() {
    this.setGlobals();
    this.initCanvas();
  }

  setGlobals() {
    this.activateOnclick =
      this.utils.isDefined(this.activateOnclick) &&
      this.utils.isDefined(this.compOptions.activateOnclick)
        ? this.compOptions.activateOnclick
        : this.activateOnclick;
    this.mp =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.maxParticles)
        ? this.compOptions.maxParticles
        : this.mp;
    this.mp = this.mp > 1200 ? 1200 : this.mp;
    this.addX =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.addX)
        ? this.compOptions.addX
        : this.addX;
    this.addY =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.addY)
        ? this.compOptions.addY
        : this.addY;
    this.startX += this.addX;
    this.startY += this.addY;
    this.startAt =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.startAt)
        ? this.compOptions.startAt
        : this.startAt;
    this.canvas = this.htmlCanvas.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    this.W = window.innerWidth;
    this.H = window.innerHeight;
    this.canvas.width = this.W;
    this.canvas.height = this.H;
    if (!this.activateOnclick) {
      setTimeout(() => {
        this.initializeConfetti(this.startX, this.startY);
      }, this.startAt);
    }
  }

  initializeConfetti(x, y) {
    this.particles = [];
    for (let i = 0; i < this.mp; i++) {
      const particleColor = this.particleColors.getColor();
      const tempVelocity = {
        x: Math.cos(this.radians * i) * (Math.random() * this.power),
        y: Math.sin(this.radians * i) * (Math.random() * this.power),
      };
      this.particles.push(
        new ConfettiParticle(
          this.canvas,
          x,
          y,
          particleColor,
          this.mp,
          tempVelocity,
          this.isDesktop
        )
      );
    }
  }

  draw() {
    this.animationFrame = requestAnimationFrame(() => {
      this.draw();
    });

    this.ctx.clearRect(0, 0, this.W, this.H);
    for (let i = 0; i < this.mp; i++) {
      if (!!this.particles[i]) {
        this.particles[i].draw();
      }
    }
    this.update();
  }

  update() {
    this.angle += 0.01;
    this.tiltAngle += 0.1;

    this.particles.forEach((particle, i) => {
      if (particle.opacity > 0) {
        this.stepParticle(particle, i);
      } else {
        this.particles.splice(i, 1);
      }
    });
  }

  stepParticle(particle, particleIndex) {
    particle.tiltAngle += particle.tiltAngleIncremental;
    particle.velocity.x *= this.friction;
    particle.velocity.y *= this.friction;
    particle.velocity.y += this.gravity;
    particle.x += particle.velocity.x;
    particle.y += particle.velocity.y;
    // particle.y += (Math.cos(this.angle + particle.d) + 10 + particle.r / 2) / 2;
    // particle.x += Math.sin(this.angle);
    particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 2) * 15;
    particle.opacity -= this.opacityDecrease;
    particle.opacity = particle.opacity < 0 ? 0 : particle.opacity;
  }

  initCanvas() {
    this.W = window.innerWidth;
    this.H = window.innerHeight;
    this.canvas.width = this.W;
    this.canvas.height = this.H;
    this.draw();
  }
}
