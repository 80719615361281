import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';

import { OfferModel } from '../models/offer';
import { Initialable } from './app-init.service';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { Student } from '@app/app.models';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init2', initializer: 'onInit' })
@EasyDebugDecorator
export class OffersService {
  private _offers: Array<OfferModel> = [];
  private _studentCustomOffers: Array<OfferModel> = [];
  public gearType: any = null;

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private storageService: StorageService
  ) {}

  async onInit() {
    if (this.networkService?.isOffline()) {
      await this.loadOffersFromStorage();
      this.gearType = await this.storageService.get('gearType');
      if (!!this.gearType?.default_offer_credit_type) {
        this.gearType = this.gearType.default_offer_credit_type;
      }
    } else {
      await this.fetchOffers().toPromise();
      this.gearType = await this.fetchBoiteDeVitesse();
    }
    return 'Offers done';
  }

  fetchOffers(postal_code?: string): Observable<any> {
    let url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/offers`;
    if (!!postal_code) {
      url = `${url}?postal_code=${postal_code}`;
    }
    return this.http.get(url).pipe(
      switchMap(data => {
        if (!!data) {
          this.storageService.set(this.storageKey(), data);
          this._offers = this.buildOffersFromRawData(data);
        }
        // console.log(this._offers);
        return of(this._offers);
      }),
      catchError(async err => {
        return {
          errorCode: 'E301',
          errorMessage: 'Offers Service failed',
          errorOriginal: err,
        };
      })
    );
  }

  get offers(): any {
    return this._offers;
  }

  get studentCustomOffers(): any {
    return this._studentCustomOffers;
  }

  private;

  storageKey(): string {
    return 'offers';
  }
  studentCustomOffersStorageKey(accountId: string): string {
    return `${accountId}-customOffers`;
  }

  buildOffersFromRawData(data: any): Array<OfferModel> {
    const offers: Array<OfferModel> = [];
    if (!!data && !!data) {
      for (const offer of data.data) {
        if (!offer.attributes.name.startsWith('Sample')) {
          // removed all Sample_
          offers.push(this.buildOfferFromRawData(offer));
        }
      }
    }
    return offers;
  }

  buildOfferFromRawData(data: any): OfferModel {
    return new OfferModel({
      id: data.id,
      offer_type: data.attributes.offer_type,
      amount: data.attributes.amount,
      max_quantity: data.attributes.max_quantity,
      min_quantity: data.attributes.min_quantity,
      discount: data.attributes.discount,
      offer_campaign: data.attributes.offer_campaign,
      name: data.attributes.name,
      credits: data.attributes.credits,
      code: data.attributes.code,
      code_exam_credits: data.attributes.code_exam_credits,
      active: data.attributes.active,
      offer_credit_type: data.attributes.offer_credit_type,
      title: data.attributes.title,
      description: data.attributes.description,
      text: data.attributes.text,
      display_offer_type: data.attributes.display_offer_type,
      price_desc: data.attributes.price_desc,
      ranking: data.attributes.ranking,
      key_points: data.attributes.key_points,
      displayable: data.attributes.displayable,
      special_offer: data.attributes.special_offer,
      created_at: data.attributes.created_at,
      updated_at: data.attributes.updated_at,
    });
  }

  async loadOffersFromStorage() {
    const rawOffers = await this.storageService.get(this.storageKey());
    this._offers = this.buildOffersFromRawData(rawOffers);
    return this._offers;
  }

  async loadStudentCustomOffers(student: Student) {
    const transformed = await this.storageService.get('transformed');

    if (this.networkService?.isOffline() || (!!transformed && transformed)) {
      this.loadStudentCustomOffersFromStorage(student);
    } else {
      this.fetchStudentCustomOffers(student).subscribe(res => {
        this._studentCustomOffers = res;
      });
    }
  }

  fetchStudentCustomOffers(
    student: Student,
    gearboxType?: string
  ): Observable<any> {
    let params = {};

    if (!!gearboxType) {
      params = {
        params: {
          gearbox_type: gearboxType.toLowerCase(),
        },
      };
    }
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/offers/user_custom_offers`;
    return this.http.get(url, params).pipe(
      switchMap(data => {
        if (!!data) {
          this.storageService.set(
            this.studentCustomOffersStorageKey(student.remoteId),
            data
          );
          this._studentCustomOffers = this.buildOffersFromRawData(data);
        }
        return of(this._studentCustomOffers);
      }),
      catchError(async err => {
        return {
          errorCode: 'E301',
          errorMessage: 'Offers Service failed',
          errorOriginal: err,
        };
      })
    );
  }

  async loadStudentCustomOffersFromStorage(student: Student) {
    const rawOffers = await this.storageService.get(
      this.studentCustomOffersStorageKey(student.remoteId)
    );
    this._studentCustomOffers = this.buildOffersFromRawData(rawOffers);
    return this._studentCustomOffers;
  }

  async fetchBoiteDeVitesse(): Promise<any> {
    const gearType = await this.storageService.get('gearType');
    if (!!gearType) {
      this.gearType = gearType;
      if (!!this.gearType.default_offer_credit_type) {
        this.gearType = this.gearType.default_offer_credit_type;
      }
      return this.gearType;
    }
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/offers/user_custom_info`;
    return this.http
      .get(url)
      .pipe(
        switchMap(async (data: any) => {
          if (!!data && !!data.default_offer_credit_type) {
            this.storageService.set('gearType', data.default_offer_credit_type);
            this.gearType = data.default_offer_credit_type;
            return this.gearType;
          }
          return null;
        }),
        catchError(async err => {
          // console.log('FECTH BOITE DE VITESSE ERR =>', err);
          return {
            errorCode: 'E301',
            errorMessage: 'Offers Boit de Vitesse failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }
}
