import { Serie } from '../../models/serie';
import { SeriesStrategy } from './seriesStrategy.interface';

import { Student } from '../../models/student';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
export class SeriesContext implements SeriesStrategy {
  private strategy: SeriesStrategy;

  constructor(private strategyInterface: SeriesStrategy) {
    this.strategy = this.strategyInterface;
  }

  init() {}

  setStrategy(strategy: SeriesStrategy) {
    this.strategy = strategy;
  }

  getSeriesList(student: Student, local?: boolean): Promise<any> {
    return this.strategy.getSeriesList(student, local);
  }

  getSerie(
    serieId: string,
    student: Student,
    withQuestions: boolean,
    local = false
  ): Promise<any> {
    return this.strategy.getSerie(serieId, student, withQuestions, local);
  }

  getSeries(student: Student, local?: boolean): Promise<any> {
    return this.strategy.getSeries(student, local);
  }

  setSerie(serie: Serie, student: Student): Promise<Serie> {
    return this.strategy.setSerie(serie, student);
  }

  setSeries(series: Serie[], student: Student): Promise<Serie[]> {
    return this.strategy.setSeries(series, student);
  }

  setSeriesList(seriesList: Serie[], student: Student): Promise<any[]> {
    return this.strategy.setSeriesList(seriesList, student);
  }

  getThemes(student: Student, local?: boolean): Promise<any> {
    return this.strategy.getThemes(student, local);
  }

  setThemes(themes: any[], student: Student): Promise<any[]> {
    return this.strategy.setThemes(themes, student);
  }
}
