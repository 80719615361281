import {
  OnInit,
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';

import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
} from '@angular/forms';

import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'checkboxes-serie-comp',
  templateUrl: 'checkboxes.html',
  styleUrls: ['checkboxes.scss'],
})
export class CheckboxesSerieComponent implements OnInit, OnChanges {
  @Input() field: any;
  @Input() selectedValues = Array<string>();
  @Output() toggle = new EventEmitter<any>();

  options = Array<any>();

  form = new UntypedFormGroup({
    items: new UntypedFormArray([]),
  });

  constructor(private utils: UtilsService) {}

  get items(): UntypedFormArray {
    return this.form.get('items') as UntypedFormArray;
  }

  ngOnInit() {
    // get input and apply possible _values to options
    if (this.utils.isDefined(this.field.possible_values)) {
      if (this.field.possible_values.length > 0) {
        this.options = this.field.possible_values;
        this.createFormControls();

        this.form.valueChanges.subscribe(value => {
          const optionsChecked = new Array<any>();
          for (let index = 0; index < this.items.length; index++) {
            const isOptionChecked = this.items.get(index.toString()).value;
            if (isOptionChecked) {
              const currentOptionValue = this.options[index].value;
              optionsChecked.push(currentOptionValue);
            }
          }
          this.toggle.emit({
            field: this.field.field_name,
            values: optionsChecked,
          });
        });
      }
    }
  }

  createFormControls() {
    if (this.items.length === 0) {
      this.options.forEach(x => {
        this.items.push(new UntypedFormControl(false));
      });
    }
  }

  ngOnChanges() {
    this.createFormControls();
    this.selectedValues.forEach(value => {
      const index: number = this.options.findIndex(opt => opt === value);
      if (index >= 0) {
        this.items.get(index.toString()).setValue(true);
      }
    });
  }
}
