import { EvsRecord } from './evsRecord';

export interface IAccount {
  email?: string;
  password?: string;
  oldPassword?: string;
  passwordConfirmation?: string;
  additionalData?: any;
}

export class Account extends EvsRecord implements IAccount {
  private _email: string;
  private _password: string;
  private _passwordConfirmation: string;
  private _oldPassword: string;
  private _additionalData: any;

  constructor(obj: IAccount) {
    super(obj);
  }

  get email(): string {
    return this._email;
  }
  set email(mail: string) {
    this._email = mail;
  }
  get password(): string {
    return this._password;
  }
  set password(pass: string) {
    this._password = pass;
  }
  get oldPassword(): string {
    return this._oldPassword;
  }
  set oldPassword(pass: string) {
    this._oldPassword = pass;
  }
  get passwordConfirmation(): string {
    return this._passwordConfirmation;
  }
  set passwordConfirmation(pass: string) {
    this._passwordConfirmation = pass;
  }
  get additionalData(): any {
    return this._additionalData;
  }
  set additionalData(data: any) {
    this._additionalData = data;
  }
}
