import { environment } from './../../../environments/environment';
import { Serie } from '../../models/serie';
import { SeriesStrategy } from './seriesStrategy.interface';

import { Student } from '../../models/student';
import { Series } from '../../models/series';
import { StorageService } from '../../services/storage.service';
import { HttpClient } from '@angular/common/http';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';
import { NetworkStatusService } from '../../services/network-status.service';

@EasyDebugDecorator
export class LocalSeries extends Series implements SeriesStrategy {
  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private networkStatusService: NetworkStatusService
  ) {
    super();
  }

  init() {}

  async getSeriesList(student: Student, local?: boolean): Promise<Serie[]> {
    const seriesList: Serie[] = [];
    if (!!student && !!student.remoteId) {
      const seriesListData =
        (await this.storageService.get(`${student.remoteId}-SeriesList`)) || [];
      // console.log('local getSeriesList from storage => ', seriesListData);
      if (!!seriesListData && seriesListData.length > 0) {
        for (const serie of seriesListData) {
          const tmpSerie = new Serie(serie);
          tmpSerie.lastSessions[0] = !!serie._lastSessions[0]
            ? serie._lastSessions[0]
            : null;
          tmpSerie.lastSessions[1] = !!serie._lastSessions[1]
            ? serie._lastSessions[1]
            : null;
          seriesList.push(tmpSerie);
        }
        return seriesList;
      } else {
        if (!!local && local) {
          // console.log('local getSeriesList in local mode');
          return seriesList;
        }
        let url: string;

        if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
          url = `${environment.cdrBase}/v${environment.apiOptiVersion}/account/${student.remoteId}/series`;
        } else {
          url = `${environment.cdrBase}/v${environment.apiOptiVersion}/series`;
        }

        return this.http
          .get(url)
          .pipe(
            switchMap(async (seriesData: any) => {
              let series: Serie[] = [];
              if (!!seriesData && !!seriesData.data) {
                series = this.fillSeries(seriesData.data);
                if (!!series && series.length > 0) {
                  await this.setSeriesList(series, student);
                }
              }
              return series;
            }),
            catchError(async () => {
              return seriesList;
            })
          )
          .toPromise();
      }
    }
    return seriesList;
  }

  async getSerie(
    serieId,
    student: Student,
    withQuestions: boolean,
    local = false
  ): Promise<Serie> {
    // console.log('getSerie local');
    if (!!student && !!student.remoteId && !!serieId) {
      const data =
        (await this.storageService.get(
          `${student.remoteId}-Serie-${serieId}`
        )) || null;
      const opt = withQuestions;
      if (
        local ||
        (!!data &&
          !!opt &&
          opt &&
          !!data._questions &&
          data._questions.length > 0)
      ) {
        if (!!data) {
          return new Serie(data);
        }
        return data;
      } else {
        if (!!data && this.networkStatusService?.isOffline()) {
          return new Serie(data);
        }
        const urlOpt = !!opt && opt ? '?with_questions=true' : '';
        let url = `${environment.cdrBase}/v${environment.apiOptiVersion}/series/${serieId}${urlOpt}`;
        if (student.remoteId !== 'GUEST') {
          url = `${environment.cdrBase}/v${environment.apiOptiVersion}/account/${student.remoteId}/series/${serieId}${urlOpt}`;
        }

        return this.http
          .get(url)
          .pipe(
            switchMap(async (dataSerie: any) => {
              if (!!dataSerie && !!dataSerie.data) {
                const serie = this.fillSerie(dataSerie.data);
                if (
                  !!serie &&
                  !!dataSerie &&
                  !!dataSerie.data &&
                  !!dataSerie.data.relationships &&
                  !!dataSerie.data.relationships.questions &&
                  !!dataSerie.data.relationships.questions.data
                ) {
                  serie.questions =
                    dataSerie.data.relationships.questions.data.map(
                      elt => elt.id
                    );
                }
                return await this.setSerie(serie, student);
              }
              return null;
            }),
            catchError(() => {
              return of(null);
            })
          )
          .toPromise();
      }
    }
    return null;
  }

  async getSeries(student: Student, local?: boolean): Promise<Serie[]> {
    let seriesList: Serie[] = [];
    if (!!student) {
      seriesList = (await this.getSeriesList(student, local)) || [];
      if (!!seriesList && seriesList.length > 0) {
        for (const localSerie of seriesList) {
          if (!!localSerie.id) {
            const serie = await this.getSerie(
              localSerie.id,
              student,
              true,
              true
            );
            if (!!serie && !!serie.questions && serie.questions.length > 0) {
              const index = seriesList.findIndex(
                elt => elt.id === localSerie.id
              );
              if (index !== -1) {
                seriesList[index].questions = serie.questions;
              }
            }
          }
        }
      }
    }
    return seriesList;
  }

  async setSeries(series: Serie[], student: Student): Promise<Serie[]> {
    const lSerie = [];
    if (!!student && !!series && series.length > 0) {
      for (const serie of series) {
        lSerie.push(await this.setSerie(serie, student));
      }
    }
    return lSerie;
  }

  async setSerie(serie: Serie, student: Student): Promise<Serie> {
    // console.log('set serie => ', serie);
    if (!!student && !!student.remoteId && !!serie && !!serie.id) {
      const data = await this.storageService.set(
        `${student.remoteId}-Serie-${serie.id}`,
        serie
      );
      if (!!data) {
        return new Serie(data);
      }
      return serie;
    }
    return null;
  }

  async setSeriesList(seriesList: Serie[], student: Student): Promise<any[]> {
    if (!!student && !!student.remoteId && !!seriesList) {
      const data = await this.storageService.set(
        `${student.remoteId}-SeriesList`,
        seriesList
      );
      if (!!data) {
        return data;
      }
      return seriesList;
    }
    return [];
  }

  async getThemes(student: Student, local = true): Promise<any[]> {
    if (!!student && !!student.remoteId) {
      let themes = await this.storageService.get(`${student.remoteId}-Themes`);
      console.log('local Themes => ', themes);
      if (!!themes && local) {
        return themes;
      } else {
        // console.log('call api question/themes');
        const url = `${environment.cdrBase}/v${environment.apiVersion}/question/themes`;
        return this.http
          .get(url)
          .pipe(
            switchMap(async (themesData: any) => {
              if (!!themesData && !!themesData.data) {
                themes = this.fillThemes(themesData.data);
                if (!!themes && themes.length > 0) {
                  await this.setThemes(themes, student);
                }
                return themes;
              }
              return [];
            }),
            catchError(async err => {
              return [];
            })
          )
          .toPromise();
      }
    }
    return [];
  }

  async setThemes(themes: any[], student: Student): Promise<any[]> {
    if (!!student && !!student.remoteId && !!themes) {
      const data = await this.storageService.set(
        `${student.remoteId}-Themes`,
        themes
      );
      if (!!data) {
        return data;
      }
      return themes;
    }
    return [];
  }
}
