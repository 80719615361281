import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';

import { CouponModel } from '../models/coupon';

import { environment } from '../../environments/environment';
import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';
import { Student } from '../models/student';

import { UserErrorHandlerService } from '../services/user-error-handler.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init2', initializer: 'onInit' })
@EasyDebugDecorator
export class CouponsService {
  private _coupons: Array<CouponModel> = [];

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private storageService: StorageService,
    private userErrorHandlerService: UserErrorHandlerService
  ) {}

  async onInit() {
    return 'Coupons done';
  }

  fetchCouponsByUserId(accountId: string): Observable<CouponModel[]> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId) {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/coupon_usages`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(this.storageKey(accountId), data);
          this._coupons = this.buildCouponsFromRowData(data);
          // console.log('fetchCouponsByUserId', this._coupons);
          return of(this._coupons);
        }),
        catchError(async err => {
          this.userErrorHandlerService.addError({
            criticity: 1,
            service: 'coupon_usages',
            platform: 'both',
            data: err,
            errorCode: 'csfcbuid',
          });
          return [];
        })
      );
    }
  }

  fetchParrainageCode(accountId: string): Promise<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return null;
    }

    if (!!accountId) {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/parrainage`;

      return this.http
        .get(url)
        .pipe(
          switchMap(async (data: any) => {
            if (!!data && !!data.data[0]) {
              return data.data[0];
            }
            return null;
          }),
          catchError(async err => {
            return {
              errorCode: 'E301',
              errorMessage: 'coupons-parrainage Service failed',
              errorOriginal: err,
            };
          })
        )
        .toPromise();
    }
  }

  async loadStudentCoupon(student: Student) {
    if (student.isGuest()) {
      this._coupons = [];
    } else {
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadFromStorage(student);
      } else {
        this.fetchCouponsByUserId(student.remoteId).subscribe(res => {
          this._coupons = res;
        });
      }
    }
  }

  get coupons(): Array<CouponModel> {
    return this._coupons;
  }

  private;

  async loadFromStorage(student: Student) {
    this.storageService.get(this.storageKey(student.remoteId)).then(res => {
      this._coupons = !!res ? this.buildCouponsFromRowData(res) : [];
    });
  }

  storageKey(accountId: string): string {
    return `${accountId}-coupons`;
  }

  buildCouponsFromRowData(data: any): Array<CouponModel> {
    const arr: Array<CouponModel> = [];
    if (!!data && !!data.data && data.data.length > 0) {
      for (const coupon of data.data) {
        arr.push(this.buildCouponFromRawData(coupon));
      }
    }
    return arr;
  }

  buildCouponFromRawData(data: any): CouponModel {
    return new CouponModel({
      id: !!data.attributes.coupon.data.id
        ? data.attributes.coupon.data.id
        : null,
      coupon_usage_id: !!data.id ? data.id : null,
      type: !!data.attributes.coupon.data.type
        ? data.attributes.coupon.data.type
        : null,
      coupon_usage_type: !!data.type ? data.type : null,
      offer_id: !!data.attributes.coupon.data.attributes.offer_id
        ? data.attributes.coupon.data.attributes.offer_id
        : null,
      name: !!data.attributes.coupon.data.attributes.name
        ? data.attributes.coupon.data.attributes.name
        : null,
      discount: !!data.attributes.coupon.data.attributes.discount
        ? data.attributes.coupon.data.attributes.discount
        : null,
      discount_percent: !!data.attributes.coupon.data.attributes
        .discount_percent
        ? data.attributes.coupon.data.attributes.discount_percent
        : null,
      free_code: !!data.attributes.coupon.data.attributes.free_code
        ? data.attributes.coupon.data.attributes.free_code
        : null,
      free_credits: !!data.attributes.coupon.data.attributes.free_credits
        ? data.attributes.coupon.data.attributes.free_credits
        : null,
      message: !!data.attributes.coupon.data.attributes.message
        ? data.attributes.coupon.data.attributes.message
        : null,
      payment_necessary: !!data.attributes.coupon.data.attributes
        .payment_necessary
        ? data.attributes.coupon.data.attributes.payment_necessary
        : null,
      source_type: !!data.attributes.coupon.data.attributes.source_type
        ? data.attributes.coupon.data.attributes.source_type
        : null,
      source_id: !!data.attributes.coupon.data.attributes.source_id
        ? data.attributes.coupon.data.attributes.source_id
        : null,
      free_prefecture_registration: !!data.attributes.coupon.data.attributes
        .free_prefecture_registration
        ? data.attributes.coupon.data.attributes.free_prefecture_registration
        : null,
      free_code_exam_credits: !!data.attributes.coupon.data.attributes
        .free_code_exam_credits
        ? data.attributes.coupon.data.attributes.free_code_exam_credits
        : null,
      free_driving_exam_credits: !!data.attributes.coupon.data.attributes
        .free_driving_exam_credits
        ? data.attributes.coupon.data.attributes.free_driving_exam_credits
        : null,
      created_at: !!data.attributes.coupon.data.attributes.created_at
        ? data.attributes.coupon.data.attributes.created_at
        : null,
      updated_at: !!data.attributes.coupon.data.attributes.updated_at
        ? data.attributes.coupon.data.attributes.updated_at
        : null,
      offer_name: !!data.attributes.coupon.data.attributes.offer_name
        ? data.attributes.coupon.data.attributes.offer_name
        : null,
      offer_type: !!data.attributes.coupon.data.attributes.offer_type
        ? data.attributes.coupon.data.attributes.offer_type
        : null,
      offer_associated: !!data.attributes.offer.data.attributes
        ? data.attributes.offer.data.attributes
        : null,
    });
  }
}
