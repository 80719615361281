<form [formGroup]="form" *ngIf="!!options">
  <div formArrayName="items">
    <div *ngFor="let item of items.controls; index as i">
      <input
        type="checkbox"
        [id]="field.field_name + '_' + options[i].text"
        [formControlName]="i" />
      <label
        class="clickable"
        [ngClass]="{'margin-top-8': i > 0}"
        [for]="field.field_name + '_' + options[i].text">
        <span class="value-label">{{options[i].value}}.</span>
        <span class="value-text">{{options[i].text}}</span>
      </label>
    </div>
  </div>
</form>
