<div
  class="modal-errors content-center"
  [ngClass]="{'evs-card-ui': isDesktop && !lightMode, 'light-mode': lightMode}">
  <div class="modal-errors-emoji">
    <img src="assets/png/face-with-head-bandage.png" alt="EnVoitureSimone" />
    <!-- 🤕 -->
  </div>
  <h3>Ce contenu est indisponible pour le moment</h3>
  <p>Une erreur est survenue sur notre service. Réessaie plus tard.</p>
</div>
