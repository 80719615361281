import { EvsRecord } from './evsRecord';

export interface ISerie {
  id: string;
  position: number;
  type: string; // (static, thematic, ...)
  scope: string; // (payed, free, registered)
  theme?: Array<string>;
  downloadState?: string; // (downaloaded, downloading, deleting, none)
  size?: number;
  updatedAt: string;
  questions?: Array<string>;
  lastSessions?: Array<string>;
  nbQuestions?: number;
}

export class Serie extends EvsRecord implements ISerie {
  private _id: string;
  private _position: number;
  private _type: string;
  private _scope: string;
  private _theme: Array<string>;
  private _downloadState: string;
  private _size: number;
  private _updatedAt: string;
  private _questions: Array<string>;
  private _lastSessions: Array<string>;
  private _nbQuestions: number;

  constructor(obj: ISerie) {
    super(obj);
  }

  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  public get position(): number {
    return this._position;
  }
  public set position(value: number) {
    this._position = value;
  }
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }
  public get scope(): string {
    return this._scope;
  }
  public set scope(value: string) {
    this._scope = value;
  }
  public get theme(): Array<string> {
    return this._theme;
  }
  public set theme(value: Array<string>) {
    this._theme = value;
  }
  public get downloadState(): string {
    return this._downloadState;
  }
  public set downloadState(value: string) {
    this._downloadState = value;
  }
  public get size(): number {
    return this._size;
  }
  public set size(value: number) {
    this._size = value;
  }
  public get updatedAt(): string {
    return this._updatedAt;
  }
  public set updatedAt(value: string) {
    this._updatedAt = value;
  }
  public get questions(): Array<string> {
    return this._questions;
  }
  public set questions(value: Array<string>) {
    this._questions = value;
  }
  public get lastSessions(): Array<string> {
    return this._lastSessions;
  }
  public set lastSessions(value: Array<string>) {
    this._lastSessions = value;
  }
  public get nbQuestions(): number {
    return this._nbQuestions;
  }
  public set nbQuestions(value: number) {
    this._nbQuestions = value;
  }
}
