import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ModalController, NavParams } from '@ionic/angular';

import { NavigationService } from '../../services/navigation.service';
import { StatsService } from '../../services/stats.service';

import { UtilsService } from '@commons/services';
import { ActivatedRoute } from '@angular/router';

import { ModalIframeComponent } from '@app/modals';

import { EasyDebugDecorator } from '../../decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'app-disconected-modal',
  templateUrl: './disconected-modal.component.html',
  styleUrls: ['./disconected-modal.component.scss'],
})
export class DisconectedModalComponent implements OnInit {
  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  constructor(
    public navController: NavController,
    public utils: UtilsService,
    private modalController: ModalController,
    public navigationService: NavigationService,
    private statsService: StatsService,
    private router: ActivatedRoute
  ) {
    this.initPage();
  }

  ngOnInit() {}

  async initPage() {
    this.statsService.send({
      name: 'modal:view',
      payload: { page: '/modal/disconnected' },
    });
  }

  closeModalPack() {
    this.statsService.send({
      name: 'modal:unview',
      payload: {
        referer: '/modal/disconnected',
        page: `/${this.router.firstChild.snapshot.routeConfig.path}`,
      },
    });
    if (!!this.modalController) {
      this.modalController.dismiss();
    }
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options?) {
    this.closeModalPack();
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
