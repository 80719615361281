import {
  NgZone,
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'tunnel-password-comp',
  templateUrl: 'tunnel-password.html',
  styleUrls: ['tunnel-password.scss'],
})
export class TunnelPasswordComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() compDataSent = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  userId = null;

  isActive: Boolean = false;

  passwordForm: UntypedFormGroup;
  formCompleted = false;
  showPassword = 'password';

  lastFieldFocused: any = null;

  passwordValidatorStates: any = {};
  enablePasswordValidator = false;

  allowNextStep: any = false;

  compDataToSend: any = null;
  dataSent = false;

  isDesktop = window.innerWidth > 767 ? true : false;

  simulateFormError = false;
  formErrorMsg = '';

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ngZone.run(() => {
      this.isDesktop = window.innerWidth > 767 ? true : false;
    });
  }

  constructor(
    private ngZone: NgZone,
    private formBuilder: UntypedFormBuilder
  ) {
    this.constructForms();
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.userId =
      this.compOptions && typeof this.compOptions.userId !== 'undefined'
        ? this.compOptions.userId
        : this.userId;
    this.isActive =
      this.compOptions && typeof this.compOptions.isActive !== 'undefined'
        ? this.compOptions.isActive
        : this.isActive;
    this.simulateFormError =
      this.compOptions &&
      typeof this.compOptions.simulateFormError !== 'undefined'
        ? this.compOptions.simulateFormError
        : this.simulateFormError;
    this.formErrorMsg =
      this.compOptions && typeof this.compOptions.formErrorMsg !== 'undefined'
        ? this.compOptions.formErrorMsg
        : this.formErrorMsg;
    if (this.isDebug) {
      // console.log('TunnelAlreadyDriveComponent - compOptions: ', this.compOptions);
    }
    this.dataSent = false; // reset
    if (this.simulateFormError) {
      setTimeout(() => {
        this.passwordForm.controls.password.setErrors({ wrongPassword: true });
      }, 0);
    }
  }

  constructForms() {
    this.passwordForm = this.formBuilder.group({
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  get getPasswordForm() {
    return this.passwordForm.controls;
  }

  toggleshowPassword() {
    this.showPassword = this.showPassword === 'text' ? 'password' : 'text';
  }

  validatePasswords(field: string): any {
    let isValid = true;
    let isMinLength = false;
    let isUppercase = false;
    let isSpecialChar = false;
    let isNumber = false;

    const format = /[#?!@$%^&*-]/;

    if (field !== '') {
      if (field.length >= 6) {
        isMinLength = true;
      }
      for (let i = 0; i < field.length; i++) {
        if (
          field.charAt(i) === field.charAt(i).toUpperCase() &&
          isNaN(Number(field.charAt(i)))
        ) {
          isUppercase = true;
        }
        if (!isNaN(Number(field.charAt(i)))) {
          isNumber = true;
        }
      }
      if (format.test(field)) {
        isSpecialChar = true;
      }
    }
    if (isMinLength && isUppercase && isSpecialChar && isNumber) {
      isValid = true;
    } else {
      isValid = false;
    }

    this.passwordValidatorStates = {
      isValid: isValid,
      isMinLength: isMinLength,
      isUppercase: isUppercase,
      isSpecialChar: isSpecialChar,
      isNumber: isNumber,
    };
  }

  resetPwd() {
    this.sendCompData({ action: 'resetPwd', data: null });
  }

  setCompDataToSend(data: any) {
    this.compDataToSend = {
      login: {
        password: this.passwordForm.value.password || null, // string
      },
    };
    // console.log('compDataToSend: ', this.passwordForm.value.password, this.compDataToSend);
  }

  sendCompData(data: any) {
    if (!this.dataSent) {
      // this.dataSent = true;
      // console.log('TunnelLocationComponent - sendCompData: ', data);
      this.setCompDataToSend(null);
      setTimeout(() => {
        this.simulateFormError = false;
        this.passwordForm.controls.password.setErrors({ wrongPassword: null });
        this.passwordForm.controls.password.updateValueAndValidity();
      }, 0);
      if (!!data) {
        data.compData = this.compDataToSend;
        this.compDataSent.emit(data);
      } else {
        this.compDataSent.emit({
          action: 'error',
          data: 'No data sent from TunnelLocationComponent',
        });
      }
    }
  }
}
