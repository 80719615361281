import { EvsRecord } from './evsRecord';

export interface IAnswer {
  id: string;
  state: string;
  serieId: string;
  timespent: number;
  updatedAt: string;
  sessionId: string;
  questionId: string;
  answer: Array<string>;
}

export class Answer extends EvsRecord implements IAnswer {
  private _id: string;
  private _state: string;
  private _serieId: string;
  private _timespent: number;
  private _updatedAt: string;
  private _sessionId: string;
  private _questionId: string;
  private _answer: Array<string>;

  constructor(obj: IAnswer) {
    super(obj);
  }

  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }
  public get state(): string {
    return this._state;
  }
  public set state(value: string) {
    this._state = value;
  }
  public get serieId(): string {
    return this._serieId;
  }
  public set serieId(value: string) {
    this._serieId = value;
  }
  public get timespent(): number {
    return this._timespent;
  }
  public set timespent(value: number) {
    this._timespent = value;
  }
  public get updatedAt(): string {
    return this._updatedAt;
  }
  public set updatedAt(value: string) {
    this._updatedAt = value;
  }
  public get sessionId(): string {
    return this._sessionId;
  }
  public set sessionId(value: string) {
    this._sessionId = value;
  }
  public get questionId(): string {
    return this._questionId;
  }
  public set questionId(value: string) {
    this._questionId = value;
  }
  public get answer(): Array<string> {
    return this._answer;
  }
  public set answer(value: Array<string>) {
    this._answer = value;
  }
}
