import {
  NgZone,
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';
import { ModalController } from '@ionic/angular';
import { ModalIframeComponent } from '@app/modals';
import { NavigationService } from '@app/app.services';

@EasyDebugDecorator
@Component({
  selector: 'tunnel-loading-comp',
  templateUrl: 'tunnel-loading.html',
  styleUrls: ['tunnel-loading.scss'],
})
export class TunnelLoadingComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() compDataSent = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  userId = null;

  passwordForm: UntypedFormGroup;
  formCompleted = false;
  showPassword = 'password';

  lastFieldFocused: any = null;

  passwordValidatorStates: any = {};
  enablePasswordValidator = false;

  allowNextStep: any = false;

  compDataToSend: any = null;

  isDesktop = window.innerWidth > 767 ? true : false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ngZone.run(() => {
      this.isDesktop = window.innerWidth > 767 ? true : false;
    });
  }

  constructor(
    private ngZone: NgZone,
    private formBuilder: UntypedFormBuilder,
    public modalController: ModalController,
    private navigationService: NavigationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.userId =
      this.compOptions && typeof this.compOptions.userId !== 'undefined'
        ? this.compOptions.userId
        : this.userId;
    if (this.isDebug) {
      // console.log('TunnelAlreadyDriveComponent - compOptions: ', this.compOptions);
    }
  }

  setCompDataToSend(data: any) {
    this.compDataToSend = {
      login: {
        password: this.passwordForm.value.password || null, // string
      },
    };
    // console.log('compDataToSend: ', this.passwordForm.value.password, this.compDataToSend);
  }

  sendCompData(data: any) {
    // console.log('TunnelLoadingComponent - sendCompData: ', data);
    // this.setCompDataToSend(null);
    if (!!data) {
      data.compData = null;
      this.compDataSent.emit(data);
    } else {
      this.compDataSent.emit({
        action: 'error',
        data: 'No data sent from TunnelLocationComponent',
      });
    }
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options?) {
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
