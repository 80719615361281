export class EvsRecord {
  constructor(obj: any) {
    for (let attr in obj) {
      if (!!attr) {
        if (attr.indexOf('_') === 0) {
          attr = attr.slice(1, attr.length);
          this[attr] = obj[`_${attr}`];
        } else {
          this[attr] = obj[attr];
        }
      }
    }
  }
}
