import {
  Component,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  AfterViewInit,
  EventEmitter,
} from '@angular/core';

import { UtilsService } from '@commons/services';

@Component({
  selector: 'fav-teacher-modal-comp',
  templateUrl: 'fav-teacher-modal.html',
  styleUrls: ['fav-teacher-modal.scss'],
})
export class FavTeacherModalComponent implements OnChanges, AfterViewInit {
  @Input() compOptions: any;
  @ViewChild('modal', { static: true }) modal: any;
  @ViewChild('modalBg') modalBg: any;
  @ViewChild('modalContainer') modalContainer: any;
  @Output() canClose = new EventEmitter<boolean>();

  isDebug = false;
  isDesktop = window.innerWidth > 767 ? true : false;

  refEl: any = null;

  id = '';
  data: any = null;
  content: any = null;

  ioDuration = 300;

  constructor(
    private renderer: Renderer2,
    private utils: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngAfterViewInit() {
    if (!!this.data) {
      console.log('data', this.data, this.isDesktop);
      if (this.isDesktop && !!this.data.id) {
        // DESKTOP
        console.log('a');
        this.refEl = document.getElementById(this.data.id);
        console.log('b');
        console.log(this.refEl);
        this.renderer.setStyle(this.refEl, 'position', 'relative');
        this.renderer.setStyle(this.refEl, 'z-index', '6001');
        this.renderer.setStyle(
          this.modalContainer.nativeElement,
          'position',
          'absolute'
        );
        this.renderer.setStyle(
          this.modalContainer.nativeElement,
          'top',
          this.refEl.getBoundingClientRect().top +
            this.refEl.offsetHeight +
            8 +
            'px'
        );
        this.renderer.setStyle(
          this.modalContainer.nativeElement,
          'left',
          this.refEl.offsetLeft + 'px'
        );
        this.renderer.setStyle(
          this.modalContainer.nativeElement,
          'width',
          this.refEl.offsetWidth + 'px'
        );
      }
    }
    this.setClasses();
  }

  setClasses() {
    this.renderer.addClass(this.modalBg.nativeElement, 'active');
    this.renderer.addClass(this.modalContainer.nativeElement, 'active');
  }

  closeModal() {
    console.log('close modal');
    this.renderer.removeClass(this.modalBg.nativeElement, 'active');
    this.renderer.addClass(this.modalBg.nativeElement, 'close');
    this.renderer.removeClass(this.modalContainer.nativeElement, 'active');
    this.renderer.addClass(this.modalContainer.nativeElement, 'close');
    if (this.isDesktop && !!this.refEl) {
      console.log('remove zindex');
      this.renderer.setStyle(this.refEl, 'position', 'initial');
      this.renderer.setStyle(this.refEl, 'z-index', 'initial');
    }
    setTimeout(() => {
      this.canClose.emit(true);
    }, this.ioDuration);
  }

  setGlobals() {
    this.isDebug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.isDebug;
    this.id =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.id)
        ? this.compOptions.id
        : this.id;
    this.data =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.data)
        ? this.compOptions.data
        : this.data;
    if (this.id !== '') {
      this.modal.nativeElement.id = this.id;
    }
    if (!!this.data) {
      console.log('data', this.data);
    }
  }

  // RESIZE EVENT
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = window.innerWidth > 767 ? true : false;
    setTimeout(() => {
      this.setClasses();
    }, 0);
  }
}
