import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: `init5`, initializer: `init` })
@EasyDebugDecorator
export class BrowserUpdateService {
  constructor() {
    // console.log('BrowserUpdate service instanciate');
  }

  async init() {
    // console.log(`Launching BrowserUpdate init`);
    return new Promise<string>((resolve, reject) => {
      setTimeout(
        () => {
          resolve('BrowserUpdate done');
          // console.log(`init BrowserUpdate done`);
        },
        Math.floor(Math.random() * 1000) + 500
      );
    });
  }
}
