import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { StudentService } from './student.service';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class ReserverLeconService {
  public favoriteTeachers: any = [];
  public reserverLeconAction: any = {};

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private studentService: StudentService
  ) {}

  async onInit() {
    // console.log('ReserverLeconService done');
    if (this.studentService.student.isGuest) {
      return;
    } else {
      await this.fetchFavoriteTeachers();
    }
    return 'ReserverLeconService done';
  }

  async fetchLocations(): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/locations`;

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          if (!!data && !!data.data) {
            return of(data.data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService fetchLocations failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async fetchNearest(lng: any, lat: any, gearbox_type: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/availabilities?longitude=${lng}&latitude=${lat}&gearbox_type=${gearbox_type}`;

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          console.log('fetchNearest data', data);
          if (!!data && !!data.data) {
            return of(data.data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService fetchNearest failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async fetchAvaibility(id: any, gearbox_type: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/locations/${id}/teachers?gearbox_type=${gearbox_type}`;

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          console.log('fetchAvaibility data', data);
          if (!!data && !!data.data) {
            return of(data.data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService fetchAvaibility failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async fetchTeacherDetails(teacherId: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/teachers/${teacherId}`;

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          console.log('fetchTeacherDetails data', data);
          if (!!data && !!data.data) {
            return of(data.data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService fetchTeacherDetails failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async fetchTeacherAvis(
    teacherId: any,
    page: any,
    items_per_page: any
  ): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    let url = `${environment.token_auth_config.apiBase}/v3/teachers/${teacherId}/ratings`;

    if (!!page) {
      url += `?page=${page}`;
    }
    if (!!items_per_page) {
      url += `&items_per_page=${items_per_page}`;
    }

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          console.log('fetchTeacherAvis data', data);
          if (!!data && !!data) {
            return of(data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService fetchTeacherAvis failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async fetchTeacherAvailabilities(
    locationId: any,
    teacherId: any,
    gearbox_type: any
  ): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    let url = `${environment.token_auth_config.apiBase}/v3/locations/${locationId}/teachers_availabilities?gearbox_type=${gearbox_type}`;

    if (!!teacherId) {
      url += `&teacher_id=${teacherId}`;
    }

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          console.log('fetchTeacherAvailabilities data', data);
          if (!!data) {
            return of(data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage:
              'ReserverLeconService fetchTeacherAvailabilities failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async fetchNextTeacherAvailabilities(
    locationId: any,
    teacherId: any,
    date: any,
    gearbox_type: any
  ): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    let url = `${environment.token_auth_config.apiBase}/v3/locations/${locationId}/teachers_availabilities?gearbox_type=${gearbox_type}`;

    if (!!teacherId) {
      url += `&teacher_id=${teacherId}`;
    }
    if (!!date) {
      url += `&from=${date}`;
    }

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          console.log('fetchNextTeacherAvailabilities data', data);
          if (!!data) {
            return of(data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage:
              'ReserverLeconService fetchNextTeacherAvailabilities failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async fetchFavoriteTeachers(): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/favorite_teachers`;

    return this.http
      .get(url)
      .pipe(
        switchMap((data: any) => {
          console.log('fetchFavoriteTeachers data', data);
          if (!!data && !!data.data) {
            this.favoriteTeachers = data.data;
            return of(data.data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService fetchFavoriteTeachers failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async addTeacherToFavorite(teacherId: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/favorite_teachers`;

    return this.http
      .post(url, { teacher_id: teacherId })
      .pipe(
        switchMap((data: any) => {
          console.log('addTeacherToFavorite data', data);
          if (!!data) {
            return of(data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService addTeacherToFavorite failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async removeTeacherToFavorite(teacherId: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/favorite_teachers/${teacherId}`;

    return this.http
      .delete(url)
      .pipe(
        switchMap((data: any) => {
          console.log('removeTeacherToFavorite data', data);
          if (!!data) {
            return of(data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService removeTeacherToFavorite failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async sendLesson(payload: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/lessons`;

    return this.http
      .post(url, payload)
      .pipe(
        switchMap((data: any) => {
          console.log('sendLesson data', data);
          if (!!data) {
            return of(data);
          } else {
            return of(null);
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'ReserverLeconService sendLesson failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }
}
