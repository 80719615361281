import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: `init0`, initializer: `init` })
@EasyDebugDecorator
export class LoggerService {
  constructor() {
    // console.log('Logger service instanciate');
  }

  async init() {
    // console.log(`Launching Logger init`);
    return new Promise<string>((resolve, reject) => {
      setTimeout(
        () => {
          resolve('Logger done');
          // console.log(`init Logger done`);
        },
        Math.floor(Math.random() * 1000) + 500
      );
    });
  }
}
