<div class="tunnel-loading-container">
  <div class="content">
    <div class="content-container">
      <div class="content-header">
        <div class="tunnel-back-controls">
          <div class="back-btn shimmer" (click)="openLink('code')">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone" />
          </div>
        </div>
      </div>
      <div class="content-body">
        <div
          class="wpe-100 border-radius-6 padding-6 display-block margin-bottom-10 shimmer"></div>
        <div
          class="wpe-100 border-radius-6 padding-6 display-block margin-bottom-10 shimmer"></div>
        <div
          class="wpe-70 border-radius-6 padding-6 display-block margin-bottom-24 shimmer"></div>
        <div class="fake-input"></div>
        <div *ngIf="isDesktop" class="btn padding-20 shimmer fake-btn"></div>
      </div>
    </div>
  </div>
  <div class="visuel shimmer"></div>
</div>
