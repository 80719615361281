import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppInitService, StudentService } from '@app/app.services';
import { AuthService } from '../../app/services/auth.service';
import { Observable, of } from 'rxjs';
import { TokenService } from '../../app/services/token.service';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Injectable({
  providedIn: 'root',
})
export class EvsMicroService implements OnInit {
  private accessToken = null;
  private baseUrl = `${environment.cmsBase}/api/v${environment.apiVersion}`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appInit: AppInitService,
    private tokenService: TokenService
  ) {}

  ngOnInit() {
    // For now I am hooking on Init but we should listen on the auth lib subscribers
    // if (this.appInit.done == true){
    //   this.initDone()
    // } else {
    //   this.appInit.onStepChange().subscribe((step) => {
    //     if (step.step == 'init3' && step.status == 'done'){this.initDone(); }
    //   })
    // }
    return 'MicroService done';
  }

  private initDone() {
    // this.getAccessToken().subscribe();
  }

  // getAccessToken(): Observable<any> {
  //   if (this.tokenService.tokenService.userSignedIn() === true) {
  //     if (this.accessToken == null) {
  //       return this.http.get(this.baseUrl + '/session/service').pipe(
  //         switchMap(
  //           res => {
  //             this.accessToken = res;
  //             return of(res);
  //           }
  //         ),
  //         catchError(
  //           async err => {
  //             return null;
  //           }
  //         )
  //       );
  //     } else {
  //       return of(this.accessToken);
  //     }
  //   } else {
  //     // User is not logued so he cant have an acces token
  //     return of(null);
  //   }
  // }

  clearToken() {
    this.accessToken = null;
  }

  buildServiceUrl(serviceName: string): string {
    const service = environment.evsServices.find(
      elt => elt.name.trim() === serviceName.trim()
    );

    if (!!service) {
      return `${service.protocol}://${service.hostname}${service.urlBase}`;
    } else {
      return null;
    }
  }
}
