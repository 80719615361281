import {
  OnInit,
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';

import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'custom-radio-comp',
  templateUrl: 'custom-radio.html',
  styleUrls: ['custom-radio.scss'],
})
export class CustomRadioComponent implements OnInit, OnChanges {
  @Input() compOptions: any;
  @Output() userSelection = new EventEmitter<any>();

  debug = false;
  options = [];
  selected: any;
  indexSelected = 0;
  widthSize = 0;
  isWhite = false;
  isSmall = false;

  constructor(private utils: UtilsService) {}

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    // detect @input changes
    if (this.utils.isDefined(compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.debug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.debug;
    this.options =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.options)
        ? this.compOptions.options
        : this.options;
    this.isWhite =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.isWhite)
        ? this.compOptions.isWhite
        : this.isWhite;
    this.isSmall =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.isSmall)
        ? this.compOptions.isSmall
        : this.isSmall;
    if (this.options.length > 0) {
      this.widthSize = Number((100 / this.options.length).toFixed(2));
      this.selected =
        this.utils.isDefined(this.compOptions) &&
        this.utils.isDefined(this.compOptions.selected)
          ? this.compOptions.selected
          : this.options[0].value;
      this.indexSelected =
        this.utils.isDefined(this.compOptions) &&
        this.utils.isDefined(this.compOptions.selected)
          ? this.options.findIndex(p => p.value === this.compOptions.selected)
          : 0;
      this.changeValue(this.indexSelected, this.selected);
    }
  }

  changeValue(index: any, value: any) {
    this.indexSelected = index;
    this.selected = value;
    this.userSelection.emit(value);
  }
}
