import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';
import { StudentService } from './student.service';

import { setAccount, setAbility } from '../decorators/permissions.decorator';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root',
})
// Need to fixe init provinding to keep instance before playing with that
@Initialable({ step: 'init3', initializer: 'init', services: [StudentService] })
@EasyDebugDecorator
export class PermissionsService {
  constructor(public currentAccount: StudentService) {
    setAccount(this.currentAccount);
  }

  async init() {
    // console.log('Permissions init');
    return new Promise<string>((resolve, reject) => {
      setAccount(this.currentAccount);
      this.loadPermissions();
      resolve('Permissions done');
    });
  }

  private;

  loadPermissions(): void {
    // Not sure if it is realy going to be usefull
  }

  can(action: string, target: any, callback: Function) {
    setAbility(`${action}${target.name}`, {
      context: target.constructor.name,
      callback: callback,
    });
  }
}
