<div
  *ngIf="text !== 'pubInApp'"
  class="medium-card clickable"
  (click)="openLink(link)"
  [ngStyle]="{width: width+'px', height: height+'px'}">
  <div *ngIf="text == ''" class="colored-bloc shimmer"></div>
  <div
    *ngIf="text !== ''"
    class="colored-bloc"
    [ngStyle]="{'background-image': 'url(assets/2022/illustrations/' + icons[index].bg + '.svg)'}"></div>
  <div *ngIf="text == ''" class="title-card text-neutral-400">
    <div class="wpe-60 border-radius-6 padding-6 shimmer"></div>
  </div>
  <div *ngIf="text !== ''" class="title-card">{{text}}</div>
</div>
<div
  *ngIf="text === 'pubInApp'"
  class="medium-card card-pub"
  (click)="openLink(link)"
  [ngStyle]="{width: width+'px', height: height+'px'}">
  <div *ngIf="text !== ''" class="colored-bloc invisible"></div>
  <div class="icon-card invisible"></div>
  <div *ngIf="text !== ''" class="title-card title-card-pub">
    <img src="assets/svg/flat-color/cdr-book-flat.svg" alt="EnVoitureSimone" />
    Avec le <span><i class="bold text-orange-evs">Pack Permis</i>,</span>
    <span>reçois un</span> <span>livret de code</span>
    <button class="btn small content-center">
      En savoir plus
      <img src="assets/svg/arrowRight.svg" alt="EnVoitureSimone" />
    </button>
  </div>
</div>
