import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '@app/env';

import { Student } from '@app/models';

import {
  Initialable,
  NetworkStatusService,
  StorageService,
} from '@app/services';

import { EasyDebugDecorator } from '@app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class StudentDocumentsService {
  public documentTypeRegistrationCertificate = 'Student::Documents::Neph';
  public documentTypeDrivingTheoryCertificate =
    'Student::Documents::CodeJustificative';
  public documentTypeDrivingLicenseNEPH =
    'Student::Documents::DrivingLicenceNeph';
  public documentTypeDrivingLicense = 'Student::Documents::DrivingLicence';

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private storageService: StorageService
  ) {}

  async onInit() {
    // console.log('Documents init');
    return 'StudentDocuments done';
  }

  fetchDocumentsForindex(student: Student) {
    if (!!student && !!student.remoteId) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${student.remoteId}/account_dashboard`;

      if (this.networkService?.isOffline() || student.isGuest()) {
        return of([]);
      }

      return this.http.get(url).pipe(
        switchMap(async data => {
          return this.buildDocumentsForIndexFromRawData(data);
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Student-documents Service failed',
            errorOriginal: err,
          };
        })
      );
    }
    return of([]);
  }

  fetchStudentsDocumentsByUserId(student: Student) {
    if (!!student && !!student.remoteId) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/student/${student.remoteId}/official_papers`;

      if (this.networkService?.isOffline() || student.isGuest()) {
        return of([]);
      }

      return this.http.get(url).pipe(
        switchMap(async data => {
          if (!!data) {
            await this.storageService.set(
              this.storageKey(student.remoteId),
              data
            );
            return this.buildDocumentsFromRawData(data);
          }
          return [];
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Student-documents Service failed',
            errorOriginal: err,
          };
        })
      );
    }
    return of([]);
  }

  private buildDocumentsForIndexFromRawData(data: any) {
    let documents = [];
    if (!!data) {
      documents = data.data.general_info.list_documents_for_index;
    }
    return documents;
  }

  private buildDocumentsFromRawData(data: any) {
    const documents = [];

    if (!!data && !!data.data) {
      for (const document of data.data) {
        documents.push(this.buildDocumentFromRawData(document));
      }
    }

    return documents;
  }

  private buildDocumentFromRawData(data: any) {
    return {
      id: data.id,
      type: data.attributes.type,
      assets: data.attributes.assets,
      state: data.attributes.state,
      confirmed_at: data.attributes.confirmed_at,
      ...data.attributes.sub_data,
    };
  }

  storageKey(accountId: string): string {
    return `${accountId}-studentDocuments`;
  }

  uploadDocument(documentData: any, remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/student/${remoteId}/official_papers`;
    let data = {};

    if (documentData.type === this.documentTypeRegistrationCertificate) {
      data = {
        type: documentData.type,
        sub_data: {
          first_name: documentData.firstName,
          family_name: documentData.lastName,
          gender: documentData.gender,
          dob: documentData.birthdate,
          // birth_location: documentData.birth_location,
          // born_abroad: documentData.born_abroad,
          number_neph: documentData.NEPHNumber,
          categ_permis: documentData.license,
        },
        assets: [],
      };
    }

    if (documentData.type === this.documentTypeDrivingTheoryCertificate) {
      data = {
        type: documentData.type,
        sub_data: {
          date: documentData.drivingTheoryCertificateDate,
          code_exam_success_statement: true,
        },
        assets: [documentData.drivingTheoryCertificateFile],
      };
    }

    if (documentData.type === this.documentTypeDrivingLicenseNEPH) {
      data = {
        type: documentData.type,
        sub_data: {
          number: documentData.licenseNumber,
          categ_permis: documentData.license,
        },
        assets: {
          recto: documentData.drivingLicenseRectoFile,
          verso: documentData.drivingLicenseVersoFile,
        },
      };
    }

    if (documentData.type === this.documentTypeDrivingLicense) {
      data = {
        type: documentData.type,
        sub_data: {},
        assets: {
          recto: documentData.drivingLicenseRectoFile,
          verso: documentData.drivingLicenseVersoFile,
        },
      };
    }

    return this.http.post(url, data).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(err);
      })
    );
  }
  updateDocument(
    documentData: any,
    remoteId: string,
    documentId: string
  ): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/student/${remoteId}/official_papers/${documentId}/update_neph_paper`;
    let data = {};

    if (documentData.type === this.documentTypeRegistrationCertificate) {
      data = {
        type: documentData.type,
        sub_data: {
          first_name: documentData.firstName,
          family_name: documentData.lastName,
          gender: documentData.gender,
          dob: documentData.birthdate,
          // birth_location: documentData.birth_location,
          // born_abroad: documentData.born_abroad,
          number_neph: documentData.NEPHNumber,
          categ_permis: documentData.license,
        },
        assets: [],
      };
    }

    if (documentData.type === this.documentTypeDrivingTheoryCertificate) {
      data = {
        type: documentData.type,
        sub_data: {
          date: documentData.drivingTheoryCertificateDate,
          code_exam_success_statement: true,
        },
        assets: [documentData.drivingTheoryCertificateFile],
      };
    }

    if (documentData.type === this.documentTypeDrivingLicenseNEPH) {
      data = {
        type: documentData.type,
        sub_data: {
          number: documentData.licenseNumber,
          categ_permis: documentData.license,
        },
        assets: {
          recto: documentData.drivingLicenseRectoFile,
          verso: documentData.drivingLicenseVersoFile,
        },
      };
    }

    if (documentData.type === this.documentTypeDrivingLicense) {
      data = {
        type: documentData.type,
        sub_data: {},
        assets: {
          recto: documentData.drivingLicenseRectoFile,
          verso: documentData.drivingLicenseVersoFile,
        },
      };
    }

    return this.http.put(url, data).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  deleteDocument(documentId: string, remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/student/${remoteId}/official_papers/${documentId}`;

    return this.http.delete(url).pipe(
      switchMap(async res => {
        return res;
      }),
      catchError(err => {
        return of(err);
      })
    );
  }
}
