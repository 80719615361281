import { environment } from '../../environments/environment';
import { switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NetworkStatusService } from './network-status.service';
import { Injectable } from '@angular/core';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { Initialable } from './app-init.service';
import { Platform } from '@ionic/angular';
import { Observable, of, Subject } from 'rxjs';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { SentryService, ServiceLocator } from '@app/app.services';

enum Version {
  MAJOR = 0,
  MINOR = 1,
  PATCH = 2,
}

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: 'init0', initializer: 'onInit' })
@EasyDebugDecorator
export class SoftDeployService {
  minorUpdate = true;
  syncObs = new Subject<number>();
  syncObs$ = this.syncObs.asObservable();
  updateFinished = new Subject<boolean>();
  updateFinished$ = this.updateFinished.asObservable();
  update = false;
  updateDone = false;
  lastVersion: number[];
  versionString = require('../../../package.json').version;
  actualVersion = this.extractVersion(require('../../../package.json').version);
  needUpdate = false;
  // à conditionner en fonction des params qu'on va recevoir via les universal links
  skipSoftDeploy = environment.skipSoftDeploy;

  versionMessages = [];

  versionMessagesObs = new Subject<any>();
  versionMessagesObs$ = this.versionMessagesObs.asObservable();
  sentryService: SentryService;

  constructor(
    private deployApi: Deploy,
    private networkService: NetworkStatusService,
    private platform: Platform,
    private http: HttpClient
  ) {}

  async onInit() {
    this.sentryService = ServiceLocator.injector.get(SentryService);
    const version = await this.fetchVersion();
    this.lastVersion = this.extractVersion(version);

    await this.fetchVersionMessages(this.versionString).toPromise();

    if (this.platform.is('cordova') && !!this.lastVersion) {
      if (!!this.versionMessages && this.versionMessages.length > 0) {
        for (const versionMessage of this.versionMessages) {
          if (!!versionMessage && !!versionMessage.message) {
            this.versionMessagesObs.next(versionMessage);
            this.skipSoftDeploy = true;
          }
        }
      } else {
        if (
          (this.lastVersion[Version.PATCH] >
            this.actualVersion[Version.PATCH] &&
            this.lastVersion[Version.MINOR] ===
              this.actualVersion[Version.MINOR]) ||
          this.lastVersion[Version.MINOR] > this.actualVersion[Version.MINOR]
        ) {
          this.needUpdate = true;
        }
        if (
          this.lastVersion[Version.MINOR] > this.actualVersion[Version.MINOR]
        ) {
          this.minorUpdate = false;
        }
        if (
          this.lastVersion[Version.MAJOR] !== this.actualVersion[Version.MAJOR]
        ) {
          this.skipSoftDeploy = true;
        }
      }
    } else {
      this.skipSoftDeploy = true;
    }
    return 'SoftDeploy done';
  }

  fetchVersionMessages(version): Observable<any[]> {
    let url: string;
    let platform = 'desktop';

    if (this.platform.is('cordova')) {
      if (this.platform.is('ios')) {
        platform = 'ios';
      }
      if (this.platform.is('android')) {
        platform = 'android';
      }
    }

    url = `${environment.cdrBase}/v${environment.apiVersion}/versions/info?version=${version}&platform=${platform}`;

    return this.http.get(url).pipe(
      switchMap((data: any) => {
        if (!!data && !!data.data) {
          const dataMessages = data.data;
          for (const dataMessage of dataMessages) {
            if (
              !!dataMessage &&
              !!dataMessage.attributes &&
              !!dataMessage.attributes.action &&
              !!dataMessage.attributes.message
            ) {
              const message = {
                message: dataMessage.attributes.message,
                openStore:
                  dataMessage.attributes.action ===
                  'SplashMessageRedirectingToStore'
                    ? true
                    : false,
              };
              this.versionMessages.push(message);
            }
          }
        }
        return of([]);
      }),
      catchError(err => {
        this.sentryService.sendToSentry(
          `SoftDeploy: Fail to fetch version messages ${JSON.stringify(err)}`
        );
        return of(null);
      })
    );
  }

  extractVersion(vers: string): number[] {
    let array = null;
    if (!!vers) {
      array = vers.split('.').map(elt => +elt);
    }
    return array;
  }

  fetchVersion(): Promise<any> {
    const url = `${environment.cdrBase}/v${environment.apiVersion}/versions/last_version`;

    return this.http
      .get(url)
      .pipe(
        switchMap((res: any) => {
          const version =
            !!res && !!res.data ? res.data.attributes.number : null;
          return of(version);
        }),
        catchError(err => {
          this.sentryService.sendToSentry(
            `SoftDeploy: Fail to fetch version ${JSON.stringify(err)}`
          );
          return of(null);
        })
      )
      .toPromise();
  }

  async checkUpdate() {
    if (this.networkService?.isOffline()) {
      return -1;
    }

    if (this.needUpdate) {
      const conf = await this.deployApi.getConfiguration();
      const update = await this.deployApi.checkForUpdate();
      if (update.available) {
        this.update = true;
        const checkDownload = await this.deployApi.downloadUpdate();
        if (!checkDownload) {
          this.sentryService.sendToSentry(
            `SoftDeploy: Fail to download new version ${JSON.stringify(checkDownload)}`
          );
          return 2;
        }
        const checkExtract = await this.deployApi.extractUpdate();
        if (!checkExtract) {
          this.sentryService.sendToSentry(
            `SoftDeploy: Fail to extract new version ${JSON.stringify(checkExtract)}`
          );
          return 2;
        }
        this.updateDone = true;
        this.updateFinished.next(true);
      }
      return conf;
    }
    return 1;
  }

  async updateApp() {
    this.deployApi
      .sync({}, progress => {
        if (this.networkService?.isOffline()) {
          this.syncObs.next(-1);
          return;
        }
        this.syncObs.next(progress);
      })
      .catch(err => {
        this.sentryService.sendToSentry(
          `SoftDeploy: Fail to sync new version ${JSON.stringify(err)}`
        );
        if (this.networkService?.isOffline()) {
          this.syncObs.next(-1);
        }
      });
  }

  async reloadApp() {
    await this.deployApi.reloadApp();
  }
}
