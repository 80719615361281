import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { Subject } from 'rxjs';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

declare const Kameleoon: any;

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: `init3`, initializer: `init` })
@EasyDebugDecorator
export class KameleoonService {
  kamRedirectObs = new Subject<any>();
  kamRedirectObs$ = this.kamRedirectObs.asObservable();
  testABTunnelOnBoardingObs = new Subject<any>();
  testABTunnelOnBoardingObs$ = this.testABTunnelOnBoardingObs.asObservable();
  testABVideoPub = new Subject<any>();
  testABVideoPub$ = this.testABVideoPub.asObservable();

  constructor() {
  }

  async init() {
    console.log(`Launching Kameleoon init`);
    window.addEventListener('TestABTunnelOnBoarding', (event: any) => {
      // console.log('KameleoonService TestABTunnelOnBoarding event', event);
      if (!!event && !!event.detail) {
        this.testABTunnelOnBoardingObs.next(event.detail);
      }
    });
    window.addEventListener('TestABPubVideos', (event: any) => {
      console.log('KameleoonService TestABPubVideos event', event);
      if (!!event && !!event.detail && !!event.detail.withPub && event.detail.withPub) {
        this.testABVideoPub.next(event.detail);
      }
    });
    window.addEventListener('KamRedirect', (event: any) => {
      if (!!event && !!event.detail) {
        this.kamRedirectObs.next(event.detail);
      }
    });
    // console.log(`Kameleoon init done`);
    return 'Kameleoon done';
  }

  isNewTunnelVariationActive(): boolean {
    const experimentId = 291729;
    const variationId = 1073822;
    const experiment = Kameleoon.API.Experiments.getActive().find((e: any) => e.id === experimentId);

    if (!experiment?.active) {
      return false;
    }

    const variation = experiment.associatedVariation;

    return !!variation && variation?.id === variationId;
  }
}
