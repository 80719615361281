<div class="tunnel-error-container">
  <div class="content">
    <div class="image">
      <img src="assets/svg/tunnel-error-illu.svg" alt="EnVoitureSimone" />
    </div>
    <h4>Oups !</h4>
    <p>Une erreur est survenue lors du chargement de la page.</p>
    <p>Réessaie ultérieurement.</p>
    <button class="btn full-width margin-top-15" (click)="openLink('code')">
      Retour
    </button>
  </div>
</div>
