import {
  Component,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'gif-comp',
  templateUrl: 'gif.component.html',
  styleUrls: ['gif.component.scss'],
})
export class GifComponent implements OnChanges, OnDestroy {
  @Input() compOptions: any;
  @Output() gifEvent = new EventEmitter<any>();
  @Output() failEvent = new EventEmitter<any>();
  @ViewChild('gifPlayer') gifPlayer: any;

  imgOneOpts: any;
  imgTwoOpts: any;

  debug = false;
  playing = false;
  display = false;
  stop = false;
  reload = false;

  imagesLoaded = false;
  firstImageLoaded = false;
  secondImageLoaded = false;

  gifInterval: any = null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    if (!!this.compOptions) {
      this.compOptions = compOptions.currentValue;
      this.setSrc();
    }
  }

  ngOnDestroy() {
    if (!!this.gifInterval) {
      this.stopGif();
    }
  }

  setSrc() {
    if (
      !!this.compOptions &&
      !!this.compOptions.debug &&
      this.compOptions.debug
    ) {
      this.debug = true;
    }
    if (
      !!this.compOptions &&
      this.compOptions.stop !== null &&
      this.compOptions.stop !== undefined
    ) {
      this.stop = this.compOptions.stop;
    }
    if (
      !!this.compOptions &&
      !!this.compOptions.src &&
      this.compOptions.src.length === 2
    ) {
      this.imgOneOpts = {
        src: this.compOptions.src[0],
        debug: this.debug,
      };
      this.imgTwoOpts = {
        src: this.compOptions.src[1],
        debug: this.debug,
      };
    }
    if (!this.playing) {
      this.playGif();
    }
    if (
      !!this.compOptions &&
      this.compOptions.stop !== null &&
      this.compOptions.stop !== undefined
    ) {
      this.stop = this.compOptions.stop;
      if (this.stop) {
        this.stopGif();
      }
    }
  }

  playGif() {
    this.playing = true;
    this.gifInterval = setInterval(() => {
      this.display = !this.display;
    }, 1000);
  }

  stopGif() {
    clearInterval(this.gifInterval);
  }

  handleFirstImageEvent(event) {
    if (!!event && !!event.loaded) {
      this.firstImageLoaded = true;
      if (this.secondImageLoaded) {
        this.imagesLoaded = true;
        this.gifEvent.emit({ loaded: true });
      }
    }
  }

  handleSecondImageEvent(event) {
    if (!!event && !!event.loaded) {
      this.secondImageLoaded = true;
      if (this.firstImageLoaded) {
        this.imagesLoaded = true;
        this.gifEvent.emit({ loaded: true });
      }
    }
  }

  handleFailEvent(event) {
    if (!!event && !!event.status && event.status === 'failed') {
      // console.log('FAIL MEDIA', event);
      this.failEvent.emit({ type: 'gif', status: 'failed' });
    }
  }
}
