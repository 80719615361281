<ion-slides
  class="presentation-slider animated fadeIn"
  (ionSlideDidChange)="slideChanged()"
  #slides>
  <!-- <div *ngIf="!!tunnelTestAB && tunnelTestAB === 'A' && !isDesktop" class="evs-logo">
    <img src="assets/img/logo-inline.svg" alt="En Voiture Simone">
  </div> -->

  <div
    class="fx-lottie default-lottie"
    #fxLottie
    [ngClass]="{'display-none': !showMotion, 'animated fadeIn': showMotion}">
    <fireworks-comp
      *ngIf="!!lottieAnim && !!lottieAnim.anim"
      [compOptions]="{addY: -80, startAt: lottieAnim.delay}"></fireworks-comp>
    <div class="motion-text">
      <lottie-player
        id="lottie-player"
        *ngIf="!!lottieAnim && lottieAnim.anim === 'heureux-kass'"
        src="../../assets/lottie/heureux-kass.json"
        background="transparent"
        autoplay></lottie-player>
      <div class="padding-h-20">
        <!-- <div class="background-permis">
          <img src="assets/svg/asset-permis.svg" alt="permis">
        </div> -->
        <div class="presentation-text">
          <h3 *ngIf="isDesktop">
            Décrocher ton permis n’a jamais été aussi simple
          </h3>
          <h4 *ngIf="!isDesktop">
            Décrocher ton permis n’a jamais été aussi simple
          </h4>
          <p [ngClass]="{'callout': isDesktop}">
            Crée ton compte et commence à t'entraîner dès maintenant.
          </p>
        </div>
      </div>
    </div>
  </div>

  <ion-slide class="change-flex"></ion-slide>
</ion-slides>

<span
  *ngIf="sliderIndex == 0"
  class="ignorer animated fadeIn"
  (click)="closeSlider()"
  >Ignorer</span
>

<div class="btn-presentation animated fadeIn">
  <button
    *ngIf="!tunnelTestAB && sliderIndex === 0"
    class="btn light margin-bottom-10"
    (click)="openAuth()">
    Me connecter
  </button>
  <button
    *ngIf="!tunnelTestAB && sliderIndex === 0"
    class="btn outline margin-bottom-10"
    (click)="openCreateAccount()">
    Créer mon compte gratuit
  </button>
  <button
    *ngIf="!!tunnelTestAB"
    class="btn margin-bottom-10"
    (click)="openCreateAccount()">
    Commencer
  </button>
  <div *ngIf="!!tunnelTestAB" class="margin-bottom-10 text-center padding-v-8">
    <span
      >Déjà un compte ?
      <a class="link" (click)="openAuth()">Se connecter</a></span
    >
  </div>
</div>
