import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';

import { Lesson } from '../models/lesson';
import { Student } from '../models/student';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import * as moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class LessonsService {
  private _lessons: Array<Lesson> = [];
  private _confirmedLessons: Array<Lesson> = [];
  private _unratedLessons: Array<Lesson> = [];
  private _newUnratedLessons: Array<Lesson> = [];
  private _nextLessons: Array<Lesson> = [];

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private networkService: NetworkStatusService
  ) {}

  async onInit() {
    // console.log('Lessons init');
    return 'Lessons done';
  }

  postUserNotation(payload: any): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of(null);
    }

    if (!!payload) {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/ratings`;

      return this.http.post(url, { lesson: payload }).pipe(
        switchMap(async (data: any) => {
          return data;
        }),
        catchError(err => {
          return of(err);
        })
      );
    } else {
      return of(null);
    }
  }

  fetchLessonsByUserId(accountId: string): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId && accountId !== 'GUEST') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(this.storageKey(accountId, 'lessons'), data);
          this._lessons = this.buildLessonsFromRawData(data);
          return of(this._lessons);
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Lessons Service failed',
            errorOriginal: err,
          };
        })
      );
    } else {
      return of([]);
    }
  }

  fetchConfirmedLessonsByUserId(accountId: string): Observable<any> {
    // console.log('student2', accountId);
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId && accountId !== 'GUEST') {
      // confirmed = not cancelled
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons/?state=confirmed`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(
            this.storageKey(accountId, 'confirmedLessons'),
            data
          );
          this._confirmedLessons = this.buildLessonsFromRawData(data);
          return of(this._confirmedLessons);
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Lessons Service failed',
            errorOriginal: err,
          };
        })
      );
    } else {
      return of([]);
    }
  }

  sendLessonDispoAlert(
    locationDetails: any,
    gearboxType: boolean
  ): Promise<any> {
    const dataToSend = {
      location_id: locationDetails.id,
      automatic_gearbox: gearboxType,
    };
    const url = `${environment.token_auth_config.apiBase}/v3/period_alert_requests`;
    return lastValueFrom(
      this.http.post(url, dataToSend).pipe(
        switchMap(async (data: any) => {
          return data;
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Send Lessons Dispo Alert Service failed',
            errorOriginal: !!err?.error?.error ? err.error.error : err,
          };
        })
      )
    );
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  fetchNewUnratedLessonsByUserId(accountId: string): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId && accountId !== 'GUEST') {
      // DATE TO CHANGE
      const timeElapsed = this.dateToFormatFr().valueOf();
      // DATE TO CHANGE
      const nowLe = this.dateToFormatFr(timeElapsed);
      // DATE TO CHANGE
      const now = this.dateToFormatFr(timeElapsed);
      now.month(now.month() - 3);
      const max3MonthFromNow = this.dateToFormatFr(now);
      // le = lower or equal
      // ge = greater or equal
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons/?rated=false&state=confirmed&ends_at_ge=${max3MonthFromNow.toISOString()}&ends_at_le=${nowLe.toISOString()}`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(
            this.storageKey(accountId, 'newUnratedLessons'),
            data
          );
          this._newUnratedLessons = this.buildLessonsFromRawData(data);
          return of(this._newUnratedLessons);
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Lessons Service failed',
            errorOriginal: err,
          };
        })
      );
    } else {
      return of([]);
    }
  }

  fetchUnratedLessonsByUserId(accountId: string): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId && accountId !== 'GUEST') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons/?rated=false&state=confirmed`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(
            this.storageKey(accountId, 'unratedLessons'),
            data
          );
          this._unratedLessons = this.buildLessonsFromRawData(data);
          return of(this._unratedLessons);
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Lessons Service failed',
            errorOriginal: err,
          };
        })
      );
    } else {
      return of([]);
    }
  }

  fetchNextLessonsByUserId(
    startAtGe,
    endsAtge,
    accountId: string
  ): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of(this._nextLessons);
    }

    if (!!accountId && accountId !== 'GUEST') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons/?state=confirmed`;
      if (startAtGe && startAtGe !== '') {
        url += '&starts_at_ge=' + startAtGe;
      }
      if (endsAtge && endsAtge !== '') {
        url += '&ends_at_ge=' + endsAtge;
      }

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(
            this.storageKey(accountId, 'nextLessons'),
            data
          );
          this._nextLessons = this.buildLessonsFromRawData(data);
          return of(this._nextLessons);
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Lessons Service failed',
            errorOriginal: err,
          };
        })
      );
    } else {
      return of([]);
    }
  }

  postAcceptLesson(accountId: string, lessonId): Promise<any> {
    let url: string;

    if (!!accountId && accountId !== '' && !!lessonId && lessonId !== '') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons/${lessonId}/confirm`;

      return this.http
        .post(url, null)
        .pipe(
          switchMap(data => {
            return of(data);
          }),
          catchError(async err => {
            return {
              errorCode: 'E301',
              errorMessage: 'postAcceptLesson Service failed',
              errorOriginal: err,
            };
          })
        )
        .toPromise();
    } else {
      return Promise.reject({
        errorCode: 'E301',
        errorMessage: 'postAcceptLesson Service failed',
        errorOriginal: 'AccountId or lessonId missing !',
      });
    }
  }

  async postDeclineLesson(accountId: string, lessonId: string): Promise<any> {
    let url: string;

    if (!!accountId && accountId !== '' && !!lessonId && lessonId !== '') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons/${lessonId}/decline`;

      return this.http
        .post(url, null)
        .pipe(
          switchMap(async (data: any) => {
            return data;
          }),
          catchError(err => {
            return of(err);
          })
        )
        .toPromise();
    } else {
      return of({
        errorMsg: 'postDeclineLesson : AccountId or lessonId missing!',
      }).toPromise();
    }
  }

  async postCancelLesson(accountId: string, lessonId: string): Promise<any> {
    let url: string;

    if (!!accountId && accountId !== '' && !!lessonId && lessonId !== '') {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/lessons/${lessonId}/cancel`;

      return this.http
        .post(url, null)
        .pipe(
          switchMap(async (data: any) => {
            return data;
          }),
          catchError(err => {
            return of(err);
          })
        )
        .toPromise();
    } else {
      return of({
        errorMsg: 'postDeclineLesson : AccountId or lessonId missing!',
      }).toPromise();
    }
  }

  async loadStudentLessons(student: Student) {
    // console.log('student1', student);
    if (student.isGuest()) {
      this._lessons = [];
      this._confirmedLessons = [];
      this._unratedLessons = [];
      this._newUnratedLessons = [];
    } else {
      // TODO: Handle offline with localstorage
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadLessonsFromStorage(student);
        this.loadConfirmedLessonsFromStorage(student);
        this.loadUnratedLessonsFromStorage(student);
        this.loadNewUnratedLessonsFromStorage(student);
        this.loadNextLessonsFromStorage(student);
      } else {
        this.fetchLessonsByUserId(student.remoteId).subscribe(res => {
          this._lessons = res;
        });
        this.fetchConfirmedLessonsByUserId(student.remoteId).subscribe(res => {
          this._confirmedLessons = res;
        });
        this.fetchUnratedLessonsByUserId(student.remoteId).subscribe(res => {
          this._unratedLessons = res;
        });
        this.fetchNewUnratedLessonsByUserId(student.remoteId).subscribe(res => {
          this._newUnratedLessons = res;
        });
      }
    }
  }

  reportTeacherNotFound(data: any, lessonId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v3/lessons/${lessonId}/cancel_absent_teacher`;
    let dataToSend = {
      assets: data.reportTeacherNotFoundFiles,
    };

    return this.http.post(url, dataToSend).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get lessons(): any {
    return this._lessons;
  }
  get confirmedLessons(): any {
    return this._confirmedLessons;
  }
  get unratedLessons(): any {
    return this._unratedLessons;
  }
  get newUnratedLessons(): any {
    return this._newUnratedLessons;
  }
  get nextLessons(): any {
    return this._nextLessons;
  }

  private;

  async loadLessonsFromStorage(student: Student) {
    this.storageService
      .get(this.storageKey(student.remoteId, 'lessons'))
      .then(res => {
        this._lessons = !!res ? this.buildLessonsFromRawData(res) : [];
      });
  }
  async loadConfirmedLessonsFromStorage(student: Student) {
    this.storageService
      .get(this.storageKey(student.remoteId, 'confirmedLessons'))
      .then(res => {
        this._lessons = !!res ? this.buildLessonsFromRawData(res) : [];
      });
  }
  async loadUnratedLessonsFromStorage(student: Student) {
    this.storageService
      .get(this.storageKey(student.remoteId, 'unratedLessons'))
      .then(res => {
        this._lessons = !!res ? this.buildLessonsFromRawData(res) : [];
      });
  }
  async loadNewUnratedLessonsFromStorage(student: Student) {
    this.storageService
      .get(this.storageKey(student.remoteId, 'newUnratedLessons'))
      .then(res => {
        this._lessons = !!res ? this.buildLessonsFromRawData(res) : [];
      });
  }
  async loadNextLessonsFromStorage(student: Student) {
    this.storageService
      .get(this.storageKey(student.remoteId, 'nextLessons'))
      .then(res => {
        this._lessons = !!res ? this.buildLessonsFromRawData(res) : [];
      });
  }

  storageKey(accountId: String, key: String): string {
    return `${accountId}-${key}`;
  }

  /// Serialize data ///
  buildLessonsFromRawData(data: any): Array<Lesson> {
    const arr: Array<Lesson> = [];
    const textData = JSON.stringify(data);
    const parsedData = JSON.parse(textData);

    if (
      !!data &&
      typeof data !== 'undefined' &&
      Object.entries(parsedData).length > 0 &&
      !!parsedData.data
    ) {
      for (const elt of parsedData.data) {
        const lesson = new Lesson({
          id: elt.id,
          type: elt.type,
          created_at: elt.attributes.created_at,
          starts_at: elt.attributes.starts_at,
          ends_at: elt.attributes.ends_at,
          cancelled_at: elt.attributes.cancelled_at,
          declined_at: elt.attributes.declined_at,
          confirmed_at: elt.attributes.confirmed_at,
          status: elt.attributes.status,
          amount: elt.attributes.amount,
          credits: elt.attributes.credits,
          duration: elt.attributes.duration,
          driving_exam_id: elt.attributes.driving_exam_id,
          teacher: elt.attributes.teacher,
          departure_point: elt.attributes.departure_point,
          arrival_point: elt.attributes.arrival_point,
          automatic: elt.attributes.automatic,
          teacher_reported_absent: elt.attributes.teacher_reported_absent,
        });
        arr.push(lesson);
      }
    }
    return arr;
  }
}
