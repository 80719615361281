<!-- <div class="bloc-header" [ngClass]="{'not-mobile': !isMobile}">
  <div class="ion-content-container">
    <div *ngIf="isBackBtn && loading" class="wpi-100 border-radius-6 padding-6 margin-bottom-10 shimmer"></div>
    <div *ngIf="isBackBtn && !loading" class="back" (click)="openLink(backUrl)">
      <ion-icon name="chevron-back"></ion-icon> <span>{{backTitle}}</span>
    </div>
    <div class="title">
      <div *ngIf="loading" class="wpi-150 border-radius-8 padding-8 shimmer"></div>
      <span *ngIf="!loading">{{title}}</span>
    </div>
  </div>
</div> -->

<div
  class="bloc-header"
  [ngClass]="{'not-mobile': !isMobile, 'not-desktop': !isDesktop}">
  <div *ngIf="loading" class="back">
    <div class="wpi-100 border-radius-6 padding-6 light-shimmer"></div>
  </div>
  <div *ngIf="!loading" class="back" [ngClass]="{'unclickable': !url}">
    <div
      *ngIf="isBackBtn"
      class="header-btn small-clickable"
      (click)="openLink(url)">
      <!-- <ion-icon *ngIf="!withOutput" name="chevron-back"></ion-icon> -->
      <img
        *ngIf="!withOutputClose"
        src="assets/2022/icons/arrow-left-2-off.svg"
        alt="EnVoitureSimone" />
      <img
        *ngIf="withOutputClose"
        class="close-btn"
        src="assets/2022/icons/close-off.svg"
        alt="EnVoitureSimone" />
    </div>
    <span
      class="fix-mute-btn"
      *ngIf="(!url || withOutputClose) && showMute"
      [ngClass]="{'center-title': !!serie}"
      >{{title}}</span
    >
    <span
      *ngIf="(!url || withOutputClose) && !showMute"
      [ngClass]="{'center-title': !!serie}"
      >{{title}}</span
    >
    <span
      *ngIf="url && !withOutputClose"
      class="small-clickable"
      [ngClass]="{'center-title': !!serie}"
      (click)="openLink(url)"
      >{{title}}</span
    >
    <div
      *ngIf="showMute"
      class="sound-btn small-clickable"
      (click)="muteMedia()">
      <img
        *ngIf="!muted"
        src="assets/2022/icons/volume-up-off.svg"
        alt="EnVoitureSimone" />
      <img
        *ngIf="muted"
        src="assets/2022/icons/volume-off-off.svg"
        alt="EnVoitureSimone" />
    </div>
  </div>
  <loader-bar-comp
    *ngIf="showLoaderBar && !loading"
    [compOptions]="{'percent': loaderBarPercent, 'hasBorder': loaderBarHasBorder, 'whiteBg': loaderBarWhiteBg, animated: loaderBarAnimated}"></loader-bar-comp>
</div>
