import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.env === 'production') {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryAddr,
  environment: environment.env,
  // integrations: [
  //   // Registers and configures the Tracing integration,
  //   // which automatically instruments your application to monitor its
  //   // performance, including custom Angular routing instrumentation
  //   new Sentry.BrowserTracing({
  //     routingInstrumentation: Sentry.routingInstrumentation,
  //   }),
  //   // Registers the Replay integration,
  //   // which automatically captures Session Replays
  //   new Sentry.Replay(),
  // ],

  // // Set tracesSampleRate to 1.0 to capture 100%
  // // of transactions for performance monitoring.
  // // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // // Capture Replay for 10% of all sessions,
  // // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = ref;

    // Otherise, log the boot error
  })
  .catch(err => {
    throw new Error('main.ts platformBrowserDynamic: ' + JSON.stringify(err));
  });
