import { Injectable, OnInit } from '@angular/core';
import { Initialable } from '../../app/services/app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Injectable({
  providedIn: 'root',
})
@Initialable({ step: 'init1', initializer: 'onInit' })
export class EvsApiHeaderService {
  private _modalShown = false;
  private _maintenanceShown = false;
  private _timeoutShown = false;

  constructor() {}

  async onInit() {
    return 'EvsApiHeader done';
  }

  // GETTER
  get modalShown(): boolean {
    return this._modalShown;
  }
  get maintenanceShown(): boolean {
    return this._maintenanceShown;
  }
  get timeoutShown(): boolean {
    return this._timeoutShown;
  }
  // SETTER
  set modalShown(value: boolean) {
    this._modalShown = value;
  }
  set maintenanceShown(value: boolean) {
    this._maintenanceShown = value;
  }
  set timeoutShown(value: boolean) {
    this._timeoutShown = value;
  }
}
