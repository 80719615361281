import {
  Component,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  AfterViewInit,
  EventEmitter,
} from '@angular/core';

import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'modal-comp',
  templateUrl: 'modal.html',
  styleUrls: ['modal.scss'],
})
export class ModalComponent implements OnChanges, AfterViewInit {
  @Input() compOptions: any;
  @ViewChild('modal', { static: true }) modal: any;
  @ViewChild('modalBg') modalBg: any;
  @ViewChild('modalContainer') modalContainer: any;
  @ViewChild('modalHeader') modalHeader: any;
  @ViewChild('modalContent') modalContent: any;
  @ViewChild('modalFooter') modalFooter: any;
  @Output() canClose = new EventEmitter<boolean>();

  isDebug = false;

  id = '';
  title = '';
  isFooter = false;
  footerText = 'Fermer';
  modalSize = 'evs_modal';

  ioDuration = 300;
  myInterval: any;
  contentSize = 0;
  closable = true;
  position = '';
  dynamicHeight = false;
  acceptTyping = true;

  constructor(
    private renderer: Renderer2,
    private utils: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngAfterViewInit() {
    this.setClasses();
  }

  setClasses() {
    this.renderer.addClass(this.modalBg.nativeElement, 'active');
    this.renderer.addClass(this.modalContainer.nativeElement, 'active');
    if (this.position !== '') {
      this.renderer.addClass(this.modalContainer.nativeElement, this.position);
    }
  }

  closeModal() {
    this.renderer.removeClass(this.modalBg.nativeElement, 'active');
    this.renderer.addClass(this.modalBg.nativeElement, 'close');
    this.renderer.removeClass(this.modalContainer.nativeElement, 'active');
    this.renderer.addClass(this.modalContainer.nativeElement, 'close');
    setTimeout(() => {
      this.canClose.emit(true);
    }, this.ioDuration);
  }

  setGlobals() {
    this.isDebug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.isDebug;
    this.id =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.id)
        ? this.compOptions.id
        : this.id;
    this.title =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.title)
        ? this.compOptions.title
        : this.title;
    this.isFooter =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.footer)
        ? this.compOptions.footer
        : this.isFooter;
    this.footerText =
      this.compOptions && typeof this.compOptions.footertext !== 'undefined'
        ? this.compOptions.footertext
        : this.footerText;
    this.modalSize =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.size)
        ? 'evs_modal ' + this.compOptions.size
        : this.modalSize;
    this.closable =
      this.compOptions && typeof this.compOptions.closable !== 'undefined'
        ? this.compOptions.closable
        : this.closable;
    this.position =
      this.compOptions && typeof this.compOptions.position !== 'undefined'
        ? this.compOptions.position
        : this.position;
    this.dynamicHeight =
      this.compOptions && typeof this.compOptions.dynamicHeight !== 'undefined'
        ? this.compOptions.dynamicHeight
        : this.dynamicHeight;
    if (this.dynamicHeight) {
      this.modalSize += ' dynamic-height';
    }
    this.acceptTyping =
      this.compOptions && typeof this.compOptions.typing !== 'undefined'
        ? this.compOptions.typing
        : this.acceptTyping;

    if (this.id !== '') {
      this.modal.nativeElement.id = this.id;
    }
  }

  // RESIZE EVENT
  @HostListener('window:resize', ['$event']) onResize(event) {
    setTimeout(() => {
      this.setClasses();
    }, 0);
  }

  @HostListener('document:keydown', ['$event']) onEscapeDown(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape') {
      if (this.acceptTyping && this.closable) {
        this.closeModal();
      } else {
        event.preventDefault();
      }
    }
  }
}
