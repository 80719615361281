import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Initialable } from '../../app/services/app-init.service';
import { BehaviorSubject, of } from 'rxjs';

import { EasyDebugLogger } from '../easy-debug-logger/easy-debug-logger';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { StudentService } from './student.service';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'init' })
@EasyDebugDecorator
export class FeatureFlagsService {
  flags: any = {};

  constructor(
    private http: HttpClient,
    private studentService: StudentService
  ) {}

  async init() {
    // console.log('FeatureFlag init');
    this.flags = await this.fetchFeatureFlags();
    // console.log('feature flags => ', this.flags);
    // console.log('FeatureFlag done');
    return 'FeatureFlag done';
  }

  fetchFeatureFlags(): Promise<any> {
    if (
      !!this.studentService.student &&
      !!this.studentService.student.remoteId &&
      this.studentService.student.remoteId !== 'GUEST'
    ) {
      const url = `${environment.cdrBase}/v${environment.apiVersion}/account/${this.studentService.student.remoteId}/features`;

      return this.http
        .get(url)
        .pipe(
          switchMap(response => {
            return of(response);
          }),
          catchError(err => {
            return of({});
          })
        )
        .toPromise();
    }
    return new Promise(resolve => {
      resolve({});
    });
  }
}
