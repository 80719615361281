import { StorageService } from './../../services/storage.service';
import { ConfigService } from './../../services/config.service';
import { of, Subscription, Observable } from 'rxjs';
import { SoftDeployService } from './../../services/softDeploy.service';
import { Component, Input, HostListener, OnDestroy } from '@angular/core';

import { ModalController, NavParams, Platform } from '@ionic/angular';

import { NavigationService } from '../../services/navigation.service';
import { EasyDebugService } from '../../services/easy-debug.service';
import { AppInitService } from '../../services/app-init.service';
import { StudentService } from '../../services/student.service';
import { OffersService } from '../../services/offers.service';
import { StatsService } from '../../services/stats.service';
import { ConfigUserService } from '../../services/config-user.service';
import { EvsApiHeaderService } from '../../../commons/services/EvsApiHeader.service';

import { Student } from '../../models/student';

import { ModalIframeComponent } from '@app/modals';

import { UtilsService, ToasterService } from '@commons/services';
import { ActivatedRoute } from '@angular/router';
import { StudentPurshasesDetails } from '@app/models';

import { EasyDebugDecorator } from '../../decorators/easy-debug.decorator';
import { WonderPushService } from '@app/app.services';

import * as moment from 'moment-timezone';

@EasyDebugDecorator
@Component({
  selector: 'modal-marketing-component',
  templateUrl: 'modal-marketing.component.html',
  styleUrls: ['modal-marketing.component.scss'],
})
export class ModalMarketingComponent implements OnDestroy {
  // Data passed in by componentProps
  @Input() type: string;

  pageTitle = 'Modal marketing';
  activeLink = 'dashboard';

  student: Student;
  configUser: any;
  userLifeCycle: any;

  imgDrivingPath = '';
  imgPremiumPath = '';
  imgRegisteredPath = '';
  imgHourOnlyPath = '';

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  premiumLink = 'tarifs';

  modalType = '';

  activeOffer = 'code';

  callCenterPhone = '0800 0200 0200';
  callCenterPhoneFormatted = '080002000200';

  studentPurshases: StudentPurshasesDetails;
  payments: any = [];
  gifts = [];
  coupons = [];
  paymentsGiftsCoupons: any = [];
  offers: any = [];
  fetchOffersFailed = false;
  isPackPermis = false;
  isPackCode = false;
  isHourOnly = false;
  hasHours = false;
  paymentsDone = false;

  hasPromos = [];
  isPromo = false;

  codeSimonePrice = 0;
  packPermisPrice = 0;
  hourPrice = 0;

  isCordova = false;
  isPlatformDesktop = false;

  isDesktop = window.innerWidth > 767 ? true : false;

  randomTxtSerieRegistered = 1;

  banner = null;

  dlProgress = 0;

  sdSub: Subscription = null;

  counter = this.navParams.get('nbGoodAnswers');
  logId = '';

  checkDiskToasterShown = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = window.innerWidth > 767 ? true : false;
  }

  constructor(
    public evsApiHeaderService: EvsApiHeaderService,
    public easyDebugService: EasyDebugService,
    public utils: UtilsService,
    public toasterService: ToasterService,
    public navParams: NavParams,
    private modalController: ModalController,
    public appInitService: AppInitService,
    public studentService: StudentService,
    public configUserService: ConfigUserService,
    public navigationService: NavigationService,
    public offersService: OffersService,
    private statsService: StatsService,
    private router: ActivatedRoute,
    public platform: Platform,
    private storageService: StorageService,
    public softDeployService: SoftDeployService,
    public wonderPushService: WonderPushService,
    public configService: ConfigService
  ) {
    this.isCordova = this.platform.is('cordova');
    this.isPlatformDesktop = this.platform.is('desktop');
    this.modalType = this.navParams.get('type');
    const bannerInfo = this.navParams.get('bannerInfo');
    if (!!bannerInfo) {
      this.banner = {
        item_id: bannerInfo.item_id,
        item_promo_type: bannerInfo.item_promo_type,
        item_location_id: bannerInfo.item_location_id,
        item_location_format: bannerInfo.item_location_format,
        item_promo_content: bannerInfo.item_promo_content,
      };
    }

    // START OF DEBUG - START OF DEBUG
    // this.modalType = 'marketingZendesk';
    // END OF DEBUG - END OF DEBUG

    if (this.modalType === 'modalPermisSerie') {
      this.randomTxtSerieRegistered = this.utils.randomMinMax(1, 4);
    }
    this.imgDrivingPath = 'assets/img/ride-over-the-moon.svg';
    this.imgPremiumPath = 'assets/img/to-the-stars.svg';
    this.imgRegisteredPath = 'assets/img/gift.svg';
    this.imgHourOnlyPath = 'assets/img/ride-over-the-moon.svg';

    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.appInitService.onStepChange().subscribe(state => {
      if (state?.initDone) {
        this.initPage();
      }
    });
  }

  async initPage() {
    this.statsService.send({
      name: 'modal:view',
      payload: { page: `/modal/${this.modalType}` },
    });
    if (!!this.banner) {
      this.statsService.send({
        name: 'item:viewed',
        payload: {
          item: this.banner,
        },
      });
    }
    this.student = this.studentService.student;
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.getPayments();
    this.fetchOffers();
    if (this.modalType === 'modalConsentPush') {
      // set storage
      if (!this.configUser) {
        this.configUser = await this.configUserService.getUserConfig(
          this.student
        );
      }
      this.configUser.pushNotifConsentActive = true;
      this.configUser = await this.configUserService.setUserConfig(
        this.student,
        this.configUser
      );
    }
    const callCenterElement = document.getElementById('call-center-phone');
    this.callCenterPhone =
      !!callCenterElement && !!callCenterElement.innerHTML
        ? callCenterElement.innerHTML
        : '';
    this.callCenterPhoneFormatted =
      !!callCenterElement && !!callCenterElement.innerHTML
        ? callCenterElement.innerHTML.replaceAll(' ', '')
        : '';
  }

  getPayments() {
    this.studentPurshases = this.studentService.getStudentPurshases();

    const resGetOffers = this.studentPurshases.offers;
    if (
      !!resGetOffers &&
      !!resGetOffers.errorCode &&
      resGetOffers.errorCode === 'E301'
    ) {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
    this.gifts = this.studentPurshases.gifts;
    this.studentPurshases.linkOfferTopayment();
    this.studentPurshases.removeCouponsIfPresentInPayments();
    this.payments = this.studentPurshases.payments;
    this.coupons = this.studentPurshases.coupons;

    this.hasHours = this.studentPurshases.hasHours();
    this.isHourOnly = this.studentPurshases.isHourOnly();
    this.isPackCode = this.studentPurshases.isPackCode();
    this.isPackPermis = this.studentPurshases.isPackPermis();

    const tmp = this.gifts.concat(this.coupons);
    this.paymentsGiftsCoupons = tmp.concat(this.payments);

    this.paymentsDone = true;
  }

  async reloadApp() {
    await this.closeModalPack();
    await this.softDeployService.reloadApp();
  }

  updateApp() {
    let interval = null;
    this.softDeployService.updateApp();
    this.modalType = 'modalMaj2';

    interval = setInterval(() => {
      if (this.dlProgress < 96) {
        this.dlProgress += this.utils.randomMinMax(1, 5);
      } else {
        if (!!interval) {
          clearInterval(interval);
          interval = null;
        }
      }
    }, 250);

    this.sdSub = this.softDeployService.syncObs$.subscribe(async progress => {
      if (this.dlProgress <= progress) {
        this.dlProgress = progress;
      }

      if (progress === -1) {
        this.closeModalPack();
        if (!!this.sdSub) {
          this.sdSub.unsubscribe();
        }
      }

      const checkSpace = await this.storageService.checkEnougthSpace();
      if (!checkSpace) {
        this.modalType = 'modalStorage4';
        if (!!this.sdSub) {
          this.sdSub.unsubscribe();
        }
      }

      if (progress >= 100) {
        if (!!this.sdSub) {
          if (!!interval) {
            clearInterval(interval);
            interval = null;
          }
          this.dlProgress = 100;
          const timeOut = setTimeout(() => {
            this.modalType = 'modalMaj3';
            this.dlProgress = 0;
            clearTimeout(timeOut);
          }, 1000);

          const timeOut2 = setTimeout(() => {
            this.softDeployService.reloadApp();
            this.closeModalPack();
            clearTimeout(timeOut2);
          }, 3000);
          this.sdSub.unsubscribe();
        }
      }
    });
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  fetchOffers() {
    this.studentPurshases = this.studentService.getStudentPurshases();
    const resGetOffers = this.studentPurshases.offers;
    if (
      !!resGetOffers &&
      !!resGetOffers.errorCode &&
      resGetOffers.errorCode === 'E301'
    ) {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
    if (!!this.offers && this.offers.length > 0 && !this.fetchOffersFailed) {
      this.hasPromos = [];
      for (const offer of this.offers) {
        if (offer.offer_type === 'hour') {
          this.hourPrice = offer.amount;
        }
        if (offer.offer_type === 'code') {
          this.codeSimonePrice = offer.amount;
        }
        if (offer.offer_type === 'big_pack') {
          this.packPermisPrice = offer.amount;
        }
        if (offer.offer_campaign.length > 0) {
          // DATE TO CHANGE
          const sd = this.dateToFormatFr(offer.offer_campaign[0].starts_at);
          // DATE TO CHANGE
          const ed = this.dateToFormatFr(offer.offer_campaign[0].ends_at);
          if (!isNaN(sd.valueOf()) && !isNaN(ed.valueOf())) {
            this.hasPromos.push({
              name: offer.offer_campaign[0].name,
              starts_at: sd,
              ends_at: ed,
              amount: offer.amount,
              max_quantity: offer.max_quantity,
              discount: offer.discount,
            });
          } else {
            console.error('Modal Marketing Promo Dates invalid !');
          }
        }
      }
      if (this.hasPromos.length > 0) {
        // DATE TO CHANGE
        const today = this.dateToFormatFr();
        for (const promo of this.hasPromos) {
          // anti-couillons => multi promo impossible pour le moment
          if (promo.name === 'Promo Pack Permis') {
            // promo.starts_at = new Date('2019/10/10 00:00:00') // TEMP FOR TEST - use / isntead of - for safari compatibility only for manual dates not ISO Dates
            // console.log(today, promo.starts_at, promo.ends_at, today >= promo.starts_at && today <= promo.ends_at);

            // check if today is between promo dates
            if (
              today.valueOf() >= promo.starts_at.valueOf() &&
              today.valueOf() <= promo.ends_at.valueOf()
            ) {
              this.isPromo = true;
            }
          }
        }
      }
    }
  }

  async goToAccueil() {
    this.evsApiHeaderService.modalShown = false;
    await this.closeModalPack();
    this.openLink('dashboard');
  }

  async activatePushNotifs() {
    const login = await this.storageService.get('login');
    const signup = await this.storageService.get('signup');
    let itemLocationId = 'guest_opt_in';
    if (!!login && login) {
      itemLocationId = 'sign_in_opt_in';
      await this.storageService.remove('login');
    } else if (!!signup && signup) {
      itemLocationId = 'sign_up_opt_in';
      await this.storageService.remove('signup');
    } else if (
      !!this.student &&
      !!this.student.remoteId &&
      this.student.remoteId != 'GUEST'
    ) {
      itemLocationId = 'logged_opt_in';
    }
    this.statsService.send({
      name: 'item:clicked',
      payload: {
        item: {
          button_action: 'yes',
          item_id: 'optin_push_notifications',
          item_location_id: 'sign_up_opt_in',
          item_location_format: 'modale',
        },
      },
    });
    const notif = await this.wonderPushService
      .activateNotification()
      .then(res => {
        console.log('WONDERPUSH SERVICE ACTIVATE NOTIFICATION RES -> ', res);
        return res;
      })
      .catch(err => {
        console.log('WONDERPUSH SERVICE ACTIVATE NOTIFICATION ERR -> ', err);
        return err;
      });
    const isSubscribed = await this.wonderPushService
      .isSubscribedToNotifications()
      .then(res => {
        console.log('WONDERPUSH SERVICE IS SUB NOTIFICATION RES -> ', res);
        return res;
      })
      .catch(err => {
        console.log('WONDERPUSH SERVICE IS SUB NOTIFICATION ERR -> ', err);
        return err;
      });

    if (isSubscribed) {
      if (this.platform.is('ios')) {
        this.statsService.send({
          name: 'user:ios_consent',
          payload: {
            label: 'activate_notifications',
            category: 'sign_up',
            value: true,
          },
        });
      } else {
        this.statsService.send({
          name: 'user:android_consent',
          payload: {
            label: 'activate_notifications',
            category: 'sign_up',
            value: true,
          },
        });
      }
    } else {
      if (this.platform.is('ios')) {
        this.statsService.send({
          name: 'user:ios_consent',
          payload: {
            label: 'activate_notifications',
            category: 'sign_up',
            value: false,
          },
        });
      } else {
        this.statsService.send({
          name: 'user:android_consent',
          payload: {
            label: 'activate_notifications',
            category: 'sign_up',
            value: false,
          },
        });
      }
    }
    // console.log('notif => ', notif);
    // console.log('consent => ', consent);
    this.evsApiHeaderService.modalShown = false;
    await this.closeModalPack();
  }

  async pushNotifConsentNext() {
    this.evsApiHeaderService.modalShown = false;
    await this.closeModalPack();
  }

  async goToRegulariser() {
    const configUser = await this.configUserService.getUserConfig(this.student);
    let ppu = null;

    configUser.outstandingPayment = true;
    await this.configUserService.setUserConfig(this.student, configUser);
    for (const payment of this.payments) {
      if (
        !!payment.payment_plan_user &&
        !!payment.payment_plan_user.data &&
        !!payment.payment_plan_user.data.attributes &&
        !!payment.payment_plan_user.data.attributes.status
      ) {
        if (payment.payment_plan_user.data.attributes.status === 'failed') {
          if (!!payment.payment_plan_user_id) {
            ppu = payment.payment_plan_user_id;
          }
        }
      }
    }
    if (!!ppu) {
      this.openLink('regulariserPayment', { ppu: ppu });
    }
  }

  readConditions() {
    if (!!this.student && !!this.student.lastCgaacUrlDoc) {
      const domain = 'https://evs-profilepics.s3.amazonaws.com/';
      const urlWithoutDomain = this.student.lastCgaacUrlDoc.replace(domain, '');
      this.openLink('cgaac', { target: '_system', cgaacUrl: urlWithoutDomain });
    } else {
      console.error('Last CGAAC DOC URL not found!');
    }
  }

  postCgaac() {
    this.studentService.acceptCgaac(this.student.remoteId).subscribe(
      res => {
        this.studentService.updateUserCgaac(res).then(() => {
          this.launchToaster({
            text: 'Merci d’avoir accepté les nouvelles conditions générales d’aide à l’apprentissage de la conduite.',
            bgcolor: 'var(--color-info)',
            color: 'var(--color-font)',
          });
          this.closeModalPack();
        });
      },
      err => {
        this.closeModalPack();
        console.error('Accept CGAAC failed: ' + JSON.stringify(err));
      }
    );
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    }
    return await modal.present();
  }

  async removeModalConfigUser() {
    if (this.modalType === 'modalImpayes') {
      this.configUser = await this.configUserService.getUserConfig(
        this.student
      );
      if (
        !!this.configUser.outstandingPaymentModalShown &&
        this.configUser.outstandingPaymentModalShown
      ) {
        this.configUser.outstandingPaymentModalShown = false;
        await this.configUserService.setUserConfig(
          this.student,
          this.configUser
        );
        await this.showStickyImpayes();
      }
    } else if (this.modalType === 'modalConsentPush') {
      if (
        !!this.student &&
        !!this.student.remoteId &&
        this.student.remoteId !== 'GUEST'
      ) {
        // alert('user');
        let userConfig = await this.configUserService.getUserConfig(
          this.student
        );
        userConfig.pushNotifConsent = true;
        userConfig.pushNotifConsentActive = false;
        // alert(JSON.stringify(userConfig));
        userConfig = await this.configUserService.setUserConfig(
          this.student,
          userConfig
        );
        // alert(JSON.stringify(userConfig));
      } else {
        // alert('guest');
        let appConfig = await this.configService.getAppConfig();
        appConfig.pushNotifConsent = true;
        // alert(appConfig);
        appConfig = await this.configService.setAppConfig(appConfig);
        // alert(JSON.stringify(appConfig));
      }
      let appConfig = await this.configService.getAppConfig();
      appConfig.pushNotifConsent = true;
      appConfig = await this.configService.setAppConfig(appConfig);
    }
  }

  launchToaster(config) {
    this.toasterService.create(config);
  }

  async showStickyImpayes() {
    if (!this.configUser || this.configUser == null) {
      this.configUser = await this.configUserService.getUserConfig(
        this.student
      );
    }
    if (
      !!this.configUser &&
      !this.configUser.impayesStickyToasterShown &&
      !this.configUser.outstandingPaymentModalShown
    ) {
      this.configUser.impayesStickyToasterShown = true;
      this.configUser.outstandingPayment = true;
      await this.configUserService.setUserConfig(this.student, this.configUser);
      this.launchToaster({
        text: 'Une de tes échéances de paiement semble avoir échoué.',
        type: 'sticky',
        borderColor: 'danger',
        ctaText: 'PAYER',
        ctaLink: 'regulariserPayment',
      });
    }
  }

  async closeModalPack() {
    this.checkDiskToasterShown = false;
    if (!!this.modalType && this.modalType === 'modalConsentPush') {
      // set storage too

      const login = await this.storageService.get('login');
      const signup = await this.storageService.get('signup');
      let itemLocationId = 'guest_opt_in';
      if (!!login && login) {
        itemLocationId = 'sign_in_opt_in';
        await this.storageService.remove('login');
      } else if (!!signup && signup) {
        itemLocationId = 'sign_up_opt_in';
        await this.storageService.remove('signup');
      } else if (
        !!this.student &&
        !!this.student.remoteId &&
        this.student.remoteId != 'GUEST'
      ) {
        itemLocationId = 'logged_opt_in';
      }
      this.statsService.send({
        name: 'item:clicked',
        payload: {
          item: {
            button_action: 'later',
            item_id: 'optin_push_notifications',
            item_location_id: itemLocationId,
            item_location_format: 'modale',
          },
        },
      });
    }
    await this.removeModalConfigUser();
    this.statsService.send({
      name: 'modal:unview',
      payload: {
        referer: `/modal/${this.modalType}`,
        page: `/${this.router.firstChild.snapshot.routeConfig.path}`,
      },
    });
    if (!!this.modalController) {
      this.modalController.dismiss();
    }
  }

  async goToCheckDiskSpace1() {
    const checkSpace = await this.storageService.checkEnougthSpace();
    if (checkSpace) {
      await this.storageService.setStorageLock(false);
      this.openLink('splash', { forceRoot: true });
      return;
    }
    const storageLockedToastConf = {
      text: 'Ton espace disque est saturé, libère au moins 25 Mo pour débloquer tes fonctionnalités',
      bgcolor: 'var(--color-danger)',
      duration: '5000',
    };
    if (!this.checkDiskToasterShown) {
      this.toasterService.create(storageLockedToastConf);
    }
    this.checkDiskToasterShown = true;
    return;
  }

  async goToCheckDiskSpace2() {
    this.openLink('code', { forceRoot: true });
  }

  async launchDebugMode() {
    await this.closeModalPack();
    this.easyDebugService.setRecordingMode('record');
  }

  async launchCleanStorage() {
    await this.storageService.clear();
    this.appInitService.init0();
    this.openLink('splash', { forceRoot: true });
  }

  async openLink(link, options?) {
    await this.closeModalPack();
    if (!!this.banner) {
      this.statsService.send({
        name: 'item:clicked',
        payload: {
          item: this.banner,
        },
      });
    }
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }

  async ngOnDestroy() {
    await this.removeModalConfigUser();
  }
}
