import { EasyDebugService } from './easy-debug.service';
import { Questions } from './../models/questions';
import { LocalQuestions } from '../Strategy/questionsStrategy/localQuestions';
import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { StorageService } from './storage.service';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Question } from '../models/question';
import { Student } from '../models/student';
import { RemoteQuestions } from '../Strategy/questionsStrategy/remoteQuestions';
import { QuestionsContext } from '../Strategy/questionsStrategy/questionsContext';
import { ServiceLocator } from './serviceLocator.service';
import { Platform } from '@ionic/angular';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: 'init5', initializer: 'onInit' })
@EasyDebugDecorator
export class QuestionsService extends Questions {
  private _questionsToSync: Array<Question> = [];
  private _questionsContext: QuestionsContext;

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private platform: Platform
  ) {
    super();
  }

  async onInit() {
    const transformed = await this.storageService.get('transformed');
    // const transformed = true;

    if (this.platform.is('cordova') || transformed) {
      this._questionsContext = new QuestionsContext(
        new LocalQuestions(
          this.storageService,
          ServiceLocator.injector.get(HttpClient)
        )
      );
    } else {
      this._questionsContext = new QuestionsContext(
        new RemoteQuestions(ServiceLocator.injector.get(HttpClient))
      );
      this._questionsContext.init();
    }
    return Promise.resolve('Questions done');
  }

  async syncQuestions(
    student: Student,
    lastUpdate: string,
    questions: Question[]
  ): Promise<Array<Question>> {
    let oldQuestions = [];
    if (!!student && !!lastUpdate && !!questions) {
      oldQuestions = questions;
      if (oldQuestions.length > 0) {
        let newQuestions = [];
        const resFetchQuestions = await this.fetchQuestions(lastUpdate);
        if (
          !!resFetchQuestions &&
          !!resFetchQuestions.errorCode &&
          resFetchQuestions.errorCode === 'E301'
        ) {
          // service failed
        } else {
          newQuestions = resFetchQuestions;
        }
        // console.log('questionsService syncQuestions newQuestions', newQuestions);
        if (!!newQuestions && newQuestions.length > 0) {
          for (const question of newQuestions) {
            const newQuestionId = !!question.id ? question.id : null;
            if (!!newQuestionId) {
              let newQuestion = null;
              const resFetchQuestion = question;
              if (
                !!resFetchQuestion &&
                !!resFetchQuestion.errorCode &&
                resFetchQuestion.errorCode === 'E301'
              ) {
                // service failed
              } else {
                newQuestion = resFetchQuestion;
              }
              // console.log('questionsService syncQuestions newQuestion', newQuestion);
              if (!!newQuestion && !!newQuestion.id) {
                let oldQuestion = null;
                const resGetQuestion = await this.questionsContext.getQuestion(
                  newQuestion.id,
                  student,
                  true
                );
                if (
                  !!resGetQuestion &&
                  !!resGetQuestion.errorCode &&
                  resGetQuestion.errorCode === 'E301'
                ) {
                  // service failed
                } else {
                  oldQuestion = resGetQuestion;
                }
                if (!!oldQuestion) {
                  newQuestion.downloadState = !!oldQuestion.downloadState
                    ? oldQuestion.downloadState
                    : false;
                  newQuestion = this.fillAssets(newQuestion, oldQuestion);
                  await this._questionsContext.setQuestion(
                    newQuestion,
                    student
                  );
                  const oldQuestionIndex = oldQuestions.findIndex(
                    elt => elt.id === newQuestion.id
                  );
                  if (oldQuestionIndex !== -1) {
                    oldQuestions.splice(oldQuestionIndex, 1, newQuestion);
                  }
                }
              }
            }
          }
          return await this._questionsContext.setQuestions(
            oldQuestions,
            student
          );
        }
      }
    }
    return oldQuestions;
  }

  fillAssets(newQuestion: Question, oldQuestion: Question): Question {
    if (!!newQuestion && !!oldQuestion) {
      newQuestion.off_answer_audio = !!oldQuestion.off_answer_audio
        ? oldQuestion.off_answer_audio
        : null;
      newQuestion.off_answer_img1 = !!oldQuestion.off_answer_img1
        ? oldQuestion.off_answer_img1
        : null;
      newQuestion.off_answer_img2 = !!oldQuestion.off_answer_img2
        ? oldQuestion.off_answer_img2
        : null;
      newQuestion.off_question_audio = !!oldQuestion.off_question_audio
        ? oldQuestion.off_question_audio
        : null;
      newQuestion.off_question_img1 = !!oldQuestion.off_question_img1
        ? oldQuestion.off_question_img1
        : null;
      newQuestion.off_question_img2 = !!oldQuestion.off_question_img2
        ? oldQuestion.off_question_img2
        : null;
      newQuestion.off_question_video = !!oldQuestion.off_question_video
        ? oldQuestion.off_question_video
        : null;
    }
    return newQuestion;
  }

  async saveQuestionsIndividualy(
    questions: Question[],
    student: Student
  ): Promise<Question[]> {
    const retQuestions: Question[] = [];
    if (!!student && !!questions && questions.length > 0) {
      for (const question of questions) {
        const questionData = await this._questionsContext.setQuestion(
          question,
          student
        );
        if (!!questionData) {
          retQuestions.push(questionData);
        }
      }
    }
    return retQuestions;
  }

  fetchQuestionsBySerie(serieId: string): Observable<any> {
    if (!!serieId) {
      const url = `${environment.cdrBase}/v${environment.apiOptiVersion}/series/${serieId}?with_questions=true`;

      return this.http.get(url).pipe(
        switchMap((seriesData: any) => {
          if (!!seriesData && !!seriesData.data) {
            const attributes = !!seriesData.data.attributes
              ? seriesData.data.attributes
              : null;
            if (!!attributes) {
              const data = !!attributes.questions ? attributes.questions : null;
              const questionsData = !!data && !!data.data ? data.data : null;
              if (!!questionsData && questionsData.length > 0) {
                return of(this.fillQuestions(questionsData, 'remote'));
              }
            }
          }
          return of({
            errorCode: 'E301',
            errorMessage: 'fetchQuestionsBySerie Service failed',
            errorOriginal: 'No seriesData or no data in seriesData',
          });
        }),
        catchError(err => {
          return of({
            errorCode: 'E301',
            errorMessage: 'fetchQuestionsBySerie Service failed',
            errorOriginal: err,
          });
        })
      );
    }
    return of([]);
  }

  async fetchQuestion(questionId: string): Promise<any> {
    if (!!questionId) {
      const url = `${environment.cdrBase}/v${environment.apiVersion}/questions/${questionId}`;

      return this.http
        .get(url)
        .pipe(
          switchMap(async (questionData: any) => {
            if (!!questionData && !!questionData.data) {
              return this.fillQuestion(questionData.data);
            }
            return of({
              errorCode: 'E301',
              errorMessage: 'fetchQuestion Service failed',
              errorOriginal: 'No questionData or no data in questionData',
            });
          }),
          catchError(err => {
            return of({
              errorCode: 'E301',
              errorMessage: 'fetchQuestion Service failed',
              errorOriginal: err,
            });
          })
        )
        .toPromise();
    }
    return null;
  }

  async fetchQuestions(updatedAt: string): Promise<any> {
    let query = '';

    if (!!updatedAt) {
      query = `?updated_at=${updatedAt}`;
      query = query.replace('+', '%2B');
      const url = `${environment.cdrBase}/v${environment.apiVersion}/questions${query}`;

      // le prob c'est que l'url me renvoie des fois vide alors que c'est pas vrai
      // c'est pas côté server/back car la data est bien là
      // c'est un prob de cache !!!!!

      return this.http
        .get(url)
        .pipe(
          switchMap(async (questionsData: any) => {
            if (
              !!questionsData &&
              !!questionsData.data &&
              questionsData.data.length > 0
            ) {
              const questions = this.fillQuestions(
                questionsData.data,
                'remote'
              );
              // console.log('########### FETCH QUESTIONS', JSON.parse(JSON.stringify(questions)));
              return questions;
            }
            return [];
          }),
          catchError(async err => {
            return {
              errorCode: 'E301',
              errorMessage: 'fetchQuestions Service failed',
              errorOriginal: err,
            };
          })
        )
        .toPromise();
    }
    return [];
  }

  public get questionsToSync(): Array<Question> {
    return this._questionsToSync;
  }
  public set questionsToSync(value: Array<Question>) {
    this._questionsToSync = value;
  }
  public get questionsContext(): QuestionsContext {
    return this._questionsContext;
  }
}
