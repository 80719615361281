import { StudentService } from '@app/app.services';
import { NavigationService } from './navigation.service';
import { ConfigUserService } from './config-user.service';
import { Injectable } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';

import { Initialable } from './app-init.service';

import { BehaviorSubject } from 'rxjs';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { environment } from '@app/env';

var MAX_DIALOG_WAIT_TIME_IOS = 5 * 1000;

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: `init5`, initializer: `init` })
@EasyDebugDecorator
export class AppRatingService {
  rating: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  rating$ = this.rating.asObservable();
  launched = false;
  configUser: any;

  constructor(
    private alertCtrl: AlertController,
    private navigationService: NavigationService,
    private configUserService: ConfigUserService,
    private platform: Platform,
    private studentService: StudentService,
    public launchReview: LaunchReview,
    public market: Market
  ) {
    // console.log('App Rating Service Instanciate');
  }

  async init() {
    // console.log(`Launching migrations init`);
    return new Promise<string>((resolve, reject) => {
      setTimeout(
        () => {
          resolve('AppRating done');
          // console.log(`init migrations done`);
        },
        Math.floor(Math.random() * 1000) + 500
      );
    });
  }

  displayRatingSlider(number: number) {
    this.rating.next(number);
  }

  async checkRating(student, sessions) {
    const steps = [3, 5, 10, 20, 40];
    if (sessions.length > 0 && this.platform.is('cordova')) {
      const usableSessions = sessions.filter(
        elt =>
          elt.mode !== 'Cdr::ErrorSession' &&
          elt.type !== 'Discovery' &&
          elt.state !== 'pending'
      );
      // console.log('usableSessions', usableSessions);
      this.configUser = await this.configUserService.getUserConfig(student);
      if (!this.configUser.appRatingStepDone) {
        this.configUser.appRatingStepDone = 0;
      }
      if (!this.configUser.appRatingDone) {
        this.configUser.appRatingDone = false;
      }
      if (this.configUser.appRatingDone === false) {
        for (let i = 0; i < steps.length; i++) {
          // pour éviter à ceux qui arrivent en cours de route avec déjà pleins de sessions en stock
          // de se prendre 4 modales d'affilés dans la cas où ils skiperai à chaque demande
          const nextStep = !!steps[i + 1] ? steps[i + 1] : 1000000;
          if (
            usableSessions.length >= steps[i] &&
            usableSessions.length < nextStep &&
            this.configUser.appRatingStepDone < steps[i]
          ) {
            this.configUser.appRatingStepDone = steps[i];
            this.configUser = await this.configUserService.setUserConfig(
              student,
              this.configUser
            );
            this.launchAppRating();
            break;
          }
        }
      }
      // console.log('configUser', this.configUser);
    }
  }

  async launchAppRating() {
    const confirm = await this.alertCtrl.create({
      header: `Que penses-tu d'En Voiture Simone ?`,
      message: `Ton avis nous intéresse ! Choisis l'une des réponses ci-dessous pour nous aider à améliorer l'application.`,
      buttons: [
        {
          text: `J'adore`,
          handler: () => {
            if (
              !!this.studentService.student &&
              !!this.studentService.student.email &&
              (this.studentService.student.email.includes(
                '@envoituresimone.com'
              ) ||
                this.studentService.student.email.includes(
                  'michelsanglier@outlook.fr'
                ))
            ) {
              this.rate();
            } else {
              this.rating.next(2);
              this.launched = true;
              this.navigationService.open('rating', { forceRoot: true });
            }
          },
        },
        {
          text: 'Je suis déçu-e',
          handler: () => {
            if (
              !!this.studentService.student &&
              !!this.studentService.student.email &&
              (this.studentService.student.email.includes(
                '@envoituresimone.com'
              ) ||
                this.studentService.student.email.includes(
                  'michelsanglier@outlook.fr'
                ))
            ) {
              this.rate();
            } else {
              this.rating.next(3);
              this.launched = true;
              this.navigationService.open('rating', { forceRoot: true });
            }
          },
        },
        {
          text: 'Plus Tard',
        },
      ],
      mode: 'ios',
    });
    confirm.present();
  }

  rate() {
    const isRatingSupported = this.launchReview.isRatingSupported();
    let ratingTimerId;

    // alert('in app rating');
    if (isRatingSupported) {
      this.launchReview.rating().subscribe(
        status => {
          if (status === 'requested') {
            if (this.platform.is('ios')) {
              ratingTimerId = setTimeout(() => {
                this.openStore();
              }, MAX_DIALOG_WAIT_TIME_IOS);
            }
          } else if (status === 'shown') {
            if (!!ratingTimerId) {
              clearTimeout(ratingTimerId);
            }
          } else if (status === 'dismissed') {
            if (!!ratingTimerId) {
              clearTimeout(ratingTimerId);
            }
          }
        },
        err => {
          // alert(JSON.stringify(err));
          if (this.platform.is('ios')) {
            this.openStore();
            clearTimeout(ratingTimerId);
          }
        }
      );
    }
  }

  openStore() {
    if (this.platform.is('ios')) {
      this.market.open(environment.iosAppId);
    }
  }
}
