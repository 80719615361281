import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { StorageService } from '../app.services';

import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root',
})
export class UserSessionInterceptor implements HttpInterceptor {
  constructor(
    private userSessionService: UserSessionService,
    private storageService: StorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.requestMatchEvsHost(request)) {
      return next.handle(request).pipe(
        tap(
          () => {},
          async (error: HttpErrorResponse) => {
            if (error.status === 401 && error.error) {
              await this.storageService.remove('jwtToken');
              await this.storageService.set('Student', {
                _remoteId: 'GUEST',
                _status: 'guest',
              });
              this.userSessionService.sessionExpiredObs.next(true);
            }
            return error;
          }
        )
      );
    }
    return next.handle(request);
  }

  private requestMatchEvsHost(request: HttpRequest<any>): boolean {
    if (
      !!request.url.match('validate_token') ||
      !!request.url.match('sign_in')
    ) {
      return false;
    }
    // Checking if the request is for one of our serveurs
    // No need to check micro-services because if they have a doubte in the session
    // they will request a new token with validate_token and the user will be notify that
    // his session has exepried
    if (
      request.url.match(environment.cmsBase) ||
      request.url.match(environment.token_auth_config.secondaryApiBase)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
