import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Initialable } from '../../app/services/app-init.service';
import { BehaviorSubject, of } from 'rxjs';

import { EasyDebugLogger } from '../easy-debug-logger/easy-debug-logger';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init0', initializer: 'init' })
@EasyDebugDecorator
export class EasyDebugService {
  recordingModeState = { recordingMode: '' };
  recordingModeChanged: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.recordingModeState
  );
  recordingModeChanged$ = this.recordingModeChanged.asObservable();

  recordingMode = null;

  reportObj = {};

  easyDebugLogger = EasyDebugLogger.getInstance();
  logs: Array<any> = [];

  constructor(private http: HttpClient) {}

  async init() {
    return 'EasyDebug done';
  }

  setRecordingMode(mode) {
    console.log('setRecordingMode', mode);
    this.recordingMode = mode;
    this.recordingModeState.recordingMode = this.recordingMode;
    this.recordingModeChanged.next(this.recordingModeState);
  }

  async sendReport(report: object) {
    if (!!report) {
      this.reportObj = report;
    }
    console.log('EASY DEBUGGING : SEND REPORT !', this.reportObj);
    const resPost = await this.postLogs(this.reportObj);
    if (!!resPost.status && resPost.status !== 200) {
      this.setRecordingMode('failed');
    } else {
      this.setRecordingMode('sended');
    }
  }

  getRecordingMode() {
    return this.recordingMode;
  }

  getLogs() {
    this.logs = this.easyDebugLogger.logs;
    return this.logs;
  }

  async postLogs(reportData: any): Promise<any> {
    const url = `${environment.cdrBase}/v${environment.apiVersion}/debug_report`;
    console.log(url);

    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key: any, value: any) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    return this.http
      .post(url, {
        debug_report: {
          report: JSON.stringify(reportData, getCircularReplacer()),
        },
      })
      .pipe(
        switchMap(async (data: any) => {
          console.log('post report data => ', data);
          return data;
        }),
        catchError(err => {
          return of(err);
        })
      )
      .toPromise();
  }
}
