<div class="tunnel-no-code-container">
  <div class="content">
    <div class="content-container">
      <div class="content-header">
        <div class="tunnel-back-controls">
          <div
            class="back-btn"
            (click)="sendCompData({action: 'navigate', data: {direction: 'prev'}})">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone" />
          </div>
        </div>
        <div class="basket-container">
          <div class="basket" (click)="toggleBasketDetails()">
            <img src="assets/svg/basket.svg" alt="EnVoitureSimone" />
          </div>
          <div
            id="pack-permis-text"
            class="basket-details"
            [ngClass]="{'show': showBasket && !!basketInfos}">
            <div class="header">
              <div *ngIf="!!basketInfos.price" class="price">
                {{basketInfos.price}}€
              </div>
              <div *ngIf="!!basketInfos.title" class="title">
                {{basketInfos.title}}
              </div>
              <div *ngIf="!!basketInfos.prefecture" class="prefecture">
                {{basketInfos.prefecture}}
              </div>
            </div>
            <div
              class="list"
              *ngIf="!!basketInfos.list && basketInfos.list.length > 0">
              <div class="item" *ngFor="let list of basketInfos.list">
                <img
                  src="assets/svg/tunnel-checkbox-ico.svg"
                  alt="EnVoitureSimone" />
                <span>{{list}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="no-code-form">
          <h5 id="question-title">Pas de code ? Pas de problème !</h5>
          <div id="info-box" class="no-code-recap">
            <p>
              Tu dois <span class="bold">passer le code</span> avant de réserver
              tes premières leçons de conduite
            </p>
          </div>
          <div class="container">
            <p>
              En poursuivant ton achat tu disposes de leçons de conduite sur
              boîte {{gearboxType}} valables 1 an
            </p>
          </div>
        </div>
      </div>
      <div class="next-controls" *ngIf="isDesktop">
        <button
          id="bouton-confirm"
          [disabled]="dataSent"
          class="btn full-width"
          (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">
          Valider
        </button>
      </div>
    </div>
  </div>
  <div id="image" class="visuel"></div>
  <div class="next-controls" *ngIf="!isDesktop">
    <button
      id="bouton-confirm"
      [disabled]="dataSent"
      class="btn full-width"
      (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">
      Valider
    </button>
  </div>
</div>
