import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Initialable } from './app-init.service';
import { StudentService } from './student.service';
import { Event, StatsService } from './stats.service';
import { Serie } from '../models/serie';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: 'init5', initializer: `onInit` })
@EasyDebugDecorator
export class GtmService {
  eventsObs = new BehaviorSubject<Event>({ name: null, payload: {} });
  constructor(
    private studentService: StudentService,
    public gtmService: GoogleTagManagerService
  ) {}

  async onInit() {
    return 'Gtm done';
  }

  objToGtm(datas: any, tag: { [key: string]: any }): { [key: string]: any } {
    for (const data of Object.entries(datas)) {
      if (!!JSON.stringify(data[1])) {
        tag[data[0]] = data[1];
      }
    }
    return tag;
  }

  arrayToGtm(objs: any[], tag: { [key: string]: any }): { [key: string]: any } {
    for (const obj of objs) {
      tag = this.objToGtm(obj, tag);
    }
    return tag;
  }

  sendGtmEvent(eventName: string, user: any, env: any, additionalData: any) {
    // console.log('sendGtmEvent', eventName, user, env, additionalData);
    let tag: { [key: string]: any } = {
      event: eventName,
    };
    if (!!additionalData) {
      tag = this.arrayToGtm([user, env, additionalData], tag);
    } else {
      tag = this.arrayToGtm([user, env], tag);
    }
    this.gtmService.pushTag(tag);
  }
}
