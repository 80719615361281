import { Component, Input, HostListener } from '@angular/core';

import {
  ModalController,
  NavParams,
  Platform,
  NavController,
} from '@ionic/angular';

import { EasyDebugService } from '../../services/easy-debug.service';
import { AppInitService } from '../../services/app-init.service';
import { EvsApiHeaderService } from '../../../commons/services/EvsApiHeader.service';

import { UtilsService, ToasterService } from '@commons/services';

import { EasyDebugDecorator } from '../../decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'modal-error-component',
  templateUrl: 'modal-error.component.html',
  styleUrls: ['modal-error.component.scss'],
})
export class ModalErrorComponent {
  // Data passed in by componentProps
  @Input() type: string;

  pageTitle = 'Modal Info User';
  activeLink = 'dashboard';

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  modalType = '';
  error = null;
  closable = true;

  isCordova = false;
  isDesktop = window.innerWidth > 767 ? true : false;

  banner = null;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = window.innerWidth > 767 ? true : false;
  }

  constructor(
    public evsApiHeaderService: EvsApiHeaderService,
    public easyDebugService: EasyDebugService,
    public utils: UtilsService,
    public toasterService: ToasterService,
    public navParams: NavParams,
    private modalController: ModalController,
    public appInitService: AppInitService,
    public platform: Platform,
    public navController: NavController
  ) {
    this.isCordova = this.platform.is('cordova');
    this.modalType = this.navParams.get('type');
    this.error = this.navParams.get('error');
    this.closable = this.navParams.get('closable');
  }

  async initPage() {}

  launchToaster(config) {
    this.toasterService.create(config);
  }

  async closeModalPack() {
    if (!!this.modalController) {
      this.modalController.dismiss();
    }
  }

  async login() {
    await this.closeModalPack();
    this.navController.navigateRoot('login/signIn');
  }

  async register() {
    await this.closeModalPack();
    this.navController.navigateRoot('login/register');
  }

  async goHome() {
    await this.closeModalPack();
    this.navController.navigateRoot('accueil');
  }
  async retry() {
    await this.closeModalPack();
  }
}
