<ng-template
  #errorTemplate
  let-field="field"
  let-doubleIcon="doubleIcon"
  let-iconOnly="iconOnly"
  let-fieldName="fieldName">
  <ng-container>
    <div
      class="field-error"
      [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.required}">
      Ce champ est obligatoire
    </div>
    <div
      class="field-error"
      [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName != 'phone_number' && fieldName != 'email'}">
      Ce champ n'est pas valide
    </div>
    <div
      class="field-error"
      [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName == 'email' && displayEMailPatternError}">
      Ce champ n'est pas valide
    </div>
    <div
      class="field-error"
      [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.minlength}">
      6 caractères minimum
    </div>
    <div
      class="field-error"
      [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.maxlength}">
      50 caractères maximum
    </div>
    <div
      class="field-error"
      [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.validatePasswords}">
      Les mots de passe ne correspondent pas
    </div>
    <div
      class="field-error"
      [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName == 'phone_number'}">
      Saisir un numéro valide en 06 ou 07
    </div>
  </ng-container>
</ng-template>

<div class="tunnel-register-container">
  <div class="content">
    <div class="content-container">
      <div class="content-header">
        <div class="tunnel-back-controls">
          <div
            class="back-btn"
            (click)="sendCompData({action: 'navigate', data: {direction: 'prev'}})">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone" />
          </div>
        </div>
      </div>
      <div class="content-body">
        <h5 id="title">Inscription avec un e-mail</h5>
        <form [formGroup]="registerForm" class="evs-form">
          <div id="firstname-form">
            <input
              *ngIf="!isActive"
              type="text"
              formControlName="first_name"
              id="first_name"
              (focus)="setFocusedField('first_name')"
              (blur)="formHasErrors()"
              autocomplete="off"
              data-lpignore="true"
              disabled />
            <input
              *ngIf="isActive"
              type="text"
              formControlName="first_name"
              id="first_name"
              (focus)="setFocusedField('first_name')"
              (blur)="formHasErrors()"
              autocomplete="off"
              data-lpignore="true" />
            <label
              for="first_name"
              [ngClass]="{'label-up': !!getRegisterForm.first_name.value && getRegisterForm.first_name.value !== ''}"
              >Prénom *</label
            >
            <img
              *ngIf="!!getRegisterForm.first_name.value && getRegisterForm.first_name.value !== ''"
              src="assets/svg/tunnel-delete-ico.svg"
              alt="EnVoitureSimone"
              class="delete-ico"
              [ngClass]="{'active': focusedField === 'first_name'}"
              (click)="clearField(registerForm, 'first_name')" />
          </div>
          <ng-container
            [ngTemplateOutlet]="errorTemplate"
            [ngTemplateOutletContext]="{field: getRegisterForm.first_name}"></ng-container>
          <div id="lastname-form">
            <input
              *ngIf="!isActive"
              type="text"
              formControlName="last_name"
              id="last_name"
              (focus)="setFocusedField('last_name')"
              (blur)="formHasErrors()"
              autocomplete="off"
              data-lpignore="true"
              disabled />
            <input
              *ngIf="isActive"
              type="text"
              formControlName="last_name"
              id="last_name"
              (focus)="setFocusedField('last_name')"
              (blur)="formHasErrors()"
              autocomplete="off"
              data-lpignore="true" />
            <label
              for="last_name"
              [ngClass]="{'label-up': !!getRegisterForm.last_name.value && getRegisterForm.last_name.value !== ''}"
              >Nom *</label
            >
            <img
              *ngIf="!!getRegisterForm.last_name.value && getRegisterForm.last_name.value !== ''"
              src="assets/svg/tunnel-delete-ico.svg"
              alt="EnVoitureSimone"
              class="delete-ico"
              [ngClass]="{'active': focusedField === 'last_name'}"
              (click)="clearField(registerForm, 'last_name')" />
          </div>
          <ng-container
            [ngTemplateOutlet]="errorTemplate"
            [ngTemplateOutletContext]="{field: getRegisterForm.last_name}"></ng-container>
          <div id="zip-code-form">
            <autocomplete-comp
              *ngIf="isActive"
              id="tunnelFormPostalCode"
              [compOptions]="{'id': 'tunnelPaiementRegisterPageAutocomplete', 'value': null, 'service': postalCodeService, 'fields': ['pc_value', 'name', 'pc_id'], 'fieldname': 'name', 'output': 'pc_id', 'placeholder': 'Code postal *'}"
              (inputSelected)="getAutocompleteValueTunnelPaiementRegister($event)"></autocomplete-comp>
          </div>
          <div id="phone-form">
            <input
              *ngIf="!isActive"
              type="tel"
              formControlName="phone_number"
              id="phone_number"
              (focus)="setFocusedField('phone_number')"
              (blur)="tunnelFormHasErrors()"
              autocomplete="off"
              data-lpignore="true"
              disabled />
            <input
              *ngIf="isActive"
              type="tel"
              formControlName="phone_number"
              id="phone_number"
              (focus)="setFocusedField('phone_number')"
              (blur)="tunnelFormHasErrors()"
              autocomplete="off"
              data-lpignore="true" />
            <label
              for="phone_number"
              [ngClass]="{'label-up': !!getRegisterForm.phone_number.value && getRegisterForm.phone_number.value !== ''}"
              >Téléphone *</label
            >
            <img
              *ngIf="!!getRegisterForm.phone_number.value && getRegisterForm.phone_number.value !== ''"
              src="assets/svg/tunnel-delete-ico.svg"
              alt="EnVoitureSimone"
              class="delete-ico"
              [ngClass]="{'active': focusedField === 'phone_number'}"
              (click)="clearField(registerForm, 'phone_number')" />
          </div>
          <ng-container
            [ngTemplateOutlet]="errorTemplate"
            [ngTemplateOutletContext]="{field: getRegisterForm.phone_number, fieldName: 'phone_number'}"></ng-container>
          <div id="email-form">
            <input
              type="email"
              formControlName="email"
              id="register_email"
              (blur)="tunnelFormHasErrors()"
              autocomplete="off"
              data-lpignore="true"
              [value]="userMail"
              disabled />
            <label
              for="register_email"
              [ngClass]="{'label-up': !!getRegisterForm.email.value && getRegisterForm.email.value !== ''}"
              >E-mail *</label
            >
          </div>
          <ng-container
            [ngTemplateOutlet]="errorTemplate"
            [ngTemplateOutletContext]="{field: getRegisterForm.email}"></ng-container>
          <div>
            <input
              *ngIf="!isActive"
              [type]="showPassword"
              formControlName="password"
              id="register_password"
              (input)="validatePasswords($event.target.value)"
              autocomplete="off"
              data-lpignore="true"
              class="double-icon"
              disabled />
            <input
              *ngIf="isActive"
              [type]="showPassword"
              formControlName="password"
              id="register_password"
              (input)="validatePasswords($event.target.value)"
              autocomplete="off"
              data-lpignore="true"
              class="double-icon" />
            <img
              src="assets/2022/icons/show-off.svg"
              alt="EnVoitureSimone"
              *ngIf="showPassword == 'password'"
              class="password-eye view"
              (click)="toggleshowPassword()" />
            <img
              src="assets/2022/icons/hide-off.svg"
              alt="EnVoitureSimone"
              *ngIf="showPassword == 'text'"
              class="password-eye no-view"
              (click)="toggleshowPassword()" />
            <label
              for="register_password"
              [ngClass]="{'label-up': !!getRegisterForm.password.value && getRegisterForm.password.value !== ''}"
              >Mot de passe *</label
            >
          </div>
          <ng-container
            [ngTemplateOutlet]="errorTemplate"
            [ngTemplateOutletContext]="{field: getRegisterForm.password, doubleIcon: true}"></ng-container>
          <mark
            class="clear-float padding-5 display-block margin-v-5 text-font"
            *ngIf="enablePasswordValidator && passwordValidatorStates.isValid"
            >Ton mot de passe est sûr 🎉</mark
          >
          <ion-grid
            class="ion-no-padding clear-float margin-bottom-20 password-validator"
            *ngIf="enablePasswordValidator && !passwordValidatorStates.isValid">
            <ion-row>
              <ion-col size="12" size-md>
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col
                      size="6"
                      class="text-left small-text"
                      [ngClass]="{'text-gray': !passwordValidatorStates.isMinLength}">
                      <i
                        *ngIf="!passwordValidatorStates.isMinLength"
                        class="icon ion-md-close-circle"></i>
                      <i
                        *ngIf="passwordValidatorStates.isMinLength"
                        class="icon ion-md-checkmark-circle"></i>
                      6 caractères min.
                    </ion-col>
                    <ion-col
                      size="6"
                      class="text-left small-text"
                      [ngClass]="{'text-gray': !passwordValidatorStates.isUppercase}">
                      <i
                        *ngIf="!passwordValidatorStates.isUppercase"
                        class="icon ion-md-close-circle"></i>
                      <i
                        *ngIf="passwordValidatorStates.isUppercase"
                        class="icon ion-md-checkmark-circle"></i>
                      1 majuscule
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size="12" size-md>
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col
                      size="6"
                      class="text-left small-text"
                      [ngClass]="{'text-gray': !passwordValidatorStates.isSpecialChar}">
                      <i
                        *ngIf="!passwordValidatorStates.isSpecialChar"
                        class="icon ion-md-close-circle"></i>
                      <i
                        *ngIf="passwordValidatorStates.isSpecialChar"
                        class="icon ion-md-checkmark-circle"></i>
                      1 caractère spécial
                      <br /><span>#?!@$%^&*-</span>
                    </ion-col>
                    <ion-col
                      size="6"
                      class="text-left small-text"
                      [ngClass]="{'text-gray': !passwordValidatorStates.isNumber}">
                      <i
                        *ngIf="!passwordValidatorStates.isNumber"
                        class="icon ion-md-close-circle"></i>
                      <i
                        *ngIf="passwordValidatorStates.isNumber"
                        class="icon ion-md-checkmark-circle"></i>
                      1 chiffre
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div class="margin-top-40">
            <button
              id="bouton-continue"
              [disabled]="!registerForm.valid || !isActive || dataSent"
              class="btn full-width"
              (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">
              S'inscrire
            </button>
          </div>
          <input
            class="display-none"
            type="checkbox"
            formControlName="nl_subscribed"
            id="nl_subscribed"
            [checked]="nlSubscribedChecked"
            autocomplete="off"
            data-lpignore="true"
            [disabled]="!isActive" />
          <div class="optin-nl" (click)="setCheckbox('nl_subscribed')">
            <span class="inline-block">
              <img
                *ngIf="nlSubscribedChecked"
                src="assets/svg/checkbox.svg"
                height="18"
                alt="EnVoitureSimone" />
              <img
                *ngIf="!nlSubscribedChecked"
                src="assets/2022/icons/checkbox-on.svg"
                height="18"
                alt="EnVoitureSimone" />
            </span>
            <span id="optout-text" class="inline-block text-left"
              >Je ne souhaite pas bénéficier des conseils et offres d’En Voiture
              Simone pour obtenir le permis.</span
            >
          </div>
          <div id="cgu-text" class="text-footer">
            En cliquant sur S’inscrire, j’accepte les
            <a class="link" (click)="openConditionsGenerales()"
              >conditions générales</a
            >
            et la
            <a class="link" (click)="openPolitiqueConfidentialite()"
              >politique de confidentialité</a
            >
            d’En Voiture Simone
          </div>
        </form>
      </div>
    </div>
  </div>
  <div id="image" class="visuel"></div>
</div>
