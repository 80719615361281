/* tslint:disable:member-ordering */
import {
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

// SERVICES
import { UtilsService } from '@commons/services';

@Directive({
  selector: '[appSliderLocal]',
  exportAs: 'SliderLocalDirective', // allow template to convert into @ViewChild
})
export class SliderLocalDirective implements OnInit, AfterViewInit, OnChanges {
  @Input('appSliderLocal') sliderConfig: any;
  @Output() canClick = new EventEmitter<boolean>();

  parent: any;
  divSlides: any;
  divBullets: any;
  originalContent = [];
  isAnimated = false;
  isDraggable = true;
  isClickBullet = false;
  focusSlider = false;
  innerWidth = window.innerWidth;

  // CONFIG
  isDebug = false;
  isBullets = false;
  isArrows = false;
  autoSlide = false;
  autoSliderTimer = 0;
  isInline = false;
  nbLines = 1;
  isVertical = false;
  verticalLimit = 0;

  // SLIDER PARAMS
  nbSlides = 0;
  currentSlide = 0;
  containerWidth: any = 0;
  inlineWidthItem = 0;
  inlineHeightItem = 0;
  retry = 0;

  // IMG
  imgToload = [];
  nbTotalImg = 0;
  imgLoaded = 0;
  imgCpt = 0;
  isFullImg = false;

  // SLIDES MOVE
  marginLeft = 0;
  marginLeftDrag = 0;

  posX = 0;
  posY = 0;
  isDrag = false;
  hasMoved = false;

  // autoslide
  timer = 5000; // millisec
  userStopAutoSlider = false;
  timeOut = null;

  // arrows
  btControls: any;
  btLeft: any;
  btRight: any;
  isClickArrow = false;

  firstInterval: any;
  waitInterval: any;
  cptWaintInerval = 0;

  id: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private utils: UtilsService
  ) {
    this.parent = this.el.nativeElement;
    this.renderer.setStyle(this.parent, 'opacity', '0');
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const sliderConfig: SimpleChange = changes.sliderConfig;
    if (this.utils.isDefined(sliderConfig)) {
      this.sliderConfig = sliderConfig.currentValue;
      this.getSliderConfig();
      setTimeout(() => {
        this.resizeInlineSlider();
      });
    }
  }

  ngOnInit() {
    this.getSliderConfig();
  }

  ngAfterViewInit() {
    // get view generated original contents when ready
    this.firstInterval = setInterval(() => {
      if (this.isDebug) {
        console.log('setInterval search for getBoundingClientRect');
      }
      if (this.parent.getBoundingClientRect().width > 0 && this.nbSlides <= 0) {
        this.initSlider();
      }
    }, 100);
  }

  initSlider() {
    clearInterval(this.firstInterval);
    const children = this.parent.childNodes;
    this.getAllContents(children);
    // nbslides == nbcontents - 1 | start at 0
    if (this.nbSlides > 0) {
      this.setSlider();
    }
  }

  resizeInlineSlider() {
    if (!!this.divSlides) {
      this.containerWidth = this.parent.getBoundingClientRect();
      const children = this.divSlides.childNodes;
      if (!!children && children.length > 0) {
        this.inlineWidthItem = children[0].offsetWidth;
      }
      let slidesWidth: any;
      if (this.isInline) {
        if (!this.isVertical) {
          slidesWidth = this.inlineWidthItem * (this.nbSlides + 1);
          if (this.nbLines > 1) {
            slidesWidth =
              this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2) <
              this.containerWidth.width
                ? this.containerWidth.width
                : this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2);
          }
          // replace slides
          if (!!this.divSlides) {
            const marginType = !this.isVertical ? 'margin-left' : 'margin-top';
            this.renderer.setStyle(this.divSlides, marginType, 0);
          }
          this.checkSlidable();
        }
      }
      if (this.isDebug) {
        console.log('resizeInlineSlider slidesWidth', slidesWidth);
      }
      this.renderer.setStyle(this.divSlides, 'width', slidesWidth + 'px');
    }
  }

  getAllContents(contents) {
    this.nbSlides = 0;
    for (const child of contents) {
      // check si on gère bien tous les types commentaires ou text et autres trucs qu'on ne voudrait pas
      if (child.nodeName !== '#comment' && child.nodeName !== '#text') {
        this.originalContent.push(child);
        this.inlineWidthItem =
          child.offsetWidth > this.inlineWidthItem
            ? child.offsetWidth
            : this.inlineWidthItem;
        this.inlineHeightItem =
          child.offsetHeight > this.inlineHeightItem
            ? child.offsetHeight
            : this.inlineHeightItem;
        if (child.nodeName.toLowerCase() === 'img') {
          this.originalContent[this.originalContent.length - 1].draggable =
            false; // chrome
          this.originalContent[this.originalContent.length - 1].ondragstart =
            () => {
              // firefox
              return false;
            };
          this.nbTotalImg++;
        }
      }
    }
    this.nbSlides = this.originalContent.length - 1;
    this.isFullImg = this.nbTotalImg - 1 === this.nbSlides ? true : false;

    if (this.isDebug) {
      console.log('getAllContents', this.nbSlides);
    }

    if (this.nbSlides > 1) {
      if (this.inlineHeightItem === 0 && this.isVertical && this.retry < 3) {
        if (this.isDebug) {
          console.log('getAllContents inlineHeightItem === 0 retry');
        }
        this.retry++;
        setTimeout(() => {
          this.getAllContents(contents);
        }, 0);
      }
    } else {
      if (this.isDebug) {
        console.log('no slider if only 1 element');
      }
      // no slider if only 1 element
      this.renderer.setStyle(this.parent, 'line-height', '0');
      this.renderer.removeStyle(this.parent, 'opacity');
    }
  }

  setSlider() {
    if (this.isDebug) {
      console.log('setSlider styles');
    }
    // Assign parent size
    this.renderer.setStyle(this.parent, 'transition', 'opacity .6s ease-out');
    if (this.isVertical) {
      const slidesHeight =
        this.verticalLimit === 0
          ? this.inlineHeightItem * (this.nbSlides + 1)
          : this.verticalLimit;
      this.renderer.setStyle(this.parent, 'height', slidesHeight + 'px');
    }
    this.renderer.setStyle(this.parent, 'overflow', 'hidden');
    if (!this.isInline) {
      this.renderer.setStyle(this.parent, 'text-align', 'center');
    }
    this.renderer.setStyle(this.parent, 'position', 'relative');
    this.renderer.setStyle(this.parent, 'line-height', '0');

    this.containerWidth = this.parent.getBoundingClientRect();

    // clean parent
    this.parent.innerHTML = '';

    setTimeout(() => {
      // const widthParent = (!this.isVertical)
      //   ? this.containerWidth.width + 'px'
      //   : (!this.isFullImg)
      //     ? this.inlineWidthItem + 'px'
      //     : this.containerWidth.width + 'px';
      const widthParent = !this.isVertical
        ? '100%'
        : !this.isFullImg
          ? this.inlineWidthItem + 'px'
          : '100%';
      if (this.isDebug) {
        console.log('setSlider widthParent', widthParent);
      }
      this.renderer.setStyle(this.parent, 'width', widthParent);
      // remove arrows if container < 250px
      this.isArrows =
        this.containerWidth.width <= 250 && !this.isVertical
          ? false
          : !!this.sliderConfig.arrows
            ? this.sliderConfig.arrows
            : false;

      if (this.nbTotalImg > 0) {
        this.loadImgs();
      } else {
        this.putContent();
      }
    }, 0);
  }

  getSliderConfig() {
    this.isDebug = !!this.sliderConfig.debug
      ? this.sliderConfig.debug
      : this.isDebug;
    this.id = !!this.sliderConfig.id ? this.sliderConfig.id : this.id;
    this.isBullets = !!this.sliderConfig.bullets
      ? this.sliderConfig.bullets
      : this.isBullets;
    this.isArrows = !!this.sliderConfig.arrows
      ? this.sliderConfig.arrows
      : this.isArrows;
    this.autoSlide = !!this.sliderConfig.autoSlide
      ? this.sliderConfig.autoSlide
      : this.autoSlide;
    this.autoSliderTimer = !!this.sliderConfig.timer
      ? this.sliderConfig.timer * 1000
      : this.timer;
    this.timer = this.autoSliderTimer; // assign

    this.isVertical = !!this.sliderConfig.vertical
      ? this.sliderConfig.vertical
      : this.isVertical;
    this.verticalLimit = !!this.sliderConfig.verticalLimit
      ? this.sliderConfig.verticalLimit
      : this.verticalLimit;

    this.isInline = !!this.sliderConfig.inline
      ? this.sliderConfig.inline
      : this.isInline;
    this.nbLines = !!this.sliderConfig.nblines
      ? this.sliderConfig.nblines
      : this.nbLines;
    // slider inline = no bullets + no autoslide
    this.isBullets = this.isInline ? false : this.isBullets;
    this.autoSlide = this.isInline ? false : this.autoSlide;
  }

  loadImgs() {
    this.imgLoaded = 0;
    for (const content of this.originalContent) {
      // Images
      if (content.nodeName.toLowerCase() === 'img') {
        this.imgToload[this.imgCpt] = new Image();
        this.imgToload[this.imgCpt].onload = () => {
          this.imgLoaded++;
          if (this.imgLoaded === this.nbTotalImg) {
            // all img loaded
            this.putContent();
          }
        };
        this.imgToload[this.imgCpt].onerror = () => {
          // img not found - remove from contents
          this.originalContent.splice(this.originalContent.indexOf(content), 1);
          this.nbTotalImg--;
          this.nbSlides = this.originalContent.length - 1;
          this.isFullImg = this.nbTotalImg - 1 === this.nbSlides ? true : false;
          if (this.imgLoaded === this.nbTotalImg) {
            // all img loaded
            this.putContent();
          }
        };
        this.imgToload[this.imgCpt].src = content.src;
      }
    }
  }

  putContent() {
    if (this.isDebug) {
      console.log('********************************');
    }
    if (this.isDebug) {
      console.log('putContent');
    }
    // remove if exist
    if (!!this.divSlides && !!this.parent) {
      if (this.divSlides.parentNode === this.parent) {
        if (this.isDebug) {
          console.log('putContent remove divSlides from parent');
        }
        this.parent.removeChild(this.divSlides);
      }
    }
    if (!!this.divBullets && !!this.parent && this.isBullets) {
      if (this.divBullets.parentNode === this.parent) {
        if (this.isDebug) {
          console.log('putContent remove divBullets from parent');
        }
        this.parent.removeChild(this.divBullets);
      }
    }
    // get containerWidth
    this.containerWidth = this.parent.getBoundingClientRect();
    if (this.isDebug) {
      console.log('putContent containerWidth', this.containerWidth);
    }
    // create divSlides if not exists
    this.divSlides = document.createElement('div');
    this.divSlides.className = 'dirslider_divSlides';
    // push divSlide into parent
    this.parent.appendChild(this.divSlides);

    // assign divSlides width from new containerWidth
    if (this.isDebug) {
      console.log('putContent currentSlide', this.currentSlide);
    }
    const nbSlides =
      this.currentSlide === 0 || this.currentSlide === this.nbSlides
        ? this.nbSlides + 2
        : this.nbSlides + 1;
    if (this.isDebug) {
      console.log('putContent nbSlides', nbSlides);
    }
    let slidesWidth: any;
    if (!this.isInline) {
      slidesWidth = this.containerWidth.width * nbSlides;
    } else {
      if (!this.isVertical) {
        slidesWidth = this.inlineWidthItem * (this.nbSlides + 1);
        if (this.nbLines > 1) {
          slidesWidth =
            this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2) <
            this.containerWidth.width
              ? this.containerWidth.width
              : this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2);
        }
        this.checkSlidable();
      }
    }
    this.renderer.setStyle(this.divSlides, 'width', slidesWidth + 'px');
    if (this.isInline) {
      if (!this.isVertical) {
        // prevent height changes when resize inline sliders
        setTimeout(() => {
          this.renderer.setStyle(
            this.divSlides,
            'height',
            this.divSlides.offsetHeight + 'px'
          );
        });
      }
    }
    // real marginLeft
    const nbDecalScreen =
      this.currentSlide === 0 ? this.currentSlide + 1 : this.currentSlide;
    this.marginLeft = !this.isInline
      ? this.containerWidth.width * nbDecalScreen * -1
      : this.marginLeftDrag;
    // make diff between real MarginLeft and dragged marginLeft - to go back to real if mouseup too soon
    this.marginLeftDrag = this.marginLeft;
    const marginType = !this.isVertical ? 'margin-left' : 'margin-top';
    this.renderer.setStyle(this.divSlides, marginType, this.marginLeft + 'px');
    // add contents
    let slide: any;

    // add previous slide
    if (this.currentSlide === 0 && !this.isInline) {
      slide = document.createElement('div');
      slide.className = 'dirslider_slide';
      slide.style.width = this.containerWidth.width + 'px';
      // append content into div
      if (this.isDebug) {
        console.log(
          'putContent cloneNode 1',
          this.originalContent[this.originalContent.length - 1]
        );
      }
      if (
        typeof this.originalContent[this.originalContent.length - 1] !==
        'undefined'
      ) {
        const clonedContent =
          this.originalContent[this.originalContent.length - 1].cloneNode(true);
        slide.appendChild(clonedContent);
        // push div into divSlide
        this.divSlides.appendChild(slide);
      }
    }

    // loop all accessible slides
    for (const content of this.originalContent) {
      slide = document.createElement('div');
      slide.className = 'dirslider_slide';
      const itemWidth = !this.isInline
        ? this.containerWidth.width
        : !this.isVertical
          ? 'initial'
          : this.containerWidth.width;
      // remove screen size wwhen inline slider horizontal
      if (itemWidth !== 'initial') {
        slide.style.width = itemWidth + 'px';
      }
      // prevent space between divs
      if (this.isInline && this.isVertical) {
        slide.style.verticalAlign = 'middle';
      }
      // append content into div
      slide.appendChild(content);
      // push div into divSlide
      this.divSlides.appendChild(slide);
    }

    // add next slide -> index 0
    if (this.currentSlide === this.nbSlides && !this.isInline) {
      slide = document.createElement('div');
      slide.className = 'dirslider_slide';
      slide.style.width = this.containerWidth.width + 'px';
      // append content into div
      // if (this.isDebug) { console.log('putContent cloneNode 2', this.originalContent[0]); }
      if (typeof this.originalContent[0] !== 'undefined') {
        const clonedContent = this.originalContent[0].cloneNode(true);
        slide.appendChild(clonedContent);
        // push div into divSlide
        this.divSlides.appendChild(slide);
      }
    }

    if (!this.isInline) {
      // >768 = 12 + 6 // <=768 = 16 + 6
      const bulletSize =
        innerWidth > 768
          ? (this.nbSlides + 1) * 18 - 6
          : (this.nbSlides + 1) * 22 - 6;
      this.isBullets =
        bulletSize > this.containerWidth.width
          ? false
          : !!this.sliderConfig.bullets
            ? this.sliderConfig.bullets
            : false;
    }

    // add bullets
    if (this.isBullets) {
      // create bullets
      this.divBullets = document.createElement('div');
      this.divBullets.className = 'dirslider_divBullets';
      let bulletDiv: any;
      for (let i = 0; i < this.originalContent.length; i++) {
        const classActive =
          i === this.currentSlide
            ? 'dirslider_bullet dirslider_activeBullet'
            : 'dirslider_bullet';
        bulletDiv = document.createElement('div');
        bulletDiv.id = i;
        bulletDiv.className = classActive;
        // push div into divBullets
        this.divBullets.appendChild(bulletDiv);
      }
      // push divSlide into parent
      this.parent.appendChild(this.divBullets);
    }
    // autoslide
    if (this.autoSlide && !this.userStopAutoSlider) {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.processAutoSlide();
      }, this.timer);
    }

    this.addArrows();

    if (this.isInline) {
      if (!!this.divSlides && !!this.containerWidth) {
        // particular case: when margin on mobile bigger than desktop
        this.marginLeftDrag =
          this.divSlides.offsetWidth -
            this.containerWidth.width +
            this.marginLeftDrag <
          0
            ? this.containerWidth.width - this.divSlides.offsetWidth
            : this.marginLeftDrag;
      }
    }

    if (this.isDebug) {
      console.log('removeStyle opacity = show ?');
    }
    this.renderer.removeStyle(this.parent, 'opacity');
  }

  addArrows() {
    // add arrows
    if (this.isArrows) {
      setTimeout(() => {
        if (typeof this.btLeft === 'undefined') {
          this.btLeft = document.createElement('div');
          this.btLeft.className = 'dirslider_btLeft';
          // this.btLeft.innerHTML = '<i class="icon ion-ios-arrow-back"></i>';
          // this.btLeft.innerHTML = '<';
          // push div into divSlide
          this.parent.appendChild(this.btLeft);
          const tmpTop = !this.isVertical
            ? this.divSlides.offsetHeight / 2 - this.btLeft.offsetHeight / 2
            : this.containerWidth.height / 2 - this.btLeft.offsetHeight / 2;
          this.renderer.setStyle(this.btLeft, 'top', tmpTop + 'px');
        }
        if (typeof this.btRight === 'undefined') {
          this.btRight = document.createElement('div');
          this.btRight.className = 'dirslider_btRight';
          // this.btRight.innerHTML = '<i class="icon ion-ios-arrow-forward"></i>';
          // this.btRight.innerHTML = '>';
          // push div into divSlide
          this.parent.appendChild(this.btRight);
          const tmpTop = !this.isVertical
            ? this.divSlides.offsetHeight / 2 - this.btRight.offsetHeight / 2
            : this.parent.offsetHeight / 2 - this.btRight.offsetHeight / 2;
          this.renderer.setStyle(this.btRight, 'top', tmpTop + 'px');
        }
        const opacity = this.focusSlider ? 1 : 0;
        this.renderer.setStyle(this.btLeft, 'opacity', opacity);
        this.renderer.setStyle(this.btRight, 'opacity', opacity);
      });
    } else {
      if (!!this.btLeft && !!this.parent) {
        if (this.btLeft.parentNode === this.parent) {
          this.parent.removeChild(this.btLeft);
          this.btLeft = undefined;
        }
      }
      if (!!this.btRight && !!this.parent) {
        if (this.btRight.parentNode === this.parent) {
          this.parent.removeChild(this.btRight);
          this.btRight = undefined;
        }
      }
    }
  }

  checkSlidable() {
    // this.containerWidth = this.parent.getBoundingClientRect();
    if (this.isInline && !this.isVertical) {
      let slidesWidth = this.inlineWidthItem * (this.nbSlides + 1);
      if (this.nbLines > 1) {
        slidesWidth = this.inlineWidthItem * Math.ceil((this.nbSlides + 1) / 2);
      }
      if (slidesWidth < this.containerWidth.width) {
        this.marginLeftDrag = 0;
        this.marginLeft = 0;
        this.isDraggable = false;
      } else {
        if (!this.isDraggable) {
          this.marginLeft = 0;
          this.marginLeftDrag = 0;
        }
        this.isDraggable = true;
      }
    }
    // remove arrows if not draggable
    this.isArrows = this.isDraggable ? true : false;
    this.addArrows();
  }

  waitForBoudingClientRect() {
    clearInterval(this.waitInterval);
    return new Promise(resolve => {
      this.waitInterval = setInterval(() => {
        // console.log('waitForBoudingClientRect');
        this.cptWaintInerval++;
        if (
          this.parent.getBoundingClientRect().width > 0 ||
          this.cptWaintInerval > 300
        ) {
          // console.log('waitForBoudingClientRect found');
          this.cptWaintInerval = 0;
          resolve(this.parent.getBoundingClientRect());
        }
      }, 40);
    });
  }

  async processResizeEvent() {
    clearTimeout(this.timeOut);
    this.containerWidth = await this.waitForBoudingClientRect();
    clearInterval(this.waitInterval);
    this.innerWidth = window.innerWidth;

    if (this.isDebug) {
      console.log(this.containerWidth);
    }

    // const widthParent = (!this.isVertical)
    //   ? this.containerWidth.width + 'px'
    //   : (!this.isFullImg)
    //     ? this.inlineWidthItem + 'px'
    //     : this.containerWidth.width + 'px';
    // this.renderer.setStyle(
    //   this.parent,
    //   'width',
    //   widthParent
    // );

    this.checkSlidable();

    if (this.isInline) {
      if (this.isVertical && !!this.divSlides && !!this.divSlides.children) {
        this.getAllContents(this.divSlides.children);
      }
      // particular case: when margin on mobile bigger than desktop
      if (!!this.divSlides && !!this.containerWidth) {
        this.marginLeftDrag =
          this.divSlides.offsetWidth -
            this.containerWidth.width +
            this.marginLeftDrag <
          0
            ? this.containerWidth.width - this.divSlides.offsetWidth
            : this.marginLeftDrag;
      }
    }
    // remove arrows if container < 250px
    this.isArrows =
      this.containerWidth.width <= 250 && !this.isVertical
        ? false
        : !!this.sliderConfig.arrows
          ? this.sliderConfig.arrows
          : false;

    // remove arrows if not draggable
    this.isArrows = this.isDraggable ? true : false;

    this.putContent();

    // re-position arrows
    let tmpTop: any;
    if (!!this.btLeft) {
      tmpTop = !this.isVertical
        ? this.divSlides.offsetHeight / 2 - this.btLeft.offsetHeight / 2 + 2
        : this.containerWidth.height / 2 - this.btLeft.offsetHeight / 2 + 2;
      this.renderer.setStyle(this.btLeft, 'top', tmpTop + 'px');
    }
    if (!!this.btRight) {
      tmpTop = !this.isVertical
        ? this.divSlides.offsetHeight / 2 - this.btRight.offsetHeight / 2 + 2
        : this.parent.offsetHeight / 2 - this.btRight.offsetHeight / 2 + 2;
      this.renderer.setStyle(this.btRight, 'top', tmpTop + 'px');
    }
  }

  // RESIZE EVENT
  @HostListener('window:resize', ['$event']) onResize(event) {
    // console.log('HostListener resize', event, this.waitInterval);
    this.processResizeEvent();
  }

  // KEYBOARD EVENT
  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    if (
      event.key === 'ArrowLeft' &&
      this.focusSlider &&
      !this.isAnimated &&
      this.isDraggable
    ) {
      this.processClickArrow(1);
    }
    if (
      event.key === 'ArrowRight' &&
      this.focusSlider &&
      !this.isAnimated &&
      this.isDraggable
    ) {
      this.processClickArrow(-1);
    }
  }

  // MOUSE EVENTS
  @HostListener('mouseover') onMouseOver() {
    this.focusSlider = true;
    if (this.isArrows && !!this.btLeft && !!this.btRight) {
      this.renderer.setStyle(this.btLeft, 'opacity', '1');
      this.renderer.setStyle(this.btRight, 'opacity', '1');
    }
  }
  @HostListener('mouseout') onMouseOut() {
    this.focusSlider = false;

    if (this.isArrows && !!this.btLeft && !!this.btRight) {
      this.renderer.setStyle(this.btLeft, 'opacity', '0');
      this.renderer.setStyle(this.btRight, 'opacity', '0');
    }
  }
  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    const target: any = event.target;
    this.utils.preventDefault(event);
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      !this.isAnimated
    ) {
      this.isClickBullet = true;
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else {
      if (!this.isAnimated && this.isDraggable) {
        this.processMouseDown(event);
      }
    }
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (!this.isAnimated && this.isDraggable) {
      this.processMouseMove(event);
    }
  }

  @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    const target: any = event.target;
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      this.isClickBullet &&
      !this.isAnimated
    ) {
      this.processClickBullet(target.id);
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(1);
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(-1);
    } else {
      this.processMouseUp(event);
    }
    this.isClickBullet = false;
    this.isClickArrow = false;
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
    this.processMouseUp(event);
  }

  // TOUCH EVENTS
  @HostListener('touchstart', ['$event']) onTouchStart(event) {
    const target: any = event.target;
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      !this.isAnimated
    ) {
      this.isClickBullet = true;
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      !this.isAnimated
    ) {
      this.isClickArrow = true;
    } else {
      if (!this.isAnimated) {
        this.processMouseDown(event);
      }
    }
  }
  @HostListener('touchmove', ['$event']) onTouchMove(event) {
    if (!this.isAnimated) {
      this.processMouseMove(event);
    }
  }
  @HostListener('touchend', ['$event']) onTouchEnd(event) {
    const target: any = event.target;
    if (
      (target.className === 'dirslider_bullet' ||
        target.className === 'dirslider_bullet dirslider_activeBullet') &&
      this.isClickBullet &&
      !this.isAnimated
    ) {
      this.processClickBullet(target.id);
    } else if (
      (target.className === 'dirslider_btLeft' ||
        target.className === 'fas fa-angle-left') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(1);
    } else if (
      (target.className === 'dirslider_btRight' ||
        target.className === 'fas fa-angle-right') &&
      this.isClickArrow &&
      !this.isAnimated
    ) {
      this.processClickArrow(-1);
    } else {
      this.processMouseUp(event);
    }
    this.isClickBullet = false;
    this.isClickArrow = false;
  }

  processMouseDown(event) {
    // remove transition during drag
    if (!!this.divSlides && !!this.parent) {
      this.divSlides.classList.add('dirslider_noTransition');
      this.posX = this.utils.unify(event).clientX - this.parent.offsetLeft;
      this.posY = this.utils.unify(event).clientY;
      this.isDrag = true;
      this.hasMoved = false;
    }
  }

  processMouseMove(event) {
    if (this.isDrag) {
      this.utils.preventDefault(event);
      const diffX =
        this.utils.unify(event).clientX - this.parent.offsetLeft - this.posX;
      const diffY = this.utils.unify(event).clientY - this.posY;
      const tmpDiffX = diffX < 0 ? diffX * -1 : diffX;
      const tmpDiffY = diffY < 0 ? diffY * -1 : diffY;
      // check if scroll or swipe
      if (tmpDiffX > tmpDiffY) {
        this.marginLeftDrag = this.marginLeft + diffX;
        if (this.isInline) {
          // can't swipe out of bounds
          this.marginLeftDrag =
            this.marginLeftDrag > 0 ? 0 : this.marginLeftDrag;
          this.marginLeftDrag =
            this.divSlides.offsetWidth -
              this.containerWidth.width +
              this.marginLeftDrag <
            0
              ? this.containerWidth.width - this.divSlides.offsetWidth
              : this.marginLeftDrag;
        }
        // can't swipe more than 1 screen
        if (!this.isInline) {
          if (this.marginLeftDrag < this.marginLeft) {
            if (
              (this.marginLeftDrag + this.marginLeft * -1) * -1 >
              this.containerWidth.width
            ) {
              this.marginLeftDrag = this.marginLeft - this.containerWidth.width;
            }
          } else {
            if (
              (this.marginLeft + this.marginLeftDrag * -1) * -1 >
              this.containerWidth.width
            ) {
              this.marginLeftDrag = this.marginLeft + this.containerWidth.width;
            }
          }
        }
        // move only if necessary
        if (this.marginLeftDrag !== this.marginLeft) {
          this.hasMoved = true;
          this.renderer.setStyle(
            this.divSlides,
            'margin-left',
            this.marginLeftDrag + 'px'
          );
        } else {
          this.hasMoved = false;
          this.canClick.emit(false);
        }
      } else {
        if (this.isInline && this.isVertical) {
          this.marginLeftDrag = this.marginLeft + diffY;
          // can't swipe out of bounds
          this.marginLeftDrag =
            this.marginLeftDrag > 0 ? 0 : this.marginLeftDrag;
          this.marginLeftDrag =
            this.divSlides.offsetHeight -
              this.containerWidth.height +
              this.marginLeftDrag <
            0
              ? this.containerWidth.height - this.divSlides.offsetHeight
              : this.marginLeftDrag;
          // move only if necessary
          if (this.marginLeftDrag !== this.marginLeft) {
            this.hasMoved = true;
            this.renderer.setStyle(
              this.divSlides,
              'margin-top',
              this.marginLeftDrag + 'px'
            );
          } else {
            this.hasMoved = false;
          }
        }
      }
    }
  }

  processMouseUp(event) {
    if (this.isDrag) {
      // add transition when drag finished
      this.divSlides.classList.remove('dirslider_noTransition');
      if (!this.isInline) {
        const diff =
          this.utils.unify(event).clientX - this.parent.offsetLeft - this.posX;
        const tmpDiff = diff < 0 ? diff * -1 : diff;
        if (tmpDiff <= this.containerWidth.width / 3 && !this.isInline) {
          this.isAnimated = true;
          // go back if drag not enough
          this.renderer.setStyle(
            this.divSlides,
            'margin-left',
            this.marginLeft + 'px'
          );
        } else {
          // user move = stop autoSlide
          clearTimeout(this.timeOut);
          this.userStopAutoSlider = true;
          // move prev/next
          if (diff < 0) {
            this.marginLeft = this.marginLeft - this.containerWidth.width;
          } else {
            this.marginLeft = this.marginLeft + this.containerWidth.width;
          }
          if (diff < 0) {
            this.currentSlide =
              this.currentSlide + 1 > this.nbSlides ? 0 : this.currentSlide + 1;
          } else {
            this.currentSlide =
              this.currentSlide - 1 < 0 ? this.nbSlides : this.currentSlide - 1;
          }
          this.renderer.setStyle(
            this.divSlides,
            'margin-left',
            this.marginLeft + 'px'
          );
        }
      }
      if (this.hasMoved === true) {
        if (this.isInline) {
          this.marginLeft = this.marginLeftDrag;
        }
        this.hasMoved = false;
        this.utils.preventDefault(event);
        // send parent user CANNOT handle clicks from element in slides
        this.canClick.emit(false);
      } else {
        this.marginLeftDrag = this.marginLeft;
        // send parent user CAN handle clicks from element in slides
        this.canClick.emit(true);
      }
      this.isDrag = false;
      if (!this.isInline) {
        setTimeout(() => {
          // remove transitions to reconstruct slider
          this.isAnimated = false;
          this.divSlides.classList.add('dirslider_noTransition');
          this.putContent();
        }, 400);
      }
    } else {
      this.canClick.emit(true);
    }
  }

  processClickBullet(index) {
    // user move = stop autoSlide
    clearTimeout(this.timeOut);
    this.userStopAutoSlider = true;
    // move to screen
    this.isAnimated = true;
    const nbDecalScreen =
      this.currentSlide === 0 ? Number(index) + 1 : Number(index);
    this.marginLeft = this.containerWidth.width * nbDecalScreen * -1;
    this.currentSlide = Number(index);
    this.renderer.setStyle(
      this.divSlides,
      'margin-left',
      this.marginLeft + 'px'
    );
    setTimeout(() => {
      // remove transitions to reconstruct slider
      this.isAnimated = false;
      this.divSlides.classList.add('dirslider_noTransition');
      this.putContent();
    }, 400);
  }

  processAutoSlide() {
    if (this.autoSlide && !this.userStopAutoSlider) {
      this.isAnimated = true;
      this.currentSlide =
        this.currentSlide + 1 > this.nbSlides ? 0 : this.currentSlide + 1;

      this.renderer.setStyle(
        this.divSlides,
        'margin-left',
        this.marginLeft - this.containerWidth.width + 'px'
      );

      setTimeout(() => {
        // remove transitions to reconstruct slider
        this.isAnimated = false;
        this.divSlides.classList.add('dirslider_noTransition');
        this.putContent();
      }, 400);
    } else {
      clearTimeout(this.timeOut);
    }
  }

  processClickArrow(sens) {
    // user move = stop autoSlide
    clearTimeout(this.timeOut);
    this.userStopAutoSlider = true;
    // process move
    this.isAnimated = true;
    if (sens > 0) {
      this.currentSlide =
        this.currentSlide - 1 >= 0 ? this.currentSlide - 1 : this.nbSlides;
    }
    if (sens < 0) {
      this.currentSlide =
        this.currentSlide + 1 > this.nbSlides ? 0 : this.currentSlide + 1;
    }

    this.containerWidth = this.parent.getBoundingClientRect();

    if (!this.isVertical) {
      this.marginLeftDrag = this.marginLeft + this.containerWidth.width * sens;
    } else {
      const decal =
        this.verticalLimit > 0
          ? this.verticalLimit
          : this.containerWidth.height;
      this.marginLeftDrag = this.marginLeft + decal * sens;
    }

    if (this.isInline) {
      // can't swipe out of bounds
      this.marginLeftDrag = this.marginLeftDrag > 0 ? 0 : this.marginLeftDrag;
      if (!this.isVertical) {
        this.marginLeftDrag =
          this.divSlides.offsetWidth -
            this.containerWidth.width +
            this.marginLeftDrag <
          0
            ? this.containerWidth.width - this.divSlides.offsetWidth
            : this.marginLeftDrag;
      } else {
        this.marginLeftDrag =
          this.divSlides.offsetHeight -
            this.containerWidth.height +
            this.marginLeftDrag <
          0
            ? this.containerWidth.height - this.divSlides.offsetHeight
            : this.marginLeftDrag;
      }
    }

    const marginType = !this.isVertical ? 'margin-left' : 'margin-top';
    this.renderer.setStyle(
      this.divSlides,
      marginType,
      this.marginLeftDrag + 'px'
    );

    if (!this.isInline) {
      setTimeout(() => {
        // remove transitions to reconstruct slider
        this.isAnimated = false;
        this.divSlides.classList.add('dirslider_noTransition');
        this.putContent();
      }, 400);
    } else {
      this.isAnimated = false;
      const nbDecalScreen =
        this.currentSlide === 0 ? this.currentSlide + 1 : this.currentSlide;
      this.marginLeft = !this.isInline
        ? this.containerWidth.width * nbDecalScreen * -1
        : this.marginLeftDrag;
      // make diff between real MarginLeft and dragged marginLeft - to go back to real if mouseup too soon
      this.marginLeftDrag = this.marginLeft;
    }
  }
}
