import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  HostListener,
} from '@angular/core';

import { NavController, Platform } from '@ionic/angular';

import { UtilsService } from '@commons/services';

import {
  AppInitService,
  StudentService,
  SeriesService,
  SessionsService,
  StorageService,
  LessonsService,
  BookletService,
  UserErrorHandlerService,
  GamificationService,
} from '@app/services';
import * as moment from 'moment-timezone';

import { Session } from '../../../app/models/session';
import { Serie } from '../../../app/models/serie';
import { Student } from '../../../app/models/student';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'stats-text-comp',
  templateUrl: 'stats-text.html',
  styleUrls: ['stats-text.scss'],
})
export class StatsTextComponent implements OnChanges {
  @Input() compOptions: any;

  debug = false;

  isCordova = false;

  showLogs = false;

  calledFrom = 'stats';
  showConduite = false;
  type = 'entrainements';
  swipeAction = '';

  componentFocused = true;

  nbAnswersRef = 40;

  student: Student;
  userLifeCycle: any;
  sessions: Array<Session> = [];
  series: Array<Serie> = [];
  fetchLessonFailed = false;
  fetchBookletFailed = false;

  isGuest = true;
  isRegistered = false;
  isEngaged = false;
  isCanDrive = false;

  customRadioOptions = [
    { value: 'Semaine', text: 'Semaine' },
    { value: 'Mois', text: 'Mois' },
    { value: 'Tout', text: 'Total' },
  ];
  timeSpan = ['Semaine', 'Mois', 'Tout'];
  selectedTimeSpan = 'Semaine';

  // Stats-text
  noStatsWeek = false;
  noStatsMonth = false;
  noStatsTotal = false;

  allStats = [];

  // conduite
  lessons: any;
  userCredits = 0;
  allTeachers = [];
  heuresFinishedTotalCount = 0;
  scheduledLesson = 0;
  scheduledHours = 0;
  acquiredSkills = 0;
  beingAcquiredSkills = 0;

  bookletCategory = [];
  booklets = [];
  bookletDatas = [];

  isLoading = true;

  isIpad = false;
  isDesktop = window.innerWidth > 767 ? true : false;

  seriesListFailed = false;
  getSessionsFailed = false;

  initServiceDone = false;
  initDataReady = false;
  initPageDone = false; // for shimmers
  fnInitPageLaunched = false; // for controllers

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = window.innerWidth > 767 ? true : false;
  }

  constructor(
    private navController: NavController,
    public platform: Platform,
    private appInitService: AppInitService,
    private studentService: StudentService,
    private seriesService: SeriesService,
    private sessionsService: SessionsService,
    private lessonsService: LessonsService,
    private bookletService: BookletService,
    private userErrorHandlerService: UserErrorHandlerService,
    private utils: UtilsService,
    private gamificationService: GamificationService
  ) {
    this.isCordova = this.platform.is('cordova');
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.appInitService.onStepChange().subscribe(state => {
      if (state?.init >= 2 && state?.stepDone && !this.fnInitPageLaunched) {
        this.fnInitPageLaunched = true;
        this.init();
      }
      if (state?.init >= 3 && state?.stepDone && !this.initDataReady) {
        this.initDataReady = true;
        this.init();
      }
      if (state?.initDone) {
        this.initDataReady = true;
        this.initServiceDone = true;
        this.init();
      }
    });
  }

  // MOUSE EVENTS
  @HostListener('mouseover') onMouseOver() {
    this.componentFocused = true;
  }
  @HostListener('mouseout') onMouseOut() {
    this.componentFocused = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.debug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.debug;
    this.calledFrom =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.calledFrom)
        ? this.compOptions.calledFrom
        : this.calledFrom;
    this.showConduite =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.conduite)
        ? this.compOptions.conduite
        : this.showConduite;
    this.type =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.type)
        ? this.compOptions.type
        : this.type;
    this.isIpad =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.isIpad)
        ? this.compOptions.isIpad
        : this.isIpad;
    // console.log('this.showConduite', this.showConduite);
    // console.log('isIpad', this.isIpad);
  }

  async init() {
    this.setGlobals();
    this.student = this.studentService.student;
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student);
    this.series = [];
    this.sessions = [];

    if (this.userLifeCycle.isUserRegistered) {
      this.isGuest = false;
      this.isRegistered = true;
      this.isEngaged = false;
      this.isCanDrive = false;
    } else if (this.userLifeCycle.isUserEngaged) {
      this.isGuest = false;
      this.isRegistered = false;
      this.isEngaged = true;
      this.isCanDrive = false;
    } else if (
      this.userLifeCycle.isUserCanDrive ||
      this.userLifeCycle.isUserDrivingSuccess
    ) {
      this.isGuest = false;
      this.isRegistered = false;
      this.isEngaged = false;
      this.isCanDrive = true;
    } else if (this.userLifeCycle.isFrozen) {
      this.isGuest = false;
      this.isRegistered = false;
      this.isEngaged = true;
      this.isCanDrive = false;
    }

    const canCallDrivingData =
      !this.userLifeCycle.isUserGuest &&
      this.showConduite &&
      !this.userLifeCycle.isUserGuest &&
      this.showConduite;
    if (
      (!this.isCordova && (this.initServiceDone || canCallDrivingData)) ||
      this.isCordova
    ) {
      if (!canCallDrivingData) {
        const resSeriesList =
          await this.seriesService.seriesContext.getSeriesList(this.student);
        if (
          !!resSeriesList &&
          !!resSeriesList.errorCode &&
          resSeriesList.errorCode === 'E301'
        ) {
          this.seriesListFailed = true;
        } else {
          this.series = resSeriesList;
        }
        if (!this.seriesListFailed) {
          this.series =
            !!this.series && this.series.length > 0
              ? this.series.filter(elt => elt.type !== 'Discovery')
              : [];
          const resgetSessions =
            await this.sessionsService.sessionsContext.getSessions(
              this.student
            );
          if (
            !!resgetSessions &&
            !!resgetSessions.errorCode &&
            resgetSessions.errorCode === 'E301'
          ) {
            this.sessions = [];
            this.getSessionsFailed = true;
            this.userErrorHandlerService.addError({
              criticity: 30,
              service: 'fetchSessions',
              platform: 'both',
              data: resgetSessions.errorOriginal,
              errorCode: 'ssfss',
            });
          } else {
            this.sessions = resgetSessions;
          }
          if (!this.getSessionsFailed) {
            this.sessions =
              !!this.sessions && this.sessions.length > 0
                ? this.sessions.filter(elt => elt.type !== 'Discovery')
                : [];
          }
        }
      }
      if (!!this.student) {
        if (
          !this.userLifeCycle.isUserGuest &&
          !this.showConduite &&
          !this.seriesListFailed &&
          !this.getSessionsFailed
        ) {
          this.calculateStatsTimeRange();
        } else if (canCallDrivingData) {
          this.fetchLessons();
        } else {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    }

    // console.log('student', this.student);
    // console.log('series', this.series);
    // console.log('sessions', this.sessions);
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  fetchLessons() {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();

    let AllLessons = [];
    this.allTeachers = [];

    const resConfirmedLessons = this.lessonsService.confirmedLessons;
    if (
      !!resConfirmedLessons &&
      !!resConfirmedLessons.errorCode &&
      resConfirmedLessons.errorCode === 'E301'
    ) {
      this.fetchLessonFailed = true;
    } else {
      AllLessons = resConfirmedLessons;
    }
    if (!this.fetchLessonFailed && AllLessons.length > 0) {
      // console.log('AllLessons', AllLessons);
      this.heuresFinishedTotalCount = 0;
      for (const lesson of AllLessons) {
        // console.log(today, new Date(lesson.starts_at), today > new Date(lesson.starts_at));
        // DATE TO CHANGE
        if (today.valueOf() > this.dateToFormatFr(lesson.starts_at).valueOf()) {
          this.heuresFinishedTotalCount += lesson.credits;
        }
        const alreadyIn = this.allTeachers.filter(
          elt => JSON.stringify(elt) === JSON.stringify(lesson.teacher)
        );
        if (alreadyIn.length === 0) {
          this.allTeachers.push(lesson.teacher);
        }
      }
    }
    this.fetchNextLessons();
  }

  fetchNextLessons() {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    // DATE TO CHANGE
    const startAtGe = this.dateToFormatFr(today).toISOString().split('T')[0]; // start date of currentWeek

    this.lessonsService
      .fetchNextLessonsByUserId(startAtGe, '', this.student.remoteId)
      .subscribe(
        res => {
          let nextLessons = [];
          if (!!res && !!res.errorCode && res.errorCode === 'E301') {
            this.fetchLessonFailed = true;
          } else {
            nextLessons = res;
          }
          if (!!nextLessons && !!nextLessons.length && nextLessons.length > 0) {
            this.scheduledLesson = 0;
            this.scheduledHours = 0;
            // console.log('nextLessons', nextLessons);
            for (const lesson of nextLessons) {
              this.scheduledLesson++;
              this.scheduledHours += lesson.credits;
            }
          }
          this.fetchBooklet();
        },
        err => {
          this.fetchBooklet();
          console.error('Stats-text fetchNextLessons: ' + JSON.stringify(err));
        }
      );
  }

  fetchBooklet() {
    const resFetchBooklet = this.bookletService.booklets;
    if (
      !!resFetchBooklet &&
      !!resFetchBooklet.errorCode &&
      resFetchBooklet.errorCode === 'E301'
    ) {
      this.booklets = null;
      this.fetchBookletFailed = true;
    } else {
      this.booklets = resFetchBooklet;
    }

    if (
      !this.fetchBookletFailed &&
      !!this.booklets &&
      !!this.booklets.length &&
      this.booklets.length > 0
    ) {
      // sort by category
      this.booklets.sort((a, b) =>
        a.category > b.category ? 1 : b.category > a.category ? -1 : 0
      );

      let catName = '';
      let nbByCat = 0;
      this.bookletCategory = []; // reset

      for (const cat of this.booklets) {
        if (catName === '') {
          catName = cat.category;
        }
        if (catName === cat.category) {
          nbByCat++;
        } else {
          this.bookletCategory.push({
            title: catName,
            maxNote: nbByCat,
          });
          catName = cat.category;
          nbByCat = 0;
          nbByCat++;
        }
      }
      const bookletsNotEmpty = this.booklets.filter(
        elt => elt.evaluation !== null
      );

      let nbA = 0; // Acquis
      let nbnil = 0; // Non évoqué
      let nbEVA = 0; // En voie d'Acquisition

      for (const cat of this.bookletCategory) {
        const currentCategory = this.booklets.filter(
          elt => elt.category === cat.title
        );
        for (const evaluation of currentCategory) {
          if (evaluation.evaluation === 'A') {
            nbA++;
          }
          if (evaluation.evaluation === 'nil') {
            nbnil++;
          }
          if (evaluation.evaluation === 'EVA') {
            nbEVA++;
          }
        }
      }
      this.acquiredSkills = nbA;
      this.beingAcquiredSkills = nbEVA;
    }
    this.userCredits =
      (this.student?.creditStocks?.manualDriving?.quantity || 0) +
      (this.student?.creditStocks?.automaticDriving?.quantity || 0);
    this.isLoading = false;
  }

  calculateStatsTimeRange() {
    this.allStats = [];
    for (const t of this.timeSpan) {
      this.allStats[t] = {};
      this.allStats[t].rangedSessions = this.sessionsService.addRangedSession(
        this.sessions,
        t
      );

      if (this.allStats[t].rangedSessions.length > 0) {
        const stateRes = this.sessionsService.stateStats(
          this.allStats[t].rangedSessions
        );
        const sortedRangedSessions = this.allStats[t].rangedSessions.sort(
          (a, b) => {
            // DATE TO CHANGE
            return +new Date(b.lastAnsweredAt) - +new Date(a.lastAnsweredAt);
          }
        );
        this.allStats[t].firstSession =
          sortedRangedSessions[sortedRangedSessions.length - 1].lastAnsweredAt;

        const totalRes = this.sessionsService.totalStats(
          stateRes,
          this.series,
          this.allStats[t].rangedSessions
        );
        this.allStats[t].answeredQuestionsTotal = totalRes.answeredTotal;
        this.allStats[t].entrainements =
          totalRes.finishedSeries - totalRes.examTotal;
        this.allStats[t].examens = totalRes.examTotal;
        this.allStats[t].moyenne = this.sessionsService.getAverageScore(
          this.allStats[t].rangedSessions
        );
        this.allStats[t].best = this.sessionsService.getBestScore(
          this.allStats[t].rangedSessions
        );
        const streak = this.gamificationService.streakDays(t);
        const isPlurial = Number(streak) > 1 ? 's' : '';
        this.allStats[t].streak = streak + ' jour' + isPlurial;
      } else {
        if (t === 'Semaine') {
          this.noStatsWeek = true;
        } else if (t === 'Mois') {
          this.noStatsMonth = true;
        } else if (t === 'Tout') {
          this.noStatsTotal = true;
        }
      }
    }
    if (
      this.allStats['Tout'].rangedSessions.length > 0 &&
      !!this.allStats['Tout'].seriesFinishedTotalCount
    ) {
      this.noStatsTotal =
        this.allStats['Tout'].seriesFinishedTotalCount === 0 ? true : false;
    }
    this.isLoading = false;
  }

  launchSerie() {
    this.sessionsService
      .resumeOrCreateSession(this.student, { mode: 'Training' })
      .subscribe(
        session => {
          const question = session.answersCount || 0;
          this.openLink(
            'series/entrainements/' +
              session.serieId +
              '/buffer/' +
              question +
              '/' +
              session.id
          );
        },
        err => {
          console.error(
            'Stats-text resumeOrCreationSession: ' + JSON.stringify(err)
          );
        }
      );
  }

  getCustomRadioSelection(event) {
    if (!!event) {
      this.selectedTimeSpan = event;
    }
  }

  openLink(link) {
    if (this.utils.isDefined(link)) {
      this.navController.navigateForward(link);
    } else {
      this.navController.back();
    }
  }
}
