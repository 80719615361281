import { EvsRecord } from './evsRecord';

export interface IBadge {
  id: string;
  name: string;
  title: string;
  subtitle: string;
  description: string;
  category: { id: number; text: string; classId: string };
  obtained_at: string;
  premium: boolean;
  cta: string;
  cta_path: string;
  position: number;
  group_name: string;
  group_position: number;
  displayable?: boolean;
}

export class BadgeModel extends EvsRecord implements IBadge {
  id: string;
  name: string;
  title: string;
  subtitle: string;
  description: string;
  category: { id: number; text: string; classId: string };
  obtained_at: string;
  premium: boolean;
  cta: string;
  cta_path: string;
  position: number;
  group_name: string;
  group_position: number;
  displayable?: boolean;

  constructor(obj: IBadge) {
    super(obj);
  }
}
