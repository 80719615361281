import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable()
export class CameFromRouteService {
  private cameFromUrl: string | null = null;
  private currentUrl: string | null = null;
  private origin: string | null = null;

  constructor(private readonly router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          if (!this.origin) {
            this.origin = event.url;
          }

          this.cameFromUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      });
  }

  getCameFromUrl(): string | null {
    return this.cameFromUrl;
  }

  getCurrentUrl(): string | null {
    return this.currentUrl;
  }

  getOrigin(): string | null {
    return this.origin;
  }
}
