<div
  class="{{!!safariFix ? 'evs-card-ui' : 'evs-card'}}{{!!type ? ' evs-card-' + type : ''}}"
  [ngClass]="{'evs-card-locked': locked}"
  (click)="handleClick()"
  #evsCard>
  <!-- locked -->
  <div class="icon-locked position-absolute top-10 right-10" *ngIf="locked">
    <svg width="16" height="16">
      <use xlink:href="" attr.xlink:href="{{iconPath + 'lock-1'}}"></use>
    </svg>
  </div>
  <!-- content -->
  <div [ngClass]="{'card-content': !nested, 'card-nested-content': nested}">
    <ng-content></ng-content>
  </div>
</div>
