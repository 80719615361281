import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Observable, of, Subject } from 'rxjs';
import { TokenService, EvsToken } from './token.service';
import { Platform } from '@ionic/angular';
import { switchMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class SiteOpener {
  private _linkList: any;

  private codeTargetsBlank = `var targets = document.getElementsByTagName("a");
                              if (typeof targets != 'undefined' && targets.length > 0) {
                                for (var i = 0; i < targets.length; i++) {
                                  if (targets[i].getAttribute("target") === "_blank") {
                                    targets[i].setAttribute("target", "_self");
                                  }
                                }
                              }
                            `;

  private codeIosToAdd = `var toHide = document.getElementsByClassName("toHide")


                          if (typeof toHide != 'undefined' && toHide.length > 0) {
                            for (var i = 0; i < toHide.length; i++) {
                              toHide[i].style.display = 'none'
                            }
                          }
                          `;

  private codeToSend = `sessionStorage.setItem('siteOpener', 'true')`;

  private iabOptions =
    'location=no,toolbar=yes,closebuttoncaption=Retour,zoom=no,clearcache=yes';
  private browserInstance: InAppBrowserObject;

  iabUrl: string;

  return: Subject<any> = new Subject<any>();
  return$ = this.return.asObservable();

  siteOpenerClosed = false;

  whiteList: string[] = [];

  constructor(
    private tokenService: TokenService,
    public iab: InAppBrowser,
    public platform: Platform,
    private authService: AuthService
  ) {}

  async open(
    linkName: string,
    student: any,
    options: any = null
  ): Promise<any> {
    if (!!linkName && !!student) {
      if (!!options && !!options.forceOutLink) {
        this.openHomePage(linkName, options);
        return `opened ${linkName}`;
      }
      const isSignedIn = await this.authService.isSignedInHard();
      if (!!isSignedIn && !!isSignedIn.errorMessage) {
        this.openHomePage(linkName, options);
        if (isSignedIn.errorCode === 'E201') {
          return {
            errorCode: 'E202',
            errorMessage: 'Open Site Opener Service isSigned check failed',
            errorOriginal: null,
          };
        } else {
          return isSignedIn;
        }
      }
      if (!!isSignedIn) {
        const path = this.fillPath(linkName, options);
        if (!!path && !!path.errorMessage) {
          return path;
        }
        if (!!path) {
          if (this.checkWhiteList(path)) {
            this.openHomePage(linkName, options);
            return `opened ${linkName}`;
          }
          return this.openSecuredPath(
            path,
            student,
            !!options ? options : null,
            linkName
          ).toPromise();
        } else {
          return {
            errorCode: 'E101',
            errorMessage: 'Open Site Opener Service missing path',
            errorOriginal: null,
          };
        }
      } else {
        this.openHomePage(linkName, options);
        return {
          errorCode: 'E202',
          errorMessage: 'Open Site Opener Service isSigned check failed',
          errorOriginal: null,
        };
      }
    }
    return of({
      errorCode: 'E101',
      errorMessage: 'Open Site Opener Service missing linkName or student',
      errorOriginal: null,
    });
  }

  openSecuredPath(
    path: string,
    student: any,
    option: any,
    linkName: string
  ): Observable<any> {
    if (!!path && !!student) {
      return this.tokenService.getTokenForSiteOpen(path, student).pipe(
        switchMap(async token => {
          if (!!token && !!token.errorMessage) {
            return token;
          }
          if (!!token) {
            return this.openSecuredBrowser(
              path,
              student,
              token,
              option,
              linkName
            );
          }
          return {
            errorCode: 'E101',
            errorMessage: 'Open Secure Path Site Opener Service missing token',
            errorOriginal: null,
          };
        })
      );
    }
    return of({
      errorCode: 'E101',
      errorMessage:
        'Open Secure Path Site Opener Service missing path or student',
      errorOriginal: null,
    });
  }

  async getTokenForSiteOpenPromise(
    path: string,
    student: any
  ): Promise<EvsToken | any> {
    return this.tokenService
      .getTokenForSiteOpen(path, student)
      .pipe(
        switchMap(async data => {
          if (!!data) {
            return data;
          }
          return {
            errorCode: 'E101',
            errorMessage: 'Open Secure Path Site Opener Service missing data',
            errorOriginal: null,
          };
        }),
        catchError(async err => {
          return {
            errorCode: 'E101',
            errorMessage:
              'Get Token For Site Open Promise Site Opener Service error catched',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  checkWhiteList(path) {
    if (!!path) {
      for (const url of this.whiteList) {
        if (path.includes(url)) {
          return true;
        }
      }
    }
    return false;
  }

  async getSecuredUrl(
    linkName: string,
    student: any,
    options: any = null
  ): Promise<any> {
    const path = this.fillPath(linkName, options);

    if (!!path && !!path.errorMessage) {
      return path;
    }

    let url = '';

    if (!!linkName && !!this._linkList && !!this._linkList[linkName]) {
      if (
        !!this._linkList[linkName].base &&
        !!this._linkList[this._linkList[linkName].base]
      ) {
        url = `${this._linkList[this._linkList[linkName].base]}${this._linkList[linkName].path}`;
      } else if (!!this._linkList[linkName].path) {
        url = `${this._linkList[linkName].path}`;
      } else {
        return {
          errorCode: 'E101',
          errorMessage:
            'Get Secured Url Site Opener Service missing base or path in linkList',
          errorOriginal: null,
        };
      }
    } else {
      return {
        errorCode: 'E101',
        errorMessage:
          'Get Secured Url Site Opener Service missing linkName, linkList or other related data',
        errorOriginal: null,
      };
    }

    if (this.checkWhiteList(path)) {
      return url;
    }

    let isSignedIn = await this.authService.isSignedInHard();
    if (!!isSignedIn && !!isSignedIn.errorCode) {
      isSignedIn = null;
    }

    if (!!isSignedIn && !!student && !!path) {
      const token = await this.getTokenForSiteOpenPromise(path, student);
      if (!!token && !!token.errorMessage) {
        return token;
      }
      if (!!this._linkList[linkName].base) {
        if (this._linkList[this._linkList[linkName].base].includes('api/v1')) {
          url = `${this._linkList[this._linkList[linkName].base]}/session/new?`;
        } else {
          url = `${this._linkList[this._linkList[linkName].base]}/api/v1/session/new?`;
        }
      }

      if (!!token) {
        const uid: string = !!student.remoteId ? student.remoteId : null;
        const clientId: string = !!student.remoteId ? student.remoteId : null;
        const createdAt: string = !!token.created_at ? token.created_at : null;
        const encodedPath: string = encodeURIComponent(path);
        const hmacToken: string = !!token.token ? token.token : null;

        if (
          !!uid &&
          !!clientId &&
          !!createdAt &&
          !!encodedPath &&
          !!hmacToken
        ) {
          url = `${url}token=${hmacToken}&uid=${uid}&client_id=${clientId}&path=${encodedPath}&created_at=${createdAt}`;
        } else {
          return {
            errorCode: 'E101',
            errorMessage:
              'Get Secured Url Site Opener Service missing token related data',
            errorOriginal: null,
          };
        }
        return url;
      }
      return {
        errorCode: 'E101',
        errorMessage: 'Get Secured Url Site Opener Service missing token',
        errorOriginal: null,
      };
    } else {
      return url;
    }
  }

  openSecuredBrowser(
    path: string,
    student: any,
    token: EvsToken,
    option: any,
    linkName: string
  ): Observable<any> {
    if (!!student && !!token && !!linkName && !!path) {
      let url = '';
      if (this._linkList[linkName].base === '') {
        url = path;
      } else {
        if (this._linkList[this._linkList[linkName].base].includes('api/v1')) {
          url = `${this._linkList[this._linkList[linkName].base]}/session/new?`;
        } else {
          url = `${this._linkList[this._linkList[linkName].base]}/api/v1/session/new?`;
        }
      }

      if (this._linkList[linkName].base === '') {
        url = path;

        if (!!option && !!option.teacherApp && option.teacherApp) {
          return of(url);
        } else {
          if (this.platform.is('cordova')) {
            return this.handleWithCordova(url, option);
          } else {
            return this.handleWithoutCordova(url, option);
          }
        }
      } else {
        const uid: string = !!student.remoteId ? student.remoteId : null;
        const clientId: string = !!student.remoteId ? student.remoteId : null;
        const createdAt: string = !!token.created_at ? token.created_at : null;
        const encodedPath: string = encodeURIComponent(path);
        const hmacToken: string = !!token.token ? token.token : null;

        if (
          !!uid &&
          !!clientId &&
          !!createdAt &&
          !!encodedPath &&
          !!hmacToken
        ) {
          url = `${url}token=${hmacToken}&uid=${uid}&client_id=${clientId}&path=${encodedPath}&created_at=${createdAt}`;
        } else {
          return of({
            errorCode: 'E101',
            errorMessage:
              'Open Secured Browser Site Opener Service missing token related data',
            errorOriginal: null,
          });
        }

        if (!!option && !!option.teacherApp && option.teacherApp) {
          return of(url);
        } else {
          if (this.platform.is('cordova')) {
            return this.handleWithCordova(url, option);
          } else {
            return this.handleWithoutCordova(url, option);
          }
        }
      }
    } else {
      return of({
        errorCode: 'E101',
        errorMessage:
          'Open Secured Browser Site Opener Service missing student, token, linkName or path',
        errorOriginal: null,
      });
    }
  }

  handleWithoutCordova(url: string, option: any): Observable<any> {
    if (!!url) {
      if (url.lastIndexOf('delete_session') >= 0) {
        return this.signOutRedirect(url);
      } else {
        if (!!option && !!option.target && option.target === '_system') {
          this.browserInstance = this.iab.create(
            url,
            option.target,
            this.iabOptions
          );
          return of('opened in system web browser');
        } else {
          this.redirect(url);
          return of('opened in the same tab');
        }
      }
    } else {
      return of({
        errorCode: 'E101',
        errorMessage: 'Handle Without Cordova Site Opener Service missing url',
        errorOriginal: null,
      });
    }
  }

  handleWithCordova(url: string, option: any): Observable<any> {
    if (!!url) {
      if (url.lastIndexOf('delete_session') === -1) {
        return this.handleAppIAB(url, option);
      } else if (url.lastIndexOf('delete_session') >= 0) {
        return this.tokenService.signOut().pipe(
          switchMap(async () => {
            return 'signed out';
          }),
          catchError(async err => {
            return {
              errorCode: 'E201',
              errorMessage: 'Handle With Cordova Site Opener error catched',
              errorOriginal: err,
            };
          })
        );
      }
    } else {
      return of({
        errorCode: 'E101',
        errorMessage: 'Handle With Cordova Site Opener missing url',
        errorOriginal: null,
      });
    }
  }

  handleAppIAB(url: string, option: any): Observable<any> {
    if (!!url) {
      if (url.substring(url.length - 1) != '/') {
        url = `${url}&siteopener=true`;
      }
      if (!!option && !!option.target) {
        this.browserInstance = this.iab.create(
          url,
          option.target,
          this.iabOptions
        );
      } else {
        this.browserInstance = this.iab.create(url, '_blank', this.iabOptions);
      }
      if (!option || !option.target || option.target === '_blank') {
        this.handleCodeIAB();
      }
      return of('opened in app browser');
    }
    return of({
      errorCode: 'E101',
      errorMessage: 'Handle App IAB Site Opener Service missing url',
      errorOriginal: null,
    });
  }

  async urlLoop(): Promise<any> {
    if (!!this.browserInstance) {
      return this.browserInstance
        .executeScript({
          code: `sessionStorage.getItem('url')`,
        })
        .then(value => {
          if (!!value && value.length > 0 && !!value[0]) {
            this.iabUrl = value[0];
            return null;
          }
          return {
            errorCode: 'E101',
            errorMessage: 'Url Loop Site Opener Service missing value[0]',
            errorOriginal: null,
          };
        })
        .catch(err => {
          return {
            errorCode: 'E203',
            errorMessage: 'Url Loop Site Opener Service promise failed',
            errorOriginal: err,
          };
        });
    }
    return new Promise(resolve =>
      resolve({
        errorCode: 'E101',
        errorMessage: 'Url Loop Site Opener Service missing browserInstance',
        errorOriginal: null,
      })
    );
  }

  async doneLoop(
    buttonInterval: any,
    doneInterval: any,
    urlInterval: any
  ): Promise<any> {
    if (
      !!this.browserInstance &&
      !!buttonInterval &&
      !!doneInterval &&
      urlInterval
    ) {
      return this.browserInstance
        .executeScript({
          code: `sessionStorage.getItem('done')`,
        })
        .then(value => {
          if (!!value && value.length > 0 && !!value[0]) {
            if (this.siteOpenerClosed) {
              return null;
            }
            if (value[0] === 'true') {
              clearInterval(buttonInterval);
              clearInterval(doneInterval);
              clearInterval(urlInterval);
              this.return.next({
                close: true,
                link: this.iabUrl,
                error: false,
                textError: null,
              });
              this.browserInstance.close();
              this.siteOpenerClosed = true;
              return null;
            }
            return {
              errorCode: 'E202',
              errorMessage: 'Done Loop Site Opener Service check failed',
              errorOriginal: null,
            };
          }
          return {
            errorCode: 'E101',
            errorMessage: 'Done Loop Site Opener Service missing value[0]',
            errorOriginal: null,
          };
        })
        .catch(err => {
          return {
            errorCode: 'E203',
            errorMessage: 'Done Loop Site Opener Service promise failed',
            errorOriginal: err,
          };
        });
    }
    return new Promise(resolve =>
      resolve({
        errorCode: 'E101',
        errorMessage:
          'Done Loop Site Opener Service missing browserInstance or an interval is missing',
        errorOriginal: null,
      })
    );
  }

  async closeLoop(closeInterval: any): Promise<any> {
    if (!!closeInterval && !!this.browserInstance) {
      return this.browserInstance
        .executeScript({
          code: `sessionStorage.getItem('close')`,
        })
        .then(value => {
          if (!!value && value.length > 0 && !!value[0]) {
            const args = value[0].split('+');
            if (args[0] === 'true') {
              clearInterval(closeInterval);
              return this.browserInstance
                .executeScript({
                  code: `window.location.href`,
                })
                .then(res => {
                  if (!!res && res.length > 0 && !!res[0]) {
                    const end = res[0]
                      .replace(`${this.linkList.base}/`, '')
                      .split('/');
                    this.return.next({
                      pathEnd: end,
                      path: args[1],
                      link: this.iabUrl,
                      error: false,
                      textError: null,
                    });
                    this.browserInstance.close();
                    return null;
                  }
                  return {
                    errorCode: 'E101',
                    errorMessage:
                      'Close Loop Site Opener Service missing res[0]',
                    errorOriginal: null,
                  };
                })
                .catch(err => {
                  return {
                    errorCode: 'E203',
                    errorMessage:
                      'Close Loop Site Opener Service promise failed (1)',
                    errorOriginal: err,
                  };
                });
            }
            return {
              errorCode: 'E202',
              errorMessage: 'Close Loop Site Opener Service check failed',
              errorOriginal: null,
            };
          }
          return {
            errorCode: 'E101',
            errorMessage: 'Close Loop Site Opener Service missing value[0]',
            errorOriginal: null,
          };
        })
        .catch(err => {
          return {
            errorCode: 'E101',
            errorMessage: 'Close Loop Site Opener Service promise failed (2)',
            errorOriginal: err,
          };
        });
    }
    return new Promise(resolve =>
      resolve({
        errorCode: 'E101',
        errorMessage: 'Handle Response Service missing data in parsedData, ->',
        errorOriginal: null,
      })
    );
  }

  ///////////BLOQUÉÉÉÉÉÉÉÉÉÉÉÉ/////////////

  handleCodeIAB(): Observable<any> {
    this.siteOpenerClosed = false;
    let globalError = null;
    if (!!this.browserInstance) {
      this.browserInstance.on('loadstart').subscribe(() => {
        const handleButtonLoop = setInterval(() => {
          let codeLines = '';
          if (this.platform.is('ios')) {
            codeLines = `${this.codeIosToAdd}\n${this.codeTargetsBlank}\n${this.codeToSend}`;
          } else {
            codeLines = `${this.codeToSend}\n${this.codeTargetsBlank}`;
          }
          this.browserInstance.executeScript({ code: codeLines }).catch(err => {
            globalError = {
              errorCode: 'E203',
              errorMessage:
                'Handle Code IAB Site Opener Service promise failed',
              errorOriginal: err,
            };
          });
        }, 100);

        const urlLoop = setInterval(() => {
          this.urlLoop();
        }, 100);

        const doneLoop = setInterval(() => {
          this.doneLoop(handleButtonLoop, doneLoop, urlLoop);
        }, 100);

        const closeLoop = setInterval(() => {
          this.closeLoop(closeLoop);
        }, 100);
      });

      this.browserInstance.on('loaderror').pipe(
        switchMap(arg => {
          if (!!arg && !!arg.code && arg.code === -999) {
            return null;
          } else {
            this.return.next({
              close: false,
              error: true,
              link: this.iabUrl,
              textError: 'An error occured while trying to open ',
              additionalData: arg,
            });
            this.browserInstance.close();
          }
        })
      );

      this.browserInstance.on('exit').subscribe(arg => {
        this.return.next({
          close: true,
          link: null,
          error: false,
          textError: null,
          additionalData: null,
        });
        this.browserInstance.close();
      });
    } else {
      return of({
        errorCode: 'E101',
        errorMessage:
          'Handle Code IAB Site Opener Service missing browserInstance',
        errorOriginal: null,
      });
    }
  }

  openHomePage(linkName: string, option: any): Promise<any> {
    let mode = '_blank';
    let url = '';

    if (!!linkName) {
      if (!!option && !!option.forceOutLink) {
        url = linkName;
      } else {
        if (!!this._linkList && !!this._linkList[linkName]) {
          if (!!this._linkList[linkName].path) {
            if (
              !!this._linkList[linkName].base &&
              !!this._linkList[this._linkList[linkName].base]
            ) {
              url = `${this._linkList[this._linkList[linkName].base]}${this._linkList[linkName].path}`;
            } else {
              url = `${this._linkList[linkName].path}`;
            }
          } else {
            return of({
              errorCode: 'E101',
              errorMessage:
                'Open Home Page Site Opener Service missing path or base in linkList',
              errorOriginal: null,
            }).toPromise();
          }
        } else {
          return of({
            errorCode: 'E101',
            errorMessage:
              'Open Home Page Site Opener Service missing linkList or related data',
            errorOriginal: null,
          }).toPromise();
        }
      }
    } else {
      return of({
        errorCode: 'E101',
        errorMessage: 'Open Home Page Site Opener Service missing linkName',
        errorOriginal: null,
      }).toPromise();
    }

    if (!!option && !!option.target) {
      mode = option.target;
    }

    if (!!option) {
      for (const key of Object.keys(option)) {
        if (url.includes(key)) {
          url = url.replace(key, option[key]);
        }
      }
    }

    if (!!option && !!option.target && option.target === '_system') {
      this.browserInstance = this.iab.create(url, mode, this.iabOptions);
    } else if (this.platform.is('cordova')) {
      return this.handleWithCordova(url, option).toPromise();
    } else {
      return of(this.redirect(url)).toPromise();
    }
  }

  signOutRedirect(url: string): Observable<any> {
    return this.tokenService.signOut().pipe(
      switchMap(async () => {
        this.redirect(url);
        return 'signed out';
      }),
      catchError(async err => {
        return {
          errorCode: 'E201',
          errorMessage: 'Sign Out Reditect Site Opener Service error catched',
          errorOriginal: err,
        };
      })
    );
  }

  fillPath(linkName: string, options: any): any {
    let path = '';

    if (
      !!linkName &&
      !!this._linkList &&
      !!this._linkList[linkName] &&
      !!this._linkList[linkName].path
    ) {
      if (!!this._linkList[linkName].base) {
        path = `${this._linkList[this._linkList[linkName].base]}${this._linkList[linkName].path}`;
      } else {
        path = `${this._linkList[linkName].path}`;
      }
    } else {
      return {
        errorCode: 'E101',
        errorMessage:
          'Fill Path Site Opener Service missing linkName or related data',
        errorOriginal: null,
      };
    }

    if (!!options) {
      for (const key of Object.keys(options)) {
        if (path.includes(key)) {
          path = path.replace(key, options[key]);
        }
      }
    }
    return path;
  }

  redirect(url: string): any {
    if (!!url) {
      window.location.assign(url);
      return null;
    }
    return {
      errorCode: 'E101',
      errorMessage: 'Redirect Site Opener Service missing url',
      errorOriginal: null,
    };
  }

  public get linkList() {
    return this._linkList;
  }
  public set linkList(value) {
    this._linkList = value;
  }
}
