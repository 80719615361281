/* tslint:disable:member-ordering */
import {
  Directive,
  ElementRef,
  HostListener,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  AfterViewInit,
} from '@angular/core';

// SERVICES
import { UtilsService } from '@commons/services';

@Directive({
  selector: '[appScrollerInApp]',
})
export class ScrollerInAppDirective
  implements OnInit, OnChanges, AfterViewInit
{
  @Input('appScrollerInApp') dirOptions: any;
  @Input() content: any;

  parent: any;
  isDebug = false;
  hideClassName = 'evs_scrollerHide';
  position = 'bottom';
  parentHeight = 0;
  parentContainer = null;
  inverted = false;
  absolute = false;

  contentScrollTop = null;
  scrollValueProcessed = false;
  scrollDirection = '';

  compensateElt = null;
  doCompensateElt = true;
  previousHeight = null;
  myTimeOut = null;

  retry = 0;
  isMobile = false;
  hasSwipe = false;
  mouseY: any;
  diffY: any;

  @HostListener('window:resize', ['$event']) onResize(event) {
    // check si ça existe encore en v4
    // if (this.utils.isDefined(this.content)) {
    //   this.content.resize();
    // }
    setTimeout(() => {
      this.applyScrollToElt();
    });
  }

  constructor(
    private el: ElementRef,
    private utils: UtilsService,
    private renderer: Renderer2
  ) {
    this.parent = this.el.nativeElement as HTMLElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes - status launch false to true
    const dirOptions: SimpleChange = changes.dirOptions;
    if (!!dirOptions) {
      this.dirOptions = dirOptions.currentValue;
      this.setGlobals();
    }
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.utils.isDefined(this.content)) {
      // bottom = close by default
      if (this.position === 'top' && !this.inverted) {
        this.renderer.addClass(this.parent, this.hideClassName);
      }
      // if (this.position === 'bottom' && this.inverted) {
      //   this.renderer.addClass(
      //     this.parent,
      //     this.hideClassName
      //   );
      // }
      // apply padding to compensate sticky
      this.compensateSticky();

      // listen for scroll events
      if (this.utils.isDefined(this.parentContainer)) {
        this.utils.addListenerMulti(this.parentContainer, 'scroll', () => {
          if (!this.isMobile && !this.hasSwipe) {
            this.setScrollDirection(this.parentContainer.scrollTop);
            this.applyScrollToElt();
            this.checkConditions();
          }
        });
      } else {
        this.content.ionScroll.subscribe(event => {
          if (!!event) {
            if (!this.isMobile && !this.hasSwipe) {
              this.setScrollDirection(event.detail.scrollTop);
              this.applyScrollToElt();
              this.checkConditions();
            }
          }
        });
        this.content.ionScrollEnd.subscribe(event => {
          if (!!event) {
            if (!this.isMobile && !this.hasSwipe) {
              this.contentScrollTop = event.detail.scrollTop;
              this.scrollValueProcessed = false;
              this.applyScrollToElt();
              this.checkConditions();
            }
          }
        });
      }
    } else {
      if (this.retry < 3) {
        setTimeout(() => {
          this.retry++;
          this.init();
        }, 100);
      } else {
        console.error('Directive AppScroller: Content undefined!');
      }
    }
  }

  ngAfterViewInit() {
    // show sticky when all finished
    setTimeout(() => {
      this.renderer.setStyle(this.parent, 'opacity', 1);
      this.renderer.setStyle(this.parent, 'transform', 'translateY(0vh)');
      setTimeout(() => {
        // fix wrong parent height
        this.scrollDirection = 'up';
        this.updateCompensate();
        this.applyScrollToElt();
      });
    });
  }

  setScrollDirection(scrollValue) {
    if (this.utils.isDefined(this.contentScrollTop)) {
      this.scrollDirection =
        scrollValue > this.contentScrollTop ? 'down' : 'up';
    }
    this.scrollValueProcessed = true;
    this.contentScrollTop = scrollValue;
  }

  setGlobals() {
    this.isDebug = this.utils.isDefined(this.dirOptions.debug)
      ? this.dirOptions.debug
      : this.isDebug;
    this.position = this.utils.isDefined(this.dirOptions.position)
      ? this.dirOptions.position
      : this.position;
    this.parentContainer = this.utils.isDefined(this.dirOptions.parent)
      ? this.dirOptions.parent
      : this.parentContainer;
    this.hideClassName = this.utils.isDefined(this.dirOptions.class)
      ? this.dirOptions.class
      : this.hideClassName;
    this.inverted = this.utils.isDefined(this.dirOptions.inverted)
      ? this.dirOptions.inverted
      : this.inverted;
    this.absolute = this.utils.isDefined(this.dirOptions.absolute)
      ? this.dirOptions.absolute
      : this.absolute;
    this.doCompensateElt = this.utils.isDefined(this.dirOptions.doCompensateElt)
      ? this.dirOptions.doCompensateElt
      : this.doCompensateElt;
  }

  compensateSticky() {
    clearTimeout(this.myTimeOut);
    this.parentHeight = this.parent.offsetHeight;
    if (this.parentHeight === 0) {
      this.myTimeOut = setTimeout(() => {
        this.compensateSticky();
      });
    } else {
      if (!this.utils.isDefined(this.compensateElt)) {
        this.previousHeight = !this.utils.isDefined(this.previousHeight)
          ? this.parentHeight
          : this.previousHeight;
        this.compensateElt = this.renderer.createElement('div');
        const size = window.innerWidth < 768 ? 85 + 20 : 85; // compensate footernav on mobile + white shadow
        this.renderer.setStyle(this.compensateElt, 'height', size + 'px');
        const dest = this.utils.isDefined(this.parentContainer)
          ? this.parentContainer
          : this.content.el;
        if (this.position === 'top') {
          this.renderer.insertBefore(dest, this.compensateElt, dest.firstChild);
        }
        if (this.position === 'bottom') {
          this.renderer.appendChild(dest, this.compensateElt);
        }
      }
    }
    this.applyScrollToElt();
  }

  updateCompensate() {
    if (this.utils.isDefined(this.compensateElt)) {
      // let applyChange = false;
      // if (this.parent.classList.contains(this.hideClassName)) {
      //   if (this.previousHeight > this.parent.offsetHeight) {
      //     applyChange = true;
      //     this.previousHeight = this.parent.offsetHeight;
      //   }
      // } else {
      //   if (this.previousHeight < this.parent.offsetHeight) {
      //     applyChange = true;
      //   }
      // }
      // if (applyChange || !this.inverted) {
      //   this.renderer.setStyle(
      //     this.compensateElt,
      //     'height',
      //     this.parent.offsetHeight + 'px'
      //   );
      // }
      // console.log(this.compensateElt, this.compensateElt.offsetHeght);
      this.renderer.setStyle(this.compensateElt, 'height', '120px');
    }
  }

  applyScrollToElt() {
    if (this.utils.isDefined(this.parent)) {
      // prevent null value
      if (!this.absolute) {
        this.contentScrollTop = this.utils.isDefined(this.contentScrollTop)
          ? this.contentScrollTop
          : 0;
        const pageHeight =
          this.content.contentHeight > window.innerHeight
            ? window.innerHeight - 56
            : this.content.contentHeight < window.innerHeight - 100
              ? window.innerHeight - 56
              : this.content.contentHeight;
        const eltPosY =
          this.position === 'top'
            ? this.contentScrollTop
            : this.position === 'bottom'
              ? this.utils.isDefined(this.parentContainer)
                ? this.contentScrollTop +
                  (this.parentContainer.offsetHeight -
                    this.parent.offsetHeight -
                    2)
                : this.contentScrollTop +
                  (pageHeight - this.parent.offsetHeight)
              : 0;
        // console.log(pageHeight, eltPosY);
        // console.log('contentScrollTop', this.contentScrollTop)
        // console.log('content.contentHeight', this.content.contentHeight)
        // console.log('parent.offsetHeight', this.parent.offsetHeight)
        this.renderer.setStyle(this.parent, 'top', eltPosY + 'px');
      } else {
        if (this.position === 'bottom') {
          this.renderer.setStyle(this.parent, 'position', 'fixed');
          this.renderer.setStyle(this.parent, 'top', 'initial');
          this.renderer.setStyle(this.parent, 'bottom', '0');
        }
      }
    }
  }

  checkConditions() {
    if (this.utils.isDefined(this.parent) && this.scrollValueProcessed) {
      // console.log('this.scrollDirection', this.scrollDirection);
      // console.log('this.scrollValueProcessed', this.scrollValueProcessed);
      if (this.scrollDirection !== '') {
        let addClassToElt: boolean;
        if (this.inverted) {
          addClassToElt =
            this.position === 'top'
              ? this.scrollDirection === 'up'
                ? false
                : true
              : this.position === 'bottom'
                ? this.scrollDirection === 'down'
                  ? false
                  : true
                : true;
        } else {
          addClassToElt =
            this.position === 'top'
              ? this.scrollDirection === 'up'
                ? true
                : false
              : this.position === 'bottom'
                ? this.scrollDirection === 'down'
                  ? true
                  : false
                : false;
        }
        if (addClassToElt) {
          this.renderer.addClass(this.parent, this.hideClassName);
        } else {
          this.renderer.removeClass(this.parent, this.hideClassName);
        }
        setTimeout(() => {
          this.updateCompensate();
          this.applyScrollToElt();
        });
      }
    }
  }

  @HostListener('window:touchstart', ['$event']) onTouchStart(event) {
    this.isMobile = true;
    this.processMouseDown(event);
  }
  @HostListener('window:touchmove', ['$event']) onTouchMove(event) {
    this.processMouseMove(event);
  }
  @HostListener('window:touchend', ['$event']) onTouchEnd(event) {
    this.processMouseEnd(event);
    // this.isMobile = false;
  }

  processMouseDown(event) {
    this.mouseY = this.utils.unify(event).clientY;
  }

  processMouseMove(event) {
    this.hasSwipe = true;
    this.scrollDirection =
      this.mouseY < this.utils.unify(event).clientY ? 'up' : 'down';
    this.scrollValueProcessed = true;
    this.mouseY = this.utils.unify(event).clientY;
    this.checkConditions();
  }

  processMouseEnd(event) {
    this.hasSwipe = false;
    this.scrollValueProcessed = false;
  }
}
