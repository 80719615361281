import { Injectable, OnDestroy } from '@angular/core';

import {
  StorageService,
  StudentService,
  CerfaService,
  LessonsService,
  OffersService,
  ExamenConduiteService,
} from '@app/services';

import { BehaviorSubject } from 'rxjs';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

import * as moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
@EasyDebugDecorator
export class DashboardCardsBuilderService implements OnDestroy {
  showAllCards = false;

  student: any;
  userLifeCycle: any;
  offers: any;
  fetchOffersFailed = false;
  sessions: any;
  lessons: any;
  fetchLessonFailed = false;
  validatedCerfa = false;
  nbAnswersRef = 40;
  streakNote = 35;

  cards: any = [];
  cards_clicked: any = null;

  allCallsDone = false;
  calls = [{ call: 'promo', done: false }];

  cardState = { cardState: '' };
  cardBuildFinished: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.cardState
  );
  cardBuildFinished$ = this.cardBuildFinished.asObservable();

  bigCardsDefinitions = [
    // CODE
    {
      name: 'acccueil_demarrez',
      content: {
        title: '',
        text: 'Démarre l’entraînement<br>C’est gratuit',
        sstext: '',
        cta: 'Lancer 1 série 🔥',
        link: 'goToSerie1',
      },
    },
    {
      name: 'acccueil_permis',
      content: {
        title: 'Passer le permis',
        text: 'Comment passer le permis avec En Voiture Simone&nbsp;?',
        sstext: '',
        cta: 'Découvrir le concept ⭐️',
        link: 'comment-ca-marche',
      },
    },
    {
      name: 'acccueil_conduite',
      content: {
        title: 'Apprendre à conduire',
        text: 'Démarre la conduite près de chez toi',
        sstext: '',
        cta: 'Réserver 1 leçon 🚗',
        link: 'conduite',
      },
    },
    {
      name: 'acccueil_offres',
      content: {
        title: 'Nos offres',
        text: 'Nous t’accompagnons tout au long de ta formation',
        sstext: '',
        cta: 'Découvrir nos offres 👀',
        link: 'offres',
      },
    },
    {
      name: 'acccueil_series',
      content: {
        title: 'S’inscrire',
        text: 'Découvre nos 45 séries d’entraînement gratuites',
        sstext: '',
        cta: 'Créer mon compte gratuit 👉',
        link: 'modalRegister',
      },
    },
    {
      name: 'acccueil_pretpermis',
      content: {
        title: '',
        text: 'Prêt à passer le permis&nbsp;?',
        sstext: '',
        cta: 'Découvrir nos offres 👀',
        link: 'offres',
      },
    },
    {
      name: 'nodata_welcome',
      content: {
        title: '',
        text: 'Bienvenue !<br>Nous allons t’accompagner tout au long de ta formation au permis. Impatient de démarrer ? Alors, en route&nbsp;!',
        sstext: '',
        cta: "Démarrer l'entraînement",
        link: 'freeSerie',
      },
    },
    {
      name: 'nodata_astuce',
      content: {
        title: '',
        text: '<b>Astuce Simone : </b>Un entraînement complet passe par la lecture attentive de cours de code. Et ça tombe bien, on t’en propose 140&nbsp;!',
        sstext: '',
        cta: 'Accéder aux cours',
        link: 'cours',
      },
    },
    {
      name: 'nodata_conduite',
      content: {
        title: '',
        text: "Et pour la conduite ?<br>Nos enseignants sont disponibles près de chez toi 7J/J, de 7h à 23h pour s'adapter à ton emploi du temps.",
        sstext: '',
        cta: 'Prends les commandes',
        link: 'conduite',
      },
    },
    {
      name: 'card1_special_50series',
      content: {
        title: '',
        text: '<b>Félicitations !</b><br>Tu as réalisé ##nbSeries## séries au total depuis le début de ta formation 🎉',
        sstext: '',
        cta: "Continuer l'entraînement",
        link: 'continueSerie',
      },
    },
    {
      name: 'card1_special_streakSeries',
      content: {
        title: '',
        text: '<b>Bravo !</b><br>Tu aurais obtenu ton code à tes ##nbSeries## derniers entraînements 💪',
        sstext: '',
        cta: "Continuer l'entraînement",
        link: 'continueSerie',
      },
    },
    {
      name: 'card1_special_streakLog',
      content: {
        title: '',
        text: "Tu t'es entraîné ##nbDays## jours d'affilé !<br>Poursuis tes efforts, c'est en étant assidu que tu atteindras tes objectifs 💪",
        sstext: '',
        cta: 'Lancer une série',
        link: 'continueSerie',
      },
    },
    {
      name: 'card1_trainedThisWeek',
      content: {
        title: '',
        text: 'Tu as réalisé ##nbSeriesThisWeek## série[s] cette semaine, continue sur cette voie&nbsp;!',
        sstext: '',
        cta: "Poursuivre l'entraînement",
        link: 'continueSerie',
      },
    },
    {
      name: 'card1_trainedThisMonth',
      content: {
        title: '',
        text: 'Tu as obtenu en moyenne ##averageScoreThisMonth## bonnes réponses sur 40 ce mois',
        sstext: '',
        cta: 'Battre mon score',
        link: 'continueSerie',
      },
    },
    {
      name: 'card1_notTrainedThisMonthWithPending',
      content: {
        title: '',
        text: "Ravi de te revoir, ##firstName## !<br>Tu as ##nbPendingSeries## série[s] d'entraînement en cours.",
        sstext: '',
        cta: "Reprendre l'entraînement",
        link: 'callSerieById',
      },
    },
    {
      name: 'card1_notTrainedThisMonthWithNoPending',
      content: {
        title: '',
        text: "Ravi de te revoir, ##firstName## !<br>Prêt à reprendre l'entraînement&nbsp;?",
        sstext: '',
        cta: 'Lancer une série',
        link: 'freeSerie',
      },
    },
    {
      name: 'card2_astuce0',
      content: {
        title: '',
        text: '<b>Astuce Simone : </b>En examen blanc, tu as largement le temps de répondre. Concentre-toi sur les questions et oublie le chrono ❤️',
        sstext: '',
        cta: 'Réaliser un examen blanc',
        link: 'startExamen',
      },
    },
    {
      name: 'card2_astuce1',
      content: {
        title: '',
        text: '<b>Astuce Simone : </b>Un entraînement complet passe par la lecture attentive de cours de code. Et ça tombe bien, on t’en propose 140&nbsp;!',
        sstext: '',
        cta: 'Accéder aux cours',
        link: 'cours',
      },
    },
    {
      name: 'card2_astuce2',
      content: {
        title: '',
        text: '<b>Astuce Simone : </b>Enregistre une alarme pour penser à réaliser quelques séries de code tous les jours ⏰',
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers0',
      content: {
        title: '',
        text: "Le savais-tu : Rihanna n'a pas le permis. Si tu continues à bien bosser, tu pourras donc être meilleur que Rihanna dans un domaine. Go&nbsp;!",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers1',
      content: {
        title: '',
        text: "Quelque part dans le monde, quelqu'un vient probablement d'avoir son permis. Bientôt, ce quelqu'un, ça sera toi. On croit en toi&nbsp;!",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers2',
      content: {
        title: '',
        text: "D'après une étude américaine qu'on vient d'inventer, les personnes qui ont le permis ont 4 fois plus de chances de gagner au Loto. On dit ça, on dit rien.",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers3',
      content: {
        title: '',
        text: '15 minute pour réviser ton code, ça te parait long ? Alors repense à toutes ces fois où tu as attendu ton bus 30 minutes...',
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers4',
      content: {
        title: '',
        text: "Dans quelques mois, tu te souviendras avec amusement de l'époque où tes potes se moquaient de toi parce que tu n'avais pas le permis... D'ici là, au boulot&nbsp;!",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers5',
      content: {
        title: '',
        text: "Si un jour on est envahi par des zombies, tu seras bien content de pouvoir prendre la voiture pour t'enfuir plus vite. Penses-y.",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers6',
      content: {
        title: '',
        text: 'Dans la vie, il y a ceux qui veulent aller quelque part, et ceux qui y vont. Tu préfères être dans quelle catégorie&nbsp;?',
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers7',
      content: {
        title: '',
        text: "Avoir le permis, c'est pouvoir prendre sa voiture en pleine nuit pour faire une ballade nocturne dans la ville endormie. Bon, ça sert à rien mais ça donne un air profond et mystérieux.",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers8',
      content: {
        title: '',
        text: "Le jour où Mario Kart sortira en réalité augmentée, tu seras bien content d'avoir déjà eu un volant en main... Allez, go&nbsp;!",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers9',
      content: {
        title: '',
        text: 'Le jour où tu auras un crush pour une personne qui habite loin de chez toi, tu iras la voir en trottinette électrique ? Allons, un peu de sérieux, au travail&nbsp;!',
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers10',
      content: {
        title: '',
        text: 'Quand tu auras le permis, tu pourras prendre des gens bizarres en stop et avoir des histoires marrantes à raconter à tes amis. Ça te fait pas super envie&nbsp;?',
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card3_cheers11',
      content: {
        title: '',
        text: "Est-ce que James Bond serait aussi cool s'il devait se déplacer en bus plutôt qu'en Aston Martin pendant ses missions ? Si tu connais la réponse, tu sais ce qu'il te reste à faire.",
        sstext: '',
        cta: '',
        link: '',
      },
    },
    {
      name: 'card4_infos0',
      content: {
        title: '',
        text: "Comment se déroule l'inscription à l'examen du code de la route ? On te dit tout dans notre tuto&nbsp;!",
        sstext: '',
        cta: "Lire l'article",
        link: 'blogInsExam',
      },
    },
    {
      name: 'card4_infos1',
      content: {
        title: '',
        text: "Inquiet à l'idée de passer le code ? On te partage nos 13 conseils pour réussir son code de la route du premier coup 💪",
        sstext: '',
        cta: "Lire l'article",
        link: 'blogConseilCode',
      },
    },
    {
      name: 'card4_infos2',
      content: {
        title: '',
        text: 'Es-tu certain de parfaitement maîtriser les règles de vitesse en fonction de la météo&nbsp;?',
        sstext: '',
        cta: 'Lire notre fiche récapitulative',
        link: 'blogVitesseMeteo',
      },
    },
    // CONDUITE
    {
      name: 'card1_nextlesson',
      content: {
        title: '',
        text: 'Ta prochaine leçon de ##nbHeures##h est prévue le ##dateTime## avec ##enseignant## ! ', // mar. 09 fév. à 18h
        sstext: '',
        cta: 'Voir le détail',
        link: 'lecons',
      },
    },
    {
      name: 'card1_nextexam',
      content: {
        title: '',
        text: "Ton examen de la conduite est prévu le ##dateTime## et c'est ##enseignant## qui t'accompagne. On croise les doigts pour toi&nbsp;!", // mar. 09 fév. à 18h
        sstext: '',
        cta: 'Voir le détail',
        link: 'lecons',
      },
    },
    {
      name: 'card1_credits5',
      content: {
        title: '',
        text: 'Pour éviter de tomber en panne en plein milieu de ta formation, n’oublie pas de renouveler les crédits de ton compte&nbsp;!',
        sstext: '',
        cta: 'Créditer mon compte',
        link: 'achats',
      },
    },
    {
      name: 'card1_credits0',
      content: {
        title: '',
        text: 'Besoin de quelques heures supplémentaires pour parfaire ta conduite ? Pense à créditer ton compte&nbsp;!  🚗',
        sstext: '',
        cta: 'Créditer mon compte',
        link: 'achats',
      },
    },
    {
      name: 'card1_firstLesson',
      content: {
        title: '',
        text: "En Voiture ##firstName## !<br>Réserve ta première heure de conduite, suis tes progrès jour après jour et fonce vers l'indépendance et la liberté&nbsp;!",
        sstext: '',
        cta: 'Je réserve ma leçon',
        link: 'reserver-lecon',
      },
    },
    {
      name: 'card1_reserver-lecon',
      content: {
        title: '',
        text: 'Enlève le frein à main et réserve des heures de conduite pour progresser plus rapidement&nbsp;!',
        sstext: '',
        cta: 'Réserver une leçon',
        link: 'reserver-lecon',
      },
    },
    {
      name: 'card2_lessonPalier5',
      content: {
        title: '',
        text: 'Bravo ##firstName##, tu as atteint le palier des 5h de conduite avec En Voiture Simone&nbsp;!',
        sstext: "N'hésite pas à nous faire part de ton expérience",
        cta: 'Répondre au questionnaire',
        link: 'exp5Typeform',
      },
    },
    {
      name: 'card2_lessonPalier20',
      content: {
        title: '',
        text: 'Félicitations ##firstName##, tu as atteint le palier des 20h de conduite à nos côtés&nbsp;!',
        sstext: "N'hésite pas à nous faire part de ton expérience",
        cta: 'Répondre au questionnaire',
        link: 'exp20Typeform',
      },
    },
    {
      name: 'card2_livretApprentissage',
      content: {
        title: '',
        text: "Une fois ta première leçon réalisée, tu pourras retrouver à tout moment tes progrès au sein de ton livret d'apprentissage",
        sstext: '',
        cta: 'Découvrir mon livret',
        link: 'livret-apprentissage',
      },
    },
    {
      name: 'card2_lessons1a9',
      content: {
        title: '',
        text: 'Tu as réalisé ##nbHoursDone##h de conduite depuis le début de ta formation. Doucement mais sûrement, tu roules vers le succès. Garde le rythme&nbsp;!',
        sstext: '',
        cta: 'Accéder à mon livret',
        link: 'livret-apprentissage',
      },
    },
    {
      name: 'card2_lessons12a20',
      content: {
        title: '',
        text: "Tu as réalisé ##nbHoursDone##h de conduite, soit l'équivalent de plus d'une saison de Game of Thrones sur les routes ! Promis, ici, il y aura un happy end 😇",
        sstext: '',
        cta: 'Accéder à mon livret',
        link: 'livret-apprentissage',
      },
    },
    {
      name: 'card2_more20lessons',
      content: {
        title: '',
        text: "Tu as réalisé ##nbHoursDone##h de conduite ! La lumière au bout du tunnel n'a jamais brillé aussi fort. Tu la sens, l'odeur de la liberté&nbsp;?",
        sstext: '',
        cta: 'Accéder à mon livret',
        link: 'livret-apprentissage',
      },
    },
    {
      name: 'card3_info0',
      content: {
        title: '',
        text: "Permis de conduire : ces fautes éliminatoires à avoir en tête pour préparer sereinement l'examen",
        sstext: '',
        cta: "Lire l'article",
        link: 'blogFautesPermis',
      },
    },
    {
      name: 'card3_info1',
      content: {
        title: '',
        text: "Stressé à l'idée de passer le permis ? On te partage nos 3 conseils essentiels pour réussir le jour J&nbsp;!",
        sstext: '',
        cta: "Lire l'article",
        link: 'blog3Conseils',
      },
    },
    {
      name: 'card3_info2',
      content: {
        title: '',
        text: "Connais-tu tous les éléments mécaniques de ton véhicule ? Si ce n'est pas le cas, pas de panique ! Tu peux toujours réviser nos cours 📚",
        sstext: '',
        cta: 'Lire les cours',
        link: 'cours/mécanique%2Féquipement',
      },
    },
  ];

  constructor(
    private storageService: StorageService,
    private studentService: StudentService,
    private lessonsService: LessonsService,
    private cerfaService: CerfaService,
    private offersService: OffersService,
    private examenConduiteService: ExamenConduiteService
  ) {
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
  }

  ngOnDestroy() {}

  resetVars() {
    this.cards = [];
    this.cardState = { cardState: '' };

    this.allCallsDone = false;
    this.calls = [{ call: 'promo', done: false }];
  }

  launchCalls() {
    // console.log('launchCalls', this.calls);
    for (const callStorage of this.calls) {
      if (callStorage.call === 'promo') {
        this.fetchOffers();
      }
      if (callStorage.call === 'cerfa/lessons') {
        this.fetchCerfa();
      }
    }
  }

  updateCalls(callName) {
    this.allCallsDone = true;
    for (const callStorage of this.calls) {
      if (callStorage.call === callName && !callStorage.done) {
        callStorage.done = true;
      }
      if (!callStorage.done) {
        this.allCallsDone = false;
      }
      // console.log('UPDATE CALLS', callStorage.call, callStorage.done);
    }
    if (this.allCallsDone) {
      // console.log('allCallsDone now buildBigCards');
      this.buildBigCards();
    }
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  async buildBigCards(receivedSessions?) {
    // console.log('buildBigCards => paymentsSubscription', this.paymentsSubscription);
    this.student = this.studentService.student;
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    // console.log('buildBigCards calls', this.calls);
    if (!!receivedSessions && receivedSessions.length > 0) {
      // console.log('get session from Fn', receivedSessions);
      this.sessions = receivedSessions;
    }
    if (!this.allCallsDone) {
      // RETREIVE ALL NEEDED DATA
      if (!!this.student && this.student.remoteId !== 'GUEST') {
        const found = this.calls.filter(elt => elt.call === 'cerfa/lessons');
        // add only 1 time
        if (found.length === 0) {
          this.calls.push({ call: 'cerfa/lessons', done: false });
        }
      }
      // console.log('builder launchCalls call', this.calls, this.allCallsDone);
      this.launchCalls();
    } else {
      // console.log('-----------------------------------');
      // console.log('builder launchCalls DONE!', this.calls, this.allCallsDone);
      // console.log('STUDENT', this.student);
      // console.log('SESSION', this.sessions);
      // console.log('OFFERS', this.offers);
      // console.log('CERFA', this.validatedCerfa);
      // console.log('LESSON', this.lessons);
      // console.log('-----------------------------------');
      // RESET
      this.resetVars();

      if (
        this.userLifeCycle.isUserCanDrive ||
        this.userLifeCycle.isUserDrivingSuccess
      ) {
        // console.log('DASHBOARDBUIDLER => CONDUITE', this.userLifeCycle);
        // **************
        // ** CONDUITE **
        // **************

        // #1 BIG CARD : Informer sur son avancement de la conduite
        const nextLesson = this.getNextLesson();
        const nextExam = await this.getNextExam();
        const userCredits = this.getUserCredits();
        const canTakeLesson = this.canTakeLesson();
        const nbHoursLessons = this.getNbHoursLessons();

        if (nextLesson.length > 0 || nextExam.length > 0) {
          if (nextLesson.length > 0) {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card1_nextlesson',
              lesson: nextLesson,
            }); // give first next lesson
          } else if (nextExam.length > 0) {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card1_nextexam',
              lesson: nextExam,
            }); // give first next lesson
          }
        } else if (userCredits <= 5) {
          if (userCredits > 0) {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card1_credits5',
            });
          } else {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card1_credits0',
            });
          }
        } else if (canTakeLesson) {
          this.cards.push({
            cardType: 'big',
            conduite: true,
            cardName: 'card1_firstLesson',
          });
        } else if (
          !!this.lessons &&
          this.lessons.length > 0 &&
          nextLesson.length === 0
        ) {
          this.cards.push({
            cardType: 'big',
            conduite: true,
            cardName: 'card1_reserver-lecon',
          });
        }

        // #2 BIG CARD : Encourager sur son évolution
        let addedSpecialCases = false;

        if (nbHoursLessons >= 5 && nbHoursLessons <= 6) {
          this.storageService
            .get(`${this.student.remoteId}-cards_clicked`)
            .then(cards_clicked => {
              this.cards_clicked = cards_clicked;
              if (!!this.cards_clicked) {
                // found something
                // card2_lessonPalier5
                const found = this.cards_clicked.filter(
                  elt => elt.card === 'card2_lessonPalier5'
                );
                if (found.length === 0) {
                  this.cards.push({
                    cardType: 'big',
                    conduite: true,
                    cardName: 'card2_lessonPalier5',
                  });
                  addedSpecialCases = true;
                }
              } else {
                // nothing
                this.cards.push({
                  cardType: 'big',
                  conduite: true,
                  cardName: 'card2_lessonPalier5',
                });
                addedSpecialCases = true;
              }
            });
        } else if (nbHoursLessons >= 20 && nbHoursLessons <= 21) {
          this.storageService
            .get(`${this.student.remoteId}-cards_clicked`)
            .then(cards_clicked => {
              this.cards_clicked = cards_clicked;
              if (!!this.cards_clicked) {
                // found something
                // card2_lessonPalier20
                const found = this.cards_clicked.filter(
                  elt => elt.card === 'card2_lessonPalier20'
                );
                if (found.length === 0) {
                  this.cards.push({
                    cardType: 'big',
                    conduite: true,
                    cardName: 'card2_lessonPalier20',
                  });
                  addedSpecialCases = true;
                }
              } else {
                // nothing
                this.cards.push({
                  cardType: 'big',
                  conduite: true,
                  cardName: 'card2_lessonPalier20',
                });
                addedSpecialCases = true;
              }
            });
        }

        if (!addedSpecialCases) {
          if (canTakeLesson) {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card2_livretApprentissage',
            });
          } else if (nbHoursLessons >= 1 && nbHoursLessons <= 9) {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card2_lessons1a9',
              nbHoursDone: nbHoursLessons,
            });
          } else if (nbHoursLessons >= 12 && nbHoursLessons <= 20) {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card2_lessons12a20',
              nbHoursDone: nbHoursLessons,
            });
          } else if (nbHoursLessons > 20) {
            this.cards.push({
              cardType: 'big',
              conduite: true,
              cardName: 'card2_more20lessons',
              nbHoursDone: nbHoursLessons,
            });
          }
        }

        // #3 BIG CARD : Apporter de l'information utile
        this.cards.push({
          cardType: 'big',
          conduite: true,
          cardName: 'card3_info' + this.randomMinMax(0, 2),
        }); // max 3 diff cards
      } else {
        // console.log('DASHBOARDBUIDLER => CODE', this.userLifeCycle);
        // **********
        // ** CODE **
        // **********

        // NO DATA
        let hasRealData = false;
        for (const session of this.sessions) {
          if (session.answersCount > 0) {
            hasRealData = true;
          }
        }

        // console.log('this.sessions.length', this.sessions.length);
        // console.log('hasRealData', hasRealData);

        if (this.userLifeCycle.isUserGuest) {
          // put here new accueil dashboard : GUEST
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_demarrez',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_permis',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_conduite',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_offres',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_series',
          });
        } else if (this.userLifeCycle.isUserRegistered) {
          // put here new accueil dashboard : REGISTERED
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_pretpermis',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_permis',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_conduite',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'acccueil_offres',
          });
        } else if (
          !this.userLifeCycle.isUserGuest &&
          !this.userLifeCycle.isUserGuest &&
          (this.sessions.length === 0 || !hasRealData)
        ) {
          // put here new accueil dashboard : NOT GUEST NOT REGISTERED BUT NOT DATA
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'nodata_welcome',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'nodata_astuce',
          });
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'nodata_conduite',
          });
        } else if (this.sessions.length > 0 && hasRealData) {
          // #1 BIG CARD : Féliciter + Poursuivre l'entraînement

          // 0) CAS EXCEPTIONNELS
          const finishedSeries = this.getFinishedSeries();
          const streakSeries = this.getStreakSeries(
            finishedSeries,
            this.streakNote
          );
          const streakDaysSeries = this.getStreakDaysSeries(finishedSeries);

          // console.log('finishedSeries', finishedSeries);
          // console.log('streakSeries', streakSeries);
          // console.log('streakDaysSeries', streakDaysSeries);

          let addedSpecialCases = false;
          if (finishedSeries.length > 0 && finishedSeries.length % 50 === 0) {
            // each 50
            this.storageService
              .get(`${this.student.remoteId}-cards_clicked`)
              .then(cards_clicked => {
                this.cards_clicked = cards_clicked;
                if (!!this.cards_clicked) {
                  // found something
                  // card1_special_50series
                  const found = this.cards_clicked.filter(
                    elt => elt.card === 'card1_special_50series'
                  );
                  if (found.length === 0) {
                    this.cards.push({
                      cardType: 'big',
                      code: true,
                      cardName: 'card1_special_50series',
                      nbSeries: finishedSeries,
                    });
                    addedSpecialCases = true;
                  }
                } else {
                  // nothing
                  this.cards.push({
                    cardType: 'big',
                    code: true,
                    cardName: 'card1_special_50series',
                    nbSeries: finishedSeries,
                  });
                  addedSpecialCases = true;
                }
              });
          } else if (streakSeries.length >= 2) {
            this.cards.push({
              cardType: 'big',
              code: true,
              cardName: 'card1_special_streakSeries',
              nbSeries: streakSeries.length,
            });
            addedSpecialCases = true;
          } else if (streakDaysSeries >= 2) {
            this.cards.push({
              cardType: 'big',
              code: true,
              cardName: 'card1_special_streakLog',
              nbDays: streakDaysSeries,
            });
            addedSpecialCases = true;
          }

          // IF NO SPECIAL CASES
          if (!addedSpecialCases) {
            // 1) S'EST ENTRAÎNE CETTE SEMAINE
            // 2) S'EST ENTRAÎNE CE MOIS
            // 3) NE S'EST PAS ENTRAÎNE CE MOIS + SERIE EN COURS
            // 4) NE S'EST PAS ENTRAÎNE CE MOIS + PAS DE SERIE EN COURS
            const nbSeriesThisWeek = this.getSerieThisWeek();
            const nbSeriesThisMonth = this.getSerieThisMonth();
            const pendingSeries = this.getPendingSeries();
            // DATE TO CHANGE
            const today = this.dateToFormatFr();
            const startWeek = this.getMonday(today);
            // DATE TO CHANGE
            const lastMonthDate = this.dateToFormatFr(
              moment({
                years: today.year(),
                months: today.month() + 1,
                days: 1,
              })
            ).valueOf();

            // console.log('------------------');
            // console.log('nbSeriesThisWeek', nbSeriesThisWeek);
            // console.log('nbSeriesThisMonth', nbSeriesThisMonth);
            // console.log('pendingSeries', pendingSeries);
            // console.log('pendingSeries this month', this.sessions.filter(elt => (new Date(elt.lastAnsweredAt) >= lastMonthDate && elt.answersCount > 0 && elt.state !== 'pending')).length);

            if (nbSeriesThisWeek.length > 0) {
              // 1) S'EST ENTRAÎNE CETTE SEMAINE
              this.cards.push({
                cardType: 'big',
                code: true,
                cardName: 'card1_trainedThisWeek',
                nbSeriesThisWeek: nbSeriesThisWeek.length,
              });
            } else if (nbSeriesThisMonth.length > 0) {
              // 2) S'EST ENTRAÎNE CE MOIS
              this.cards.push({
                cardType: 'big',
                code: true,
                cardName: 'card1_trainedThisMonth',
                averageScoreThisMonth: this.getAverageScore(nbSeriesThisMonth),
              });
            } else if (
              nbSeriesThisMonth.length === 0 &&
              pendingSeries.length > 0
            ) {
              // 3) NE S'EST PAS ENTRAÎNE CE MOIS + SERIE EN COURS
              this.cards.push({
                cardType: 'big',
                code: true,
                cardName: 'card1_notTrainedThisMonthWithPending',
                nbPendingSeries: pendingSeries.length,
                lastPendingSerie: pendingSeries[0],
              });
              // DATE TO CHANGE
            } else if (
              nbSeriesThisMonth.length === 0 &&
              this.sessions.filter(
                elt =>
                  this.dateToFormatFr(elt.lastAnsweredAt).valueOf() >=
                    lastMonthDate &&
                  elt.answersCount > 0 &&
                  elt.state !== 'pending'
              ).length > 0
            ) {
              // 4) NE S'EST PAS ENTRAÎNE CE MOIS + PAS DE SERIE EN COURS
              this.cards.push({
                cardType: 'big',
                code: true,
                cardName: 'card1_notTrainedThisMonthWithNoPending',
              });
            }
          }

          // #2 BIG CARD : Encourager + Donner des conseils pédagogiques
          // sur app on ajoute une feature Alarme que l'on retire en desktop
          // const maxRandomValue = (this.platform.is('cordova')) ? 2 : 1;
          const maxRandomValue = 2; // always 2 we removed CTA but keep card
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'card2_astuce' + this.randomMinMax(0, maxRandomValue),
          });

          // #3 BIG CARD BONUS : Bonus d'encouragement
          // ajouter côté back historique de connexion pour ajout une fois toutes les 5 connexions à l'app
          // en fait on gère pas l'historique mais on fait un bon vieux random des familles et si random ok on random parmis les 12 cards
          if (this.randomMinMax(1, 5) === 5) {
            // fake simulation 1/5 session
            this.cards.push({
              cardType: 'big',
              code: true,
              cardName: 'card3_cheers' + this.randomMinMax(0, 11),
            }); // max 12 diff cards
          }

          // #4 BIG CARD : Apporter de l'information utile
          this.cards.push({
            cardType: 'big',
            code: true,
            cardName: 'card4_infos' + this.randomMinMax(0, 2),
          }); // max 3 diff cards
        }
      }

      // *******************************
      // * DEBUG SHOW ALL CARDS *
      // *******************************
      if (this.showAllCards) {
        for (const card of this.bigCardsDefinitions) {
          this.cards.push({ cardType: 'big', code: true, cardName: card.name });
        }
      }
      // ***********************
      // * END OF DEBUG *
      // ***********************

      // this.addPromoCards();

      this.replacePlaceholdersByContents();
    }
  }

  addPromoCards() {
    // ADD PROMO IF PROMO
    if (!!this.offers && this.offers.length > 0 && !this.fetchOffersFailed) {
      const hasPromos = [];
      for (const offer of this.offers) {
        if (offer.offer_campaign.length > 0) {
          hasPromos.push({
            name: offer.offer_campaign[0].name,
            // DATE TO CHANGE
            starts_at: this.dateToFormatFr(offer.offer_campaign[0].starts_at),
            // DATE TO CHANGE
            ends_at: this.dateToFormatFr(offer.offer_campaign[0].ends_at),
            amount: offer.amount,
            discount: offer.discount,
          });
        }
      }
      if (hasPromos.length > 0) {
        // DATE TO CHANGE
        const today = this.dateToFormatFr();
        for (const promo of hasPromos) {
          // anti-couillons => multi promo impossible pour le moment
          if (promo.name === 'Promo Pack Permis') {
            // promo.starts_at = new Date('2019/10/10 00:00:00') // TEMP FOR TEST - use / isntead of - for safari compatibility only for manual dates not ISO Dates
            // console.log(today, promo.starts_at, promo.ends_at, today >= promo.starts_at && today <= promo.ends_at);

            // check if today is between promo dates
            if (
              today.valueOf() >= promo.starts_at.valueOf() &&
              today.valueOf() <= promo.ends_at.valueof()
            ) {
              // add a card in position N depending on user status
              if (this.userLifeCycle.isUserGuest) {
                // N = 2
                this.cards.splice(1, 0, {
                  cardType: 'big',
                  code: true,
                  cardName: 'cardPromo_' + promo.name,
                });
              } else if (this.userLifeCycle.isUserRegistered) {
                // N = 1
                this.cards.unshift({
                  cardType: 'big',
                  code: true,
                  cardName: 'cardPromo_' + promo.name,
                });
              }
            }
          }
        }
      }
    }
  }

  replacePlaceholdersByContents() {
    // Replace placeholders by real values
    // console.log('NOW replace key in cards by real content', this.cards);
    // console.log('paymentsBuilderFinished', this.paymentsBuilderFinished);
    if (this.cards.length > 0) {
      // console.log('UserPayments', this.storagePayments);
      const populatedCards = [];
      for (const buildedCard of this.cards) {
        for (const card of this.bigCardsDefinitions) {
          if (buildedCard.cardName === card.name) {
            const cardcontent = {
              title: card.content.title,
              text: card.content.text,
              sstext: card.content.sstext,
              cta: card.content.cta,
              link: card.content.link,
              icon: '',
            };
            const keys = Object.keys(cardcontent);
            const keysDetails = Object.keys(buildedCard);
            for (const key of keys) {
              // only if not empty & with a placeholder
              if (typeof cardcontent[key] === 'string' && key === 'link') {
                if (card.name === 'card1_nextlesson') {
                  cardcontent[key] =
                    cardcontent[key] + '/' + buildedCard.lesson[0].id;
                }
                if (card.name === 'card1_nextexam') {
                  cardcontent[key] =
                    cardcontent[key] + '/' + buildedCard.lesson[0].id;
                }
              }
              if (typeof cardcontent[key] === 'string' && key !== 'link') {
                if (cardcontent[key] !== '') {
                  if (cardcontent[key].indexOf('[e]') !== -1) {
                    if (!!this.student) {
                      cardcontent[key] = cardcontent[key].replace(
                        new RegExp('\\[e\\]', 'g'),
                        !!this.student.gender
                          ? this.student.gender === 'f'
                            ? 'e'
                            : ''
                          : '∙e'
                      );
                    } else {
                      cardcontent[key] = cardcontent[key].replace(
                        new RegExp('\\[e\\]', 'g'),
                        '∙e'
                      );
                    }
                  }
                  if (cardcontent[key].indexOf('##') !== -1) {
                    const tab_jour = new Array(
                      'dim',
                      'lun',
                      'mar',
                      'mer',
                      'jeu',
                      'ven',
                      'sam'
                    );
                    const tab_mois = new Array(
                      'jan',
                      'fév',
                      'mar',
                      'avr',
                      'mai',
                      'juin',
                      'juil',
                      'août',
                      'sept',
                      'oct',
                      'nov',
                      'déc'
                    );

                    if (cardcontent[key].indexOf('##firstName##') !== -1) {
                      if (
                        !!this.student &&
                        (!!this.student.first_name || !!this.student.last_name)
                      ) {
                        cardcontent[key] = cardcontent[key].replace(
                          new RegExp('##firstName##', 'g'),
                          this.student.first_name || this.student.last_name
                        );
                      } else {
                        cardcontent[key] = cardcontent[key].replace(
                          new RegExp('##firstName##', 'g'),
                          ''
                        );
                      }
                    }
                    // console.log('CARD TO REPLACE', card);
                    for (const keyDetails of keysDetails) {
                      if (
                        keyDetails !== 'cardType' &&
                        keyDetails !== 'conduite' &&
                        keyDetails !== 'code' &&
                        keyDetails !== 'cardName'
                      ) {
                        // console.log('%c' + keyDetails + ' = ' + this.cardDetails[keyDetails], 'background: #FF0000; color: #FFFF00; padding: 6px 8px; border-radius: 4px;');
                        const str = '##' + keyDetails + '##';
                        if (cardcontent[key].indexOf(str) !== -1) {
                          cardcontent[key] = cardcontent[key].replace(
                            new RegExp(str, 'g'),
                            buildedCard[keyDetails]
                          );
                          // replace [s]
                          if (
                            keyDetails === 'nbSeriesThisWeek' ||
                            keyDetails === 'nbPendingSeries'
                          ) {
                            cardcontent[key] = cardcontent[key].replace(
                              new RegExp('\\[s\\]', 'g'),
                              !!buildedCard[keyDetails]
                                ? buildedCard[keyDetails] > 1
                                  ? 's'
                                  : ''
                                : '[s]'
                            );
                          }
                        }
                        // replace datetime + enseignant
                        // console.log(buildedCard);
                        if (!!buildedCard.lesson) {
                          if (card.name === 'card1_nextlesson') {
                            const placeholders = [
                              'dateTime',
                              'enseignant',
                              'nbHeures',
                            ];
                            for (const placeholder of placeholders) {
                              const str = '##' + placeholder + '##';
                              if (cardcontent[key].indexOf(str) !== -1) {
                                let replacement = '';
                                if (placeholder === 'dateTime') {
                                  // DATE TO CHANGE
                                  const examDate = this.dateToFormatFr(
                                    buildedCard.lesson[0].starts_at
                                  );
                                  const jour: any = examDate.day();
                                  let day: any = examDate.date();
                                  day = day < 10 ? '0' + day : day;
                                  const month: any = examDate.month();
                                  let hour: any = examDate.hours();
                                  hour = hour < 10 ? '0' + hour : hour;
                                  let minutes: any = examDate.minutes();
                                  minutes =
                                    minutes < 10 ? '0' + minutes : minutes;
                                  replacement =
                                    '<b>' +
                                    tab_jour[jour] +
                                    '. ' +
                                    day +
                                    ' ' +
                                    tab_mois[month] +
                                    ' à ' +
                                    hour +
                                    'h' +
                                    minutes +
                                    '</b>';
                                }
                                if (placeholder === 'enseignant') {
                                  replacement =
                                    '<b>' +
                                    buildedCard.lesson[0].teacher.first_name +
                                    ' ' +
                                    buildedCard.lesson[0].teacher.last_name
                                      .charAt(0)
                                      .toUpperCase() +
                                    '.</b>';
                                }
                                if (placeholder === 'nbHeures') {
                                  replacement = buildedCard.lesson[0].credits;
                                }
                                cardcontent[key] = cardcontent[key].replace(
                                  new RegExp(str, 'g'),
                                  replacement
                                );
                              }
                            }
                          }
                          if (card.name === 'card1_nextexam') {
                            // console.log('NEXT EXAM CARD REPLACEMENT', card, buildedCard.lesson[0]);
                            const placeholders = ['dateTime', 'enseignant'];
                            for (const placeholder of placeholders) {
                              const str = '##' + placeholder + '##';
                              if (cardcontent[key].indexOf(str) !== -1) {
                                let replacement = '';
                                if (placeholder === 'dateTime') {
                                  // DATE TO CHANGE
                                  const examDate = this.dateToFormatFr(
                                    buildedCard.lesson[0].starts_at
                                  );
                                  const jour: any = examDate.day();
                                  let day: any = examDate.date();
                                  day = day < 10 ? '0' + day : day;
                                  const month: any = examDate.month();
                                  let hour: any = examDate.hours();
                                  hour = hour < 10 ? '0' + hour : hour;
                                  let minutes: any = examDate.minutes();
                                  minutes =
                                    minutes < 10 ? '0' + minutes : minutes;
                                  replacement =
                                    '<b>' +
                                    tab_jour[jour] +
                                    '. ' +
                                    day +
                                    ' ' +
                                    tab_mois[month] +
                                    ' à ' +
                                    hour +
                                    'h' +
                                    minutes +
                                    '</b>';
                                }
                                if (placeholder === 'enseignant') {
                                  replacement =
                                    '<b>' +
                                    buildedCard.lesson[0].teacher.first_name +
                                    ' ' +
                                    buildedCard.lesson[0].teacher.last_name
                                      .charAt(0)
                                      .toUpperCase() +
                                    '.</b>';
                                }
                                cardcontent[key] = cardcontent[key].replace(
                                  new RegExp(str, 'g'),
                                  replacement
                                );
                              }
                            }
                            // console.log(card, cardcontent);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            cardcontent['card'] = buildedCard;
            populatedCards.push(cardcontent);
          }
        }
      }
      // console.log('add big cards in storage');

      // FINISHED
      this.storageService
        .set(`${this.student.remoteId}-DashboardCards`, populatedCards)
        .catch(err => {
          console.error(
            'Dahboardbuilder storageService set: ' + JSON.stringify(err)
          );
        });
      setTimeout(() => {
        // console.log('SUBSCRIBE NEXT BIGCARDSBUILDER');
        this.cardState.cardState = 'done';
        this.cardBuildFinished.next(this.cardState);
        this.cardState.cardState = '';
      });
    }
  }

  fetchCerfa() {
    // console.log('DashboardCardsBuilderService fetchCerfa', this.userLifeCycle);
    if (
      !this.userLifeCycle.isUserCanDrive &&
      !this.userLifeCycle.isUserDrivingSuccess
    ) {
      const cerfaStatus = this.cerfaService.cerfa;
      // console.log('DashboardCardsBuilderService fetchCerfa', cerfaStatus);
      if (!!cerfaStatus) {
        this.validatedCerfa =
          !!cerfaStatus.confirmed_at && cerfaStatus.confirmed_at !== null
            ? true
            : false;
      }
      this.updateCalls('cerfa/lessons');
    } else if (
      this.userLifeCycle.isUserCanDrive ||
      this.userLifeCycle.isUserDrivingSuccess
    ) {
      this.fetchNextLessons();
    }
  }

  fetchNextLessons() {
    // console.log('DashboardCardsBuilderService fetchNextLessons');
    // DATE TO CHANGE
    const startAtGe = this.dateToFormatFr().toISOString().split('T')[0]; // today

    this.lessonsService
      .fetchNextLessonsByUserId('', '', this.student.remoteId)
      .subscribe(
        res => {
          if (!!res && !!res.errorCode && res.errorCode === 'E301') {
            this.fetchLessonFailed = true;
          } else {
            this.lessons = res;
            // order by date desc
            this.lessons.sort((a, b) => {
              return +new Date(b.starts_at) - +new Date(a.starts_at);
            });
          }
          this.updateCalls('cerfa/lessons');
        },
        err => {
          this.updateCalls('cerfa/lessons');
          console.error(
            'DashboardBuilder fetchNextLessons: ' + JSON.stringify(err)
          );
        }
      );
  }

  fetchOffers() {
    const resGetOffers = this.offersService.offers;
    if (
      !!resGetOffers &&
      !!resGetOffers.errorCode &&
      resGetOffers.errorCode === 'E301'
    ) {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
    // console.log('offersService', this.offers);
    this.updateCalls('promo');
  }

  getFinishedSeries() {
    return this.sessions.filter(
      elt => elt.state === 'right' || elt.state === 'wrong'
    );
  }

  getStreakSeries(series, note) {
    return series.filter(
      elt => elt.state === 'right' && elt.stats.goodAnswers >= note
    );
  }

  getStreakDaysSeries(series) {
    const streaks = [1];
    let streak = 1;
    let previousDate: any;
    for (const serie of series) {
      // DATE TO CHANGE
      const currentDate: any = this.dateToFormatFr(
        serie.lastAnsweredAt
      ).valueOf();
      if (!!previousDate) {
        if (
          Math.round((previousDate - currentDate) / (1000 * 3600 * 24)) === 1
        ) {
          streak++;
        } else {
          if (
            Math.round((previousDate - currentDate) / (1000 * 3600 * 24)) > 1
          ) {
            streaks.push(streak);
            streak = 1;
          }
        }
        previousDate = currentDate;
      } else {
        previousDate = currentDate;
      }
    }
    streaks.push(streak);
    const bestStreak = streaks.sort((a, b) => b - a);
    return bestStreak[0];
  }

  getSerieThisWeek() {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    const startWeek = this.getMonday(today);
    return this.sessions.filter(
      elt =>
        this.dateToFormatFr(elt.lastAnsweredAt).valueOf() >=
          startWeek.valueOf() &&
        elt.answersCount > 0 &&
        elt.state !== 'pending'
    );
  }

  getMonday(d: moment.Moment): moment.Moment {
    // DATE TO CHANGE
    const day = d.day();
    const diff = d.date() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    d.date(diff);
    // DATE TO CHANGE
    return this.dateToFormatFr(d);
  }

  getSerieThisMonth() {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    // DATE TO CHANGE
    const lastMonthDate = this.dateToFormatFr(
      moment({ years: today.year(), months: today.month() + 1, days: 1 })
    ).valueOf();
    // DATE TO CHANGE
    return this.sessions.filter(
      elt =>
        this.dateToFormatFr(elt.lastAnsweredAt).valueOf() >= lastMonthDate &&
        elt.answersCount > 0 &&
        elt.state !== 'pending'
    );
  }

  getPendingSeries() {
    const pendingSeries = this.sessions.filter(
      elt =>
        elt.state === 'pending' &&
        elt.answersCount > 0 &&
        elt.type === 'entrainement'
    );
    const knowSeriesId = [];
    const distinctPendingSeries = [];
    for (const serie of pendingSeries) {
      if (knowSeriesId.indexOf(serie.serieId) === -1) {
        knowSeriesId.push(serie.serieId);
        distinctPendingSeries.push(serie);
      }
    }
    return distinctPendingSeries;
  }

  getScoresBySession(sessions) {
    const otherSessions = sessions.filter(elt => elt.state !== 'pending');

    const nbGoodAnswersBySession = [];
    for (const session of otherSessions) {
      if (session.answersCount !== this.nbAnswersRef) {
        nbGoodAnswersBySession.push(
          (session.stats.goodAnswers * this.nbAnswersRef) / session.answersCount
        );
      } else {
        nbGoodAnswersBySession.push(session.stats.goodAnswers);
      }
    }
    return nbGoodAnswersBySession;
  }

  getAverageScore(sessions) {
    const scoresBySession = this.getScoresBySession(sessions);

    let averageScore = 0;
    if (scoresBySession.length > 0) {
      const sum = scoresBySession.reduce((a, b) => a + b, 0);
      averageScore = Math.round(sum / scoresBySession.length);
    }

    return averageScore;
  }

  canTakeLesson() {
    return !!this.lessons &&
      this.lessons.length === 0 &&
      (this.userLifeCycle.isUserCanDrive ||
        this.userLifeCycle.isUserDrivingSuccess)
      ? true
      : false;
  }

  getNextLesson() {
    let nextLesson = [];
    if (!!this.lessons && this.lessons.length > 0) {
      const onlyLessons = this.lessons.filter(
        elt => elt.driving_exam_id === null
      );
      // DATE TO CHANGE
      const today = this.dateToFormatFr();

      for (const lesson of onlyLessons) {
        // DATE TO CHANGE
        const lessonDate = this.dateToFormatFr(lesson.starts_at);
        if (lessonDate.valueOf() >= today.valueOf()) {
          nextLesson.push(lesson);
        }
      }
      nextLesson.sort((a, b) => {
        // DATE TO CHANGE
        return +new Date(b.starts_at) - +new Date(a.starts_at);
      });
      if (nextLesson.length > 0) {
        nextLesson = [nextLesson[nextLesson.length - 1]];
      }
    }
    return nextLesson;
  }

  async getNextExam() {
    let nextExam: any = [];
    if (!!this.lessons && this.lessons.length > 0) {
      const onlyExams = this.lessons.filter(
        elt => elt.driving_exam_id !== null
      );
      // DATE TO CHANGE
      const today = this.dateToFormatFr();

      for (const lesson of onlyExams) {
        const resFetchDrivingExam =
          await this.examenConduiteService.fetchDrivingExamenById(
            lesson.driving_exam_id
          );
        if (
          !!resFetchDrivingExam &&
          !!resFetchDrivingExam.errorCode &&
          resFetchDrivingExam.errorCode === 'E301'
        )
          continue;
        if (
          resFetchDrivingExam?.data?.attributes?.status_details?.text ===
          'En recherche d’enseignant remplaçant'
        )
          continue;

        const lessonDate = this.dateToFormatFr(lesson.starts_at);
        if (lessonDate.valueOf() >= today.valueOf()) {
          nextExam.push(lesson);
        }
      }
      nextExam.sort((a, b) => {
        // DATE TO CHANGE
        return +new Date(a.starts_at) - +new Date(b.starts_at);
      });
      if (nextExam.length > 0) {
        nextExam = [nextExam[0]];
      }
    }
    return nextExam;
  }

  getUserCredits() {
    let userCredits = 0;
    if (!!this.student) {
      userCredits =
        (this.student?.creditStocks?.manualDriving?.quantity || 0) +
        (this.student?.creditStocks?.automaticDriving?.quantity || 0);
    }
    return userCredits;
  }

  getNbHoursLessons() {
    let nbHeuresConduite = 0;
    if (!!this.lessons && this.lessons.length > 0) {
      // DATE TO CHANGE
      const today = this.dateToFormatFr();
      for (const lesson of this.lessons) {
        // DATE TO CHANGE
        if (today.valueOf() > this.dateToFormatFr(lesson.starts_at).valueOf()) {
          nbHeuresConduite += lesson.credits;
        }
      }
    }
    return nbHeuresConduite;
  }

  randomMinMax(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
