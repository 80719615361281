import { ToasterService } from '@commons/services';
import { environment } from './../../environments/environment';

import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { Initialable } from './app-init.service';
import { Platform, ModalController } from '@ionic/angular';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: `init1`, initializer: `init` })
@EasyDebugDecorator
export class ConfigService {
  storageLocked = false;

  constructor(
    private storageService: StorageService,
    private platform: Platform
  ) {
    // console.log('Config service instanciate');
  }

  async init() {
    // console.log(`Launching Config init`);
    let config = await this.getAppConfig();
    if (!!config) {
      config = await this.checkDbVersion(config);
    } else {
      config = await this.initConfig();
    }
    // console.log('Config init done ==> ', config);
    config.iOSATTSliderShown = false; // modal shown but not answered
    await this.setAppConfig(config);

    return config;
  }

  async initConfig(): Promise<any> {
    const config = {
      dbVersion: environment.dbVersion,
      sessionExpired: false,
      hasMadeWootric: false,
      hasMadeSoftDeploy: false,
      iOSATTSliderShown: false,
      iOSATTFromRegister: false,
      iOSATTAnswered: false,
      iOSATTFirstTime: true,
    };

    return this.setAppConfig(config);
  }

  async checkDbVersion(config: any): Promise<any> {
    if (config.dbVersion < environment.dbVersion) {
      // Keep user's choice about iOSATT
      const iOSATTSliderShown = config.iOSATTSliderShown;
      const iOSATTFromRegister = config.iOSATTFromRegister;
      const iOSATTAnswered = config.iOSATTAnswered;
      const iOSATTFirstTime = config.iOSATTFirstTime;

      await this.storageService.clear();
      config = await this.initConfig();
      config.iOSATTSliderShown = iOSATTSliderShown;
      config.iOSATTFromRegister = iOSATTFromRegister;
      config.iOSATTAnswered = iOSATTAnswered;
      config.iOSATTFirstTime = iOSATTFirstTime;
      return await this.setAppConfig(config);
    }
    return config;
  }

  checkSessionExpiredDisplayed(config: any): boolean {
    if (config.sessionExpired) {
      return true;
    } else {
      return false;
    }
  }

  async switchBoolean(prop: string): Promise<boolean> {
    const config = await this.getAppConfig();
    if (!!config[prop]) {
      config[prop] = !config[prop];
    }
    await this.setAppConfig(config);
    return config[prop];
  }

  async getAppConfig(): Promise<any> {
    return this.storageService.get('AppConfig');
  }

  async setAppConfig(config: any): Promise<any> {
    return await this.storageService.set('AppConfig', config);
  }
}
