<div
  class="custom-radio"
  [ngClass]="{'white-btn': isWhite, 'cr-small': isSmall}"
  *ngIf="options.length > 0">
  <div class="cr-container">
    <div
      class="cr-button cr-bg-color"
      [style.left.%]="widthSize * indexSelected"
      [style.width.%]="widthSize"></div>
    <div class="cr-text">
      <div
        class="cr-button content-center"
        [ngClass]="{'cr-selected': selected === option.value}"
        *ngFor="let option of options; let i = index"
        [style.width.%]="widthSize"
        [id]="'cr-btn-' + option.value"
        (click)="changeValue(i, option.value)">
        {{option.text}}
      </div>
    </div>
  </div>
</div>
