import {
  Component,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  EventEmitter,
} from '@angular/core';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { Platform } from '@ionic/angular';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';
import { EasyDebugLogger } from '@app/easy-debug-logger/easy-debug-logger';

@EasyDebugDecorator
@Component({
  selector: 'image-comp',
  templateUrl: 'image.component.html',
  styleUrls: ['image.component.scss'],
})
export class ImageComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() imageEvent = new EventEmitter<any>();
  @Output() failEvent = new EventEmitter<any>();
  @ViewChild('imagePlayer', { static: true }) imagePlayer: any;

  display = false;
  debug = false;
  imageSize = 'medium';
  easyDebugLogger = EasyDebugLogger.getInstance();

  constructor(
    private webview: WebView,
    public platform: Platform
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    if (!!this.compOptions) {
      this.compOptions = compOptions.currentValue;
      this.setSrc();
    }
  }

  setSrc() {
    if (!!this.compOptions && !!this.compOptions.src) {
      this.imagePlayer.nativeElement.src = this.compOptions.src;
    }
    if (
      !!this.compOptions &&
      !!this.compOptions.debug &&
      this.compOptions.debug
    ) {
      this.debug = true;
    }
  }

  fallbackImg() {
    this.imagePlayer.nativeElement.src =
      './uikit-assets/imgs/image-non-disponible.png';
    this.failEvent.emit({ type: 'image', status: 'failed' });
  }

  imageLoaded() {
    this.display = true;
    this.imageEvent.emit({ loaded: true });
  }
}
