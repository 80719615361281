import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  HostListener,
  OnInit,
} from '@angular/core';

import * as moment from 'moment-timezone';
import { UtilsService } from '@commons/services';

import {
  AppInitService,
  StudentService,
  SeriesService,
  SessionsService,
  StorageService,
  UserErrorHandlerService,
} from '@app/services';

import { Session } from '../../../app/models/session';
import { Serie } from '../../../app/models/serie';
import { Student } from '../../../app/models/student';
import { Platform } from '@ionic/angular';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
class MyCanvas {
  // Elements
  canvas = document.createElement('canvas');
  canvasDone = false;
  parent: any;
  ctx: CanvasRenderingContext2D = null;

  // size
  parentWidth: number;
  parentHeight: number;
  halfWidth: number;
  halfHeight: number;

  // animation
  pauseAnimation = false;

  // controled FPS
  stop = false;
  fps: any;
  fpsInterval: any;
  startTime: any;
  now: any;
  then: any;
  elapsed: any;

  // fps
  currentSecond = 0;
  frameCount = 0;
  framesLastSecond = 0;

  constructor(parent: any) {
    if (!!parent) {
      this.parent = parent;
      this.canvas.style.display = 'block';
      this.ctx = this.canvas.getContext('2d');
      if (!this.canvasDone) {
        // console.log(this.canvasDone);
        this.parent.appendChild(this.canvas);
        this.canvasDone = true;
      }
    }
  }

  setSize(parentWidth: number, parentHeight: number) {
    if (!!parentWidth && !!parentHeight) {
      this.parentWidth = parentWidth;
      this.parentHeight = parentHeight;
      this.canvas.width = parentWidth;
      this.canvas.height = parentHeight;

      this.halfWidth = Math.floor(parentWidth / 2);
      this.halfHeight = Math.floor(parentHeight / 2);
    }
  }

  setFps(sec: any) {
    if (!!sec) {
      if (sec !== this.currentSecond) {
        this.currentSecond = sec;
        this.framesLastSecond = this.frameCount;
        this.frameCount = 1;
      } else {
        this.frameCount++;
      }
    }
  }

  showFps(x?: number, y?: number) {
    const posX = !!x ? x : this.parentWidth - 8;
    const posY = !!y ? y : 20;
    if (!!this.framesLastSecond) {
      this.text('FPS: ' + this.framesLastSecond, posX, posY, {
        textAlign: 'right',
      });
    }
  }

  clear() {
    if (!!this.canvas && !!this.ctx) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  text(text: string, x: number, y: number, params?: any) {
    let color: any = 'black';
    let bold: any = '';
    let size: any = '14px';
    let textAlign: any = 'left';
    let fontFamily: any = 'Arial';
    if (!!params) {
      if (!!params.color) {
        color = params.color;
      }
      if (!!params.bold) {
        bold = params.bold;
      }
      if (!!params.size) {
        size = params.size;
      }
      if (!!params.fontFamily) {
        fontFamily = params.fontFamily;
      }
      if (!!params.textAlign) {
        textAlign = params.textAlign;
      }
    }
    // TXT
    this.ctx.globalAlpha = 1;
    this.ctx.fillStyle = color;
    this.ctx.font = bold + size + ' ' + fontFamily;
    this.ctx.font = size + ' ' + fontFamily;
    this.ctx.textAlign = textAlign;
    this.ctx.fillText(text, x, y);
  }

  drawBg(color: string) {
    const myColor = color ? color : 'black';
    this.ctx.globalAlpha = 1;
    this.ctx.fillStyle = myColor;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }
}

@EasyDebugDecorator
@Component({
  selector: 'stats-progression-comp',
  templateUrl: 'stats-progression.html',
  styleUrls: ['stats-progression.scss'],
})
export class StatsProgressionComponent implements OnInit, OnChanges {
  @ViewChild('canvasContainer', { static: true }) canvasContainer: any;
  @Input() compOptions: any;

  debug = false;

  calledFrom = 'stats';
  type = 'Cdr::TrainingSession';
  noStatsEntrainements = true;
  noStatsExamens = true;
  swipeAction = '';

  componentFocused = false;

  isGuest = true;
  isRegistered = false;
  isEngaged = false;

  student: Student;
  userLifeCycle: any;
  sessions: Array<Session> = [];
  rangedSessions: Array<Session> = [];
  series: Array<Serie> = [];

  myCanvas: any;
  renderReady = false;

  refreshId: any;

  start_at: any;
  end_at: any;

  // note max
  nbAnswersRef = 40;

  // colors
  gray = '#9E9E9E';
  averageSquare = '#FFFFFF';
  averageLine = '#7F9AA4';
  chartLine = '#FB5607';
  dotScore = '#EA5B0C';

  // font size
  fontSize = 14;

  // padding
  padding = 10;

  // X / Y axes
  startX = 30;
  endX = 0;
  startY = 30;
  endY = 0;

  // steps Y
  stepYSize = 0;

  // coordinates X
  minCoordinatesX = 2;
  maxCoordinatesX = 8;
  coordinatesXSize = 50;
  page = 1;
  goBack = true;

  // labels X
  nbLabelCoordinatesX = this.maxCoordinatesX;

  // points X
  nbPointsCoordinatesX = this.maxCoordinatesX;

  // average score for pedriod
  averageScore = 0;

  dataReady = false;
  noData = false;
  fakeForNoDatas = [
    { date: '2020-10-12', note: 13 },
    { date: '2020-10-12', note: 12 },
    { date: '2020-10-14', note: 22 },
    { date: '2020-10-15', note: 28 },
    { date: '2020-10-16', note: 22 },
    { date: '2020-10-16', note: 18 },
    { date: '2020-10-16', note: 35 },
    { date: '2020-10-17', note: 40 },
  ];
  datas = this.fakeForNoDatas;

  usedDatas = [];

  isLoading = true;

  pointsCoordinates = [];
  addPointsCoordinates = true;

  isDesktop = window.innerWidth > 767 ? true : false;
  customRadioOptions = [
    { value: 'Cdr::TrainingSession', text: 'Entraînements' },
    {
      value: 'Cdr::ExamSession',
      text: this.isDesktop ? 'Examens blancs' : 'Examens',
    },
  ];

  seriesListFailed = false;
  getSessionsFailed = false;

  initServiceDone = false;
  initDataReady = false;

  isCordova = false;
  initPageDone = false; // for shimmers
  fnInitPageLaunched = false; // for controllers

  constructor(
    private appInitService: AppInitService,
    private studentService: StudentService,
    private seriesService: SeriesService,
    private sessionsService: SessionsService,
    private userErrorHandlerService: UserErrorHandlerService,
    private platform: Platform,
    private utils: UtilsService
  ) {
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest

    this.isCordova = this.platform.is('cordova');

    this.appInitService.onStepChange().subscribe(state => {
      if (state?.init >= 2 && state?.stepDone && !this.fnInitPageLaunched) {
        this.fnInitPageLaunched = true;
        this.init();
      }
      if (state?.init >= 4 && state?.stepDone && !this.initDataReady) {
        this.initDataReady = true;
        this.init();
      }
    });
  }

  // RESIZE EVENT OR ORIENTATIONS
  @HostListener('window:resize') onResize() {
    this.isDesktop = window.innerWidth > 767 ? true : false;
    this.customRadioOptions = [
      { value: 'Cdr::TrainingSession', text: 'Entraînements' },
      {
        value: 'Cdr::ExamSession',
        text: this.isDesktop ? 'Examens blancs' : 'Examens',
      },
    ];
    if (this.renderReady && !!this.myCanvas) {
      this.myCanvas.setSize(
        this.canvasContainer.nativeElement.offsetWidth,
        this.canvasContainer.nativeElement.offsetHeight
      );
      // reset tooltips
      this.addPointsCoordinates = true;
      this.pointsCoordinates = [];
      this.drawGraph();
    }
  }
  // MOUSE EVENTS
  @HostListener('mouseover') onMouseOver() {
    this.componentFocused = true;
  }
  @HostListener('mouseout') onMouseOut() {
    this.componentFocused = false;
  }
  @HostListener('mousedown', ['$event']) onMousedown(event: MouseEvent) {
    if (event.target === this.canvasContainer.nativeElement.firstChild) {
      this.checkCoordinates(event);
    }
  }
  @HostListener('mousemove', ['$event']) onMousemove(event: MouseEvent) {
    if (!this.isCordova && this.isDesktop) {
      if (event.target === this.canvasContainer.nativeElement.firstChild) {
        this.checkCoordinates(event);
      }
    }
  }
  @HostListener('touchstart', ['$event']) onTouchStart(event: any) {
    if (event.target === this.canvasContainer.nativeElement.firstChild) {
      this.checkCoordinates(event);
    }
  }
  // KEYBOARD EVENT
  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    if (
      event.key === 'ArrowLeft' &&
      this.componentFocused &&
      this.renderReady &&
      this.goBack
    ) {
      this.changePage(1);
    }
    if (
      event.key === 'ArrowRight' &&
      this.componentFocused &&
      this.renderReady &&
      this.page > 1
    ) {
      this.changePage(-1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  async init() {
    this.setGlobals();
    this.student = this.studentService.student;
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student);
    // const resSeriesList = await this.seriesService.seriesContext.getSeriesList(this.student);
    // if (!!resSeriesList && !!resSeriesList.errorCode && resSeriesList.errorCode === 'E301') {
    //   this.seriesListFailed = true;
    // } else {
    //   this.series = resSeriesList;
    // }
    // this.series = (!!this.series && this.series.length > 0) ? (this.series).filter(elt => elt.type !== 'Discovery') : [];
    if ((!this.isCordova && this.initDataReady) || this.isCordova) {
      const resgetSessions =
        await this.sessionsService.sessionsContext.getSessions(this.student);
      if (
        !!resgetSessions &&
        !!resgetSessions.errorCode &&
        resgetSessions.errorCode === 'E301'
      ) {
        this.sessions = [];
        this.getSessionsFailed = true;
        this.userErrorHandlerService.addError({
          criticity: 30,
          service: 'fetchSessions',
          platform: 'both',
          data: resgetSessions.errorOriginal,
          errorCode: 'ssfss',
        });
      } else {
        this.sessions = resgetSessions;
      }
      this.sessions =
        !!this.sessions && this.sessions.length > 0
          ? this.sessions.filter(elt => elt.type !== 'Discovery')
          : [];

      // console.log('student', this.student);
      // console.log('series', this.series);
      // console.log('sessions', this.sessions);
      if (!!this.student) {
        if (this.userLifeCycle.isUserRegistered) {
          this.isGuest = false;
          this.isRegistered = true;
          this.isEngaged = false;
        } else if (
          !this.userLifeCycle.isUserGuest &&
          !this.userLifeCycle.isUserRegistered
        ) {
          this.isGuest = false;
          this.isRegistered = false;
          this.isEngaged = true;
        }
        this.calculateDatas();
      }
    }
  }

  ngOnInit() {
    this.setNoStats();
    this.waitForRendering();
  }

  setGlobals() {
    this.debug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.debug;
    this.calledFrom =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.calledFrom)
        ? this.compOptions.calledFrom
        : this.calledFrom;
    this.swipeAction =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.swipeAction)
        ? this.compOptions.swipeAction
        : this.swipeAction;

    if (this.dataReady && this.renderReady) {
      this.processSwipe();
    }
  }

  getCustomRadioSelection(event) {
    if (!!event) {
      this.type = event;
      this.calculateDatas();
    }
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  calculateDatas() {
    if (!this.isGuest && this.sessions.length > 0) {
      this.pointsCoordinates = [];
      this.addPointsCoordinates = true;
      this.rangedSessions = this.sessionsService.addRangedSession(
        this.sessions,
        'Tout'
      );
      const sortedRangedSessions = this.rangedSessions.sort((a, b) => {
        // DATE TO CHANGE
        return +new Date(a.lastAnsweredAt) - +new Date(b.lastAnsweredAt);
      });
      this.datas = [];
      for (const session of sortedRangedSessions) {
        if (session.state !== 'pending' && this.type === session.mode) {
          let note = 0;
          if (session.answersCount !== this.nbAnswersRef) {
            note = Math.round(
              (session.stats.goodAnswers * this.nbAnswersRef) /
                session.answersCount
            );
          } else {
            note = Math.round(session.stats.goodAnswers);
          }
          this.datas.push({
            date: session.lastAnsweredAt,
            note: note,
          });
        }
      }
      if (this.datas.length === 0) {
        this.setNoStats();
      } else {
        this.noData = false;
        this.noStatsEntrainements =
          this.type === 'Cdr::TrainingSession' ? false : true;
        this.noStatsExamens = this.type === 'Cdr::ExamSession' ? false : true;
      }
    } else {
      this.setNoStats();
    }
    // TEMP : add a fake data to test 1 result per page
    // this.datas.unshift({date: '2019-11-01T05:00:00.000+01:00', note: 40});

    this.dataReady = true;
    this.parseData();
    if (!this.renderReady) {
      this.waitForRendering();
    } else {
      this.drawGraph();
    }
  }

  setNoStats() {
    this.noStatsEntrainements =
      this.type === 'Cdr::TrainingSession' ? true : false;
    this.noStatsExamens = this.type === 'Cdr::ExamSession' ? true : false;
    this.datas = this.fakeForNoDatas;
    this.noData = true;
    this.parseData();
  }

  waitForRendering() {
    this.refreshId = setTimeout(() => {
      const condition =
        !!this.canvasContainer.nativeElement.offsetWidth &&
        this.canvasContainer.nativeElement.offsetWidth > 0 &&
        !!this.canvasContainer.nativeElement.offsetHeight &&
        this.canvasContainer.nativeElement.offsetHeight > 0;
      if (condition || this.renderReady) {
        clearTimeout(this.refreshId);

        // CANVAS
        // this.canvasContainer.nativeElement.innerHTML = '';
        if (!this.myCanvas) {
          this.myCanvas = new MyCanvas(this.canvasContainer.nativeElement);
        }
        if (!!this.myCanvas) {
          this.myCanvas.setSize(
            this.canvasContainer.nativeElement.offsetWidth,
            this.canvasContainer.nativeElement.offsetHeight
          );
          this.drawGraph();
        }
        // RENDERING READY
        if (this.dataReady) {
          this.renderReady = true;
        }
      } else {
        clearTimeout(this.refreshId);
      }
    }, 60);
  }

  processSwipe() {
    if (this.swipeAction !== '') {
      if (
        this.swipeAction.substring(0, 5) === 'right' &&
        this.componentFocused &&
        this.renderReady &&
        this.goBack
      ) {
        this.changePage(1);
      }
      if (
        this.swipeAction.substring(0, 4) === 'left' &&
        this.componentFocused &&
        this.renderReady &&
        this.page > 1
      ) {
        this.changePage(-1);
      }
      this.swipeAction = '';
    }
  }

  changePage(value) {
    if (!this.noData) {
      // reset tooltips
      this.addPointsCoordinates = true;
      this.pointsCoordinates = [];

      this.page += value;
      this.parseData();
      this.drawGraph();
    }
  }

  drawGraph() {
    this.calculateRendering();
    this.myCanvas.clear();
    this.drawReperes();
    this.drawData();
  }

  calculateRendering() {
    // X / Y axes
    this.endX = this.myCanvas.parentWidth - 20;
    this.endY = this.myCanvas.parentHeight - 30;

    // steps Y
    this.stepYSize = Math.floor((this.endY - this.startY) / 4);
  }

  parseData() {
    this.usedDatas = [];
    let from = this.datas.length - this.maxCoordinatesX * this.page;
    if (from <= 0) {
      from = 0;
      this.goBack = false;
    } else {
      this.goBack = true;
    }
    const to = this.datas.length - this.maxCoordinatesX * (this.page - 1);
    if (from === 0 && to === 0) {
      this.goBack = false;
    }
    this.usedDatas = this.datas.slice(from, to);

    if (this.usedDatas.length > 0) {
      // date Start_at / end_at
      let date: moment.Moment;
      let cleanDay;
      let cleanMonth;
      // DATE TO CHANGE
      date = this.dateToFormatFr(this.usedDatas[0].date);
      cleanDay = date.date() < 10 ? '0' + date.date() : date.date();
      cleanMonth =
        date.month() + 1 < 10 ? '0' + (date.month() + 1) : date.month() + 1;
      this.start_at = cleanDay + '/' + cleanMonth;

      // DATE TO CHANGE
      date = this.dateToFormatFr(
        this.usedDatas[this.usedDatas.length - 1].date
      );
      cleanDay = date.date() < 10 ? '0' + date.date() : date.date();
      cleanMonth =
        date.month() + 1 < 10 ? '0' + (date.month() + 1) : date.month() + 1;
      this.end_at = cleanDay + '/' + cleanMonth;

      this.nbLabelCoordinatesX = this.usedDatas.length;
      this.nbPointsCoordinatesX = this.usedDatas.length;

      // average score
      let sum = 0;
      for (const data of this.usedDatas) {
        sum += data.note;
      }
      this.averageScore = Math.round(sum / this.usedDatas.length);
    }
  }

  drawReperes() {
    // dashed lines
    const dashSize = 8;

    // line config
    this.myCanvas.ctx.lineWidth = 1;
    this.myCanvas.ctx.strokeStyle = this.gray;

    // draw reperes
    // this.myCanvas.ctx.beginPath();
    // this.myCanvas.ctx.setLineDash([]);
    // this.myCanvas.ctx.moveTo(this.startX, this.startY);
    // this.myCanvas.ctx.lineTo(this.startX, this.endY);
    // this.myCanvas.ctx.lineTo(this.endX, this.endY);
    // this.myCanvas.ctx.stroke();

    // add dashed lines
    // for (let i = 0; i <= 3; i++) {
    //   this.myCanvas.ctx.beginPath();
    //   this.myCanvas.ctx.setLineDash([dashSize]);
    //   this.myCanvas.ctx.moveTo(this.startX, this.startY + (i * this.stepYSize));
    //   this.myCanvas.ctx.lineTo(this.endX, this.startY + (i * this.stepYSize));
    //   this.myCanvas.ctx.stroke();
    // }

    // add Y coordinates
    if (!this.noData) {
      for (let i = 0; i <= 4; i++) {
        if (i === 0 || i === 2 || i === 4) {
          this.myCanvas.text(
            this.nbAnswersRef - i * 10,
            this.startX - this.padding,
            this.startY + i * this.stepYSize + this.fontSize / 2,
            { textAlign: 'right', color: this.gray }
          );
        }
      }
    }

    // calculate nb X coordinates
    for (let i = this.nbLabelCoordinatesX; i >= this.minCoordinatesX; i--) {
      this.nbLabelCoordinatesX = i;
      if (
        (this.coordinatesXSize + this.padding) * i <=
        this.myCanvas.parentWidth
      ) {
        break;
      }
    }
  }

  drawData() {
    const stepLabelXSize =
      (this.endX - this.startX) / (this.nbLabelCoordinatesX - 1);
    const stepPointsXSize =
      (this.endX - this.startX) / (this.nbPointsCoordinatesX - 1);
    const stepYPointSize = (this.endY - this.startY) / this.nbAnswersRef;
    let cpt = 0;
    let posX = 0;
    let posY = 0;

    // add date X coordinates
    if (!this.noData) {
      for (let i = 0; i < this.nbLabelCoordinatesX; i++) {
        let index = i;
        if (this.nbLabelCoordinatesX < this.nbPointsCoordinatesX) {
          if (i === 0) {
            index = 0;
          } else if (i === this.nbLabelCoordinatesX - 1) {
            index = this.usedDatas.length - 1;
          } else {
            index = Math.floor(
              (this.nbPointsCoordinatesX / this.nbLabelCoordinatesX) * i
            );
          }
        }
        // DATE TO CHANGE
        const date = this.dateToFormatFr(this.usedDatas[index].date);
        const cleanDay = date.date() < 10 ? '0' + date.date() : date.date();
        const cleanMonth =
          date.month() + 1 < 10 ? '0' + (date.month() + 1) : date.month() + 1;
        const cleanDate = cleanDay + '/' + cleanMonth;
        let finalStartX = this.startX + i * stepLabelXSize;
        if (this.usedDatas.length === 1) {
          finalStartX = this.startX + (this.endX - this.startX) / 2;
        }
        // this.myCanvas.text(cleanDate, finalStartX, this.endY + this.padding + this.fontSize, {textAlign: 'center', color: this.gray});
      }
    }

    // draw average score
    if (!this.noData) {
      const dashSize = 10;

      posY = this.endY - Math.floor(35 * stepYPointSize);
      this.myCanvas.ctx.beginPath();
      this.myCanvas.ctx.setLineDash([dashSize]);
      this.myCanvas.ctx.moveTo(this.startX - 10, posY);
      this.myCanvas.ctx.lineTo(this.endX, posY);
      this.myCanvas.ctx.strokeStyle = this.averageLine;
      this.myCanvas.ctx.lineWidth = 0.5;
      this.myCanvas.ctx.stroke();

      // draw average rounded rectangle with average score text
      const rectW = this.startX - this.padding / 2;
      const rectH = 20;
      const rectRadius = 4;
      const rectX = (this.startX - rectW) / 2;
      const rectY = posY - rectH / 2;
      this.roundRect(
        this.myCanvas.ctx,
        rectX,
        rectY,
        rectW,
        rectH,
        rectRadius,
        false,
        '',
        false,
        '',
        1,
        false,
        'average'
      );
      this.myCanvas.text(
        35,
        (rectX + rectW) / 2 - 1.5,
        posY + (rectH / 2 - this.fontSize / 2.3),
        { textAlign: 'center', color: this.gray, size: '14px' }
      );
    }

    // add line between points X coordinates
    let highestNote = 0;

    posX = this.startX;
    posY = this.endY - Math.floor(this.usedDatas[0].note * stepYPointSize);

    posX = this.startX;
    posY = this.endY - Math.floor(this.usedDatas[0].note * stepYPointSize);

    const myPointsBg = [posX, posY];
    for (let i = 1; i < this.usedDatas.length; i++) {
      posY = this.endY - Math.floor(this.usedDatas[i].note * stepYPointSize);
      highestNote = posY > highestNote ? posY : highestNote;
      posX = this.startX + i * stepPointsXSize;
      myPointsBg.push(posX);
      myPointsBg.push(posY);
    }

    this.drawCurve(this.myCanvas.ctx, myPointsBg);

    // en bas à droite
    this.myCanvas.ctx.lineTo(posX, this.endY);
    // en bas à gauche
    this.myCanvas.ctx.lineTo(this.startX, this.endY);
    // et on rejoint à la première note
    this.myCanvas.ctx.lineTo(
      this.startX,
      this.endY - Math.floor(this.usedDatas[0].note * stepYPointSize)
    );

    this.myCanvas.ctx.globalAlpha = 0.7;
    const grd: any = this.myCanvas.ctx.createLinearGradient(0, 0, 0, this.endY);
    grd.addColorStop(0, '#FFECC9');
    grd.addColorStop(0.7, '#FFE7B4');
    grd.addColorStop(1, '#FFFFFF');

    this.myCanvas.ctx.fillStyle = grd;
    this.myCanvas.ctx.fill();
    this.myCanvas.ctx.globalAlpha = 1;

    // then draw line of chart

    posX = this.startX;
    posY = this.endY - Math.floor(this.usedDatas[0].note * stepYPointSize);

    const myPoints = [posX, posY];
    for (let i = 1; i < this.usedDatas.length; i++) {
      posY = this.endY - Math.floor(this.usedDatas[i].note * stepYPointSize);
      posX = this.startX + i * stepPointsXSize;
      myPoints.push(posX);
      myPoints.push(posY);
    }

    this.drawCurve(this.myCanvas.ctx, myPoints);

    this.myCanvas.ctx.strokeStyle = this.chartLine;
    this.myCanvas.ctx.lineWidth = 2;
    this.myCanvas.ctx.stroke();

    // add points X coordinates
    cpt = 0;
    for (const data of this.usedDatas) {
      posY = this.endY - Math.floor(data.note * stepYPointSize);
      this.myCanvas.ctx.beginPath();
      let finalStartX = this.startX + cpt * stepPointsXSize;
      if (this.usedDatas.length === 1) {
        finalStartX = this.startX + (this.endX - this.startX) / 2;
      }
      if (this.addPointsCoordinates) {
        this.pointsCoordinates.push({
          x: finalStartX,
          y: posY,
          value: data.note,
          showTooltip: false,
          date: data.date,
        });
      }
      this.myCanvas.ctx.arc(finalStartX, posY, 4, 0, 2 * Math.PI, false);
      this.myCanvas.ctx.fillStyle = this.noData ? this.chartLine : 'white';
      this.myCanvas.ctx.fill();
      this.myCanvas.ctx.lineWidth = 2;
      this.myCanvas.ctx.strokeStyle = this.noData
        ? this.chartLine
        : this.dotScore;
      this.myCanvas.ctx.stroke();
      cpt++;
    }
    if (!this.noData && this.addPointsCoordinates) {
      this.pointsCoordinates[this.pointsCoordinates.length - 1].showTooltip =
        true;
    }

    // add tooltips
    this.drawTooltips();
  }

  drawCurve(
    ctx,
    ptsa,
    tension = 0.5,
    isClosed = false,
    numOfSegments = 16,
    showPoints = false
  ) {
    isClosed = isClosed ? isClosed : false;
    numOfSegments = numOfSegments ? numOfSegments : 16;
    showPoints = showPoints ? showPoints : false;

    this.myCanvas.ctx.beginPath();
    this.myCanvas.ctx.setLineDash([]);

    this.drawLines(
      ctx,
      this.getCurvePoints(ptsa, tension, isClosed, numOfSegments)
    );
  }

  getCurvePoints(pts, tension, isClosed, numOfSegments) {
    // use input value if provided, or use a default value
    tension = typeof tension !== 'undefined' ? tension : 0.5;
    isClosed = isClosed ? isClosed : false;
    numOfSegments = numOfSegments ? numOfSegments : 16;

    let _pts = [];
    const res = []; // clone array
    let x,
      y, // our x,y coords
      t1x,
      t2x,
      t1y,
      t2y, // tension vectors
      c1,
      c2,
      c3,
      c4, // cardinal points
      st,
      t,
      i; // steps based on num. of segments

    // clone array so we don't change the original
    //
    _pts = pts.slice(0);

    // The algorithm require a previous and next point to the actual point array.
    // Check if we will draw closed or open curve.
    // If closed, copy end points to beginning and first points to end
    // If open, duplicate first points to befinning, end points to end
    if (isClosed) {
      _pts.unshift(pts[pts.length - 1]);
      _pts.unshift(pts[pts.length - 2]);
      _pts.unshift(pts[pts.length - 1]);
      _pts.unshift(pts[pts.length - 2]);
      _pts.push(pts[0]);
      _pts.push(pts[1]);
    } else {
      _pts.unshift(pts[1]); // copy 1. point and insert at beginning
      _pts.unshift(pts[0]);
      _pts.push(pts[pts.length - 2]); // copy last point and append
      _pts.push(pts[pts.length - 1]);
    }

    // ok, lets start..

    // 1. loop goes through point array
    // 2. loop goes through each segment between the 2 pts + 1e point before and after
    for (let i = 2; i < _pts.length - 4; i += 2) {
      for (t = 0; t <= numOfSegments; t++) {
        // calc tension vectors
        t1x = (_pts[i + 2] - _pts[i - 2]) * tension;
        t2x = (_pts[i + 4] - _pts[i]) * tension;

        t1y = (_pts[i + 3] - _pts[i - 1]) * tension;
        t2y = (_pts[i + 5] - _pts[i + 1]) * tension;

        // calc step
        st = t / numOfSegments;

        // calc cardinals
        c1 = 2 * Math.pow(st, 3) - 3 * Math.pow(st, 2) + 1;
        c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2);
        c3 = Math.pow(st, 3) - 2 * Math.pow(st, 2) + st;
        c4 = Math.pow(st, 3) - Math.pow(st, 2);

        // calc x and y cords with common control vectors
        x = c1 * _pts[i] + c2 * _pts[i + 2] + c3 * t1x + c4 * t2x;
        y = c1 * _pts[i + 1] + c2 * _pts[i + 3] + c3 * t1y + c4 * t2y;

        // store points in array
        res.push(x);
        res.push(y);
      }
    }
    return res;
  }

  drawLines(ctx, pts) {
    ctx.moveTo(pts[0], pts[1]);
    for (let i = 2; i < pts.length - 1; i += 2) {
      ctx.lineTo(pts[i], pts[i + 1]);
    }
  }

  roundRect(
    ctx,
    x,
    y,
    width,
    height,
    radius,
    fill = false,
    fillColor = null,
    stroke = false,
    strokeColor = null,
    lineWidth = 1,
    shadow = false,
    name = ''
  ) {
    if (!radius) {
      radius = 5;
    }
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (const side in defaultRadius) {
        if (radius[side]) {
          radius[side] = radius[side] || defaultRadius[side];
        }
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fillColor) {
      ctx.fillStyle = fillColor;
    }
    if (fill) {
      if (shadow) {
        ctx.globalAlpha = 0.1;
        ctx.shadowColor = '#000000';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 3;
        ctx.fill();
        ctx.globalAlpha = 1;
        ctx.shadowColor = 'black';
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
      } else {
        ctx.fill();
      }
    }
    if (strokeColor) {
      ctx.strokeStyle = strokeColor;
    }
    if (lineWidth) {
      ctx.lineWidth = lineWidth;
    }
    if (stroke) {
      ctx.stroke();
    }
    // comprends pas il est interprété comme false quand il est true et inversement
    // if (!shadow) {
    //   ctx.shadowColor = '#E9E9E9';
    //   ctx.shadowBlur = 6;
    //   ctx.shadowOffsetX = 0;
    //   ctx.shadowOffsetY = 3;
    // } else {
    //   ctx.shadowBlur = 0;
    //   ctx.shadowOffsetX = 0;
    //   ctx.shadowOffsetY = 0;
    // }
  }

  drawTooltips() {
    clearInterval(this.refreshId);
    const rectRadius = 6;
    // console.log(this.pointsCoordinates);
    for (const point of this.pointsCoordinates) {
      if (point.showTooltip) {
        this.myCanvas.ctx.font = '14px Ubuntu';
        // DATE TO CHANGE
        const date = this.dateToFormatFr(point.date);
        const strDate = date.date() + ' ' + date.format('MMMM');
        const strDateWidth = this.myCanvas.ctx.measureText(strDate).width;

        this.myCanvas.ctx.font = '20px Ubuntu';
        const separator = ' | ';
        const separatorWidth = this.myCanvas.ctx.measureText(separator).width;

        this.myCanvas.ctx.font = 'bold 20px Ubuntu';
        const strNote = point.value;
        const strNoteWidth = this.myCanvas.ctx.measureText(strNote).width;

        const rectW = strDateWidth + separatorWidth + strNoteWidth + 16;
        const rectH = 34;

        let upArrow = false;
        let rectX = point.x - rectW / 2 - 2; // -2 for right border limit
        let rectY = point.y - rectH - 16;
        if (rectY < 0) {
          upArrow = true;
          rectY = point.y + rectH / 2 + 2;
        }
        // check canvas limit
        if (rectX <= this.startX) {
          rectX = this.startX - 20;
        }
        if (rectX + rectW >= this.endX) {
          rectX = this.endX + 20 - rectW - 2;
        }

        // add triangle
        if (!upArrow) {
          this.roundRect(
            this.myCanvas.ctx,
            rectX,
            rectY,
            rectW,
            rectH,
            rectRadius,
            true,
            'white',
            false,
            this.gray,
            1,
            true,
            'tooltip'
          );
          this.roundRect(
            this.myCanvas.ctx,
            rectX,
            rectY,
            rectW,
            rectH,
            rectRadius,
            true,
            'white',
            false,
            this.gray,
            1,
            false,
            'tooltip'
          );

          this.myCanvas.ctx.globalAlpha = 0.2;
          this.myCanvas.ctx.shadowColor = '#000000';
          this.myCanvas.ctx.shadowBlur = 3;
          this.myCanvas.ctx.shadowOffsetX = 0;
          this.myCanvas.ctx.shadowOffsetY = 3;
          this.myCanvas.ctx.beginPath();
          this.myCanvas.ctx.moveTo(point.x - 5, rectY + rectH - 2);
          this.myCanvas.ctx.lineTo(point.x + 5, rectY + rectH - 2);
          this.myCanvas.ctx.lineTo(point.x, rectY + rectH + 6);
          this.myCanvas.ctx.closePath();
          this.myCanvas.ctx.fillStyle = 'white';
          this.myCanvas.ctx.fill();

          this.myCanvas.ctx.globalAlpha = 1;
          this.myCanvas.ctx.shadowColor = 'black';
          this.myCanvas.ctx.shadowBlur = 0;
          this.myCanvas.ctx.shadowOffsetX = 0;
          this.myCanvas.ctx.shadowOffsetY = 0;
          this.myCanvas.ctx.beginPath();
          this.myCanvas.ctx.moveTo(point.x - 5, rectY + rectH - 2);
          this.myCanvas.ctx.lineTo(point.x + 5, rectY + rectH - 2);
          this.myCanvas.ctx.lineTo(point.x, rectY + rectH + 6);
          this.myCanvas.ctx.closePath();
          this.myCanvas.ctx.fillStyle = 'white';
          this.myCanvas.ctx.fill();
        } else {
          this.myCanvas.ctx.globalAlpha = 0.2;
          this.myCanvas.ctx.shadowColor = '#000000';
          this.myCanvas.ctx.shadowBlur = 3;
          this.myCanvas.ctx.shadowOffsetX = 0;
          this.myCanvas.ctx.shadowOffsetY = 3;
          this.myCanvas.ctx.beginPath();
          this.myCanvas.ctx.moveTo(point.x - 5, rectY + 2);
          this.myCanvas.ctx.lineTo(point.x + 5, rectY + 2);
          this.myCanvas.ctx.lineTo(point.x, rectY - 6);
          this.myCanvas.ctx.closePath();
          this.myCanvas.ctx.fillStyle = 'white';
          this.myCanvas.ctx.fill();

          this.myCanvas.ctx.globalAlpha = 1;
          this.myCanvas.ctx.shadowColor = 'black';
          this.myCanvas.ctx.shadowBlur = 0;
          this.myCanvas.ctx.shadowOffsetX = 0;
          this.myCanvas.ctx.shadowOffsetY = 0;
          this.myCanvas.ctx.beginPath();
          this.myCanvas.ctx.moveTo(point.x - 5, rectY + 2);
          this.myCanvas.ctx.lineTo(point.x + 5, rectY + 2);
          this.myCanvas.ctx.lineTo(point.x, rectY - 6);
          this.myCanvas.ctx.closePath();
          this.myCanvas.ctx.fillStyle = 'white';
          this.myCanvas.ctx.fill();

          this.roundRect(
            this.myCanvas.ctx,
            rectX,
            rectY,
            rectW,
            rectH,
            rectRadius,
            true,
            'white',
            false,
            this.gray,
            1,
            true,
            'tooltip'
          );
          this.roundRect(
            this.myCanvas.ctx,
            rectX,
            rectY,
            rectW,
            rectH,
            rectRadius,
            true,
            'white',
            false,
            this.gray,
            1,
            false,
            'tooltip'
          );
        }

        this.myCanvas.text(
          strDate,
          rectX + 8,
          rectY + (rectH / 2 + 14 / 2 - 2),
          {
            textAlign: 'left',
            color: '#494E5A',
            size: '14px',
            fontFamily: 'Ubuntu',
          }
        );
        this.myCanvas.text(
          separator,
          rectX + 8 + strDateWidth,
          rectY + (rectH / 2 + 16 / 2 - 2),
          {
            textAlign: 'left',
            color: '#F4F4F4',
            size: '20px',
            fontFamily: 'Ubuntu',
          }
        );
        this.myCanvas.text(
          strNote,
          rectX + 8 + strDateWidth + separatorWidth,
          rectY + (rectH / 2 + 16 / 2 - 2),
          {
            textAlign: 'left',
            color: '#2A2D34',
            size: '20px',
            bold: 'bold',
            fontFamily: 'Ubuntu',
          }
        );
        this.myCanvas.text(
          strNote,
          rectX + 7 + strDateWidth + separatorWidth,
          rectY + (rectH / 2 + 16 / 2 - 2),
          {
            textAlign: 'left',
            color: '#2A2D34',
            size: '20px',
            bold: 'bold',
            fontFamily: 'Ubuntu',
          }
        );
      }
    }
  }

  checkCoordinates(event: any) {
    if (this.renderReady && !this.noData) {
      // console.log(this.pointsCoordinates);
      const posX =
        this.utils.unify(event).clientX -
        this.canvasContainer.nativeElement.getBoundingClientRect().x;
      const posY =
        this.utils.unify(event).clientY -
        this.canvasContainer.nativeElement.getBoundingClientRect().y;
      const detectRange = 20;
      let foundPoint = null;
      for (const point of this.pointsCoordinates) {
        if (
          posX >= point.x - detectRange &&
          posX <= point.x + detectRange &&
          posY >= point.y - detectRange &&
          posY <= point.y + detectRange
        ) {
          foundPoint = point;
          point.showTooltip = true;
        }
      }
      for (const point of this.pointsCoordinates) {
        point.showTooltip = foundPoint === point ? true : false;
      }
      this.addPointsCoordinates = false;
      this.drawGraph();
    }
  }
}
