import { StudentService } from './student.service';
import { SeriesService } from './series.service';
import { Initialable } from './app-init.service';
import { QuestionsService } from './questions.service';

import { Injectable } from '@angular/core';

import { File } from '@awesome-cordova-plugins/file/ngx';
import {
  FileTransfer,
  FileTransferObject,
} from '@awesome-cordova-plugins/file-transfer/ngx';
import { Platform } from '@ionic/angular';

import { Serie } from '../models/serie';
import { Question } from '../models/question';
import { Student } from '../models/student';
import { Subject } from 'rxjs';
import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { EasyDebugLogger } from '@app/easy-debug-logger/easy-debug-logger';

@Injectable({ providedIn: 'root' })
@Initialable({ step: `init3`, initializer: `init` })
@EasyDebugDecorator
export class AssetsService {
  easyDebugLogger = EasyDebugLogger.getInstance();
  abortDownload = false;
  toDownload = false;
  seriesToDownload: Array<Serie> = [];
  seriesToDelete: Array<Serie> = [];
  downloadedSeries: Array<Serie> = [];
  fileTransfer: FileTransferObject = null;
  videoList: Array<string> = ['answer_video', 'question_video'];
  imgList: Array<string> = [
    'answer_img1',
    'answer_img2',
    'question_img1',
    'question_img2',
  ];
  audioList: Array<string> = ['answer_audio', 'question_audio'];
  downloadingSeries: Array<any> = [];
  deletingSeries: Array<any> = [];
  downloadingSeriesObs$ = new Subject<any>();
  downloadedSeriesObs$ = new Subject<any>();
  deletingSeriesObs$ = new Subject<any>();
  deletedSeriesObs$ = new Subject<any>();
  student: Student;
  deletingSubsciption;
  downloadingSubsciption;

  constructor(
    private transfer: FileTransfer,
    public file: File,
    public platform: Platform,
    private seriesService: SeriesService,
    private questionsService: QuestionsService,
    private networkService: NetworkStatusService,
    private studentService: StudentService,
    private storageService: StorageService
  ) {}

  async init() {
    // console.log(`Launching Assets init`);
    this.fileTransfer = this.transfer.create();
    if (this.platform.is('cordova')) {
      this.fileTransfer = this.transfer.create();
      this.networkService.networkStatusObs$.subscribe(isNetworkOnline => {
        if (isNetworkOnline) {
          this.abortDownload = false;
        } else {
          this.abortDownload = true;
        }
      });

      const student = await this.studentService.getProfil();

      // console.log('student init assets => ', student);

      if (!!this.downloadingSubsciption) {
        this.downloadingSubsciption.unsubscribe();
      }

      if (!!this.deletingSubsciption) {
        this.deletingSubsciption.unsubscribe();
      }

      if (!!student && student.remoteId !== 'GUEST') {
        let dlList: any[] = await this.storageService.get(
          `${student.remoteId}-dlList`
        );
        if (!!dlList && dlList.length > 0) {
          this.downloadingSeries = dlList;
          this.launchDownload(student, true);
        }

        let rmList: any[] = await this.storageService.get(
          `${student.remoteId}-rmList`
        );
        if (!!rmList && rmList.length > 0) {
          this.deletingSeries = rmList;
          this.launchDelete(student, true);
        }

        this.downloadingSubsciption = this.downloadingSeriesObs$.subscribe(
          async obj => {
            // console.log('dl obj => ', obj);
            if (
              this.downloadingSeries.findIndex(elt => elt.id === obj.id) === -1
            ) {
              this.downloadingSeries.push(obj);
              dlList = await this.storageService.get(
                `${student.remoteId}-dlList`
              );
              if (!!dlList && dlList.length > 0) {
                if (dlList.findIndex(elt => elt.id === obj.id) !== -1) {
                  dlList[dlList.findIndex(elt => elt.id === obj.id)] = obj;
                } else {
                  dlList.push(obj);
                }
                dlList = await this.storageService.set(
                  `${student.remoteId}-dlList`,
                  dlList
                );
              }
              this.launchDownload(student);
            }
          }
        );

        this.deletingSubsciption = this.deletingSeriesObs$.subscribe(
          async obj => {
            // console.log('delete obj => ', JSON.stringify(obj));
            // console.log('delete series => ', JSON.stringify(this.deletingSeries.findIndex(elt => elt.id === obj.id)));
            if (
              this.deletingSeries.findIndex(elt => elt.id === obj.id) === -1
            ) {
              this.deletingSeries.push(obj);
              rmList = await this.storageService.get(
                `${student.remoteId}-rmList`
              );
              if (!!rmList && rmList.length > 0) {
                if (rmList.findIndex(elt => elt.id === obj.id) !== -1) {
                  rmList[rmList.findIndex(elt => elt.id === obj.id)] = obj;
                } else {
                  rmList.push(obj);
                }
                rmList = await this.storageService.set(
                  `${student.remoteId}-rmList`,
                  rmList
                );
              }
              this.launchDelete(student);
            }
          }
        );
      }
    }
    // console.log(`Assets init done`);
    return 'Assets done';
  }

  async getDownloadedSeries(student: Student): Promise<string[]> {
    return this.storageService.get(`${student.remoteId}-downloadedSeries`);
  }

  async setDownloadedSeries(downloadedSeries: string[], student: Student) {
    return this.storageService.set(
      `${student.remoteId}-downloadedSeries`,
      downloadedSeries
    );
  }

  async launchDownload(student: Student, force = false) {
    if (
      (!!this.downloadingSeries && this.downloadingSeries.length === 1) ||
      force
    ) {
      while (this.downloadingSeries.length > 0) {
        const serie = await this.seriesService.seriesContext.getSerie(
          this.downloadingSeries[0].id,
          student,
          true
        );
        const dlSerie = await this.downloadSerie(serie, student);
        if (!!dlSerie && dlSerie.downloadState === 'downloaded') {
          this.removeFromDownloadList(serie.id);
          this.downloadedSeriesObs$.next(serie.id);
        }
      }
    }
  }

  async launchDelete(student: Student, force = false) {
    // console.log('delete series => ', JSON.stringify(this.deletingSeries));
    if ((!!this.deletingSeries && this.deletingSeries.length === 1) || force) {
      while (this.deletingSeries.length > 0) {
        // console.log('deleting series before delete => ', JSON.stringify(this.deletingSeries));
        const serie = await this.seriesService.seriesContext.getSerie(
          this.deletingSeries[0].id,
          student,
          true,
          true
        );
        // console.log('serie to delete => ', JSON.stringify(serie));
        await this.deleteSerie(serie, student);
        this.removeFromDeleteList(serie.id);
        // console.log('deleting series after delete => ', this.deletingSeries);
        this.deletedSeriesObs$.next(serie.id);
      }
    }
  }

  openDownload() {
    if (this.toDownload) {
      this.toDownload = false;
    } else {
      this.toDownload = true;
    }
  }

  bytesToSize(bytes: any): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Bytes';
    }
    const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10);
    if (i === 0) {
      return `${bytes} ${sizes[i]})`;
    }
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }

  async getFreeDiskSpace(): Promise<string> {
    const space = await this.file.getFreeDiskSpace();
    if (this.platform.is('cordova') && this.platform.is('ios')) {
      return this.bytesToSize(space);
    } else {
      return this.bytesToSize(space * 1000);
    }
  }

  async downloaded(serie: Serie, student: Student): Promise<Serie> {
    serie.downloadState = 'downloaded';

    // console.log('student => ', student);
    const downloadedSeries = (await this.getDownloadedSeries(student)) || [];

    if (!downloadedSeries.includes(serie.id)) {
      downloadedSeries.push(serie.id);
    }

    await this.setDownloadedSeries(downloadedSeries, student);

    const series =
      await this.seriesService.seriesContext.getSeriesList(student);
    if (!!series && series.length > 0) {
      series.find(elt => elt.id === serie.id).downloadState = 'downloaded';
    }
    await this.seriesService.seriesContext.setSeriesList(series, student);
    return await this.seriesService.seriesContext.setSerie(serie, student);
  }

  async downloadFile(url: string, dir: string, filename: string): Promise<any> {
    return await this.fileTransfer
      .download(url, `${this.file.dataDirectory}${dir}/${filename}`)
      .then(path => {
        return path.nativeURL;
      })
      .catch(err => {
        return err;
      });
  }

  changeOriginalExtension(url: string, size: string, extension = null): string {
    if (!!url) {
      if (!!extension) {
        const replaced = url.replace(/original/, size);
        const subed = replaced.substr(0, replaced.lastIndexOf('.'));
        const final = subed + extension;
        return final;
      } else {
        const replaced = url.replace(/original/, size);
        return replaced;
      }
    } else {
      return null;
    }
  }

  async checkFile(dir: string, fileName: string): Promise<boolean> {
    const filesList = await this.file
      .listDir(this.file.dataDirectory, dir)
      .catch(() => {
        return [];
      });
    if (!!filesList && filesList.length > 0) {
      for (const file of filesList) {
        if (!!file.name && fileName === file.name) {
          return true;
        }
      }
    }
    return false;
  }

  async checkDir(dir: string): Promise<boolean> {
    const isPresent = await this.file
      .checkDir(this.file.dataDirectory, dir)
      .catch(() => {
        return false;
      });
    return isPresent;
  }

  async downloadVideo(
    question: Question,
    serieId: string,
    type: string
  ): Promise<any> {
    const url = question[type];
    if (!!url) {
      return await this.download(
        this.changeOriginalExtension(url, 'large-mp4'),
        serieId,
        'Videos'
      );
    }
  }

  async download(url: string, serieId: string, dir: string): Promise<any> {
    const fileName = url.substr(url.lastIndexOf('/') + 1, url.length);
    const fileExist = await this.checkFile(dir, fileName);
    if (!fileExist) {
      return await this.downloadFile(url, dir, fileName).catch(err => {
        this.downloadingSeries.find(elt => elt.id === serieId).error = true;
        this.downloadingSeries.find(elt => elt.id === serieId).text_error = err;
        return null;
      });
    } else {
      return `${this.file.dataDirectory}${dir}/${fileName}`;
    }
  }

  async downloadAssets(question: Question, serieId: string): Promise<Question> {
    for (const videoAsset of this.videoList) {
      if (!!question[videoAsset]) {
        question[`off_${videoAsset}`] = await this.downloadVideo(
          question,
          serieId,
          videoAsset
        );
      }
    }
    for (const audioAsset of this.audioList) {
      if (!!question[audioAsset]) {
        question[`off_${audioAsset}`] = await this.download(
          this.changeOriginalExtension(question[audioAsset], '24k'),
          serieId,
          'Audios'
        );
      }
    }
    for (const imgAsset of this.imgList) {
      if (!!question[imgAsset]) {
        question[`off_${imgAsset}`] = await this.download(
          this.changeOriginalExtension(question[imgAsset], 'medium'),
          serieId,
          'Imgs'
        );
      }
    }
    question.downloadState = true;
    return question;
  }

  async createDir(name: string): Promise<any> {
    const dirExist = await this.checkDir(name);
    if (!dirExist) {
      await this.file.createDir(this.file.dataDirectory, name, false);
    }
    return;
  }

  async createDirs(): Promise<any> {
    await this.createDir('Imgs');
    await this.createDir('Audios');
    await this.createDir('Videos');
    return;
  }

  async downloadSerie(serie: Serie, student: Student): Promise<Serie> {
    await this.createDirs();
    // console.log('serie => ', serie);
    if (!!serie && !!student) {
      if (!!serie.questions && serie.questions.length > 0) {
        for (const questionId of serie.questions) {
          let question = null;
          const resGetQuestion =
            await this.questionsService.questionsContext.getQuestion(
              questionId,
              student
            );
          if (
            !!resGetQuestion &&
            !!resGetQuestion.errorCode &&
            resGetQuestion.errorCode === 'E301'
          ) {
            // service failed
          } else {
            question = resGetQuestion;
          }
          if (
            !!this.downloadingSeries.find(elt => elt.id === serie.id) &&
            (this.networkService?.isOffline() ||
              this.downloadingSeries.find(elt => elt.id === serie.id).abort ||
              this.abortDownload ||
              this.downloadingSeries.find(elt => elt.id === serie.id).error)
          ) {
            let del: any;
            if (this.downloadingSeries.find(elt => elt.id === serie.id).error) {
              del = {
                id: serie.id,
                count: serie.nbQuestions,
                error: true,
                text_error: this.downloadingSeries.find(
                  elt => elt.id === serie.id
                ).text_error,
              };
            } else {
              del = {
                id: serie.id,
                count: serie.nbQuestions,
                error: false,
                text_error: '',
              };
            }
            this.removeFromDownloadList(serie.id);
            this.deletingSeriesObs$.next(del);
            serie.downloadState = 'deleting';
            return serie;
          }
          if (!!question) {
            const dlQuestion = await this.downloadAssets(question, serie.id);
            // console.log('dlQuestion => ', dlQuestion);
            await this.questionsService.questionsContext.setQuestion(
              dlQuestion,
              student
            );
            if (!!this.downloadingSeries.find(elt => elt.id === serie.id)) {
              this.downloadingSeries.find(elt => elt.id === serie.id).count++;
            }
          }
        }
      }
      const dlSerie = await this.downloaded(serie, student);
      const sdlSerie = await this.seriesService.seriesContext.setSerie(
        dlSerie,
        student
      );
      return sdlSerie;
    }
    return null;
  }

  async initDownload(serie: Serie, student: Student): Promise<Serie> {
    if (!!serie && !!student) {
      const downloadedSeries = (await this.getDownloadedSeries(student)) || [];
      if (!downloadedSeries.includes(serie.id)) {
        const lSerie = await this.seriesService.seriesContext.getSerie(
          serie.id,
          student,
          true
        );
        this.downloadingSeriesObs$.next({
          id: serie.id,
          count: 0,
          error: false,
          text_error: '',
          abort: false,
        });
        lSerie.downloadState = 'downloading';
        const sSerie = await this.seriesService.seriesContext.setSerie(
          lSerie,
          student
        );
        return sSerie;
      } else {
        const lSerie = await this.seriesService.seriesContext.getSerie(
          serie.id,
          student,
          true
        );
        lSerie.downloadState = 'downloaded';
        const sSerie = await this.seriesService.seriesContext.setSerie(
          lSerie,
          student
        );
        await this.downloaded(serie, student);
        return sSerie;
      }
    }
    return null;
  }

  removeFromDownloadList(serieId: string) {
    const index = this.downloadingSeries.findIndex(elt => elt.id === serieId);
    if (index !== -1) {
      this.downloadingSeries.splice(index, 1);
    }
  }

  async deleteSerie(serie: Serie, student: Student): Promise<Serie> {
    // console.log('deleteSerie');
    for (const questionId of serie.questions) {
      let question = null;
      const resGetQuestion =
        await this.questionsService.questionsContext.getQuestion(
          questionId,
          student,
          true
        );
      if (
        !!resGetQuestion &&
        !!resGetQuestion.errorCode &&
        resGetQuestion.errorCode === 'E301'
      ) {
        // service failed
      } else {
        question = resGetQuestion;
      }
      if (!!question) {
        await this.questionsService.questionsContext.setQuestion(
          await this.deleteAssets(question),
          student
        );
      }
      // console.log('delete count => ', this.deletingSeries.find(elt => elt.id === serie.id).count);
      if (this.deletingSeries.find(elt => elt.id === serie.id).count > 0) {
        this.deletingSeries.find(elt => elt.id === serie.id).count--;
      }
    }
    const dlSerie = await this.deleted(serie, student);
    const sdlSerie = await this.seriesService.seriesContext.setSerie(
      dlSerie,
      student
    );
    return sdlSerie;
  }

  async initDelete(serie: Serie, student: Student): Promise<Serie> {
    const downloadedSeries = (await this.getDownloadedSeries(student)) || [];
    // console.log('initDelete downloadedSeries', JSON.stringify(downloadedSeries));

    if (downloadedSeries.includes(serie.id)) {
      const lSerie = await this.seriesService.seriesContext.getSerie(
        serie.id,
        student,
        true
      );
      this.deletingSeriesObs$.next({
        id: serie.id,
        count: serie.nbQuestions,
        error: false,
        text_error: '',
        abort: false,
      });
      lSerie.downloadState = 'deleting';
      const sSerie = await this.seriesService.seriesContext.setSerie(
        lSerie,
        student
      );
      return sSerie;
    } else {
      // anti-couillons si downloadedSeries ne contient pas serie.id
      const lSerie = await this.seriesService.seriesContext.getSerie(
        serie.id,
        student,
        true
      );
      lSerie.downloadState = 'none';
      const sSerie = await this.seriesService.seriesContext.setSerie(
        lSerie,
        student
      );
      await this.deleted(serie, student);
      return sSerie;
    }
  }

  removeFromDeleteList(serieId: string) {
    const index = this.deletingSeries.findIndex(elt => elt.id === serieId);
    if (index !== -1) {
      this.deletingSeries.splice(index, 1);
    }
  }

  async deleteAssets(question: Question): Promise<Question> {
    for (const videoAsset of this.videoList) {
      if (!!question[`off_${videoAsset}`]) {
        await this.deleteFile(question[`off_${videoAsset}`], 'Videos');
        question[`off_${videoAsset}`] = null;
      }
    }
    for (const audioAsset of this.audioList) {
      if (!!question[`off_${audioAsset}`]) {
        await this.deleteFile(question[`off_${audioAsset}`], 'Audios');
        question[`off_${audioAsset}`] = null;
      }
    }
    for (const imgAsset of this.imgList) {
      if (!!question[`off_${imgAsset}`]) {
        await this.deleteFile(question[`off_${imgAsset}`], 'Imgs');
        question[`off_${imgAsset}`] = null;
      }
    }
    question.downloadState = false;
    return question;
  }

  async deleteFile(url: any, dir: string): Promise<any> {
    if (!!url && !!url.target) {
      url = url.target;
    }

    const fileName = url.substr(url.lastIndexOf('/') + 1, url.length);
    const fileExist = await this.checkFile(dir, fileName);
    if (!!fileExist) {
      return this.file.removeFile(
        `${this.file.dataDirectory}${dir}/`,
        fileName
      );
    }
  }

  async deleted(serie: Serie, student: Student): Promise<Serie> {
    serie.downloadState = 'none';
    const downloadedSeries = (await this.getDownloadedSeries(student)) || [];

    if (downloadedSeries.includes(serie.id)) {
      downloadedSeries.splice(downloadedSeries.indexOf(serie.id), 1);
    }

    await this.setDownloadedSeries(downloadedSeries, student);

    const series =
      await this.seriesService.seriesContext.getSeriesList(student);
    if (!!series && series.length > 0) {
      series.find(elt => elt.id === serie.id).downloadState = 'none';
    }
    await this.seriesService.seriesContext.setSeriesList(series, student);
    return await this.seriesService.seriesContext.setSerie(serie, student);
  }

  abort(serie: any) {
    if (!!this.downloadingSeries.find(elt => elt.id === serie.id)) {
      this.downloadingSeries.find(elt => elt.id === serie.id).abort = true;
    }
  }
}
