import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class PointsDeRendezVousService {
  constructor(
    protected http: HttpClient,
    protected networkService: NetworkStatusService
  ) {}

  async onInit() {
    // console.log('PointsDeRendezVous done');
    return 'PointsDeRendezVous done';
  }

  fetchLocations(gearboxType = null): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/locations`;

    if (this.networkService?.isOffline()) {
      return of([]);
    }
    let params = {};

    if (!!gearboxType) {
      params = {
        params: {
          gearbox_type: gearboxType,
        },
      };
    }

    return this.http.get(url, params).pipe(
      switchMap(data => {
        return of(data);
      }),
      catchError(async err => {
        return {
          errorCode: 'E301',
          errorMessage: 'FetchLocations Service failed',
          errorOriginal: err,
        };
      })
    );
  }

  fetchByLocationId(id: string, gearboxType = null): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/availability_group_by_day/${id}`;

    if (this.networkService?.isOffline()) {
      return of([]);
    }
    let params = {};

    if (!!gearboxType) {
      params = {
        params: {
          gearbox_type: gearboxType,
        },
      };
    }

    return this.http.get(url, params).pipe(
      switchMap(data => {
        return of(data);
      }),
      catchError(async err => {
        return {
          errorCode: 'E301',
          errorMessage: 'FetchByLocationId Service failed',
          errorOriginal: err,
        };
      })
    );
  }

  fetchByCoordinates(
    lat: any,
    lng: any,
    notServed = false,
    gearboxType = null
  ): Observable<any> {
    // const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/availability?lat=${lat}&lng=${lng}&nb_location=25`;
    let paramURl = '';
    if (!!notServed && notServed) {
      paramURl = '&without_distance=true&nb_location=1';
    }
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/availability?lat=${lat}&lng=${lng}${paramURl}`;

    let params = {};

    if (!!gearboxType) {
      params = {
        params: {
          gearbox_type: gearboxType,
        },
      };
    }

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    return this.http.get(url, params).pipe(
      switchMap(data => {
        return of(data);
      }),
      catchError(async err => {
        return {
          errorCode: 'E301',
          errorMessage: 'FetchByCoordinates Service failed',
          errorOriginal: err,
        };
      })
    );
  }

  fetchNearestLocation(lat: any, lng: any, gearType: string): Promise<any> {
    // console.log('fetchNearestLocation service => ', lat, lng, gearType);
    let gearboxType = null;
    if (!!gearType && gearType.toUpperCase() === 'BVM') {
      gearboxType = '&gearbox_type=bvm';
    } else if (!!gearType && gearType.toUpperCase() === 'BVA') {
      gearboxType = '&gearbox_type=bva';
    }

    // console.log('gearboxType => ', gearboxType);
    const url = `${environment.token_auth_config.apiBase}/v2/locations/nearest?lat=${lat}&lng=${lng}${gearboxType}`;

    if (this.networkService?.isOffline()) {
      return Promise.resolve([]);
    }

    return this.http
      .get(url)
      .pipe(
        switchMap(async (data: any) => {
          // console.log('fetchNearestLocation data => ', data.data);
          if (!!data && !!data.data && data.data.length > 0) {
            return data.data;
          } else {
            return {
              errorCode: 'E301',
              errorMessage: 'FetchNearestLocation Service data failed',
              errorOriginal: data,
            };
          }
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'FetchNearestLocation Service failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }
}
