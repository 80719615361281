import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterService } from '@commons/services';

import { AppInitService, Initialable } from './app-init.service';
import { PostalCodeService } from './postal-code-auto-complete.service';
import { StorageService } from './storage.service';

import { AbleTo } from '../decorators/permissions.decorator';

import { StudentPurshasesDetails } from '../models/StudentPurshasesDetails';
import { Lesson } from '../models/lesson';
import { Serie } from '../models/serie';
import { CreditStock, CreditStocks, Student } from '../models/student';

import { NavController, Platform } from '@ionic/angular';

import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TokenService } from './token.service';
import { AuthService } from './auth.service';

import { environment } from '../../environments/environment';
import { OfferModel, OfferTypes } from '../models/offer';
import { PaymentModel } from '../models/payment';
import { BookletService } from './booklet.service';
import { CerfaService } from './cerfa.service';
import { CouponsService } from './coupons.service';
import { CPFSubscriptionsService } from './cpf-subscriptions.service';
import { ExamenCodeService } from './exam-code.service';
import { ExamenConduiteService } from './exam-conduite.service';
import { GiftsService } from './gifts.service';
import { LessonsService } from './lessons.service';
import { NetworkStatusService } from './network-status.service';
import { OffersService } from './offers.service';
import { PaymentsService } from './payments.service';
import { UserErrorHandlerService } from './user-error-handler.service';

import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import * as moment from 'moment-timezone';

@AbleTo([
  {
    action: 'read',
    target: Serie,
    eval: function (acc, instance) {
      if (acc.status === 'engaged') {
        return true;
      } else if (
        acc.status === 'registered' &&
        instance.scope === 'registered'
      ) {
        return true;
      } else if (instance.scope === 'free') {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    action: 'update',
    target: Serie,
    eval: function (acc, instance) {
      if (acc.status === 'engaged') {
        return true;
      } else if (
        acc.status === 'registered' &&
        instance.scope === 'registered'
      ) {
        return true;
      } else if (instance.scope === 'free') {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    action: 'index',
    target: Lesson,
    eval: function (acc, instance) {
      return true;
    },
  },
  {
    action: 'write',
    target: Lesson,
    eval: function (acc, instance) {
      return false;
    },
  },
  {
    action: 'read',
    target: Lesson,
    eval: function (acc, instance) {
      if (acc.isGuest() === true) {
        return false;
      } else {
        return instance.accountId === acc.remoteId;
      }
    },
  },
  {
    action: 'buy',
    target: OfferModel,
    eval: function (acc, offer: OfferModel) {
      switch (offer.offer_type) {
        case OfferTypes.BigPack: {
          if (acc.isGuest()) {
            // Register required
            return false;
            break;
          }
          // if (acc.boughtAnOffer(offer.offer_type)) {
          //   return false; break;
          // }
          return true;
          break;
        }
        default: {
          console.error(
            `Dont know how to handle buy action with offer ${offer.id} (type: ${offer.offer_type})`
          );
        }
      }
    },
  },
])
@Injectable({
  providedIn: 'root',
})
@Initialable({ step: 'init2', initializer: 'onInit' })
@EasyDebugDecorator
export class StudentService {
  private _student: Student | any = null;
  private _dataProperties: Student = null;
  // private _isSignedInSoft: boolean;
  private payments: Array<PaymentModel> = [];

  guest = new Student({
    remoteId: 'GUEST',
    status: 'guest',
    subStatus: null,
    gender: null,
    dob: null,
    postalCode: null,
    email: null,
    phone: null,
    picUrl: null,
    firstName: null,
    lastName: null,
    code: null,
    credits: null,
    codeExamCredits: null,
    drivingExamCredits: null,
    creditStocks: {
      codeExamCredits: null,
      automaticDrivingCredits: null,
      manualDrivingCredits: null,
    },
    codePassedAt: null,
    dateLastStatusChange: null,
    initialStatus: null,
    initialWish: null,
    engagedAt: null,
    paymentsBalance: null,
    couponsName: null,
    creditsObtained: null,
    lessonsPassed: null,
    lessonsToCome: null,
    offers: null,
    provider: null,
    neph: null,
    lastCgaacUrlDoc: null,
    cgaacAcceptedAt: null,
    learningContractAcceptedAt: null,
    showLearningContract: null,
    emailConfirmedAt: null,
    evalPostAchat: null,
    dateLimitToConfirmEmail: null,
    eligibleForRDVP: null,
    examReservation: null,
    driving_department: null,
    near_bva_location: null,
    elearningEligibility: null,
    userCdrVersion: null,
    currentCdrVersion: null,
  });

  debugMode = false;
  debugData: any = [];
  debugTitle: string[] = [];

  provider: string = null;

  studentInitLaunched = false;

  public email: string;
  public phone: string;
  public pic_url: string;
  public first_name: string;
  public last_name: string;
  public gender: string;
  public dob: string;
  public postal_code_id: string;

  creditStocks = null;

  easyDebugAutoLogUrl = null;
  deepLinkAutoLogUrl = null;

  accountSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  accounSub$ = this.accountSub.asObservable();

  autologCheck = false;
  easyDebugCheck = false;

  logId = null;

  constructor(
    private platformService: Platform,
    private storageService: StorageService,
    private initService: AppInitService,
    public authService: AuthService,
    private tokenService: TokenService,
    private postalCodeService: PostalCodeService,
    private http: HttpClient,
    private paymentService: PaymentsService,
    private giftService: GiftsService,
    private couponService: CouponsService,
    private offerService: OffersService,
    private cerfaService: CerfaService,
    private bookletService: BookletService,
    private examenCodeService: ExamenCodeService,
    private examenConduiteService: ExamenConduiteService,
    private lessonsService: LessonsService,
    private networkService: NetworkStatusService,
    private userErrorHandlerService: UserErrorHandlerService,
    private navController: NavController,
    private wonderPushService: WonderPush,
    private CPFSubscriptionService: CPFSubscriptionsService,
    public toasterService: ToasterService
  ) {
    // console.log('Student service instanciate');
  }

  async onInit() {
    this.studentInitLaunched = true;
    const actualUrl = location.toLocaleString();
    const transformed = await this.storageService.get('transformed');
    // console.log('transformed studentservice => ', transformed, !transformed);

    if (actualUrl.includes('debug_id') && !transformed) {
      this.easyDebugAutoLogUrl = actualUrl;
    }

    // console.log(`Launching Student Service init`, this.easyDebugAutoLogUrl, actualUrl);

    if (!!this.easyDebugAutoLogUrl) {
      // console.log('easyDebugAutoLogUrl => ', this.easyDebugAutoLogUrl);
      if (!this.easyDebugCheck) {
        this.easyDebugCheck = true;
        this._student = await this.autoLogPromise(this.easyDebugAutoLogUrl);
        // console.log('student easydebugautologurl => ', JSON.stringify(this._student));
        // console.log('easydebugautologurl => ', this.easyDebugAutoLogUrl);
      }
    }
    // console.log('student 1 => ', this._student);

    if (!this.autologCheck && !transformed) {
      this.autologCheck = true;
      this._student = await this.autoLogPromise();
    }
    // console.log('student 2 => ', this._student);

    const signedIn = await this.isSignedInHard();
    // console.log('student 3 => ', this._student);

    if (!!this._student && !!this._student.errorMessage) {
      // if (this.errorCatcherService.getRow('ME001').message !== this._student.errorMessage) {
      // this.sentryService.sendToSentry(`EVSERROR Student Service onInit student ${JSON.stringify(this._student)}`);
      // }
      this._student = null;
    }
    // console.log('student 4 => ', this._student);

    if (this._student === null || this._student === undefined) {
      if (signedIn || this.networkService?.isOffline()) {
        this._student = await this.getProfil();
      } else {
        this._student = await this.initGuest();
      }
    }
    // Pour optim faire un forkJoin
    // console.log('studentService => student', this._student);
    // console.log('student 5 => ', this._student);

    this.paymentService.loadStudentPayments(this._student);
    this.paymentService.loadCreditsProvisionsHistory(this._student);
    this.giftService.loadStudentGifts(this._student);
    this.couponService.loadStudentCoupon(this._student);
    this.cerfaService.loadStudentCerfa(this._student);
    this.bookletService.loadStudentBooklets(this._student);
    this.examenCodeService.loadStudentExamens(this._student);
    this.lessonsService.loadStudentLessons(this._student);
    this.CPFSubscriptionService.loadStudentCPFSubscriptions(this._student);
    this.offerService.loadStudentCustomOffers(this._student);
    if (
      !!this._student &&
      !!this._student.remoteId &&
      this._student.remoteId != 'GUEST' &&
      this.platformService.is('cordova')
    ) {
      await this.wonderPushService.setUserId(this._student.remoteId);
      const update = await this.updateWonderPush();
      if (!!update && !!update.errorMessage) {
        // Gérer erreur
      }
    }
    return this._student;
  }

  updateWonderPush(): Promise<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/wonderpush/update_user`;

    return this.http
      .post(url, {})
      .pipe(
        switchMap(async res => {
          return true;
        }),
        catchError(async err => {
          return {
            errorCode: 'E301',
            errorMessage: 'Update WonderPush Student Service Request Failed',
            errorOriginal: err,
          };
        })
      )
      .toPromise();
  }

  async initGuest() {
    return await this.fillAccount(
      { student_info: { data: { attributes: { status: 'guest' } } } },
      'GUEST'
    );
  }

  async isSignedInHard(): Promise<boolean> {
    if (this.networkService?.isOffline()) {
      const student = await this.getProfil();
      if (!!student && student.status !== 'guest') {
        return true;
      }
    }

    const isSignedIn = await this.authService.isSignedInHard();
    // console.log('isSignedIn => ', isSignedIn);
    if (!!isSignedIn?.errorCode) {
      this._student = await this.initGuest();
      // console.log('student isSignedInHard => ', this._student);
      return false;
    }
    if (
      !!isSignedIn &&
      !!isSignedIn?._remoteId &&
      isSignedIn?._remoteId !== 'GUEST'
    ) {
      // console.log('isSignedInHard => ', isSignedIn._remoteId);
      const localStudent = await this.getProfil();
      await this.fetchStudentCreditStocks(this._student.remoteId);
      const remoteStudentData = await this.authService.fetchProfile(
        isSignedIn._remoteId,
        'students'
      );
      const remoteStudent = await this.fillAccount(
        remoteStudentData,
        isSignedIn._remoteId
      );
      if (!(JSON.stringify(localStudent) === JSON.stringify(remoteStudent))) {
        await this.setProfil(remoteStudent);
      }
      return true;
    }
    this._student = await this.initGuest();
    // console.log('student 2 isSignedInHard => ', this._student);
    return false;
  }

  async isSignedInSoft(): Promise<boolean> {
    if (this.networkService?.isOffline()) {
      const student = await this.getProfil();
      if (!!student && student.status !== 'guest') {
        return true;
      }
    }

    const isSignedIn = await this.authService.isSignedInHard();
    // console.log('isSignedIn => ', isSignedIn);
    if (!!isSignedIn?.errorCode) {
      this._student = await this.initGuest();
      // console.log('student isSignedInHard => ', this._student);
      return false;
    }
    if (
      !!isSignedIn &&
      !!isSignedIn?._remoteId &&
      isSignedIn?._remoteId !== 'GUEST'
    ) {
      // console.log('isSignedInHard => ', isSignedIn._remoteId);
      const localStudent = await this.getProfil();
      if (!!localStudent && localStudent.status !== 'guest') {
        return true;
      }
      const remoteStudentData = await this.authService.fetchProfile(
        isSignedIn._remoteId,
        'students'
      );
      const remoteStudent = await this.fillAccount(
        remoteStudentData,
        isSignedIn._remoteId
      );
      if (!(JSON.stringify(localStudent) === JSON.stringify(remoteStudent))) {
        await this.setProfil(remoteStudent);
      }
      return true;
    }
    this._student = await this.initGuest();
    // console.log('student 2 isSignedInHard => ', this._student);
    return false;
  }

  async getProfil(): Promise<Student> {
    const student = new Student(await this.storageService.get('Student'));
    if (!!student) {
      return student;
    }
    return this.initGuest();
  }

  async setProfil(profil: any): Promise<Student> {
    return new Student(await this.storageService.set('Student', profil));
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  async updateUserProfil(profile: any): Promise<Student> {
    this.first_name = profile.fName;
    this.last_name = profile.lName;
    if (profile.birthday.year !== '') {
      // DATE TO CHANGE
      const tmp = this.dateToFormatFr();
      tmp.year(parseInt(profile.birthday.year));
      tmp.month(parseInt(profile.birthday.month) - 1);
      tmp.date(parseInt(profile.birthday.day));
      this.dob = tmp.toJSON();
    } else {
      this.dob = null;
    }
    (this.gender = profile.gender),
      (this.phone = profile.phone),
      (this.postal_code_id = profile.postalCode);
    const lStudent = await this.getProfil();
    const student = new Student(lStudent);
    (student.firstName = this.first_name),
      (student.lastName = this.last_name),
      (student.gender = this.gender),
      (student.dob = this.formatDOB(this.dob)),
      (student.postalCode = this.postal_code_id),
      (student.phone = this.phone);
    return await this.setProfil(student);
  }

  async updateEmailProfil(email: any): Promise<Student> {
    const lStudent = await this.getProfil();
    const student = new Student(lStudent);
    student.email = email;
    return await this.setProfil(student);
  }

  async fillAccount(data: any, accountId: string): Promise<Student> {
    if (!!data) {
      // console.log('fillAccount', data);
      this._student = new Student({
        remoteId: accountId,
        status: this.checkStudentInfoPropertie(data, 'status', null),
        subStatus: this.checkStudentInfoPropertie(data, 'sub_status', null),
        gender: !!data.gender ? data.gender : null,
        dob: !!data.dob ? this.formatDOB(data.dob) : null,
        postalCode: await this.getPostalCode(data),
        email: !!data.email ? data.email : null,
        phone: !!data.phone ? data.phone : null,
        picUrl: await this.getPictUrl(data),
        firstName: !!data.first_name ? data.first_name : null,
        lastName: !!data.last_name ? data.last_name : null,
        code: this.checkStudentInfoPropertie(data, 'code', null),
        credits: this.checkStudentInfoPropertie(data, 'credits', 0),
        codeExamCredits: this.checkStudentInfoPropertie(
          data,
          'code_exam_credits',
          0
        ),
        drivingExamCredits: this.checkStudentInfoPropertie(
          data,
          'driving_exam_credits',
          0
        ),
        creditStocks: {
          ...this.creditStocks,
        },
        codePassedAt: this.checkStudentInfoPropertie(
          data,
          'code_passed_at',
          null
        ),
        userCdrVersion: this.checkStudentInfoPropertie(
          data,
          'user_cdr_version',
          null
        ),
        currentCdrVersion: this.checkStudentInfoPropertie(
          data,
          'current_cdr_version',
          null
        ),
        dateLastStatusChange: this.checkStudentInfoPropertie(
          data,
          'date_last_status_change',
          null
        ),
        initialStatus: this.checkStudentInfoPropertie(
          data,
          'initial_status',
          null
        ),
        initialWish: this.checkStudentInfoPropertie(data, 'initial_wish', null),
        engagedAt: data.engaged_at,
        paymentsBalance: data.payments_balance,
        couponsName: data.coupons_name,
        creditsObtained: data.credits_obtained,
        lessonsPassed:
          !!data.lessons_passed && !!data.lessons_passed.data
            ? data.lessons_passed.data
            : [],
        lessonsToCome:
          !!data.lessons_to_come && !!data.lessons_to_come.data
            ? data.lessons_to_come.data
            : [],
        offers: !!data.offer && !!data.offer.data ? data.offer.data : [],
        provider: !!data.provider ? data.provider : null,
        neph: this.checkStudentInfoPropertie(data, 'neph_number', null),
        lastCgaacUrlDoc: !!data.last_cgaac_url_doc
          ? data.last_cgaac_url_doc
          : null,
        cgaacAcceptedAt: !!data.cgaac_accepted_at
          ? data.cgaac_accepted_at
          : null,
        learningContractAcceptedAt: !!data.learning_contract_accepted_at
          ? data.learning_contract_accepted_at
          : null,
        showLearningContract: !!data.show_learning_contract
          ? data.show_learning_contract
          : null,
        evalPostAchat:
          data.eval_post_achat === null ? null : data.eval_post_achat,
        emailConfirmedAt: !!data.email_confirmed_at
          ? data.email_confirmed_at
          : null,
        dateLimitToConfirmEmail: !!data.date_limit_to_confirm_email
          ? data.date_limit_to_confirm_email
          : null,
        eligibleForRDVP: !!data.eligible_for_rdvp
          ? data.eligible_for_rdvp
          : null,
        examReservation: !!data.exam_reservation ? data.exam_reservation : null,
        driving_department: !!data.driving_department
          ? data.driving_department
          : null,
        near_bva_location: !!data.near_bva_location
          ? data.near_bva_location
          : null,
        elearningEligibility: !!data.elearning_eligibility
          ? data.elearning_eligibility
          : null,
      });
    } else {
      this._student = await this.initGuest();
    }

    if (
      !!this._student &&
      !!this._student.picUrl &&
      this.platformService.is('cordova') &&
      this._student.picUrl.slice(
        this._student.picUrl.lastIndexOf('?') + 1,
        this._student.picUrl.length
      ) !== 'missing_avatar.png'
    ) {
      // await this.downloadProfilePic(this._student.picUrl).then(
      //   res => {
      //     if (!!res) {
      //       this._student.picUrl = res;
      //     }
      //   }
      // );
    } else if (
      !!this._student &&
      (!this._student.picUrl ||
        (!!this._student.picUrl &&
          this._student.picUrl.slice(
            this._student.picUrl.lastIndexOf('?') + 1,
            this._student.picUrl.length
          ) === 'missing_avatar.png'))
    ) {
      this._student.picUrl = null;
    }

    return await this.storageService.set('Student', this._student);
  }

  async getPictUrl(data): Promise<string> {
    let picture_url = null;
    if (!!data.picture_url) {
      picture_url = data.picture_url;
    } else if (!!data.fb_pic) {
      picture_url = data.fb_pic;
    } else {
      const student = await this.getProfil();
      return student.picUrl;
    }
    return picture_url;
  }

  async getPostalCode(data): Promise<string> {
    // en fonction de devise ou du server le modèle n'est pas le même -_-
    if (
      !!data.postal_code &&
      !!data.postal_code.data &&
      !!data.postal_code.data.attributes &&
      !!data.postal_code.data.attributes.value
    ) {
      return data.postal_code.data.attributes.value;
    } else {
      const student = await this.getProfil();
      return student.postalCode;
    }
  }

  checkStudentInfoPropertie(data: any, propertie: string, errorValue: any) {
    // console.log('checkStudentInfoPropertie =>', data, propertie, errorValue);
    let value = errorValue;
    if (
      !!data.student_info &&
      !!data.student_info.data &&
      !!data.student_info.data.attributes &&
      !!data.student_info.data.attributes[propertie]
    ) {
      value = data.student_info.data.attributes[propertie];
    } else if (!!data.student_info && !!data.student_info[propertie]) {
      value = data.student_info[propertie];
    }
    return value;
  }

  fetchStudentCreditStocks(accountId) {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId) {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/credit_stocks`;

      return this.http
        .get(url)
        .pipe(
          switchMap(data => {
            this.creditStocks = this.buildCreditStocksFromRawData(data['data']);
            return of(this.creditStocks);
          }),
          catchError(async err => {
            this.userErrorHandlerService.addError({
              criticity: 1,
              service: 'student-qervice-credit-stocks',
              platform: 'both',
              data: err,
              errorCode: 'gsfgbuid',
            });
            return [];
          })
        )
        .toPromise();
    }
  }

  buildCreditStocksFromRawData(data: any): CreditStocks {
    const creditStocks: CreditStocks = {};
    if (!!data && typeof data === 'object') {
      for (const creditStockKey of Object.keys(data)) {
        const finalKey = creditStockKey.replace(/([-_][a-z])/g, group =>
          group.toUpperCase().replace('-', '').replace('_', '')
        );
        creditStocks[finalKey] = this.buildCreditStockFromRawData(
          data[creditStockKey]
        );
      }
    }
    // console.log('creditStocks => ', creditStocks);
    return creditStocks;
  }

  buildCreditStockFromRawData(data: any): CreditStock {
    return {
      name: data.display_name || 'Name error',
      quantity: data.quantity || 0,
      ranking: data.ranking || 0,
      redirectOfferId: data.redirect_offer_id || null,
      subname: data.secondary_name || null,
      quantityDetails: data.quantity_details || null,
    };
  }

  getCreditStocksAsOrderedArray() {
    let orderedCreditStocks = [];
    if (!!this.creditStocks && Object.keys(this.creditStocks).length > 0) {
      for (const creditStockKey of Object.keys(this.creditStocks)) {
        orderedCreditStocks.push({
          key: creditStockKey,
          value: this.creditStocks[creditStockKey],
        });
      }
      orderedCreditStocks = orderedCreditStocks.sort((a, b) => {
        return a.value.ranking - b.value.ranking;
      });
    }
    return orderedCreditStocks;
  }

  boughtAnOffer(offer: OfferModel): boolean {
    const payments = this.paymentService.studentPaymentsByOfferType(
      offer.offer_type
    );
    // Gifts are not linked with offers, so no check on them

    return !!payments ? payments.length > 0 : false;
  }

  getStudentPurshases(): StudentPurshasesDetails {
    return new StudentPurshasesDetails({
      gifts: this.giftService.gifts,
      payments: this.paymentService.payments,
      coupons: this.couponService.coupons,
      offers: this.offerService.offers,
      studentCustomOffers: this.offerService.studentCustomOffers,
      cpfSubscriptions: this.CPFSubscriptionService.CPFSubscriptions,
    });
  }

  formatDOB(dob: string) {
    if (!!dob) {
      const temp = dob.substring(0, 10).split('-');
      const nDob = `${temp[2]}/${temp[1]}/${temp[0]}`;
      return nDob;
    }
    return dob;
  }

  isGuest() {
    return this._student.isGuest();
  }

  isEmailAvalaible(email: string): Promise<any> {
    // const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/availability?lat=${lat}&lng=${lng}&nb_location=25`;
    if (!!email && email !== '') {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/users/check_email?email=${email}`;

      if (this.networkService?.isOffline()) {
        return null;
      }

      return this.http
        .get(url)
        .pipe(
          switchMap(async data => {
            return data;
          }),
          catchError(async err => {
            return {
              errorCode: 'E301',
              errorMessage: 'StudentService isEmailAvalaible Service failed',
              errorOriginal: err,
            };
          })
        )
        .toPromise();
    }
    return null;
  }

  signIn(email: string, password: string): Observable<Student> {
    let remoteId: string;

    return this.authService.signIn(email, password).pipe(
      switchMap(async (res: any) => {
        if (!!res && !!res.errorCode) {
          return res;
        }
        remoteId = res.id;
        return await this.authService.fetchProfile(remoteId, 'students');
      }),
      switchMap(async (res: any) => {
        if (!!res && !!res.errorCode) {
          return res;
        }
        return await this.fillAccount(res, remoteId);
      }),
      switchMap(async (res: any) => {
        if (!!res && !!res.errorCode) {
          return res;
        }
        return this.initService.init2().then(() => {
          return res;
        });
      })
    );
  }

  register(
    email: string,
    password: string,
    passwordConfirmation: string,
    additionalData: any
  ): Observable<any> {
    let remoteId: string;
    return this.authService
      .register(email, password, passwordConfirmation, additionalData)
      .pipe(
        switchMap(async (res: any) => {
          if (!!res && !!res.errorCode) {
            return of(res);
          }
          await this.storageService.set(
            `${res.id}-isSignUpGeo`,
            res.is_sign_up_geo
          );
          remoteId = res.id;
          return this.authService.signIn(email, password);
        }),
        switchMap(res => {
          return res;
        }),
        switchMap(async (res: any) => {
          if (!!res && !!res.errorCode) {
            return res;
          }
          remoteId = res.id;
          return await this.authService.fetchProfile(remoteId, 'students');
        }),
        switchMap(async res => {
          if (!!res && !!res.errorCode) {
            return res;
          }
          return await this.fillAccount(res, remoteId);
        }),
        switchMap(async res => {
          if (!!res && !!res.errorCode) {
            return res;
          }
          return from(
            this.initService.init2().then(() => {
              return res;
            })
          );
        }),
        catchError(async err => {
          return err;
        })
      );
  }

  async signOut(): Promise<any> {
    const student = await this.getProfil();
    if (!!student && student.status !== 'guest') {
      return this.authService
        .signOut()
        .pipe(
          switchMap(async res => {
            if (!!res && !!res.errorCode) {
              return res;
            }
            if (this.platformService.is('cordova')) {
              if (this.provider === 'google') {
                await this.authService.googleLogOut();
              } else if (this.provider === 'facebook') {
                await this.authService.facebookLogOut();
              }
            } else {
              this.authService.socialAuthService.signOut();
            }
            this._student = null;
            return res;
          }),
          switchMap(res => {
            if (!!res && !!res.errorCode) {
              return res;
            }
            if (!!res) {
              return from(
                this.initService.init2().then(() => {
                  return res;
                })
              );
            }
          })
        )
        .toPromise();
    }
    return null;
  }

  async autoLogPromise(url: string = null): Promise<Student> {
    const autoLog = await this.autoLog(url)
      .pipe(
        map(async (res: any) => {
          return res;
        }),
        catchError(async err => {
          return err;
        })
      )
      .toPromise();
    return autoLog;
  }

  autoLog(url: string = null): Observable<Student> {
    let remoteId: string;
    return this.authService.autoLog(url).pipe(
      switchMap(async (res: any) => {
        if (!!res?.errorCode) {
          return res;
        }
        if (!!res?.debugId) {
          this.logId = res.debugId;
        }
        // console.log('logId => ', this.logId);
        if (!!res.res) {
          // console.log('res.res => ', res.res);
          const jwtToken = await this.storageService.get('jwtToken');
          // console.log('jwtToken => ', jwtToken);
          await this.storageService.clear();
          if (!!jwtToken) {
            const test = await this.storageService.set('jwtToken', jwtToken);
            // console.log('test => ', test);
          }
          return res.res;
        }
        return null;
      }),
      switchMap(async (res: any) => {
        // console.log('RES => ', res);
        if (!!res?.errorCode) {
          return res;
        }
        if (!!res) {
          remoteId = res;
          return this.authService.fetchProfile(remoteId, 'students');
        }
        return null;
      }),
      switchMap(async (res: any) => {
        if (!!res?.errorCode) {
          return res;
        }
        if (!!res) {
          return this.fillAccount(res, remoteId);
        }
        return null;
      }),
      switchMap(async res => {
        if (!!res?.errorCode) {
          return res;
        }
        if (!!res) {
          await this.initService.init2();
          return res;
        }
        return null;
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.authService.forgotPassword(email).pipe(
      switchMap(async res => {
        return res;
      })
    );
  }

  updatePassword(
    newPassword: string,
    confirmedPassword: string,
    oldPassword: string
  ): Observable<any> {
    return this.authService
      .updatePassword(newPassword, confirmedPassword, oldPassword)
      .pipe(
        switchMap(async res => {
          return res;
        })
      );
  }

  async signInFacebook(studentInfo: {
    postalCodeId: string;
    phoneNumber: string;
    email: string;
  }): Promise<any> {
    if (this.platformService.is('cordova')) {
      // console.log('facebook app');
      this.provider = 'facebook';
      return this.authService
        .logWithFacebookMobile(studentInfo)
        .then(async (studentData: any) => {
          // alert('studentData');
          if (!!studentData && !!studentData.errorMessage) {
            return studentData;
          }
          if (!!studentData?.data?.id) {
            const student = await this.fillAccount(
              studentData.data,
              studentData.data.id
            );
            if (!!student) {
              await this.initService.init2();
              return student;
            }
            return {
              errorCode: 'E101',
              errorMessage: 'Sign In Facebook Student Service missing student',
              errorOriginal: null,
            };
          }
          return {
            errorCode: 'E101',
            errorMessage:
              'Sign In Facebook Student Service missing studentData',
            errorOriginal: studentData,
          };
        })
        .catch(err => {
          // alert('error');
          // alert(`err => ${JSON.stringify(err)}`);
          return err;
        });
    } else {
      const facebookData = await this.authService.logWithFacebookWeb();
      if (!!facebookData && !!facebookData.errorMessage) {
        const row = `Tu dois d\'abord sélectionner un code postal avant de continuer`;
        if (
          !!row &&
          !!facebookData.errorOriginal &&
          !!facebookData.errorOriginal.error &&
          !!facebookData.errorOriginal.error.msg &&
          facebookData.errorOriginal.error.msg.includes(row)
        ) {
          this.toasterService.create({
            text: `Aucun compte En Voiture Simone n'est associé à cet identifiant Facebook`,
            bgcolor: 'var(--color-danger)',
            color: 'white',
          });
        }
        return facebookData;
      }
      const student: any = await this.fillAccount(
        facebookData.data,
        facebookData.id
      );
      if (!!student && !!student.errorMessage) {
        return student;
      }
      if (!!student) {
        await this.initService.init2();
        return student;
      }
    }
  }

  async signInGoogle(studentInfo: {
    postalCodeId: string;
    phoneNumber: string;
  }): Promise<any> {
    if (this.platformService.is('cordova')) {
      this.provider = 'google';
      return await this.authService
        .logWithGoogleMobile(studentInfo)
        .then(async studentData => {
          if (!!studentData && !!studentData.errorMessage) {
            return studentData;
          }
          if (!!studentData?.data?.id) {
            const student = await this.fillAccount(
              studentData.data,
              studentData.data.id
            );
            if (!!student) {
              await this.initService.init2();
              return student;
            }
            return {
              errorCode: 'E101',
              errorMessage: 'Sign In Google Student Service missing student',
              errorOriginal: null,
            };
          }
          return {
            errorCode: 'E101',
            errorMessage:
              'Sign In Google Student Service missing currentUserData or studentData',
            errorOriginal: studentData,
          };
        })
        .catch(async err => {
          return err;
        });
    } else {
      // console.log('signInGoogle web');
      const googleData = await this.authService.logWithGoogleWeb();
      if (!!googleData && !!googleData.errorMessage) {
        const row = `Tu dois d\'abord sélectionner un code postal avant de continuer`;
        if (
          !!row &&
          !!googleData.errorOriginal &&
          !!googleData.errorOriginal.error &&
          !!googleData.errorOriginal.error.msg &&
          googleData.errorOriginal.error.msg.includes(row)
        ) {
          this.toasterService.create({
            text: `Aucun compte En Voiture Simone n'est associé à cet identifiant Google`,
            bgcolor: 'var(--color-danger)',
            color: 'white',
          });
        }
        return googleData;
      }
      if (!!googleData && !!googleData.data && !!googleData.id) {
        // console.log('fillAccount googleData => ', googleData.data, googleData.id);
        const student: any = await this.fillAccount(
          googleData.data,
          googleData.id
        );
        if (!!student && !!student.errorMessage) {
          return student;
        }
        if (!!student) {
          await this.initService.init2();
          return student;
        }
      } else {
        return null;
      }
    }
  }

  acceptCgaac(remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/students/${remoteId}/cgaac`;
    const data = { cgaac: true };

    return this.http.post(url, data).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(null);
      })
    );
  }

  async updateUserCgaac(data) {
    // console.log(data);
    const lStudent = await this.getProfil();
    const student = new Student(lStudent);
    student.cgaacAcceptedAt =
      !!data && !!data.cgaac_accepted_at ? data.cgaac_accepted_at : null;
    this._student = student; // assign to cache var
    return await this.setProfil(student);
  }

  updateProfile(profile: any, remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/students/${remoteId}/profile`;
    let dob;
    // console.log('birthday => ', profile.birthday);
    if (profile.birthday.year !== '') {
      // DATE TO CHANGE
      const birthdate = moment(
        `${profile.birthday.day}/${profile.birthday.month}/${profile.birthday.year}`,
        'DD/MM/YYYY'
      );
      const tmp = this.dateToFormatFr(birthdate);
      dob = tmp.toString();
    } else {
      dob = null;
    }
    const data = {
      first_name: profile.fName,
      last_name: profile.lName,
      dob: dob,
      gender: profile.gender,
      // email: profile.email,
      phone: profile.phone,
      postal_code: profile.postalCode,
      driving_department_postal_code: profile.driving_departmentPostalCode
        ? profile.driving_departmentPostalCode
        : null,
    };

    return this.http.put(url, data).pipe(
      switchMap(async res => {
        return res;
      }),
      catchError(async err => {
        return err;
      })
    );
  }

  updateEmail(profile: any, remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/students/${remoteId}/email`;
    const data = {
      email: profile.email,
      password: profile.password,
    };

    return this.http.put(url, data).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  uploadProfilePic(image: any, remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/students/${remoteId}/profilepic`;

    const data = {
      student_id: remoteId,
      picture: image,
    };

    return this.http.put(url, data).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(null);
      })
    );
  }

  validateEmail(remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/students/${remoteId}/validate_email`;
    const data = { validate_email: true };

    return this.http.post(url, data).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(null);
      })
    );
  }

  deleteAccount(datas: any, remoteId: string): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/delete`;
    let data = !!datas.reason ? { reason: datas.reason } : {};

    return this.http.post(url, data).pipe(
      switchMap(async res => {
        await this.storageService.remove('jwtToken');
        await this.storageService.set('Student', {
          _remoteId: 'GUEST',
          _status: 'guest',
        });
        await this.removeUserData(remoteId);
        this._student = null;
        await this.initService.init2();
        return of(res);
      }),
      catchError(err => {
        return of(null);
      })
    );
  }

  async postRegistration(postRegData: any): Promise<any> {
    const url = `${environment.cdrBase}/v${environment.apiVersion}/tunnel_answers`;

    return this.http
      .post(url, postRegData)
      .pipe(
        switchMap(async (data: any) => {
          return data;
        }),
        catchError(err => {
          return null;
        })
      )
      .toPromise();
  }

  getUserLifeCycle(student: Student) {
    const lifeCycles = {
      isUserGuest: true,
      isUserRegistered: false,
      isUserEngaged: false,
      isUserCanDrive: false,
      isUserDrivingSuccess: false,
      isFrozen: false,
    };
    console.log(1, lifeCycles);
    if (!!student) {
      lifeCycles.isUserGuest = this.isUserGuest(student);
      lifeCycles.isUserRegistered = this.isUserRegistered(student);
      lifeCycles.isUserEngaged = this.isUserEngaged(student);
      lifeCycles.isUserCanDrive = this.isUserCanDrive(student);
      lifeCycles.isUserDrivingSuccess = this.isUserDrivingSuccess(student);
      lifeCycles.isFrozen = this.isFrozen(student);
    }
    console.log(2, lifeCycles);
    return lifeCycles;
  }

  isUserGuest(student: Student) {
    return student.isGuest();
  }

  isUserRegistered(student: Student) {
    const whiteList = ['registered'];
    if (!!student) {
      if (!!student.status) {
        if (whiteList.indexOf(student.status) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  isUserEngaged(student: Student) {
    const whiteList = [
      'engaged',
      'advanced_registered',
      'neph_validated',
      'cdr_training',
      'cdr_booked',
    ];
    if (!!student) {
      if (!!student.status) {
        if (whiteList.indexOf(student.status) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  isUserCanDrive(student: Student) {
    const whiteList = [
      'cdr_success',
      'driving_training',
      'waiting_for_booking',
      'driving_booked',
    ];
    if (!!student) {
      if (!!student.status) {
        if (whiteList.indexOf(student.status) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  isUserDrivingSuccess(student: Student) {
    const whiteList = ['driving_success'];
    if (!!student) {
      if (!!student.status) {
        if (whiteList.indexOf(student.status) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  isFrozen(student: Student) {
    const whiteList = ['frozen'];
    if (!!student) {
      if (!!student.status) {
        if (whiteList.indexOf(student.status) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  async updateLocalPic(res: any): Promise<any> {
    const lStudent = await this.getProfil();
    const student = new Student(lStudent);
    const textRes = JSON.stringify(res);
    const data = JSON.parse(textRes);
    student.picUrl = data.picture_url;
    return this.setProfil(student);
  }

  async removeGuestData(): Promise<string[]> {
    const keys = await this.storageService.keys();
    for (const key of keys) {
      if (key.includes('GUEST')) {
        if (key.includes('badges')) {
          continue;
        }
        await this.storageService.remove(key);
      }
    }
    return this.storageService.keys();
  }

  async removeUserData(remoteId: string): Promise<string[]> {
    const keys = await this.storageService.keys();
    for (const key of keys) {
      if (key.includes(remoteId)) {
        await this.storageService.remove(key);
      }
    }
    return this.storageService.keys();
  }

  fetchUserProperties(): Promise<any> {
    if (
      !!this.student &&
      this.student.status !== 'guest' &&
      !!this.student.remoteId
    ) {
      if (!!this._dataProperties) {
        return new Promise(resolve => {
          resolve(this._dataProperties);
        });
      } else {
        const url = `${environment.cdrBase}/v${environment.apiVersion}/students/${this.student.remoteId}/data_properties`;

        return this.http
          .get(url)
          .pipe(
            map(async (userData: any) => {
              if (!!userData && !!userData.data && !!userData.data.attributes) {
                this._dataProperties = userData.data.attributes;
                return this._dataProperties;
              }
            }),
            catchError(async err => {
              const customError = {
                errorCode: 'E301',
                errorMessage:
                  'Fetch User Properties Student Service request failed',
                errorOriginal: err,
              };
              this.userErrorHandlerService.addError({
                criticity: 1,
                service: 'user_properties',
                platform: 'both',
                data: this._student,
                errorCode: 'ssfup',
              });
              // this.sentryService.sendToSentry(`EVSERROR Student Service fetchUserProperties customError ${JSON.stringify(customError)}`, this._student);
              return null;
            })
          )
          .toPromise();
      }
    }
    return new Promise(resolve => {
      resolve(null);
    });
  }

  fetchStudentExamReservation(): Promise<any> {
    if (
      !!this.student &&
      this.student.status !== 'guest' &&
      !!this.student.remoteId &&
      !!this.student.examReservation
    ) {
      const url = `${environment.cdrBase}/v${environment.apiVersion}/students/${this.student.remoteId}/exam_reservations/${this.student.examReservation}`;
      return this.http
        .get(url)
        .pipe(
          map(async (examReservation: any) => {
            if (!!examReservation && !!examReservation.data) {
              return examReservation.data;
            }
          }),
          catchError(async err => {
            const customError = {
              errorCode: 'E301',
              errorMessage:
                'Fetch Exam Reservation Student Service request failed',
              errorOriginal: err,
            };
            this.userErrorHandlerService.addError({
              criticity: 1,
              service: 'exam_reservation',
              platform: 'both',
              data: this._student,
              errorCode: 'ssfup',
            });
            // this.sentryService.sendToSentry(`EVSERROR Student Service fetchUserProperties customError ${JSON.stringify(customError)}`, this._student);
            return null;
          })
        )
        .toPromise();
    }
    return null;
  }

  uploadExamReservationResult(examReservationData: any): Observable<any> {
    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/driving_exams/${examReservationData.drivingExamId}/submit_result`;
    let data = {
      result: examReservationData.examResult,
    };

    return this.http.post(url, data).pipe(
      switchMap(res => {
        return of(res);
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  fetchStudentAccountDashboard(student: Student) {
    if (!!student && !!student.remoteId) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${student.remoteId}/account_dashboard`;

      if (this.networkService?.isOffline() || student.isGuest()) {
        return of([]).toPromise();
      }

      return this.http
        .get(url)
        .pipe(
          switchMap(async data => {
            return this.buildStudentAccountDashboardFromRawData(data);
          }),
          catchError(async err => {
            return {
              errorCode: 'E301',
              errorMessage: 'Student Service failed',
              errorOriginal: err,
            };
          })
        )
        .toPromise();
    }
    return of([]).toPromise();
  }

  private buildStudentAccountDashboardFromRawData(data: any) {
    let dashboard = [];
    if (!!data) {
      dashboard = data.data;
    }
    return dashboard;
  }

  updateCP(cp: string): Promise<any> {
    let dob;
    if (!!this.student.dob) {
      dob = this.student.dob.split(/\/|-/);
    } else {
      dob = ['', '', ''];
    }
    const data = {
      fName: this.student.firstName,
      lName: this.student.lastName,
      birthday: {
        year: dob[2],
        month: dob[1],
        day: dob[0],
      },
      gender: this.student.gender,
      // email: values.email,
      phone: this.student.phone,
      postalCode: this.student.postalCode,
      driving_departmentPostalCode: cp,
    };

    return this.updateProfile(data, this.student.remoteId).toPromise();
    // const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/profile`;

    // if (this.networkService?.isOffline()) {
    //   return Promise.resolve([]);
    // }

    // return this.http.post(url, {driving_department_postal_code: cp}).pipe(
    //   switchMap(
    //     async (data: any) => {
    //       return data;
    //   }),
    //   catchError(
    //     async err => {
    //       return { errorCode: 'E301', errorMessage: 'Update CP Service failed', errorOriginal: err};
    //     }
    //   )
    // ).toPromise();
  }

  get student(): Student {
    return this._student;
  }
  set student(student: Student) {
    this._student = student;
  }
  // get isSignedInSoft(): boolean { return this.tokenService.isSignedInSoft; }
}
