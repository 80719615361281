import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'radial-progress-comp',
  templateUrl: 'radial-progress.html',
  styleUrls: ['radial-progress.scss'],
})
export class RadialProgressComponent implements OnInit, OnChanges {
  @ViewChild('radialElt', { static: true }) radialElt: any;
  @Input() compOptions: any;

  score = 0;
  size = 50; // default
  colorClass = '';
  scoreMax = 40;
  percent = 0;
  showData = true;
  borderSize = 12;

  constructor(
    private utils: UtilsService,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.size =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.size)
        ? this.compOptions.size
        : 100; // default
    this.score =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.score)
        ? this.compOptions.score
        : 0;
    this.colorClass =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.color)
        ? this.compOptions.color
        : this.colorClass;
    this.scoreMax =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.scoreMax)
        ? this.compOptions.scoreMax
        : this.scoreMax;
    this.showData =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.showData)
        ? this.compOptions.showData
        : this.showData;
    this.borderSize =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.border)
        ? this.compOptions.border
        : this.borderSize;
    this.score = this.score > this.scoreMax ? this.scoreMax : this.score; // limit to 40
    this.percent = Math.ceil((this.score / this.scoreMax) * 100);

    this.radialElt.nativeElement.setAttribute('data-size', this.size);
    const circles = this.radialElt.nativeElement.getElementsByTagName('circle');
    const svg = this.radialElt.nativeElement.getElementsByTagName('svg')[0];
    this.renderer.setStyle(
      svg,
      'width',
      this.size * 2 + this.borderSize + 'px'
    );
    this.renderer.setStyle(
      svg,
      'height',
      this.size * 2 + this.borderSize + 'px'
    );
    const circlesList = Array.prototype.slice.call(circles);
    circlesList.forEach(elt => {
      elt.setAttribute('cx', this.size);
      elt.setAttribute('cy', this.size);
      elt.setAttribute('r', this.size);
      this.renderer.setStyle(
        elt,
        'stroke-dasharray',
        Math.floor(2 * this.size * Math.PI)
      );
      this.renderer.setStyle(
        elt,
        'transform',
        'rotate(-90deg) translate(-' +
          (this.size * 2 + this.borderSize / 2) +
          'px, ' +
          this.borderSize / 2 +
          'px)'
      );
      if (elt.className.baseVal.includes('active')) {
        let borderMin = Math.floor(this.borderSize / 1.5);
        borderMin =
          this.borderSize - borderMin < 2
            ? 2
            : this.borderSize - borderMin > 8
              ? 8
              : this.borderSize - borderMin;
        this.renderer.setStyle(
          elt,
          'stroke-width',
          this.borderSize - borderMin
        );
        const val = Math.floor(
          Math.floor(2 * this.size * Math.PI) -
            (Math.floor(2 * this.size * Math.PI) * this.percent) / 100
        );
        this.renderer.setStyle(elt, 'stroke-dashoffset', val);
      } else {
        this.renderer.setStyle(elt, 'stroke-width', this.borderSize);
      }
    });
  }
}
