import { Session } from './session';
import { Answer } from './answer';
import { Serie } from './serie';
import * as moment from 'moment-timezone';

export class Sessions {
  nbAnswersRef = 40;
  private _sessions: Session[] = [];
  private _sessionsDetails: Session[] = [];

  constructor() {}

  fillSession(data: any, serieId: string, serieType = null) {
    // console.log('sessionsService fillSession data' + JSON.stringify(data));
    let session: Session = null;
    if (!!data && !!serieId) {
      const attributes = !!data.attributes ? data.attributes : null;
      if (!!attributes && !!data.id) {
        const answersData =
          !!attributes.answers && !!attributes.answers.data
            ? attributes.answers.data
            : null;
        session = new Session({
          id: !!data.id ? data.id : null,
          fakeId: null,
          type: !!serieType
            ? !!serieType.split('::')[1]
              ? serieType.split('::')[1]
              : null
            : null,
          mode: !!attributes.type ? attributes.type : null,
          state: !!attributes.state ? attributes.state : null,
          lastAnsweredAt: !!attributes.last_answered_at
            ? attributes.last_answered_at
            : null,
          serieId: serieId,
          answersCount: !!attributes.answers_count
            ? attributes.answers_count
            : null,
          answers: !!answersData ? this.arrayToAnswersArray(answersData) : [],
          stats: {
            badAnswers: !!attributes.stats
              ? !!attributes.stats.false
                ? attributes.stats.false
                : 0
              : 0,
            goodAnswers: !!attributes.stats
              ? !!attributes.stats.right
                ? attributes.stats.right
                : 0
              : 0,
          },
        });
      }
    }
    return session;
  }

  arrayToAnswersArray(datas: any[]): Answer[] {
    const answers: Answer[] = [];
    for (const data of datas) {
      if (!!data) {
        const attributes = data.attributes;
        const id = data.id;
        const answer = new Answer({
          id: id,
          state: attributes.state,
          serieId: attributes.serie_id,
          timespent: attributes.timespent,
          updatedAt: attributes.updated_at,
          sessionId: attributes.session_id,
          questionId: attributes.question_id,
          answer: attributes.answer,
        });
        answers.push(answer);
      }
    }
    return answers;
  }

  serializeAnswers(answersData: any): Answer[] {
    const answers = [];
    if (!!answersData && answersData.length > 0) {
      for (let answer of answersData) {
        if (!!answer) {
          answer = new Answer(answer);
          answers.push(answer);
        }
      }
    }
    return answers;
  }

  sessionsNeedSync(sessions: Session[], localSessions: Session[]) {
    const sessionsToSync: Session[] = [];
    if (Array.isArray(sessions) && sessions.length > 0) {
      if (!!localSessions && localSessions.length > 0) {
        for (const session of sessions) {
          sessionsToSync.push(session);
        }
      }
    }
    // console.log('sessions to sync => ', sessionsToSync);
    return sessionsToSync;
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  weekDates(date: moment.Moment): Array<moment.Moment> {
    const dates: Array<moment.Moment> = [];
    // DATE TO CHANGE
    let tmp: moment.Moment = this.dateToFormatFr(date);
    let day: number;

    tmp.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    day = tmp.day();
    tmp.date(tmp.date() - day + (day === 0 ? -6 : 1));
    dates.push(tmp);

    // DATE TO CHANGE
    tmp = this.dateToFormatFr(date);

    tmp.set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
    tmp.date(tmp.date() - (day - 1) + 6);
    dates.push(tmp);

    return dates;
  }

  monthDates(date: moment.Moment): Array<moment.Moment> {
    const dates: Array<moment.Moment> = [];
    let tmp: moment.Moment;

    // DATE TO CHANGE
    tmp = moment({ years: date.year(), months: date.month(), days: 1 });
    tmp = this.dateToFormatFr(tmp);
    tmp.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    dates.push(tmp);

    // DATE TO CHANGE
    tmp = moment({ years: date.year(), months: date.month() + 1, days: 0 });
    tmp = this.dateToFormatFr(tmp);
    tmp.set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
    dates.push(tmp);

    return dates;
  }

  addRangedSession(sessions: Array<Session>, timeSpan: string): Array<Session> {
    let rangedSessions: Array<Session> = [];
    if (timeSpan === 'Tout') {
      rangedSessions = sessions.filter(elt => elt.answersCount > 0);
    } else {
      let span: Array<moment.Moment>;
      if (timeSpan === 'Mois') {
        // DATE TO CHANGE
        span = this.monthDates(this.dateToFormatFr());
      } else if (timeSpan === 'Semaine') {
        // DATE TO CHANGE
        span = this.weekDates(this.dateToFormatFr());
      }
      rangedSessions = sessions.filter(
        elt =>
          this.between(elt.lastAnsweredAt, span[0], span[1]) &&
          elt.answersCount > 0
      );
      // Stat mois au 02/11 affiche : Mon Oct 29 2018 00:00:00 -> Thu Nov 08 2018 23:59:59
      // Quelle est la logique du coup ? C'est depuis le début mois en cours ?
      // Ou 1 mois à partir de la date demandée ex au 02/11 on requete du 02/10 au 02/11
      // PS : j'ai pas vérifié la stat semaine mais ya un risque de foirage aussi :)
    }
    return rangedSessions;
  }

  getPendingSessions(sessions: Array<Session>) {
    const pendingSeries = sessions.filter(
      elt => elt.state === 'pending' && elt.answersCount > 0
    );
    const knowSeriesId = [];
    const distinctPendingSeries = [];
    for (const serie of pendingSeries) {
      if (knowSeriesId.indexOf(serie.serieId) === -1) {
        knowSeriesId.push(serie.serieId);
        distinctPendingSeries.push(serie);
      }
    }
    return distinctPendingSeries.length;
  }

  getSessionsBySlice(sessions: Array<Session>) {
    const scoresBySession = this.getScoresBySession(sessions);
    // reset seriesRepartitionParTranche
    const seriesRepartitionParTranche = {
      '35-40': 0,
      '20-34': 0,
      '0-19': 0,
      pending: this.getPendingSessions(sessions),
    };

    for (const score of scoresBySession) {
      if (score < 20) {
        seriesRepartitionParTranche['0-19']++;
      } else if (score < 35) {
        seriesRepartitionParTranche['20-34']++;
      } else if (score <= 40) {
        seriesRepartitionParTranche['35-40']++;
      }
    }

    return {
      seriesRepartitionParTranche: seriesRepartitionParTranche,
    };
  }

  // by session ca veut dire par session sinon on l'appel by sessionS
  // du coup je peux pas m'en servir par session faudra changer ca
  getScoresBySession(sessions: Array<Session>) {
    const otherSessions = sessions.filter(elt => elt.state !== 'pending');

    const nbGoodAnswersBySession = [];
    for (const session of otherSessions) {
      if (session.answersCount !== this.nbAnswersRef) {
        nbGoodAnswersBySession.push(
          (session.stats.goodAnswers * this.nbAnswersRef) / session.answersCount
        );
      } else {
        nbGoodAnswersBySession.push(session.stats.goodAnswers);
      }
    }
    return nbGoodAnswersBySession;
  }

  getAverageScore(sessions: Array<Session>) {
    const scoresBySession = this.getScoresBySession(sessions);

    let averageScore = 0;
    if (scoresBySession.length > 0) {
      const sum = scoresBySession.reduce((a, b) => a + b, 0);
      averageScore = Math.round(sum / scoresBySession.length);
    }

    return averageScore;
  }

  getBestScore(sessions: Array<Session>) {
    let bestScore = 0;
    const scoresBySession = this.getScoresBySession(sessions);
    if (scoresBySession.length > 0) {
      const sortedByScore = scoresBySession.sort((a, b) => b - a);
      bestScore = sortedByScore[0];
    }
    return Math.round(bestScore);
  }

  between(variable: string, min: moment.Moment, max: moment.Moment): boolean {
    if (!variable) {
      return false;
    }
    // DATE TO CHANGE
    return (
      min <= this.dateToFormatFr(variable) &&
      this.dateToFormatFr(variable) <= max
    );
  }

  stateStats(sessions: Array<Session>): any {
    const successSessions = sessions.filter(
      elt => elt.state === 'right'
    ).length;
    const pendingSessions = sessions.filter(
      elt => elt.state === 'pending'
    ).length;
    const failedSessions = sessions.filter(elt => elt.state === 'wrong').length;
    return {
      success: successSessions,
      pending: pendingSessions,
      failed: failedSessions,
    };
  }

  totalStats(
    stats: any,
    series: Array<Serie>,
    rangedSessions: Array<Session>
  ): any {
    let answeredQuestionsTotal = 0;
    let nbExams = 0;
    let totalSuccessAnswers = 0;
    let totalBadAnswers = 0;

    if (!!series && series.length > 0) {
      for (const serie of series) {
        const sessions = rangedSessions.filter(elt => elt.serieId === serie.id);
        let tmpBadAnswers = 0;
        let tmpGoodAnswers = 0;
        if (!!sessions && sessions.length > 0) {
          for (const session of sessions) {
            answeredQuestionsTotal += session.answersCount;
            if (session.state !== 'pending') {
              tmpBadAnswers += session.stats.badAnswers;
              tmpGoodAnswers += session.stats.goodAnswers;
              if (session.mode === 'Cdr::ExamSession') {
                nbExams++;
              }
            }
          }
        }
        totalSuccessAnswers += (tmpGoodAnswers / serie.nbQuestions) * 40;
        totalBadAnswers += (tmpBadAnswers / serie.nbQuestions) * 40;
      }
    }
    const seriesTotalCount = series.length;
    const seriesStartedTotalCount =
      stats.success + stats.failed + stats.pending;
    const seriesFinishedTotalCount = stats.success + stats.failed;

    return {
      successAnswers: totalSuccessAnswers,
      badAnswers: totalBadAnswers,
      seriesTotal: seriesTotalCount,
      startedSeries: seriesStartedTotalCount,
      finishedSeries: seriesFinishedTotalCount,
      answeredTotal: answeredQuestionsTotal,
      examTotal: nbExams,
    };
  }

  diffDates(d1, d2, u) {
    let div = 1;
    switch (u) {
      case 's':
        div = 1000;
        break;
      case 'm':
        div = 1000 * 60;
        break;
      case 'h':
        div = 1000 * 60 * 60;
        break;
      case 'd':
        div = 1000 * 60 * 60 * 24;
        break;
    }
    const diff = d2.getTime() - d1.getTime();
    return Math.ceil(diff / div);
  }

  streakDays(sessions: Array<Session>) {
    const streaks = [1];
    let streak = 1;
    let previousDate: any;
    for (const session of sessions) {
      // DATE TO CHANGE
      const currentDate: any = this.dateToFormatFr(
        session.lastAnsweredAt
      ).valueOf();
      if (!!previousDate) {
        if (
          Math.round((previousDate - currentDate) / (1000 * 3600 * 24)) === 1
        ) {
          streak++;
        } else {
          if (
            Math.round((previousDate - currentDate) / (1000 * 3600 * 24)) > 1
          ) {
            streaks.push(streak);
            streak = 1;
          }
        }
        previousDate = currentDate;
      } else {
        previousDate = currentDate;
      }
    }
    streaks.push(streak);
    const bestStreak = streaks.sort((a, b) => b - a);
    return bestStreak[0];
  }

  percentageStats(stats: any, totalStats: any): any {
    let successSeriesInPercentage = 0;
    let pendingSeriesInPercentage = 0;
    let failedSeriesInPercentage = 0;
    let rightAnswersAverage = 0;
    let badAnswersAverage = 0;

    if (totalStats.startedSeries > 0) {
      successSeriesInPercentage = Math.round(
        (stats.success * 100) / totalStats.startedSeries
      );
      pendingSeriesInPercentage = Math.round(
        (stats.pending * 100) / totalStats.startedSeries
      );
      failedSeriesInPercentage = Math.round(
        (stats.failed * 100) / totalStats.startedSeries
      );
    }
    if (totalStats.finishedSeries > 0) {
      rightAnswersAverage = Math.round(
        totalStats.successAnswers / totalStats.finishedSeries
      );
      badAnswersAverage = Math.round(
        totalStats.badAnswers / totalStats.finishedSeries
      );
      if (rightAnswersAverage + badAnswersAverage < 40) {
        rightAnswersAverage = Math.round(
          (rightAnswersAverage / (rightAnswersAverage + badAnswersAverage)) * 40
        );
        badAnswersAverage = Math.round(
          (badAnswersAverage / (rightAnswersAverage + badAnswersAverage)) * 40
        );
      }
    }
    return {
      successPercent: successSeriesInPercentage,
      failedPercent: failedSeriesInPercentage,
      pendingPercent: pendingSeriesInPercentage,
      rightAnswersAverage: rightAnswersAverage,
      badAnswersAverage: badAnswersAverage,
    };
  }

  isInArray(data: Array<any>, date: string): boolean {
    for (const elem of data) {
      if (elem.date === date) {
        return true;
      }
    }
    return false;
  }

  public get sessions(): Session[] {
    return this._sessions;
  }
  public get sessionsDetails(): Session[] {
    return this._sessionsDetails;
  }
  public set sessionsDetails(value: Session[]) {
    this._sessionsDetails = value;
  }
  public set sessions(value: Session[]) {
    this._sessions = value;
  }
}
