import { EvsRecord } from './evsRecord';

export interface ICDRLesson {
  remoteId: number;
  slug: string;
  name: string;
  type: string;
  theme: string;
  subtheme: string;
  rank: number;
  paragraphs?: Array<IParagraph>;
}

export interface IParagraph {
  parentRemoteId: number;
  remoteId: number;
  lessonSubtheme: string;
  lessonName: string;
  type: string;
  title: string;
  text: string;
  photo: string;
}

export class CDRLesson extends EvsRecord implements ICDRLesson {
  public remoteId: number;
  public slug: string;
  public name: string;
  public type: string;
  public theme: string;
  public subtheme: string;
  public rank: number;
  public paragraphs?: Array<IParagraph>;

  constructor(obj: ICDRLesson) {
    super(obj);
  }
}
