<div
  *ngIf="!componentBlocked"
  class="evs_autocomplete"
  #autoComplete
  [id]="id"
  [ngClass]="{'select': type === 'select'}">
  <form
    autocomplete="off"
    [formGroup]="acForm"
    [ngClass]="{'flexForm': (type == 'search')}">
    <div [ngClass]="{'searchEngine': (type == 'search')}">
      <ng-container *ngIf="type !== 'select'">
        <input
          autocomplete="off"
          id="ac{{id}}"
          type="text"
          class="evs_inputSearch"
          formControlName="search"
          (focus)="setFocus()"
          (keydown)="keyDown($event)"
          [ngClass]="{'hasResults': (!forceClose && hasResults)}"
          (blur)="sendBlurEvent()"
          [placeholder]="placeholder"
          #searchInput />
        <label
          for="ac{{id}}"
          [ngClass]="{'label-up': !!getACForm.search.value && getACForm.search.value !== ''}"
          >{{placeholder}}</label
        >
        <!-- finir le truc selected nomatch -->
        <div
          *ngIf="hasSelected || hasNotMatch"
          class="evs_delete"
          (click)="deleteUserInput()"
          [ngClass]="{'evs_noMatch': hasNotMatch}">
          <img src="assets/svg/tunnel-delete-ico.svg" alt="EnvoitureSimone" />
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'select'">
        <div (click)="openSelect()">
          <input
            id="se{{id}}"
            autocomplete="off"
            type="text"
            formControlName="search"
            class="evs_inputSelect"
            [ngClass]="{'fake-focus': !forceClose, 'selected': hasSelected}" />
          <label [ngClass]="{'label-up': hasSelected}">{{placeholder}}</label>
          <label class="label-select-value" *ngIf="hasSelected && forceClose"
            >{{selectedSelect}}</label
          >
        </div>
      </ng-container>
      <div
        class="evs_options"
        *ngIf="!forceClose && options.length > 0"
        #resultOptions>
        <div
          class="evs_option"
          *ngFor="let option of options; let i = index"
          (click)="optionSelected(option)"
          [ngClass]="{'keyboardSelection': (keyboardIndex == i)}">
          {{option.name}}
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="!getACForm.search.valid && (getACForm.search.dirty || getACForm.search.touched)">
      <div class="field-error" *ngIf="getACForm.search.errors?.required">
        Ce champ est obligatoire
      </div>
      <!-- <div class="autocomplete-icon-invalid"></div> -->
    </ng-container>
    <button
      *ngIf="type == 'search'"
      inline
      class="btn"
      size="small"
      (click)="sendResult()">
      OK
    </button>
  </form>
</div>
<div *ngIf="componentBlocked" class="evs_autocomplete">
  <form>
    <input
      autocomplete="off"
      type="text"
      class="evs_inputSearch noService"
      placeholder="Ce composant ne dispose d'aucun service !"
      disabled />
    <label class="text-muted">Ce composant ne dispose d'aucun service !</label>
  </form>
</div>
