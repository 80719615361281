import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: `init0`, initializer: `init` })
@EasyDebugDecorator
export class WonderPushService {
  constructor(public wonderPush: WonderPush) {}

  async init() {
    return await new Promise(async (resolve, reject) => {
      resolve('WonderPush done');
    });
  }

  async activateNotification(): Promise<boolean> {
    const notifSub = await this.wonderPush
      .subscribeToNotifications()
      .then(res => {
        return true;
      })
      .catch(err => {
        return false;
      });
    return notifSub;
  }

  async getUserConsent(): Promise<boolean> {
    const userConsent = await this.wonderPush
      .setUserConsent(true)
      .then(res => {
        return true;
      })
      .catch(err => {
        return false;
      });
    return userConsent;
  }

  async isSubscribedToNotifications(): Promise<boolean> {
    const isSubscribed = await this.wonderPush
      .isSubscribedToNotifications()
      .then(res => {
        return true;
      })
      .catch(err => {
        return false;
      });
    return isSubscribed;
  }
}
