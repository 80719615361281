import {
  Component,
  Input,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'svg-icon-comp',
  templateUrl: 'svg-icon.html',
  styleUrls: ['svg-icon.scss'],
})
export class SvgIconComponent {
  @ViewChild('svgIcon', { static: true }) svgIconElt: any;
  @Input() compOptions: any;

  isDebug = false;

  iconName = '';
  customClass = [];

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes['compOptions'];
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.iconName =
      this.compOptions && typeof this.compOptions.iconName !== 'undefined'
        ? this.compOptions.iconName
        : this.iconName;
    this.customClass =
      this.compOptions && typeof this.compOptions.customClass !== 'undefined'
        ? this.compOptions.customClass
        : this.customClass;

    if (this.customClass.length > 0) {
      for (const customClass of this.customClass) {
        if (!!this.svgIconElt) {
          this.renderer.addClass(this.svgIconElt.nativeElement, customClass);
        }
      }
    }
  }
}
