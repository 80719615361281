<div class="tunnel-select-hour-container">
  <div class="content">
    <div class="content-container">
      <div class="content-header">
        <div class="tunnel-back-controls">
          <div
            class="back-btn"
            (click)="sendCompData({action: 'navigate', data: {direction: 'prev'}})">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone" />
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="select-hour-form">
          <h5 id="question-title">
            Sélectionne le nombre d'heure que tu souhaites réaliser
          </h5>
          <div class="form-container">
            <form [formGroup]="selectHourForm" class="evs-form">
              <div id="drop-down-menu">
                <autocomplete-comp
                  id="tunnelFormSelectHour"
                  [compOptions]="{type: 'select', 'placeholder': 'Nombre d\'heures de conduite', 'fields': ['name'], 'fieldname': 'name', 'output': 'value', selectData: hoursList}"
                  (inputSelected)="setSelectHour($event)"></autocomplete-comp>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="next-controls" *ngIf="isDesktop">
        <div *ngIf="formCompleted" id="price-bar" class="hour-recap">
          <span id="price-tag"
            >{{getSelectHourForm.selectHour.value.resume_text}}</span
          >
          <span id="price" class="bold"
            >{{getSelectHourForm.selectHour.value.price}}</span
          >
        </div>
        <button
          id="bouton-confirm"
          [disabled]="!formCompleted || !isActive || dataSent"
          class="btn full-width"
          (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">
          Continuer
        </button>
      </div>
    </div>
  </div>
  <div id="image" class="visuel"></div>
  <div class="next-controls" *ngIf="!isDesktop">
    <div *ngIf="formCompleted" id="price-bar" class="hour-recap">
      <span id="price-tag"
        >{{getSelectHourForm.selectHour.value.resume_text}}</span
      >
      <span id="price" class="bold"
        >{{getSelectHourForm.selectHour.value.price}}</span
      >
    </div>
    <button
      id="bouton-confirm"
      [disabled]="!formCompleted || dataSent"
      class="btn full-width"
      (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">
      Continuer
    </button>
  </div>
</div>
