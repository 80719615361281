import { ErrorHandler, Injectable } from '@angular/core';
import { Initialable } from './app-init.service';
import {
  AdjustEvent,
  Adjust,
  AdjustConfig,
  AdjustEnvironment,
} from '@awesome-cordova-plugins/adjust/ngx';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { Platform } from '@ionic/angular';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
@Initialable({ step: 'init5', initializer: `onInit` })
@EasyDebugDecorator
export class AdjustService {
  public defaultAdId = '00000000-0000-0000-0000-000000000000';

  private _idfa = null;
  private _adid = null;
  private _googleAdId = null;

  public get idfa(): string {
    return this._idfa;
  }

  public set idfa(v: string) {
    this._idfa = v;
  }

  public get adid(): string {
    return this._adid;
  }

  public set adid(v: string) {
    this._adid = v;
  }

  public get googleAdId(): string {
    return this._googleAdId;
  }

  public set googleAdId(v: string) {
    this._googleAdId = v;
  }

  constructor(
    public adjust: Adjust,
    private platform: Platform,
    private errorHanlerService: ErrorHandler
  ) {
    this.initAdjust();
  }

  async onInit() {
    return 'Adjust done';
  }

  private async initAdjust() {
    this.platform
      .ready()
      .then(() => {
        const config = new AdjustConfig(
          'tfsr5cpne5ts',
          environment.env === 'production'
            ? AdjustEnvironment.Production
            : AdjustEnvironment.Sandbox
        );
        this.adjust.create(config);
      })
      .finally(async () => {
        await this.initIds();
      });
  }

  private async initIds() {
    let [idfa, adid, googleAdId] = Array(3).fill(this.defaultAdId);

    if (this.platform.is('cordova')) {
      [idfa, adid, googleAdId] = await Promise.all([
        this.getIdfa(),
        this.getAdid(),
        this.getGoogleAdId(),
      ]);
    }

    this.idfa = idfa;
    this.adid = adid;
    this.googleAdId = googleAdId;
  }

  private async getIdfa() {
    try {
      return await this.adjust.getIdfa();
    } catch (err) {
      this.errorHanlerService.handleError(
        new Error(`Adjust get IDFA failed: ${JSON.stringify(err)} / ${err}`)
      );
      return this.defaultAdId;
    }
  }

  private async getAdid() {
    try {
      return await this.adjust.getAdid();
    } catch (err) {
      this.errorHanlerService.handleError(
        new Error(`Adjust get ADID failed: ${JSON.stringify(err)} / ${err}`)
      );
      return this.defaultAdId;
    }
  }

  private async getGoogleAdId() {
    try {
      return await this.adjust.getGoogleAdId();
    } catch (err) {
      this.errorHanlerService.handleError(
        new Error(`Adjust get GAID failed: ${JSON.stringify(err)} / ${err}`)
      );
      return this.defaultAdId;
    }
  }

  private objToAdjust(datas: any, adjustEvent: AdjustEvent): AdjustEvent {
    for (const data of Object.entries(datas)) {
      if (!!JSON.stringify(data[1])) {
        adjustEvent.addCallbackParameter(
          data[0],
          JSON.stringify(data[1]).split('"').join('')
        );
      }
    }
    return adjustEvent;
  }

  private arrayToAdjust(objs: any[], adjustEvent: AdjustEvent): AdjustEvent {
    for (const obj of objs) {
      adjustEvent = this.objToAdjust(obj, adjustEvent);
    }
    return adjustEvent;
  }

  public sendAdjustEvent(
    eventName: string,
    user: any,
    env: any,
    additionalData: any
  ) {
    // console.log('sendAdjustEvent', eventName, user, env, additionalData);
    let adjustEvent = new AdjustEvent(eventName);
    if (!!additionalData) {
      adjustEvent = this.arrayToAdjust(
        [user, env, additionalData],
        adjustEvent
      );
    } else {
      adjustEvent = this.arrayToAdjust([user, env], adjustEvent);
    }
    this.adjust.trackEvent(adjustEvent);
  }
}
