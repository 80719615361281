import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'changeOriginalTo' })
export class ChangeOriginalPipe implements PipeTransform {
  // small / medium / large

  transform(url: string, size: string): string {
    if (!!url) {
      return url.replace(/original/, size);
    } else {
      return null;
    }
  }
}
