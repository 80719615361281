import { SoftDeployService } from './softDeploy.service';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Initialable } from '../../app/services/app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { StorageService } from '../app.services';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init1', initializer: 'init' })
@EasyDebugDecorator
export class DevDebugModeService {
  transformed = false;
  debugMode = false;
  report = null;

  constructor(
    private storageService: StorageService,
    private http: HttpClient
  ) {}

  async init() {
    const localtransformed = await this.storageService.get('transformed');
    if (!!localtransformed) {
      this.transformed = localtransformed;
    }
    if (this.transformed) {
      this.report = await this.storageService.get('report');
      console.log('EASY DEBUG REPORT ==> ', this.report);
    }
    return 'DevDebug done';
  }

  async fetchLogs(logId: string): Promise<any> {
    const url = `${environment.cdrBase}/v${environment.apiVersion}/debug_report/${logId}`;

    return this.http
      .get(url)
      .pipe(
        switchMap(async (data: any) => {
          const reportString = data.data.attributes.report;
          this.report = JSON.parse(reportString);
          return this.report.storage;
        }),
        catchError(async err => {
          return null;
        })
      )
      .toPromise();
  }

  async fillStorage(dataSet: any) {
    this.storageService.clear();

    for (const [key, value] of Object.entries(dataSet)) {
      await this.storageService.set(key, value);
    }
  }

  async transformStorageData(logId: string) {
    console.log('logId => ', logId);
    const dataUserLogs = await this.fetchLogs(logId);
    await this.fillStorage(dataUserLogs);
    await this.storageService.set('report', this.report);
    await this.storageService.set('transformed', true);
  }
}
