import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { NavigationService } from '@app/services';

import { ModalIframeComponent } from '@app/modals';

import {
  Gesture,
  GestureController,
  IonContent,
  ModalController,
} from '@ionic/angular';

import { EasyDebugDecorator } from '@app/app.decorators';
import * as moment from 'moment-timezone';

@EasyDebugDecorator
@Component({
  selector: 'calendar-daily-streak',
  templateUrl: 'calendar-daily-streak.html',
  styleUrls: ['calendar-daily-streak.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDailyStreakComponent implements OnInit, OnChanges {
  @Input() compOptions: any;
  @Output() changeMonth = new EventEmitter<boolean>();
  @ViewChild('dailyStreakCalendar') dailyStreakCalendar: ElementRef;

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  isDesktop = window.innerWidth > 767 ? true : false;

  daysFirstLetter = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  fullDays = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];
  months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  dailyStreakDates = [];
  firstDayOfMonth: moment.Moment = null;
  lastDayOfMonth: moment.Moment = null;
  currentMonthDays = [];
  currentMonth = '';
  maxMonthYear = null;
  isMaxMonthYear = true;
  gesture: Gesture;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = window.innerWidth > 767 ? true : false;
    this.setGesture();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private modalController: ModalController,
    private gestureController: GestureController
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
      this.setGlobals();
    }
  }

  ngOnInit() {
    this.gesture = this.gestureController.create(
      {
        el: this.dailyStreakCalendar.nativeElement,
        gestureName: 'month-swipe-gesture',
        threshold: 30,
        onEnd: event => this.monthSwipeHandlerEnd(event),
      },
      true
    );
    this.setGesture();
    this.setGlobals();
    // console.log('dailyStreakDates', this.dailyStreakDates);

    this.setMonth(true);
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.dailyStreakDates =
      this.compOptions &&
      typeof this.compOptions.dailyStreakDates !== 'undefined'
        ? this.compOptions.dailyStreakDates
        : this.dailyStreakDates;

    this.cdr.detectChanges();
  }

  setGesture() {
    if (this.isDesktop) {
      this.gesture.enable(false);
    }
    if (!this.isDesktop) {
      this.gesture.enable(true);
    }
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  setMonth(init = false) {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    today.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const todayYear = today.year();
    const todayMonth =
      today.month() + 1 >= 10 ? today.month() + 1 : '0' + (today.month() + 1);
    const todayDay = today.date() < 10 ? '0' + today.date() : today.date();
    const todayFormattedDate = `${todayYear}-${todayMonth}-${todayDay}`;

    if (init) {
      this.maxMonthYear = `${today.month()}-${today.year()}`;

      // DATE TO CHANGE
      this.firstDayOfMonth = this.dateToFormatFr(
        new Date(today.year(), today.month(), 1)
      );
      // DATE TO CHANGE
      this.lastDayOfMonth = this.dateToFormatFr(
        new Date(today.year(), today.month() + 1, 0)
      );
    }

    this.currentMonth = this.months[this.firstDayOfMonth.month()];

    for (let i = 0; i < this.lastDayOfMonth.date(); i++) {
      // DATE TO CHANGE
      const tmpDay = this.dateToFormatFr(this.firstDayOfMonth);
      tmpDay.date(tmpDay.date() + i);

      const year = tmpDay.year();
      const month =
        tmpDay.month() + 1 >= 10
          ? tmpDay.month() + 1
          : '0' + (tmpDay.month() + 1);
      const day = tmpDay.date() < 10 ? '0' + tmpDay.date() : tmpDay.date();
      const formattedDate = `${year}-${month}-${day}`;
      this.currentMonthDays.push({
        day: tmpDay,
        dayNumber: tmpDay.date(),
        isStreak: this.dailyStreakDates.includes(formattedDate),
        isActive: formattedDate === todayFormattedDate,
      });
    }

    this.fillDays();
    this.convertDaysToWeeks();

    this.cdr.detectChanges();
    // console.log('currentMonthDays', this.currentMonthDays);
  }

  fillDays() {
    const firstDay =
      this.currentMonthDays[0].day.day() === 0
        ? 7
        : this.currentMonthDays[0].day.day();
    const lastDay =
      this.currentMonthDays[this.currentMonthDays.length - 1].day.day() === 0
        ? 7
        : this.currentMonthDays[this.currentMonthDays.length - 1].day.day();

    if (firstDay != 1) {
      for (let i = 1; i < firstDay; i++) {
        this.currentMonthDays.unshift(null);
      }
    }
    if (lastDay != 0) {
      for (let i = lastDay; i < 7; i++) {
        this.currentMonthDays.push(null);
      }
    }
  }

  convertDaysToWeeks() {
    let week = [];
    this.currentMonthDays = this.currentMonthDays.reduce((acc, curr, index) => {
      week.push(curr);
      if (week.length === 7) {
        acc.push(week);
        week = [];
      }

      return acc;
    }, []);
  }

  changeMonthEvent(direction) {
    let currentMonth = this.firstDayOfMonth.month();
    let currentMonthYear = `${currentMonth}-${this.firstDayOfMonth.year()}`;
    this.isMaxMonthYear = currentMonthYear === this.maxMonthYear;

    if (direction === 'next') {
      if (this.isMaxMonthYear) {
        return;
      }
      this.firstDayOfMonth =
        currentMonth === 11
          ? this.dateToFormatFr(new Date(this.firstDayOfMonth.year() + 1, 0, 1))
          : this.dateToFormatFr(
              new Date(
                this.firstDayOfMonth.year(),
                this.firstDayOfMonth.month() + 1,
                1
              )
            );
      this.lastDayOfMonth = this.dateToFormatFr(
        new Date(
          this.firstDayOfMonth.year(),
          this.firstDayOfMonth.month() + 1,
          0
        )
      );
    }
    if (direction === 'previous') {
      this.firstDayOfMonth =
        currentMonth === 0
          ? this.dateToFormatFr(
              new Date(this.firstDayOfMonth.year() - 1, 11, 1)
            )
          : this.dateToFormatFr(
              new Date(
                this.firstDayOfMonth.year(),
                this.firstDayOfMonth.month() - 1,
                1
              )
            );
      this.lastDayOfMonth = this.dateToFormatFr(
        new Date(
          this.firstDayOfMonth.year(),
          this.firstDayOfMonth.month() + 1,
          0
        )
      );
    }
    currentMonth = this.firstDayOfMonth.month();
    currentMonthYear = `${currentMonth}-${this.firstDayOfMonth.year()}`;
    this.isMaxMonthYear = currentMonthYear === this.maxMonthYear;
    this.currentMonthDays = [];
    this.setMonth();

    this.changeMonth.emit(direction);
  }

  monthSwipeHandlerEnd(event) {
    if (event?.type === 'pan') {
      const deltaX = event.deltaX;
      const direction = deltaX < 0 ? 'next' : 'previous';
      this.changeMonthEvent(direction);
    }
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options?) {
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
