<button
  [type]="buttonType"
  class="btn evs-loading-btn"
  (click)="outputClick($event)"
  [disabled]="disabled"
  #loadingButton>
  <span [ngClass]="{'display-none': isLoading && !loadingWithText}">
    <ion-spinner
      *ngIf="isLoading && !disabled && loadingWithText && iconPosition == 'left'"
      [name]="spinnerName"
      [class]="spinnerColor"></ion-spinner>
    <span *ngIf="!isLoading || (isLoading && !loadingText)"
      >{{buttonText}}</span
    >
    <span *ngIf="isLoading && loadingText">{{loadingText}}</span>
    <ion-spinner
      *ngIf="isLoading && !disabled  && loadingWithText && iconPosition == 'right'"
      [name]="spinnerName"
      [class]="spinnerColor"></ion-spinner>
  </span>
  <ion-spinner
    *ngIf="isLoading && !disabled  && !loadingWithText"
    class="content-center"
    [name]="spinnerName"
    [class]="spinnerColor"></ion-spinner>
</button>
