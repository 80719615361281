import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'loader-bar-comp',
  templateUrl: 'loader-bar.html',
  styleUrls: ['loader-bar.scss'],
})
export class LoaderBarComponent implements OnInit, OnChanges {
  @Input() compOptions: any;

  debug = false;
  percent = 0;
  hide = false;
  hasBorder = false;
  whiteBg = false;
  rounded = false;
  animated = false;

  constructor(private utils: UtilsService) {}

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    // detect @input changes
    if (this.utils.isDefined(compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.debug =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.debug)
        ? this.compOptions.debug
        : this.debug;
    this.percent =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.percent)
        ? this.compOptions.percent
        : this.percent;
    this.hide =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.hide)
        ? this.compOptions.hide
        : this.hide;
    this.hasBorder =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.hasBorder)
        ? this.compOptions.hasBorder
        : this.hasBorder;
    this.rounded =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.rounded)
        ? this.compOptions.rounded
        : this.rounded;
    this.whiteBg =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.whiteBg)
        ? this.compOptions.whiteBg
        : this.whiteBg;
    this.animated =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.animated)
        ? this.compOptions.animated
        : this.animated;
  }
}
