import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { NavigationService } from '@app/services';

import { ModalIframeComponent } from '@app/modals';

import { IonContent, ModalController } from '@ionic/angular';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';
import * as moment from 'moment-timezone';

@EasyDebugDecorator
@Component({
  selector: 'calendar-dashboard-comp',
  templateUrl: 'calendar-dashboard.html',
  styleUrls: ['calendar-dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDashboardComponent implements OnInit, OnChanges {
  @Input() compOptions: any;
  @Input() content: IonContent;
  @Output() hasClicked = new EventEmitter<boolean>();
  @ViewChild('modalCalendar') modalCalendar: any;

  showCalendar = 0;

  sessionsSubscription: Subscription;

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  account: any;
  lessons = [];

  calledFrom = 'dashboard';
  isIpad = false;
  setDate = null;

  months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  // DATE TO CHANGE
  today = this.dateToFormatFr();
  todayDay = this.today.date(); // 0-31
  todayMonth = this.today.month(); // 0-11
  todayYear = this.today.year(); // 2018
  // DATE TO CHANGE
  selectedDate = this.dateToFormatFr(this.today);
  month: any = this.selectedDate.month(); // 0-11
  year = this.selectedDate.year(); // 2018

  first_date = this.year + '-' + (this.month + 1) + '-0' + 1;
  // DATE TO CHANGE
  tmp = this.dateToFormatFr(this.first_date).toString();
  first_day = this.tmp.substring(0, 3);
  day_name = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  day_no = this.day_name.indexOf(this.first_day);
  // DATE TO CHANGE
  nbDay = this.dateToFormatFr(new Date(this.year, this.month + 1, 0)).date();

  daysWithLetter = [];
  daysDate = [];

  modalObj: any = {};
  showModal = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private modalController: ModalController
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
      this.setGlobals();
    }
  }

  ngOnInit() {
    this.setGlobals();
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (
          !moment.isMoment(input) &&
          !!input.year &&
          !!input.month &&
          !!input.day
        ) {
          // custom object
          const year = input.year;
          const month =
            Number(input.month) < 10
              ? '0' + Number(input.month)
              : Number(input.month);
          const day = input.day < 10 ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error(
        'dateToFormatFr output',
        output,
        output.toString(),
        output.isValid()
      );
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = 'Europe/Paris';
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  setGlobals() {
    this.isDebug =
      this.compOptions && typeof this.compOptions.debug !== 'undefined'
        ? this.compOptions.debug
        : this.isDebug;
    this.calledFrom =
      this.compOptions && typeof this.compOptions.calledFrom !== 'undefined'
        ? this.compOptions.calledFrom
        : this.calledFrom;
    this.isIpad =
      this.compOptions && typeof this.compOptions.isIpad !== 'undefined'
        ? this.compOptions.isIpad
        : this.isIpad;
    this.setDate =
      this.compOptions && typeof this.compOptions.date !== 'undefined'
        ? this.compOptions.date
        : this.setDate;
    this.lessons =
      this.compOptions && typeof this.compOptions.lessons !== 'undefined'
        ? this.compOptions.lessons
        : this.lessons;

    if (!!this.setDate) {
      this.setDatefn();
    }

    this.constructSlider(true);
    this.cdr.detectChanges();
  }

  setDatefn() {
    // DATE TO CHANGE
    this.today = this.dateToFormatFr();
    this.todayDay = this.today.date(); // 0-31
    this.todayMonth = this.today.month(); // 0-11
    this.todayYear = this.today.year(); // 2018
    // DATE TO CHANGE
    this.selectedDate = this.dateToFormatFr(this.setDate);
    this.month = this.selectedDate.month(); // 0-11
    let cleanMonthForSafari = this.month;
    if (Number(this.month) < 10) {
      // console.log(Number(this.month));
      cleanMonthForSafari =
        Number(this.month) === 9 ? this.month + 1 : '0' + (this.month + 1);
      // console.log(cleanMonthForSafari);
    } else {
      cleanMonthForSafari = this.month + 1;
    }
    this.year = this.selectedDate.year(); // 2018

    // console.log('this.today', this.today);
    // console.log('this.todayDay', this.todayDay);
    // console.log('this.todayMonth', this.todayMonth);
    // console.log('this.todayYear', this.todayYear);
    // console.log('this.selectedDate', this.selectedDate);
    // console.log('this.month', this.month);
    // console.log('this.year', this.year);

    this.first_date = this.year + '-' + cleanMonthForSafari + '-0' + 1;
    // console.log('this.first_date', this.first_date);
    // DATE TO CHANGE
    this.tmp = this.dateToFormatFr(this.first_date).toString();
    this.first_day = this.tmp.substring(0, 3);
    // console.log('this.tmp', this.tmp);
    // console.log('this.first_day', this.first_day);
    this.day_name = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.day_no = this.day_name.indexOf(this.first_day);
    // DATE TO CHANGE
    this.nbDay = this.dateToFormatFr(
      new Date(this.year, cleanMonthForSafari, 0)
    ).date();

    // if (this.isDebug) {
    //   console.log('today', this.today);
    //   console.log('todayDay', this.todayDay);
    //   console.log('todayMonth', this.todayMonth);
    //   console.log('todayYear', this.todayYear);
    //   console.log('selectedDate', this.selectedDate);
    //   console.log('month', this.month);
    //   console.log('year', this.year);

    //   console.log('first_date', this.first_date);
    //   console.log('tmp', this.tmp);
    //   console.log('first_day', this.first_day);
    //   console.log('day_name', this.day_name);
    //   console.log('day_no', this.day_no);
    //   console.log('nbDay', this.nbDay);
    //   console.log('-----------------');
    // }
  }

  constructSlider(withData) {
    // construct data for calendar
    this.daysWithLetter = [];
    for (const day of this.days) {
      this.daysWithLetter.push({
        day: day,
        letter: this.getFirstLetterDay(day),
      });
    }
    let i = 0;
    this.daysDate = [];
    for (const item of this.numberToArray(35 + (this.day_no + 1))) {
      if (i < this.day_no) {
        this.daysDate.push({
          class: '',
          tooltip: '',
          text: '&nbsp;',
        });
      } else if (i >= this.day_no && this.checkIndex(i)) {
        this.daysDate.push({
          class: withData ? this.addClasses(i) : '',
          tooltip: withData ? this.populateTooltip(i) : '',
          text: i + 1 - this.day_no,
        });
      } else {
        this.daysDate.push({
          class: '',
          tooltip: '',
          text: '&nbsp;',
        });
      }
      i++;
    }
    this.showCalendar = 1;
    this.cdr.detectChanges();
  }

  getFirstLetterDay(value) {
    return value.substring(0, 1);
  }

  numberToArray(length) {
    return new Array(length);
  }

  checkIndex(value) {
    return Number(value + 1 - this.day_no) <= this.nbDay ? true : false;
  }

  addClasses(value) {
    const classes: any = {};
    // check lessons
    const isLesson = '';
    if (this.lessons.length > 0) {
      for (const lesson of this.lessons) {
        const lessonDate = this.dateToFormatFr(new Date(lesson.starts_at));
        const lessonDay = lessonDate.date(); // 0-31
        const lessonMonth = lessonDate.month(); // 0-11
        const lessonYear = lessonDate.year(); // 2018
        if (
          lessonMonth + '-' + lessonDay + '-' + lessonYear ===
          this.month + '-' + (value + 1 - this.day_no) + '-' + this.year
        ) {
          if (
            lesson.status === 'awaiting confirmation' ||
            lesson.status === 'awaiting student confirmation'
          ) {
            if (lessonDate < this.today) {
              if (lesson.driving_exam_id !== null) {
                classes.pastWaitingExam = true;
              } else {
                classes.pastWaitingLesson = true;
              }
            } else {
              if (lesson.driving_exam_id !== null) {
                classes.waitingExam = true;
              } else {
                classes.waitingLesson = true;
              }
            }
          }
          if (lesson.status === 'confirmed') {
            if (lessonDate < this.today) {
              if (!!lesson.driving_exam_id) {
                classes.pastConfirmedExam = true;
              } else {
                classes.pastConfirmedLesson = true;
              }
            } else {
              if (!!lesson.driving_exam_id) {
                classes.confirmedExam = true;
              } else {
                classes.confirmedLesson = true;
              }
            }
          }
          if (
            lesson.status === 'cancelled by student not due' ||
            lesson.status === 'cancelled by student after deadline' ||
            lesson.status === 'cancelled by teacher' ||
            lesson.status === 'cancelled by admin after deadline' ||
            lesson.status === 'cancelled by admin not due' ||
            lesson.status === 'cancelled by admin not due' ||
            lesson.status === 'declined' ||
            lesson.status === 'declined by student'
          ) {
            if (lessonDate < this.today) {
              if (lesson.driving_exam_id !== null) {
                classes.pastCancelledExam = true;
              } else {
                classes.pastCancelledLesson = true;
              }
            } else {
              if (lesson.driving_exam_id !== null) {
                classes.cancelledExam = true;
              } else {
                classes.cancelledLesson = true;
              }
            }
          }
        }
      }
    }
    // add today only if no event for the date
    if (Object.keys(classes).length === 0) {
      if (
        this.todayMonth + '-' + this.todayDay + '-' + this.todayYear ===
        this.month + '-' + (value + 1 - this.day_no) + '-' + this.year
      ) {
        classes.today = true;
      }
    }
    return classes;
  }

  populateTooltip(value) {
    let tooltipText = '';
    // check lessons
    const isLesson = '';
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    let returnedObject: any = {};

    for (const lesson of this.lessons) {
      // DATE TO CHANGE
      const lessonDate = this.dateToFormatFr(lesson.starts_at);
      // DATE TO CHANGE
      const lessonDateEnd = this.dateToFormatFr(lesson.ends_at);
      const lessonDay = lessonDate.date(); // 0-31
      const lessonMonth = lessonDate.month(); // 0-11
      const lessonYear = lessonDate.year(); // 2018
      if (
        lessonMonth + '-' + lessonDay + '-' + lessonYear ===
        this.month + '-' + (value + 1 - this.day_no) + '-' + this.year
      ) {
        // console.warn('lecon', lesson)

        // convert data to tooltip view
        const ttLessonDate = lessonDate;
        const tmp = ttLessonDate.toString();
        const first_day = tmp.substring(0, 3);
        const day_no = this.day_name.indexOf(first_day);
        const frenchStringDate =
          this.days[day_no] +
          ' ' +
          lessonDay +
          ' ' +
          (this.months[lessonMonth].charAt(0).toUpperCase() +
            this.months[lessonMonth].slice(1));

        const startLessonHour =
          lessonDate.hours() < 0
            ? '0' + lessonDate.hours()
            : lessonDate.hours() === 0
              ? lessonDate.hours() + '0'
              : lessonDate.hours();
        const startLessonMinutes =
          lessonDate.minutes() < 0
            ? '0' + lessonDate.minutes()
            : lessonDate.minutes() === 0
              ? lessonDate.minutes() + '0'
              : lessonDate.minutes();
        const endLessonHour =
          lessonDateEnd.hours() < 0
            ? '0' + lessonDateEnd.hours()
            : lessonDateEnd.hours() === 0
              ? lessonDateEnd.hours() + '0'
              : lessonDateEnd.hours();
        const endLessonMinutes =
          lessonDateEnd.minutes() < 0
            ? '0' + lessonDateEnd.minutes()
            : lessonDateEnd.minutes() === 0
              ? lessonDateEnd.minutes() + '0'
              : lessonDateEnd.minutes();

        const startAt = startLessonHour + ':' + startLessonMinutes;
        const endAt = endLessonHour + ':' + endLessonMinutes;
        let teacherName = 'EVS';

        if (!!lesson.teacher.first_name || lesson.teacher.last_name) {
          if (!!lesson.teacher.last_name && lesson.teacher.last_name !== '') {
            if (
              !!lesson.teacher.first_name &&
              lesson.teacher.first_name !== ''
            ) {
              teacherName =
                lesson.teacher.first_name +
                ' ' +
                lesson.teacher.last_name.charAt(0).toUpperCase() +
                '.';
            } else {
              if (!!lesson.teacher.gender && lesson.teacher.gender !== '') {
                teacherName = lesson.teacher.gender === 'f' ? 'Mme' : 'Mr';
                teacherName += ' ' + lesson.teacher.last_name;
              }
            }
          }
        }

        returnedObject = {};
        returnedObject.showBtn = true;
        returnedObject.date = frenchStringDate;
        returnedObject.time = 'De ' + startAt + ' à ' + endAt;
        if (teacherName !== 'EVS') {
          returnedObject.teacher = teacherName;
        }
        returnedObject.location =
          lesson.departure_point.line_1 +
          ' ' +
          lesson.departure_point.zipcode +
          ' ' +
          lesson.departure_point.city;
        returnedObject.link = { url: '/lesson', id: lesson.id };

        if (
          lesson.status === 'awaiting confirmation' ||
          lesson.status === 'awaiting student confirmation'
        ) {
          tooltipText = 'En attente de confirmation';
          returnedObject.showBtn = false;
        }
        if (lesson.status === 'confirmed') {
          if (lessonDate > today) {
            if (!!lesson.driving_exam_id) {
              tooltipText = 'Examen confirmé';
            } else {
              tooltipText = 'Leçon confirmée';
            }
          } else {
            if (!!lesson.driving_exam_id) {
              tooltipText = 'Examen réalisé';
            } else {
              tooltipText = 'Leçon réalisée';
            }
          }
        }
        if (lesson.status === 'cancelled by student not due') {
          tooltipText = "Leçon annulée par l'élève avant la date limite";
        }
        if (lesson.status === 'cancelled by student after deadline') {
          tooltipText = "Leçon annulée par l'élève après la date limite";
        }
        if (lesson.status === 'cancelled by teacher') {
          tooltipText = "Leçon annulée par l'enseignant";
        }
        if (lesson.status === 'cancelled by admin after deadline') {
          tooltipText =
            "Leçon annulée par l'administrateur après la date limite";
        }
        if (lesson.status === 'cancelled by admin not due') {
          tooltipText =
            "Leçon annulée par l'administrateur avant la date limite";
        }
        if (lesson.status === 'declined') {
          tooltipText = "Leçon refusée par l'enseignant";
        }
        if (lesson.status === 'declined by student') {
          tooltipText = "Leçon refusée par l'élève";
        }
        returnedObject.text = tooltipText;
      }
    }
    if (tooltipText === '') {
      // check today
      if (
        this.todayMonth + '-' + this.todayDay + '-' + this.todayYear ===
        this.month + '-' + (value + 1 - this.day_no) + '-' + this.year
      ) {
        returnedObject = {};
        returnedObject.date = 'none';
        returnedObject.text = "Aujourd'hui";
      }
    }
    return returnedObject;
  }

  showModalFn(data) {
    if (
      !!data.tooltip.date &&
      data.tooltip.date !== '' &&
      !!data.tooltip.text &&
      data.tooltip.text !== ''
    ) {
      this.modalObj = data;
      this.modalObj.tooltip.class = 'txt-' + Object.keys(data.class)[0];
      this.showModal = true;
    }
  }

  hideModal() {
    this.modalObj = {};
    this.showModal = false;
  }

  callCloseModal() {
    this.modalCalendar.closeModal();
  }

  lessonDetails(link) {
    this.callCloseModal();
    this.openLink('lecons/' + link.id);
  }

  changeMonth(sens) {
    this.hasClicked.emit(sens);
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options?) {
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
