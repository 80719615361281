import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { UniversalLinkGuard } from './services/routeGuards.service';

const routes: Routes = [
  { path: '', redirectTo: 'splash', pathMatch: 'full' },

  // APPLICATION
  {
    path: 'splash',
    loadChildren: () =>
      import('../pages/splash/splash.module').then(m => m.SplashPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../pages/dashboard/dashboard.module').then(
        m => m.DashboardPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'accueil',
    loadChildren: () =>
      import('../pages/dashboard/dashboard.module').then(
        m => m.DashboardPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'code',
    loadChildren: () =>
      import('../pages/code-home/code-home.module').then(
        m => m.CodeHomePageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'conduite',
    loadChildren: () =>
      import('../pages/conduite/conduite.module').then(
        m => m.ConduiteHomePageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'convocation',
    loadChildren: () =>
      import('../pages/convocation/convocation.module').then(
        m => m.ConvocationPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'profil',
    loadChildren: () =>
      import('../pages/profil/profil.module').then(m => m.ProfilPageModule),
    runGuardsAndResolvers: 'always',
    canActivate: [UniversalLinkGuard],
  },
  {
    path: 'edit-profil',
    loadChildren: () =>
      import('../pages/edit-profil/edit-profil.module').then(
        m => m.EditProfilPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'edit-email',
    loadChildren: () =>
      import('../pages/edit-email/edit-email.module').then(
        m => m.EditEmailPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'edit-password',
    loadChildren: () =>
      import('../pages/edit-password/edit-password.module').then(
        m => m.EditPasswordPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'delete-account',
    loadChildren: () =>
      import('../pages/delete-account/delete-account.module').then(
        m => m.DeleteAccountPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // { path: 'permis-de-conduire', loadChildren: () => import('../pages/permis-de-conduire/permis-de-conduire.module').then(m => m.PermisDeConduirePageModule), runGuardsAndResolvers: 'always' },
  {
    path: 'confettis',
    loadChildren: () =>
      import('../pages/confettis/confettis.module').then(
        m => m.ConfettisComponentPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'particles',
    loadChildren: () =>
      import('../pages/particles/particles.module').then(
        m => m.ParticlesComponentPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // series
  {
    path: 'series/:type',
    loadChildren: () =>
      import('../pages/series/series.module').then(m => m.SeriesPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'series/:type/:serieId/results/:sessionId',
    loadChildren: () =>
      import('../pages/serie-results/serie-results.module').then(
        m => m.SerieResultsPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'series/:type/:serieId/last-results/:sessionId',
    loadChildren: () =>
      import('../pages/serie-results/serie-results.module').then(
        m => m.SerieResultsPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'series/:type/:serieId/:questionIndex/:sessionId',
    loadChildren: () =>
      import('../pages/serie/serie.module').then(m => m.SeriePageModule),
    runGuardsAndResolvers: 'always',
  },
  // buffer
  {
    path: 'series/:type/:serieId/buffer',
    loadChildren: () =>
      import('../pages/buffer/buffer.module').then(m => m.BufferPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'series/:type/:serieId/buffer/:questionIndex/:sessionId',
    loadChildren: () =>
      import('../pages/buffer/buffer.module').then(m => m.BufferPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'series/:type/buffer',
    loadChildren: () =>
      import('../pages/buffer/buffer.module').then(m => m.BufferPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'serie-discovery',
    loadChildren: () =>
      import('../pages/buffer/buffer.module').then(m => m.BufferPageModule),
    runGuardsAndResolvers: 'always',
  },
  // statistiques
  {
    path: 'statistiques',
    loadChildren: () =>
      import('../pages/statistiques/statistiques.module').then(
        m => m.StatistiquesPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // cours
  {
    path: 'cours',
    loadChildren: () =>
      import('../pages/cours/cours.module').then(m => m.CoursPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cours/:theme',
    loadChildren: () =>
      import('../pages/cours/cours.module').then(m => m.CoursPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cours/:theme/:subtheme',
    loadChildren: () =>
      import('../pages/cours/cours.module').then(m => m.CoursPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cours/:theme/:subtheme/:paragraph',
    loadChildren: () =>
      import('../pages/cours/cours.module').then(m => m.CoursPageModule),
    runGuardsAndResolvers: 'always',
  },
  // login
  {
    path: 'login',
    loadChildren: () =>
      import('../pages/login/login.module').then(m => m.LoginPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'login/:loginType',
    loadChildren: () =>
      import('../pages/login/login.module').then(m => m.LoginPageModule),
    runGuardsAndResolvers: 'always',
  },
  // achats
  {
    path: 'achats',
    loadChildren: () =>
      import('../pages/achats/achats.module').then(m => m.AchatsPageModule),
    runGuardsAndResolvers: 'always',
  },
  // rating
  {
    path: 'rating',
    loadChildren: () =>
      import('../pages/rating/rating.module').then(m => m.RatingPageModule),
    runGuardsAndResolvers: 'always',
  },
  // offres
  {
    path: 'offres',
    loadChildren: () =>
      import('../pages/offres/offres.module').then(m => m.OffresPageModule),
    runGuardsAndResolvers: 'always',
  },
  // tunnel-paiement
  {
    path: 'tunnel-paiement',
    loadChildren: () =>
      import('../pages/tunnel-paiement/tunnel-paiement.module').then(
        m => m.TunnelPaiementPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'tunnelpaiementv2',
    loadChildren: () =>
      import(
        '../pages/tunnel-paiement-refonte/tunnel-paiement-refonte.module'
      ).then(m => m.TunnelPaiementRefonteModule),
    runGuardsAndResolvers: 'always',
  },
  // points-de-rendez-vous
  // { path: 'points-de-rendez-vous', loadChildren: () => import('../pages/points-de-rendez-vous/points-de-rendez-vous.module').then(m => m.PointsRendezVousPageModule), runGuardsAndResolvers: 'always' },
  {
    path: 'reserver-lecon',
    loadChildren: () =>
      import('../pages/reserver-lecon/reserver-lecon.module').then(
        m => m.ReserverLeconPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'reserver-lecon/:gearbox_type',
    loadChildren: () =>
      import('../pages/reserver-lecon/reserver-lecon.module').then(
        m => m.ReserverLeconPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'points-de-rendez-vous',
    loadChildren: () =>
      import('../pages/map-teacher/map-teacher.module').then(
        m => m.MapTeacherPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'gmap',
    loadChildren: () =>
      import('../pages/gmap/gmap.module').then(m => m.GMapPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'gmap-non-desservies',
    loadChildren: () =>
      import('../pages/gmap-non-desservies/gmap-non-desservies.module').then(
        m => m.GMapNonDesserviesPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'gmap-new',
    loadChildren: () =>
      import('../pages/gmap-new/gmap-new.module').then(
        m => m.GmapNewPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // leçons
  {
    path: 'lecons',
    loadChildren: () =>
      import('../pages/lecons/lecons.module').then(m => m.LeconsPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lecons/:lessonId',
    loadChildren: () =>
      import('../pages/lecons/lecons.module').then(m => m.LeconsPageModule),
    runGuardsAndResolvers: 'always',
  },
  // première leçon
  {
    path: 'premiere-lecon',
    loadChildren: () =>
      import('../pages/premiere-lecon/premiere-lecon.module').then(
        m => m.PremiereLeconPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // gamification
  {
    path: 'reussites',
    loadChildren: () =>
      import('../pages/gamification/gamification.module').then(
        m => m.GamificationPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // notation leçons
  {
    path: 'notation',
    loadChildren: () =>
      import('../pages/notation/notation.module').then(
        m => m.NotationPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // comment ca marche
  // { path: 'comment-ca-marche', loadChildren: () => import('../pages/comment/comment.module').then(m => m.CommentPageModule), runGuardsAndResolvers: 'always' },
  // go site
  // { path: 'acceder-au-site', loadChildren: () => import('../pages/gosite/gosite.module').then(m => m.GoSitePageModule), runGuardsAndResolvers: 'always' },
  // impayes
  {
    path: 'impayes',
    loadChildren: () =>
      import('../pages/impayes/impayes.module').then(m => m.ImpayesPageModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'paiement-confirmation',
    loadChildren: () =>
      import(
        '../pages/paiement-confirmation/paiement-confirmation.module'
      ).then(m => m.PaiementConfirmationPageModule),
    runGuardsAndResolvers: 'always',
  },
  // fiches-recap
  {
    path: 'fiches-recap',
    loadChildren: () =>
      import('../pages/fiches-recap/fiches-recap.module').then(
        m => m.FichesRecapPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // documents
  {
    path: 'documents',
    loadChildren: () =>
      import('../pages/student-documents/student-documents.module').then(
        m => m.StudentDocumentsPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // terminer-mon-inscription
  {
    path: 'terminer-mon-inscription',
    loadChildren: () =>
      import(
        '../pages/terminer-mon-inscription/terminer-mon-inscription.module'
      ).then(m => m.TerminerMonInscriptionPageModule),
    runGuardsAndResolvers: 'always',
  },
  // mon-inscription-examen
  {
    path: 'mon-inscription-examen',
    loadChildren: () =>
      import(
        '../pages/mon-inscription-examen/mon-inscription-examen.module'
      ).then(m => m.MonInscriptionExamenPageModule),
    runGuardsAndResolvers: 'always',
  },
  // reservation-examen
  {
    path: 'reservation-examen-code',
    loadChildren: () =>
      import(
        '../pages/code-exam-reservation/code-exam-reservation.module'
      ).then(m => m.CodeExamReservationPagePageModule),
    runGuardsAndResolvers: 'always',
  },
  // eligibilite-cpf
  {
    path: 'eligibilite-cpf/:secureId',
    loadChildren: () =>
      import('../pages/eligibilite-cpf/eligibilite-cpf.module').then(
        m => m.EligibiliteCPFPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // parrainage
  {
    path: 'parrainage',
    loadChildren: () =>
      import('../pages/parrainage/parrainage.module').then(
        m => m.ParrainagePageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // disconnected
  {
    path: 'disconnected',
    loadChildren: () =>
      import('../pages/disconnected/disconnected.module').then(
        m => m.DisconnectedPageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // test page
  {
    path: 'testpage',
    loadChildren: () =>
      import('../pages/testpage/testpage.module').then(m => m.TestPageModule),
    runGuardsAndResolvers: 'always',
  },
  // template page
  {
    path: 'template',
    loadChildren: () =>
      import('../pages/template/template.module').then(
        m => m.TemplatePageModule
      ),
    runGuardsAndResolvers: 'always',
  },
  // sentry page
  {
    path: 'sentry',
    loadChildren: () =>
      import('../pages/sentry/sentry.module').then(m => m.SentryPageModule),
    runGuardsAndResolvers: 'always',
  },

  // ELSE 404
  {
    path: '**',
    loadChildren: () =>
      import('../pages/notfound/notfound.module').then(
        m => m.NotFoundPageModule
      ),
    canActivate: [UniversalLinkGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
