import { Question } from './../../../app/models/question';
import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { Platform } from '@ionic/angular';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'audio-comp',
  templateUrl: 'audio.component.html',
  styleUrls: ['audio.component.scss'],
})
export class AudioComponent implements OnChanges, OnDestroy {
  @Input() compOptions: any;
  @Output() audioEvent = new EventEmitter<any>();
  @Output() failEvent = new EventEmitter<any>();

  debug = false;

  audioPlayer: HTMLAudioElement = null;

  pause = false;
  mute = false;
  stop = false;

  audioErrors = [];
  playAudioFail = false;

  soundPlaying = false;

  timePerChar = 150;

  question: Question;

  timePerCharInterval = null;

  serieType;

  constructor(public platform: Platform) {}

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    if (!!this.compOptions) {
      this.compOptions = compOptions.currentValue;
      this.setSrc();
    }
  }

  async setSrc() {
    if (
      !!this.compOptions &&
      this.compOptions.mute !== null &&
      this.compOptions.mute !== undefined
    ) {
      this.mute = this.compOptions.mute;
      if (!!this.audioPlayer) {
        this.muteAudio();
      }
    }
    if (!!this.compOptions && !!this.compOptions.serieType) {
      this.serieType = this.compOptions.serieType;
    }
    if (!!this.compOptions && !!this.compOptions.question && this.mute) {
      if (this.serieType === 'examens') {
        this.question = new Question(this.compOptions.question);
        let size = this.countQuestionChar(this.question);
        this.timePerCharInterval = setInterval(() => {
          if (size === 0) {
            this.audioEvent.emit({ ended: true });
            clearInterval(this.timePerCharInterval);
          }
          if (!this.pause) {
            size--;
          }
        }, this.timePerChar);
      } else {
        this.audioEvent.emit({ ended: true });
      }
    }
    if (!!this.compOptions && !!this.compOptions.src) {
      if (this.audioPlayer === null && !this.mute) {
        await this.launchAudio(this.compOptions.src);
      }
    }
    if (
      !!this.compOptions &&
      !!this.compOptions.debug &&
      this.compOptions.debug
    ) {
      this.debug = true;
    }
    if (
      !!this.compOptions &&
      this.compOptions.pause !== null &&
      this.compOptions.pause !== undefined
    ) {
      this.pause = this.compOptions.pause;
      if (this.pause) {
        if (this.soundPlaying) {
          this.pauseAudio();
        }
      } else if (!this.pause && !!this.audioPlayer) {
        if (!this.soundPlaying && !this.mute) {
          await this.playAudio();
        }
      }
    }
    if (
      !!this.compOptions &&
      this.compOptions.stop !== null &&
      this.compOptions.stop !== undefined
    ) {
      this.stop = this.compOptions.stop;
      if (this.stop) {
        this.pauseAudio();
        this.audioPlayer = null;
      }
    }
    if (this.debug) {
      console.log('compOptions audio => ', this.compOptions);
    }
  }

  ngOnDestroy() {
    if (!!this.audioPlayer) {
      this.pauseAudio();
      this.audioPlayer = null;
    }
    if (!!this.timePerCharInterval) {
      clearInterval(this.timePerCharInterval);
    }
  }

  countQuestionChar(question: Question): number {
    let fullChar = '';
    if (!!question) {
      if (!!question.question_text) {
        fullChar = fullChar.concat(question.question_text);
      }
      if (!!question.text1) {
        fullChar = fullChar.concat(question.text1);
      }
      if (!!question.text2) {
        fullChar = fullChar.concat(question.text2);
      }
      if (!!question.answer_a) {
        fullChar = fullChar.concat(question.answer_a);
      }
      if (!!question.answer_b) {
        fullChar = fullChar.concat(question.answer_b);
      }
      if (!!question.answer_c) {
        fullChar = fullChar.concat(question.answer_b);
      }
      if (!!question.answer_d) {
        fullChar = fullChar.concat(question.answer_b);
      }
    }
    fullChar = fullChar.replace(/[^a-z\d\s]+/gi, '');
    fullChar = fullChar.replace(/\s/g, '');
    return fullChar.length;
  }

  async launchAudio(src: string) {
    const media = src;
    this.audioPlayer = new Audio(media);
    await this.playAudio();
    if (!!this.audioPlayer) {
      this.audioPlayer.onended = event => {
        this.audioEvent.emit({ ended: true });
      };
    }
  }

  async playAudio() {
    let error = false;
    try {
      await this.audioPlayer.play();
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        this.playAudioFail = true;
        error = true;
      }
      if (err.name === 'NotSupportedError') {
        this.audioFailed();
      }
      this.audioErrors.push(err);
    }
    if (!error) {
      this.playAudioFail = false;
      this.soundPlaying = true;
    }
  }

  pauseAudio() {
    this.audioPlayer.pause();
    this.soundPlaying = false;
  }

  muteAudio() {
    this.audioPlayer.muted = this.mute;
  }

  audioFailed() {
    this.failEvent.emit({ type: 'audio', status: 'failed' });
  }
}
