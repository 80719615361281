<div
  class="loader-bar-container"
  [ngClass]="{'rounded': rounded, 'white-bg': whiteBg}">
  <div class="loader-bar-outer">
    <div class="loader-bar-inner" [style.width.%]="percent"></div>
  </div>
  <!-- <div *ngIf="animated && percent === 100" class="circle-animated"> -->
  <div
    *ngIf="animated"
    class="animated-circle"
    [ngClass]="{'show-circle': percent === 100}">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="9.21212" fill="url(#paint0_linear)" />
      <circle
        cx="16"
        cy="16"
        r="15"
        stroke="url(#paint1_linear)"
        stroke-width="2" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="6.77996"
          y1="15.7907"
          x2="25.2042"
          y2="15.7907"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FB8841" />
          <stop offset="1" stop-color="#EA5B0C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-0.0137924"
          y1="15.6364"
          x2="31.9862"
          y2="15.6364"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FB8841" />
          <stop offset="1" stop-color="#EA5B0C" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</div>
