<div [ngClass]="{'evs-card-ui margin-bottom-15': calledFrom === 'stats'}">
  <div class="stats-progression">
    <div
      class="stats-selector"
      [ngClass]="{'padding-left-0': calledFrom === 'dashboard'}">
      <span
        *ngIf="!renderReady"
        class="wpi-150 border-radius-8 padding-8 margin-v-5 shimmer"></span>
      <span *ngIf="renderReady && calledFrom != 'dashboard'"
        ><b>Progression</b></span
      >
      <div
        *ngIf="renderReady"
        class="block-right"
        [ngClass]="{'wpe-70': isDesktop && calledFrom != 'dashboard', 'wpe-100': !isDesktop || calledFrom == 'dashboard', 'display-none': isGuest || (noStatsEntrainements && noStatsExamens)}">
        <custom-radio-comp
          [compOptions]="{'options': customRadioOptions, selected: 'Cdr::TrainingSession', isSmall: true}"
          (userSelection)="getCustomRadioSelection($event)"></custom-radio-comp>
        <!-- <form class="evs-form">
            <div>
              <select name="statsProgressionType" (change)="selectChanged()" [(ngModel)]="type" class="padding-0 small-text">
                <option value="Cdr::TrainingSession" [selected]="type === 'Cdr::TrainingSession'">Entraînements</option>
                <option value="Cdr::ExamSession" [selected]="type === 'Cdr::ExamSession'">Examens blancs</option>
              </select>
            </div>
          </form> -->
      </div>
    </div>
    <!-- <div *ngIf="isLoading" class="loading-container">
        <ion-spinner name="crescent"></ion-spinner>
      </div> -->
    <div
      class="component-stats-progression-container margin-top-20 margin-bottom-10">
      <div
        class="canvas-container position-relative"
        [ngClass]="{'margin-bottom-0': noData, 'dashboard-fix': calledFrom === 'dashboard'}"
        #canvasContainer>
        <div
          class="canvas-shimmer shimmer"
          [ngClass]="{'hide': renderReady}"></div>
      </div>
      <div
        class="stats-progression-controls margin-top-20 margin-bottom-10"
        *ngIf="!noData">
        <img
          src="assets/svg/arrowLeft.svg"
          alt="EnVoitureSimone"
          height="20"
          class="left-control small-clickable"
          (click)="changePage(1)"
          *ngIf="goBack" />
        <div>
          <b class="inline-block">{{averageScore}}</b>
          <span class="inline-block"
            >Bonnes réponses en moyenne sur la période</span
          >
        </div>
        <img
          src="assets/svg/arrowRight.svg"
          alt="EnVoitureSimone"
          height="20"
          class="right-control small-clickable"
          (click)="changePage(-1)"
          *ngIf="page > 1" />
        <!-- <div class="wpe-65 left-control">
            <img src="assets/svg/arrowLeft.svg" alt="EnVoitureSimone" height="20" class="float-left small-clickable" (click)="changePage(1)" *ngIf="goBack">
            <div class="padding-left-20">
              <div class="title uppercase">Date</div>
              <div class="note"><b>Du {{start_at}} au {{end_at}}</b></div>
            </div>
          </div>
          <div class="wpe-35 right-control">
            <div class="padding-right-20 text-right">
              <div class="title uppercase">Moyenne</div>
              <div class="note"><b>{{averageScore}}</b></div>
            </div>
            <img src="assets/svg/arrowRight.svg" alt="EnVoitureSimone" height="20" class="float-right small-clickable" (click)="changePage(-1)" *ngIf="page > 1">
          </div> -->
        <!-- <button class="btn" (click)="changePage(1)" *ngIf="goBack">Back</button> {{start_at}} - {{end_at}}
          <button class="btn" (click)="changePage(-1)" *ngIf="page > 1">Next</button> {{averageScore}} -->
      </div>
    </div>
    <div *ngIf="!renderReady" class="no-stats-progression">
      <div class="wpe-49 inline-block">
        <div class="wpe-65 border-radius-6 padding-6 margin-v-10 shimmer"></div>
        <div class="wpe-35 border-radius-6 padding-6 margin-v-10 shimmer"></div>
      </div>
      <div class="wpe-49 inline-block">
        <div class="wpe-65 border-radius-6 padding-6 margin-v-10 shimmer"></div>
        <div class="wpe-35 border-radius-6 padding-6 margin-v-10 shimmer"></div>
      </div>
    </div>
    <div
      *ngIf="renderReady && (isGuest || (noStatsEntrainements && type === 'Cdr::TrainingSession') || (noStatsExamens && type === 'Cdr::ExamSession'))"
      class="no-stats-progression margin-bottom-20">
      <p>
        Tes progrès seront enregistrés au fur et à mesure de tes entraînements.
      </p>
    </div>
  </div>
</div>
