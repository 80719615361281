import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';

import {
  AppInitService,
  StudentService,
  NavigationService,
  StorageService,
} from '@app/services';

import { Student, StudentPurshasesDetails } from '@app/models';

import { ModalController } from '@ionic/angular';
import { ModalMarketingComponent, ModalIframeComponent } from '@app/modals';

import { UtilsService } from '@commons/services';

import { environment } from '../../../environments/environment';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'footer-nav-component',
  templateUrl: 'footer-nav.component.html',
  styleUrls: ['footer-nav.component.scss'],
})
export class FooterNavComponent implements OnChanges {
  @Input() compOptions: any;

  titlePage: string;
  activeLink: string;

  openLinkAllowed = false;

  student: Student;
  userLifeCycle: any;

  studentPurshases: StudentPurshasesDetails;
  payments: any = [];
  gifts: any = [];
  coupons: any = [];
  paymentsGiftsCoupons: any = [];
  offers: any = [];
  isPackPermis = false;
  isPackCode = false;
  isPackCodePremium = false;
  isHourOnly = false;
  hasHours = false;
  paymentsDone = false;

  iconPath = './uikit-assets/icons/orion-svg-sprite-footer.svg#';

  navItems = null;

  initPageDone = false; // for shimmers
  fnInitPageLaunched = false; // for controllers

  gender = 'men';

  fetchOffersFailed = false;

  constructor(
    public appInitService: AppInitService,
    public studentService: StudentService,
    public navigationService: NavigationService,
    private modalController: ModalController,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.appInitService.onStepChange().subscribe(async state => {
      if (state?.init >= 3 && state?.stepDone && !this.fnInitPageLaunched) {
        this.fnInitPageLaunched = true;
        this.initPage();
      }
    });
    this.setGlobals();
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
      this.setGlobals();
    }
  }

  async initPage() {
    this.student = this.studentService.student;
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest

    // this.gender = (this.student.gender === 'f') ? 'women' : this.gender;
    this.getPayments();

    this.setNavItems();

    this.initPageDone = true;
    this.openLinkAllowed = true;
  }

  setGlobals() {
    // SERIES
    this.titlePage =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.page)
        ? this.compOptions.page
        : 'En Voiture Simone';
    this.activeLink =
      this.utils.isDefined(this.compOptions) &&
      this.utils.isDefined(this.compOptions.active)
        ? this.compOptions.active
        : 'Dashboard';
    console.log('activeLink', this.activeLink);
  }

  setNavItems() {
    if (this.userLifeCycle.isUserGuest) {
      this.navItems = [
        {
          title: 'Code',
          link: 'code',
          icon: 'code-off',
          iconActive: 'code-on',
        },
        {
          title: 'Permis',
          link: 'conduite',
          icon: 'driving-off',
          iconActive: 'driving-on',
        },
        {
          title: 'Offres',
          link: 'offres',
          icon: 'offers-off',
          iconActive: 'offers-on',
        },
        {
          title: 'Profil',
          link: 'profil',
          icon: 'profile-off',
          iconActive: 'profile-on',
        },
      ];
    } else if (this.userLifeCycle.isUserRegistered) {
      this.navItems = [
        {
          title: 'Code',
          link: 'code',
          icon: 'code-off',
          iconActive: 'code-on',
        },
        {
          title: 'Permis',
          link: 'conduite',
          icon: 'driving-off',
          iconActive: 'driving-on',
        },
        {
          title: 'Offres',
          link: 'offres',
          icon: 'offers-off',
          iconActive: 'offers-on',
        },
        {
          title: 'Profil',
          link: 'profil',
          icon: 'profile-off',
          iconActive: 'profile-on',
        },
      ];
    } else {
      if (
        this.isPackCode ||
        this.isPackCodePremium ||
        this.isPackPermis ||
        this.hasHours
      ) {
        this.navItems = [
          {
            title: 'Accueil',
            link: 'dashboard',
            icon: 'home-off',
            iconActive: 'home-on',
          },
          {
            title: 'Code',
            link: 'code',
            icon: 'code-off',
            iconActive: 'code-on',
          },
          {
            title: 'Conduite',
            link: 'conduite',
            icon: 'driving-off',
            iconActive: 'driving-on',
          },
          {
            title: 'Offres',
            link: 'offres',
            icon: 'offers-off',
            iconActive: 'offers-on',
          },
          {
            title: 'Profil',
            link: 'profil',
            icon: 'profile-off',
            iconActive: 'profile-on',
          },
        ];
      } else {
        this.navItems = [
          {
            title: 'Code',
            link: 'code',
            icon: 'code-off',
            iconActive: 'code-on',
          },
          {
            title: 'Permis',
            link: 'conduite',
            icon: 'driving-off',
            iconActive: 'driving-on',
          },
          {
            title: 'Offres',
            link: 'offres',
            icon: 'offers-off',
            iconActive: 'offers-on',
          },
          {
            title: 'Profil',
            link: 'profil',
            icon: 'profile-off',
            iconActive: 'profile-on',
          },
        ];
      }
    }
  }

  getPayments() {
    this.studentPurshases = this.studentService.getStudentPurshases();

    const resGetOffers = this.studentPurshases.offers;
    if (
      !!resGetOffers &&
      !!resGetOffers.errorCode &&
      resGetOffers.errorCode === 'E301'
    ) {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
    this.gifts = this.studentPurshases.gifts;
    this.studentPurshases.linkOfferTopayment();
    this.studentPurshases.removeCouponsIfPresentInPayments();
    this.payments = this.studentPurshases.payments;
    this.coupons = this.studentPurshases.coupons;

    this.hasHours = this.studentPurshases.hasHours();
    this.isHourOnly = this.studentPurshases.isHourOnly();
    this.isPackCode = this.studentPurshases.isPackCode();
    this.isPackCodePremium = this.studentPurshases.isPackCodePremium();
    this.isPackPermis = this.studentPurshases.isPackPermis();

    const tmp = this.gifts.concat(this.coupons);
    this.paymentsGiftsCoupons = tmp.concat(this.payments);

    this.paymentsDone = true;
  }

  async openModalPack(
    bannerInfo?: any,
    type?: string,
    noClose = false,
    customClass = ''
  ) {
    // evs-global.scss : small-modal | medium-modal
    if (!type) {
      type = 'marketingRegular';
    }
    let props;
    if (!!bannerInfo) {
      props = {
        type: type,
        bannerInfo: {
          item_id: bannerInfo.itemId,
          item_promo_type: bannerInfo.itemPromoType,
          item_location_id: bannerInfo.itemLocationId,
          item_location_format: bannerInfo.itemLocationFormat,
          item_promo_content: bannerInfo.itemPromoContent,
        },
      };
    } else {
      props = {
        type: type,
        bannerInfo: null,
      };
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        cssClass: customClass,
        backdropDismiss: false,
        componentProps: props,
      });
    } else {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        componentProps: props,
        cssClass: customClass,
      });
    }
    return await modal.present();
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          type: type,
          url: url,
        },
      });
    }
    return await modal.present();
  }

  closeModals() {
    const modals = document.getElementsByTagName('ion-modal');
    while (modals.length > 0) {
      modals[0].parentNode.removeChild(modals[0]);
    }
  }

  async openLink(link: any, options?: any) {
    if (!options) {
      options = { forceRoot: true };
    } else {
      options.forceRoot = true;
    }
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
