/* tslint:disable:member-ordering */
import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
} from '@angular/core';

@Directive({
  selector: '[appZoom]',
})
export class ZoomDirective {
  @Input('appZoom') dirOptions: any;
  @Input() content: any;

  parent: any;

  posX = null;
  posY = null;
  diffX = null;
  diffY = null;

  diff = 0;

  scaling = false;
  distHistory = [];

  hasImg = false;

  bgContent: any = null;
  clonedContent: any = null;

  originalWidth = null;
  originalHeight = null;
  ratio = 1;

  booster = 1.8;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.parent = this.el.nativeElement;
  }

  // ngAfterViewInit() {
  //   if (!!this.content) {
  //     this.content.ionScrollEnd.subscribe(()=> {
  //       this.positionTooltip();
  //     });
  //   }
  // }

  //  MOUSE EVENTS
  // @HostListener('mousedown', ['$event']) onMouseDown(e) {
  //   // reset
  //   this.scaling = false;
  //   this.distHistory = [];
  //   this.posX = null;
  //   this.posY = null;
  //   this.diffX = 0;
  //   this.diffY = 0;
  //   this.diff = 0;
  //   this.hasImg = false;
  //   this.ratio = 1;
  //   // remove background
  //   if (this.bgContent !== null) {
  //     document.body.removeChild(this.bgContent);
  //     this.bgContent = null;
  //   }
  //   // remove clonedContent
  //   if (this.clonedContent !== null) {
  //     document.body.removeChild(this.clonedContent);
  //     this.clonedContent = null;
  //   }

  //   this.scaling = true;

  //   this.posX = e.clientX;
  //   this.posY = e.clientY;

  //   this.pinchStart(e);
  // }

  // @HostListener('document:mouseup', ['$event']) onMouseUp(e) {
  //   if (this.scaling) {
  //     this.pinchEnd(e);
  //   }
  // }

  // TOUCH EVENTS
  @HostListener('touchstart', ['$event']) onTouchStart(e) {
    // reset
    if (e.touches.length === 2) {
      this.scaling = false;
      this.distHistory = [];
      this.posX = null;
      this.posY = null;
      this.diffX = 0;
      this.diffY = 0;
      this.hasImg = false;
      this.ratio = 1;
      // remove background
      if (this.bgContent !== null) {
        document.body.removeChild(this.bgContent);
        this.bgContent = null;
      }
      // remove clonedContent
      if (this.clonedContent !== null) {
        document.body.removeChild(this.clonedContent);
        this.clonedContent = null;
      }

      this.scaling = true;

      const posX1 = e.touches[0].clientX;
      const posY1 = e.touches[0].clientY;
      const posX2 = e.touches[1].clientX;
      const posY2 = e.touches[1].clientY;
      this.posX = (posX1 + posX2) / 2;
      this.posY = (posY1 + posY2) / 2;

      this.pinchStart(e);
    }
  }

  @HostListener('touchmove', ['$event']) onTouchMove(e) {
    if (this.scaling) {
      // prevent default
      event.preventDefault ? event.preventDefault() : (event.returnValue = !1);
      this.pinchMove(e);
    }
  }
  @HostListener('document:touchend', ['$event']) onTouchEnd(e) {
    if (this.scaling) {
      this.pinchEnd(e);
    }
  }
  @HostListener('window:resize', ['$event']) onResize(e) {
    // console.log('resize appZoom');
    if (this.bgContent !== null) {
      document.body.removeChild(this.bgContent);
      this.bgContent = null;
    }
    if (this.clonedContent !== null) {
      document.body.removeChild(this.clonedContent);
      this.clonedContent = null;
    }
  }

  createClone() {
    if (this.clonedContent === null) {
      // clone content
      this.clonedContent = this.parent.cloneNode(true);
      document.body.appendChild(this.clonedContent);
      // check for images
      const children = this.clonedContent.childNodes;
      let str = '';
      for (const child of children) {
        // if images
        str += child.nodeName.toLowerCase() + ' - ';
        // if (child.nodeName.toLowerCase() === 'img' || child.nodeName.toLowerCase() === 'video' || child.nodeName.toLowerCase() === 'img-comp' || child.nodeName.toLowerCase() === 'video-comp'  || child.nodeName.toLowerCase() === 'gif-comp') {
        if (
          child.nodeName.toLowerCase() === 'img' ||
          child.nodeName.toLowerCase() === 'video'
        ) {
          this.hasImg = true;
          // remove draggable
          child.draggable = false;
          // width 100%
          this.renderer.setStyle(child, 'width', '100%');
        }
      }
      this.originalWidth = this.parent.offsetWidth;
      this.originalHeight = this.parent.offsetHeight;
      this.ratio = this.originalHeight / this.originalWidth;
    }
    // create background
    if (this.bgContent === null) {
      this.bgContent = document.createElement('div');
      this.bgContent.className = 'evs_zoom_bg';
      document.body.appendChild(this.bgContent);
    }

    // set clone absolute - 1072
    this.renderer.setStyle(this.clonedContent, 'position', 'absolute');
    this.renderer.setStyle(this.clonedContent, 'z-index', '1072');
    this.renderer.setStyle(this.clonedContent, 'overflow', 'hidden');

    this.renderer.setStyle(
      this.clonedContent,
      'transform-origin',
      `${this.posX}px ${this.posY}px`
    );

    // position clonedContent
    this.positionClone();
  }

  positionClone(skipDiff?) {
    let diff =
      this.distHistory[this.distHistory.length - 1] - this.distHistory[0];

    if (isNaN(diff as any)) {
      diff = 0;
    }
    // return to original position
    if (skipDiff !== null && skipDiff) {
      diff = 0;
      this.diffX = 0;
      this.diffY = 0;
    }

    if (!!this.clonedContent) {
      this.diff++;
      this.renderer.setStyle(
        this.clonedContent,
        'height',
        this.parent.offsetHeight + 'px'
      );
      this.renderer.setStyle(
        this.clonedContent,
        'left',
        this.getPosition(this.parent).left + diff / 2 + this.diffX + 'px'
      );
      // // console.log('this.parent', this.parent);
      // // console.log('this.getPosition(this.parent).top', this.getPosition(this.parent).top);
      // // console.log('diff', diff);
      // // console.log('this.booster', this.booster);
      // // console.log('diffY', this.diffY);
      // console.log((this.getPosition(this.parent).top - ((diff / 2) * this.booster) + (this.diffY * this.booster)) + 'px');
      this.renderer.setStyle(
        this.clonedContent,
        'top',
        this.getPosition(this.parent).top + diff / 2 + this.diffY + 'px'
      );
    }
    const scalePercent = (100 * diff) / window.innerHeight;
    let scaleRes = (7 * scalePercent) / 100;
    if (scaleRes < 0) {
      scaleRes = 0;
    }
    this.renderer.setStyle(
      this.clonedContent,
      'transform',
      `scale(${1 + scaleRes})`
    );

    if (!!this.bgContent) {
      // show bg
      this.renderer.setStyle(this.bgContent, 'opacity', '1');
    }
  }

  pinchStart(e) {
    if (!!this.clonedContent) {
      this.renderer.removeClass(this.clonedContent, 'evs_zoom_animated');
    }

    this.addInHistory(e);
    this.createClone();
  }

  pinchMove(e) {
    this.addInHistory(e);
    this.positionClone();
  }

  pinchEnd(e) {
    this.addInHistory(e);
    this.positionClone();

    // add transition to smooth back to original
    if (!!this.clonedContent) {
      this.renderer.addClass(this.clonedContent, 'evs_zoom_animated');
    }

    if (this.bgContent !== null) {
      // hide bg
      this.renderer.setStyle(this.bgContent, 'opacity', '0');
    }

    this.positionClone(true);
    this.scaling = false;
    // then wait until animation finished to removediv
    setTimeout(() => {
      if (this.bgContent !== null) {
        document.body.removeChild(this.bgContent);
        this.bgContent = null;
      }
      if (this.clonedContent !== null) {
        document.body.removeChild(this.clonedContent);
        this.clonedContent = null;
      }
    }, 300);
  }

  addInHistory(e) {
    if (!!e.touches && !!e.touches.length && e.touches.length === 2) {
      const dist = Math.hypot(
        e.touches[0].clientX - e.touches[1].clientX,
        e.touches[0].clientY - e.touches[1].clientY
      );

      const posX1 = e.touches[0].clientX;
      const posY1 = e.touches[0].clientY;
      const posX2 = e.touches[1].clientX;
      const posY2 = e.touches[1].clientY;
      const moyX = (posX1 + posX2) / 2;
      const moyY = (posY1 + posY2) / 2;

      this.diffX = moyX - this.posX;
      this.diffY = moyY - this.posY;

      this.distHistory.push(dist);
    } else {
      const dist = Math.hypot(e.clientX - e.clientX, e.clientY - e.clientY);

      const posX1 = e.clientX;
      const posY1 = e.clientY;
      const moyX = posX1;
      const moyY = posY1;

      this.diffX = moyX - this.posX;
      this.diffY = moyY - this.posY;

      this.distHistory.push(dist);
    }
  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }
}
