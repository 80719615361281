import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { PaymentModel } from '../models/payment';

import { environment } from '../../environments/environment';
import { OfferTypes } from '../models/offer';
import { Student } from '../models/student';
import { UserErrorHandlerService } from '../services/user-error-handler.service';
import { StorageService } from './storage.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init2', initializer: 'onInit' })
@EasyDebugDecorator
export class PaymentsService {
  private _creditsProvisionsHistory = [];
  private _payments: Array<PaymentModel> = [];

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private networkService: NetworkStatusService,
    private userErrorHandlerService: UserErrorHandlerService
  ) {}

  async onInit() {
    // console.log('Payments done');
    return 'Payments done';
  }

  fetchPaymentsByUserId(accountId: string): Observable<PaymentModel[]> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId) {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/payments`;

      // TODO: Handle offline / timeout
      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(this.storageKey(accountId), data);
          this._payments = this.buildPaymentsFromRawData(data);
          return of(this.payments);
        }),
        catchError(async err => {
          this.userErrorHandlerService.addError({
            criticity: 1,
            service: 'payments',
            platform: 'both',
            data: err,
            errorCode: 'psfpbuid',
          });
          return [];
        })
      );
    }
  }

  studentPaymentsByOfferType(offerType: OfferTypes): Array<PaymentModel> {
    return this._payments.filter((pay: PaymentModel) => {
      pay.offer_type === offerType;
    });
  }

  async loadStudentPayments(student: Student) {
    if (student.isGuest()) {
      this._payments = [];
    } else {
      // TODO: Handle offline with localstorage
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadFromStorage(student);
      } else {
        this.fetchPaymentsByUserId(student.remoteId).subscribe(res => {
          this._payments = res;
        });
      }
    }
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get payments(): Array<PaymentModel> {
    return this._payments;
  }

  private;

  async loadFromStorage(student: Student) {
    this.storageService.get(this.storageKey(student.remoteId)).then(res => {
      this._payments = !!res ? this.buildPaymentsFromRawData(res) : [];
    });
  }

  storageKey(accountId: String): string {
    return `${accountId}-payments`;
  }

  /// Serialize data ///
  buildPaymentFromRawData(data: any): PaymentModel {
    return new PaymentModel({
      id: data.id,
      type: data.type,
      offer_id: data.attributes.offer_id,
      offer_associated: null,
      order_reference: data.attributes.order_reference,
      amount: data.attributes.amount,
      created_at: data.attributes.created_at,
      updated_at: data.attributes.updated_at,
      card_last4: data.attributes.card_last4,
      card_exp: data.attributes.card_exp,
      quantity: data.attributes.quantity,
      payment_plan_user: data.attributes.payment_plan_user,
      payment_plan_user_id: data.attributes.payment_plan_user_id,
      additional_infos: data.attributes.additional_infos,
      is_offer_trial: data.attributes.is_offer_trial,
      is_trial_completed: data.attributes.is_trial_completed,
      plan_position: data.attributes.plan_position,
      invoice_number: data.attributes.invoice_number,
      offer_name: data.attributes.offer_name,
      offer_type: data.attributes.offer_type,
      refunds: data.attributes.refunds, // can be an object
      coupons: data.attributes.coupons,
    });
  }

  buildPaymentsFromRawData(data: any): Array<PaymentModel> {
    const arr: Array<PaymentModel> = [];
    if (!!data && !!data.data && data.data.length > 0) {
      for (const elt of data.data) {
        arr.push(this.buildPaymentFromRawData(elt));
      }
    }
    return arr;
  }

  fetchCreditsProvisionsHistory(accountId: string): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId) {
      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/credits_provisions_history`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(
            this.creditsProvisionsHistoryStorageKey(accountId),
            data
          );
          this._creditsProvisionsHistory = data['data'];
          return of(this._creditsProvisionsHistory);
        }),
        catchError(async err => {
          this.userErrorHandlerService.addError({
            criticity: 1,
            service: 'payments',
            platform: 'both',
            data: err,
            errorCode: 'gsfgbuid',
          });
          return [];
        })
      );
    } else {
      return of([]);
    }
  }

  async loadCreditsProvisionsHistory(student: Student) {
    if (student.isGuest()) {
      this._creditsProvisionsHistory = [];
    } else {
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadCreditsProvisionsHistoryFromStorage(student);
      } else {
        this.fetchCreditsProvisionsHistory(student.remoteId).subscribe(res => {
          this._creditsProvisionsHistory = res;
        });
      }
    }
  }

  async loadCreditsProvisionsHistoryFromStorage(student: Student) {
    this.storageService
      .get(this.creditsProvisionsHistoryStorageKey(student.remoteId))
      .then(res => {
        this._creditsProvisionsHistory = !!res ? res : [];
      });
  }

  get creditsProvisionsHistory() {
    return this._creditsProvisionsHistory;
  }

  creditsProvisionsHistoryStorageKey(accountId: String): string {
    return `${accountId}-creditsProvisionsHistory`;
  }
}
